import React, { Component } from 'react';
import { withFirebase } from '../../Firebase';
import styled from 'styled-components';
import AssignmentViewTeacher from './AssignmentViewTeacher';
import * as ROUTES from '../../../constants/routes';

import { Redirect, Route, Switch } from 'react-router-dom';
import AssignmentViewStudent from './AssignmentViewStudent';
import Loading from '../../UI-Library/Misc/Loading';
import TeacherAssignmentRoute from './TeacherAssignmentRoute';
import StudentAssignmentRoute from './StudentAssignmentRoute';

const AssignmentViewWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

class AssignmentView extends Component {
  constructor(props) {
    super(props);
    this.state = { ready: false, assignmentData: null };
    this.editDetails = this.editDetails.bind(this);
  }

  async componentDidMount() {
    try {
      const params = this.props.match.params;
      const assignmentId = params.assignmentId;
      const classId = params.classId;
      const results = await Promise.all([
        this.props.firebase.getAssignment(assignmentId),
        this.props.firebase.getClass(classId),
      ]);
      const classData = results[1];
      const assignmentData = results[0];
      this.setState({
        ready: true,
        assignmentData: assignmentData,
        classData: classData,
      });
    } catch (err) {
      this.setState({ error: err });
    }
  }

  async editDetails(name, desc, release, due) {
    try {
      const assignmentData = this.state.assignmentData;
      assignmentData.name = name;
      assignmentData.description = desc;
      assignmentData.releaseDate = release;
      assignmentData.dueDate = due;
      await this.props.firebase.editAssignment(
        this.state.assignmentData.ref,
        name,
        desc,
        release,
        due
      );
      this.props.firebase.analytics.logEvent('assignment_edit');
      this.setState({ assignmentData: assignmentData });
    } catch (err) {
      this.setState({ error: err });
    }
  }

  render() {
    if (this.state.error) throw this.state.error;
    if (!this.state.ready) return <Loading />;
    const params = this.props.match.params;
    const assignmentId = params.assignmentId;
    const classId = params.classId;
    const classAdmin = this.state.classData.admin;
    const uid = this.props.firebase.auth.currentUser.uid;
    const isAdmin = classAdmin === uid;
    const redirectLink = isAdmin
      ? `/internal/classView/${classId}/assignments/${assignmentId}/teacher/summary`
      : `/internal/classView/${classId}/assignments/${assignmentId}/student/null`;
    return (
      <AssignmentViewWrapper>
        <Switch>
          <TeacherAssignmentRoute
            path={ROUTES.ASSIGNMENTTEACHER}
            redirectPath={redirectLink}
            isAdmin={isAdmin}
            classData={this.state.classData}
            assignmentData={this.state.assignmentData}
            assignmentId={assignmentId}
            classId={classId}
            editDetails={this.editDetails}
            component={AssignmentViewTeacher}
          />
          <StudentAssignmentRoute
            path={ROUTES.ASSIGNMENTSTUDENT}
            redirectPath={redirectLink}
            isAdmin={isAdmin}
            classData={this.state.classData}
            assignmentData={this.state.assignmentData}
            assignmentId={assignmentId}
            classId={classId}
            component={AssignmentViewStudent}
          />
          <Route render={() => <Redirect to={redirectLink} />} />
        </Switch>
      </AssignmentViewWrapper>
    );
  }
}

export default withFirebase(AssignmentView);
