import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ColorContainer = styled.div``;

const ColorCircle = styled.p`
  border-radius: 50%;
  border: 2px solid transparent;
  width: 32px;
  height: 32px;
  margin: 3px;
  display: inline-block;
  &:hover {
    border: 2px solid var(--rsPrimary);
    cursor: pointer;
  }
`;

const SelectedColorCircle = styled.p`
  border: 2px solid var(--rsPrimary);
  border-radius: 50%;
  width: 32px;
  height: 32px;
  margin: 3px;
  display: inline-block;
`;

/**
 * The component to choose from a selection of colors and highlight the
 * selected one.
 */
class ColorPicker extends Component {
  render() {
    return (
      <ColorContainer>
        {this.props.hexList.map((row, i) => {
          return (
            <div key={i}>
              {row.map((hexCode, j) => {
                return this.props.selected === hexCode ? (
                  <SelectedColorCircle
                    key={hexCode}
                    style={{ background: hexCode }}
                    onClick={() => this.props.onSelect(hexCode)}
                  />
                ) : (
                  <ColorCircle
                    key={hexCode}
                    style={{ background: hexCode }}
                    onClick={() => this.props.onSelect(hexCode)}
                  />
                );
              })}
            </div>
          );
        })}
      </ColorContainer>
    );
  }
}

ColorPicker.propTypes = {
  /**
   * The nested list of hex codes to be filled in.
   */
  hexList: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  /**
   * The function that is fired when a color is selected. The most common use
   * case is to have this function change the passed 'selected' prop in the
   * parent component.
   */
  onSelect: PropTypes.func,
  /**
   * The currently selected color.
   */
  selected: PropTypes.string,
};

export default ColorPicker;
