import React from 'react';
import styled from 'styled-components';
import { device } from '../../../constants/breakpoints';

const MainWrapper = styled.div``;

const Item = styled.button`
  font-weight: ${(props) => (props.active ? 'bold' : '300')};
  font-size: 20px;
  color: black;
  margin-right: 2vw;
  outline: none;
  background: none;
  border: none;
  border-bottom: ${(props) =>
    props.active ? '3px solid var(--rsBlue)' : '3px solid transparent'};
  @media ${device.laptopL} {
    font-size: 16px;
  }
  @media ${device.tablet} {
    font-size: 12px;
  }
  @media ${device.mobileL} {
    font-size: 10px;
  }
  @media ${device.mobileS} {
    font-size: 8px;
  }

  &:hover {
    border-bottom: 3px solid rgba(var(--rsBlueRGB), 0.25);
    font-weight: bold;
  }
`;

class TeamSelect extends React.Component {
  render() {
    return (
      <MainWrapper>
        {this.props.keys.map((option) => (
          <Item
            key={'LANDINGSELECT_' + option}
            active={this.props.selected === option}
            onClick={() => this.props.onSelect(option)}
          >
            {option}
          </Item>
        ))}
      </MainWrapper>
    );
  }
}

export default TeamSelect;
