import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import * as ROUTES from '../../../../constants/routes';
import styled from 'styled-components';
import { withFirebase } from '../../../Firebase';
import { Collapse } from 'react-bootstrap';
import Subjects from '../../../UI-Library/Icons/Subjects';

const UnitLink = {
  verticalAlign: 'middle',
  color: 'var(--rsSecondary)',
  textDecoration: 'none',
  marginLeft: '26px',
  width: '80%',
  display: 'block',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  marginTop: '5px',
  marginBottom: '0px',
};

const SubjectText = styled.p`
  vertical-align: middle;
  display: inline-block;
  font-weight: bold;
  font-size: 14px;
  color: var(--rsTertiary);
  margin-bottom: 0;
  margin-left: 10px;
`;

const Caret = styled.img`
  margin-top: -4px;
  transform: ${(props) => (props.open ? 'rotate(0deg)' : 'rotate(-90deg)')};
`;

const MainHeader = styled.p`
  vertical-align: middle;
  display: inline-block;
  font-weight: bold;
  margin-bottom: 0;
  color: var(--rsTertiary);
  font-size: 14px;
  transition: color 0.3s ease;
  &:hover {
    color: var(--rsPrimary);
  }
  margin-top: 0;
`;

const SubjectItemWrapper = styled.div`
  margin-bottom: 5px;
`;

const Toggler = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const MainWrapper = styled.div`
  margin-bottom: 10px;
`;

export class SubjectItem extends Component {
  render() {
    const unitLinks = this.props.units.map((item, key) => (
      <NavLink
        key={key}
        to={ROUTES.UNITVIEW + '/' + item.id}
        style={UnitLink}
        className='nav-item vertNav-link'
      >
        {item.title}
      </NavLink>
    ));
    const name =
      this.props.name === 'English Language Arts' ? 'ELA' : this.props.name;

    return (
      <SubjectItemWrapper>
        <div
          style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}
        >
          <Subjects size='18px' subject={this.props.name} />
          <SubjectText>{name.toUpperCase()}</SubjectText>
        </div>

        <ul className='nav flex-column'>{unitLinks}</ul>
      </SubjectItemWrapper>
    );
  }
}

/**
 * LibraryHeader is simply the component for the header of the library section
 * in the vertical nav bar.
 */
export class LibraryHeader extends Component {
  render() {
    return (
      <Toggler
        className='noPadMar ghost'
        onClick={this.props.toggle}
        aria-controls='library'
        aria-expanded={this.props.open}
      >
        <MainHeader>YOUR LIBRARY</MainHeader>
        <Caret
          src='/images/icons/utility/caretgrey.png'
          open={this.props.open}
        ></Caret>
      </Toggler>
    );
  }
}

/**
 * Library is the component for the "Library" <ul> in VerticalNav. It contains
 * a list of the user's bookmarked topics by subject.
 */
export class Library extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
    };
    this.toggle = this.toggle.bind(this);
    this.renderLinks = this.renderLinks.bind(this);
  }

  toggle() {
    this.setState({ open: !this.state.open });
  }

  renderLinks() {
    return Object.keys(this.props.library).map((item, key) => (
      <SubjectItem key={key} name={item} units={this.props.library[item]} />
    ));
  }

  render() {
    return (
      <MainWrapper>
        <LibraryHeader open={this.state.open} toggle={this.toggle} />
        {this.props.library != null && (
          <Collapse in={this.state.open} id='library'>
            <div>{this.renderLinks()}</div>
          </Collapse>
        )}
      </MainWrapper>
    );
  }
}

export default withFirebase(Library);
