import React, { Component } from 'react';
import styled from 'styled-components';

import RsButton from '../../ButtonsOrLinks/RsButton';
import RsModal from '../../Modals/RsModal';

const Body = styled.p`
  margin-bottom: 0;
`;

class DeleteConfirmModal extends Component {
  render() {
    return (
      <RsModal
        show={this.props.show}
        toggle={this.props.toggle}
        title={this.props.title || 'Delete?'}
      >
        <RsModal.Body>
          <Body>This action cannot be undone.</Body>
        </RsModal.Body>
        <RsModal.Footer jContent='space-between'>
          <RsButton onClick={this.props.toggle} secondary={true}>
            No, go back!
          </RsButton>
          <RsButton
            onClick={() => {
              this.props.delete();
              this.props.toggle();
            }}
          >
            Delete
          </RsButton>
        </RsModal.Footer>
      </RsModal>
    );
  }
}

export default DeleteConfirmModal;
