import app from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/analytics';

import {
  doSignOut,
  doSignInGoogle,
  getUserToken,
  doSignInEmail,
  createUserWithEmail,
  resendVerification,
  authStateChangedListener,
  emailSubscriberCheck,
  codeSubscriberCheck,
  resetPassword,
} from './auth.js';

import { getBrowse, cloudSearchAlgolia } from './browse.js';

import {
  getUnitData,
  getTopicsInUnit,
  getSubtopicsInTopic,
  getUnitImageURL,
  getTopicImageURL,
} from './unitview.js';

import {
  getQuestionList,
  updateCustomQuestions,
  createCustomQuestions,
  deleteCustomQuestions,
  setCustomQuestionImg,
  getQuestionSetsForSong,
} from './review.js';

import {
  addToLibrary,
  inLibrary,
  removeFromLibrary,
  getLibrary,
  sortLibrary,
} from './library.js';

import {
  deleteCustomAnnotations,
  updateCustomAnnotations,
  createCustomAnnotations,
  getAnnotationDict,
  getAnnotationSetsForSong,
} from './breakdown.js';

import {
  getMp3,
  getSong,
  getJson,
  getSongCover,
  incrementSongCount,
  initUserSongDoc,
} from './songview.js';

import { completeFeedback } from './karaoke.js';

import {
  getKeywordSetsForSong,
  deleteCustomKeywords,
  createCustomKeywords,
  updateCustomKeywords,
  getKeywordDict,
} from './blanks.js';

import { getCustomSongs } from './contentmanager.js';

import { cloudSearchUnsplash, downloadUnsplash } from './unsplash';

import {
  getAuthoredPlaylists,
  getFollowedPlaylists,
  getAuthoredPlaylistNameArray,
  getPlaylist,
  getPlaylistSongs,
  getPlaylistSongsById,
  addPlaylist,
  removePlaylist,
  addPlaylistSong,
  removePlaylistSong,
  editPlaylist,
  updatePlaylistSongsOrder,
  playlistCompareByCreated,
  playlistsOfPlaylistsFollowed,
} from './playlists.js';

import {
  doSignUpContact,
  createInquiry,
  completeOnboarding,
  setStudentSurvey,
  setEducatorSurvey,
  completeFirstBreakdown,
  completeFirstBlanks,
  completeFirstReview,
  completeFirstKaraoke,
  setFeedback,
  getPilotAgreement,
  getTutorialGifs,
  generatePin,
} from './misc.js';

import {
  getUserDoc,
  getUserSchool,
  getName,
  getFirstName,
  editUserInfo,
  updateLastAccess,
  getUserProfile,
} from './user.js';

import { getRecentSongs, getNewReleases } from './recents.js';

import {
  getMembershipClasses,
  getClass,
  joinClassAsStudent,
  createClass,
  approveDenyStudentToJoinClass,
  removeStudentFromClass,
  addContentToClass,
  removeContentFromClass,
  getClassSharedContent,
  getAdminClasses,
  getPendingClasses,
  deleteClass,
  changeClassColor,
  editClassDetails,
} from './classes.js';

import {
  getSetsForAssignment,
  getAllClassAssignments,
  getAssignment,
  getSubmissionsForStudent,
  getSubmissionsForAssignment,
  createAssignment,
  saveSubmission,
  editAssignment,
  getPastAssignmentsFollowed,
  deleteAssignment,
} from './assignments.js';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

class Firebase {
  constructor() {
    app.initializeApp(config);
    this.firebase = app;
    this.analytics = app.analytics();

    this.db = app.firestore();
    this.auth = app.auth();
    this.storage = app.storage();
    this.functions = app.functions();

    this.NotFoundError = {
      name: 'Document not found',
      message: 'Asset not found.',
    };
    //New errors to the pop up
    this.EnrolledStudentError = {
      name: 'Already Enrolled',
    };
    this.PendingStudentError = {
      name: 'Already Requested',
    };

    this.PermissionDenied = {
      name: 'Permission Denied',
    };

    //this.doSignIn = this.doSignIn.bind(this);
  }
}

// AUTH ASSIGNMENT
Firebase.prototype.doSignOut = doSignOut;
Firebase.prototype.doSignInGoogle = doSignInGoogle;
Firebase.prototype.getUserToken = getUserToken;
Firebase.prototype.doSignInEmail = doSignInEmail;
Firebase.prototype.createUserWithEmail = createUserWithEmail;
Firebase.prototype.authStateChangedListener = authStateChangedListener;
Firebase.prototype.emailSubscriberCheck = emailSubscriberCheck;
Firebase.prototype.codeSubscriberCheck = codeSubscriberCheck;
Firebase.prototype.resendVerification = resendVerification;
Firebase.prototype.resetPassword = resetPassword;

// BROWSE
Firebase.prototype.getBrowse = getBrowse;
Firebase.prototype.cloudSearchAlgolia = cloudSearchAlgolia;

// UNITVIEW
Firebase.prototype.getUnitData = getUnitData;
Firebase.prototype.getTopicsInUnit = getTopicsInUnit;
Firebase.prototype.getUnitImageURL = getUnitImageURL;
Firebase.prototype.getTopicImageURL = getTopicImageURL;
Firebase.prototype.getSubtopicsInTopic = getSubtopicsInTopic;

// REVIEW
Firebase.prototype.getQuestionList = getQuestionList;
Firebase.prototype.updateCustomQuestions = updateCustomQuestions;
Firebase.prototype.createCustomQuestions = createCustomQuestions;
Firebase.prototype.deleteCustomQuestions = deleteCustomQuestions;
Firebase.prototype.setCustomQuestionImg = setCustomQuestionImg;
Firebase.prototype.getQuestionSetsForSong = getQuestionSetsForSong;

// LIBRARY
Firebase.prototype.addToLibrary = addToLibrary;
Firebase.prototype.inLibrary = inLibrary;
Firebase.prototype.removeFromLibrary = removeFromLibrary;
Firebase.prototype.getLibrary = getLibrary;
Firebase.prototype.sortLibrary = sortLibrary;

// BREAKDOWN
Firebase.prototype.deleteCustomAnnotations = deleteCustomAnnotations;
Firebase.prototype.updateCustomAnnotations = updateCustomAnnotations;
Firebase.prototype.createCustomAnnotations = createCustomAnnotations;
Firebase.prototype.getAnnotationDict = getAnnotationDict;
Firebase.prototype.getAnnotationSetsForSong = getAnnotationSetsForSong;

// BLANKS
Firebase.prototype.getKeywordSetsForSong = getKeywordSetsForSong;
Firebase.prototype.deleteCustomKeywords = deleteCustomKeywords;
Firebase.prototype.createCustomKeywords = createCustomKeywords;
Firebase.prototype.updateCustomKeywords = updateCustomKeywords;
Firebase.prototype.getKeywordDict = getKeywordDict;

// CUSTOM CONTENT
Firebase.prototype.getCustomSongs = getCustomSongs;

// SONGVIEW
Firebase.prototype.getMp3 = getMp3;
Firebase.prototype.getSong = getSong;
Firebase.prototype.getJson = getJson;
Firebase.prototype.getSongCover = getSongCover;
Firebase.prototype.initUserSongDoc = initUserSongDoc;
Firebase.prototype.incrementSongCount = incrementSongCount;

// KARAOKE
Firebase.prototype.completeFeedback = completeFeedback;

// UNSPLASH
Firebase.prototype.cloudSearchUnsplash = cloudSearchUnsplash;
Firebase.prototype.downloadUnsplash = downloadUnsplash;

// PLAYLISTS
Firebase.prototype.getAuthoredPlaylistNameArray = getAuthoredPlaylistNameArray;
Firebase.prototype.getPlaylist = getPlaylist;
Firebase.prototype.getPlaylistSongs = getPlaylistSongs;
Firebase.prototype.getPlaylistSongsById = getPlaylistSongsById;
Firebase.prototype.addPlaylist = addPlaylist;
Firebase.prototype.removePlaylist = removePlaylist;
Firebase.prototype.addPlaylistSong = addPlaylistSong;
Firebase.prototype.removePlaylistSong = removePlaylistSong;
Firebase.prototype.editPlaylist = editPlaylist;
Firebase.prototype.updatePlaylistSongsOrder = updatePlaylistSongsOrder;
Firebase.prototype.playlistCompareByCreated = playlistCompareByCreated;
Firebase.prototype.playlistsOfPlaylistsFollowed = playlistsOfPlaylistsFollowed;
Firebase.prototype.getAuthoredPlaylists = getAuthoredPlaylists;
Firebase.prototype.getFollowedPlaylists = getFollowedPlaylists;

// MISC
Firebase.prototype.doSignUpContact = doSignUpContact;
Firebase.prototype.createInquiry = createInquiry;
Firebase.prototype.completeOnboarding = completeOnboarding;
Firebase.prototype.setStudentSurvey = setStudentSurvey;
Firebase.prototype.setEducatorSurvey = setEducatorSurvey;
Firebase.prototype.completeFirstBreakdown = completeFirstBreakdown;
Firebase.prototype.completeFirstBlanks = completeFirstBlanks;
Firebase.prototype.completeFirstReview = completeFirstReview;
Firebase.prototype.completeFirstKaraoke = completeFirstKaraoke;
Firebase.prototype.setFeedback = setFeedback;
Firebase.prototype.getPilotAgreement = getPilotAgreement;
Firebase.prototype.getTutorialGifs = getTutorialGifs;
Firebase.prototype.generatePin = generatePin;

// USER
Firebase.prototype.getUserDoc = getUserDoc;
Firebase.prototype.getUserSchool = getUserSchool;
Firebase.prototype.getName = getName;
Firebase.prototype.getFirstName = getFirstName;
Firebase.prototype.editUserInfo = editUserInfo;
Firebase.prototype.updateLastAccess = updateLastAccess;
Firebase.prototype.getUserProfile = getUserProfile;

// RECENTS
Firebase.prototype.getRecentSongs = getRecentSongs;
Firebase.prototype.getNewReleases = getNewReleases;

// CLASSES
Firebase.prototype.getClass = getClass;
Firebase.prototype.getMembershipClasses = getMembershipClasses;
Firebase.prototype.joinClassAsStudent = joinClassAsStudent;
Firebase.prototype.createClass = createClass;
Firebase.prototype.approveDenyStudentToJoinClass = approveDenyStudentToJoinClass;
Firebase.prototype.addContentToClass = addContentToClass;
Firebase.prototype.removeContentFromClass = removeContentFromClass;
Firebase.prototype.getClassSharedContent = getClassSharedContent;
Firebase.prototype.removeStudentFromClass = removeStudentFromClass;
Firebase.prototype.getAdminClasses = getAdminClasses;
Firebase.prototype.getPendingClasses = getPendingClasses;
Firebase.prototype.deleteClass = deleteClass;
Firebase.prototype.changeClassColor = changeClassColor;
Firebase.prototype.editClassDetails = editClassDetails;

//ASSIGNMENTS
Firebase.prototype.getSetsForAssignment = getSetsForAssignment;
Firebase.prototype.getAllClassAssignments = getAllClassAssignments;
Firebase.prototype.getAssignment = getAssignment;
Firebase.prototype.getSubmissionsForStudent = getSubmissionsForStudent;
Firebase.prototype.getSubmissionsForAssignment = getSubmissionsForAssignment;
Firebase.prototype.createAssignment = createAssignment;
Firebase.prototype.saveSubmission = saveSubmission;
Firebase.prototype.editAssignment = editAssignment;
Firebase.prototype.getPastAssignmentsFollowed = getPastAssignmentsFollowed;
Firebase.prototype.deleteAssignment = deleteAssignment;

export default Firebase;
