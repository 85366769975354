import React, { Component } from 'react';
import QuestionEdit from './QuestionEdit';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  paddingLeft: '0',
  paddingRight: '0',

  // change background colour if dragging
  // background: isDragging ? 'lightgreen' : 'grey',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightgrey' : '#121212',
  // padding: grid,
  width: '100%',
});

/**
 * QuestionEditor component is the component that houses multiple QuestionEdit components.
 *
 * Figma: https://www.figma.com/file/yEqp78EvktVUTG9Pw9J211/rapStudy?node-id=1609%3A0
 *
 * Props passed in:
 * - Question array
 *
 * - FUNCTION: removeQuestion
 * - FUNCTION: editChoice
 * ... and more
 */
class QuestionEditor extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   items: this.props.questions,
    // };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = this.props.reorder(
      result.source.index,
      result.destination.index
    );

    this.setState({
      items,
    });
  }

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  render() {
    if (!this.props.qList) return null;
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId='droppable'>
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {this.props.qList.questions.map((item, index) => (
                <Draggable
                  key={'draggable_' + item.id}
                  draggableId={item.id}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <QuestionEdit
                        question={item}
                        key={'questionEdit_' + item.id}
                        makeChoiceTrue={this.props.makeChoiceTrue}
                        handleChoiceChange={this.props.handleChoiceChange}
                        handleQuestionChange={this.props.handleQuestionChange}
                        deleteQuestion={this.props.deleteQuestion}
                        selectImage={this.props.selectImage}
                        setStartEnd={this.props.setStartEnd}
                        index={index}
                        lrc={this.props.lrc}
                        removeImage={this.props.removeImage}
                        toggleChooseLyricModal={
                          this.props.toggleChooseLyricModal
                        }
                        toggleChooseImageModal={
                          this.props.toggleChooseImageModal
                        }
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

export default QuestionEditor;
