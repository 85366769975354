import React, { Component } from 'react';
import CreateClassModal from './CreateClassModal';
import JoinClassModal from './JoinClassModal';

import RsButton from '../../ButtonsOrLinks/RsButton';

class CreateJoinClass extends Component {
  // TODO ClassShare: Move component to UI-Library.
  constructor(props) {
    super(props);
    this.state = {
      showCreateClassModal: false,
      showJoinClassModal: false,
    };

    this.handleShowHideModal = this.handleShowHideModal.bind(this);
  }

  handleShowHideModal() {
    if (this.props.userToken.claims.teacher) {
      this.setState({ showCreateClassModal: !this.state.showCreateClassModal });
    } else if (this.props.userToken.claims.student) {
      this.setState({ showJoinClassModal: !this.state.showJoinClassModal });
    }
  }

  render() {
    let classButtonText = this.props.userToken.claims.teacher
      ? 'Create Class'
      : 'Join Class';
    return (
      <div>
        <RsButton onClick={this.handleShowHideModal}>
          {classButtonText}
        </RsButton>
        <CreateClassModal
          show={this.state.showCreateClassModal}
          toggle={() => {
            this.setState({
              showCreateClassModal: !this.state.showCreateClassModal,
            });
          }}
        />

        <JoinClassModal
          show={this.state.showJoinClassModal}
          toggle={() => {
            this.setState({
              showJoinClassModal: !this.state.showJoinClassModal,
            });
          }}
        />
      </div>
    );
  }
}

export default CreateJoinClass;
