import React from 'react';
import styled from 'styled-components';
import { device } from '../../../../constants/breakpoints';
// import RsInput from '../../../UI-Library/Forms/RsInput';
import ContactInput from '../ContactInput';

import { withFirebase } from '../../../Firebase';
import { withAlert } from 'react-alert';
import RsButton from '../../../UI-Library/ButtonsOrLinks/RsButton';

const MainWrapper = styled.div`
  height: calc(100vh - 80px);
  background: white;
`;

const Jumbo1 = styled.div`
  background: linear-gradient(180deg, #4831d4 0%, rgba(255, 255, 255, 0) 100%),
    #37dbf6;
  height: 100%;
  padding-left: 7%;
  padding-top: max(80px, 12%);
  padding-right: 5%;
`;

const Header = styled.h1`
  max-width: 1000px;
  font-size: 48px;
  font-weight: 800;
  @media ${device.tablet} {
    font-size: 44px;
  }
  @media ${device.mobileL} {
    font-size: 40px;
  }
  @media ${device.mobileM} {
    font-size: 30px;
  }
`;

const InputHeader = styled.p`
  font-size: 32px;
  margin-top: 3.5vh;
  @media ${device.tablet} {
    font-size: 24px;
  }
  @media ${device.mobileM} {
    font-size: 18px;
  }
`;

const Info = styled.p`
  font-size: 28px;
  margin-bottom: 5px;
  margin-left: 20px;
  @media ${device.tablet} {
    font-size: 24px;
  }
  @media ${device.mobileM} {
    font-size: 18px;
  }
`;

class AboutMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      message: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(evt) {
    const value = evt.target.value;
    this.setState({ [evt.target.name]: value, disabled: value === '' });
  }

  handleSubmit() {
    const name = this.state.name;
    const email = this.state.email;
    const message = this.state.message;
    this.props.firebase.createInquiry(name, email, message).then(() => {
      // this.props.history.push(ROUTES.LANDING);
      if (this.props.alert) {
        this.props.alert.show(
          "We've received your message and we'll get back to you soon!"
        );
      }
    });
    this.setState({ email: '', name: '', message: '' });
  }

  render() {
    const disabled =
      this.state.name.trim().length === 0 ||
      this.state.message.trim().length === 0 ||
      !/\S+@\S+\.\S+/.test(this.state.email);
    return (
      <MainWrapper>
        <Jumbo1>
          <div
            style={{
              maxWidth: '1440px',
              margin: '0 auto',
            }}
          >
            <div className='row'>
              <div className='col-lg-6'>
                <Header className='bold'>Contact Us</Header>
                <Info>info@rapstudy.com </Info>
                <Info>(203) 540-9722</Info>
              </div>
              <div className='col-lg-6' style={{ paddingRight: '4%' }}>
                <InputHeader>Questions or Inquiries</InputHeader>
                <ContactInput
                  name='name'
                  placeholder='Name'
                  mb='10px'
                  value={this.state.name}
                  onChange={this.handleChange}
                />
                <ContactInput
                  name='email'
                  placeholder='Email'
                  mb='10px'
                  value={this.state.email}
                  onChange={this.handleChange}
                />
                <ContactInput
                  name='message'
                  placeholder='Message'
                  textarea={true}
                  value={this.state.message}
                  onChange={this.handleChange}
                  minHeight={'20vh'}
                />
                <div style={{ width: '100%' }} className='text-right'>
                  <RsButton
                    onClick={this.handleSubmit}
                    disabled={disabled}
                    mt={'20px'}
                    mr={'20px'}
                    landing={true}
                  >
                    Submit
                  </RsButton>
                </div>
              </div>
            </div>
          </div>
        </Jumbo1>
      </MainWrapper>
    );
  }
}

export default withAlert()(withFirebase(AboutMain));
