import React, { Component } from 'react';
import styled from 'styled-components';

import { Modal } from 'react-bootstrap';

import { Scrollbars } from 'react-custom-scrollbars';

import Selection from '../../../../Classes/Selection';
import RsButton from '../../ButtonsOrLinks/RsButton';
import Cross from '../../Icons/Cross';

const ModalTitle = styled(Modal.Title)`
  font-weight: bold;
`;

const ModalBody = styled(Modal.Body)`
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 0;
  height: calc(100vh - 300px);
  padding-top: 30px;
  padding-bottom: 30px;
  border-radius: 0 !important;
`;

const ModalHeader = styled(Modal.Header)`
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 60px;
  padding-bottom: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ModalFooter = styled(Modal.Footer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 60px;
  padding-right: 60px;
  padding-bottom: 60px;
`;

const Instructions = styled.p`
  font-size: 13px;
`;

const SpanBlue = styled.span`
  background-color: var(--rsMajor);
  color: black;
  width: 100%;
`;

const SpanPeach = styled.span`
  width: 100%;
`;

const Lyrics = styled.div`
  border-radius: 0 !important;
`;

/**
 * The modal component for selecting lyrics from a given LRC object.
 * This is a reusable component for all features.
 *
 * Props:
 * - [function]: selectLrcRef
 * - {Lrc} lrc
 */
export class ChooseLyricModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showHide: false,
      disabled: true,
      invalid: true,
      beg: null,
      end: null,
      initBeg: this.props.beg,
      initEnd: this.props.end,
      hasBeenChanged: false,
    };
    this.handleModalShowHide = this.handleModalShowHide.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.submit = this.submit.bind(this);
    this.handleSelection = this.handleSelection.bind(this);

    // this.handleOnClick = this.handleOnClick.bind(this);
  }

  componentDidMount() {
    document.onmouseup = () => {
      if (window.getSelection) {
        const selection = window.getSelection();
        var startEnd = Selection.getStartEndLine(this.props.id, selection);
        if (startEnd === null) {
          if (!this.state.invalid) {
            this.setState({ invalid: true });
          }
        } else {
          if (!this.state.invalid) {
            this.setState({
              beg: startEnd[0],
              end: startEnd[1],
            });
          } else {
            this.setState({
              invalid: false,
              beg: startEnd[0],
              end: startEnd[1],
            });
          }
        }
        return;
      } else if (document.selection && document.selection.type !== 'Control') {
        // for IE compatability
        return;
      }
    };
  }

  componentDidUpdate(prevProps, prevState) {
    // Need to perform this check to reset the modal when a different question is in use
    if (prevProps.beg !== this.props.beg || prevProps.end !== this.props.end) {
      this.setState({ initBeg: this.props.beg, initEnd: this.props.end });
    }
  }

  handleModalShowHide() {
    this.setState({
      beg: null,
      end: null,
      initBeg: this.props.beg,
      initEnd: this.props.end,
    });
    this.props.toggle(this.props.index);
  }

  async submit() {
    this.props.setStartEnd(
      this.props.index,
      this.state.initBeg,
      this.state.initEnd
    );
    this.setState({
      disabled: true,
      invalid: true,
    });
    this.handleModalShowHide();
  }

  onKeyDown(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      this.submit(e.target.value);
    }
  }

  getContent() {
    var result = [];
    if (this.props.lrc) {
      const lrc = this.props.lrc;

      for (var x = 0; x < lrc.blocks.length; x++) {
        var block = lrc.blocks[x];

        for (var y = 0; y < block.lines.length; y++) {
          var line = block.lines[y];
          if (
            this.state.initBeg !== null &&
            this.state.initEnd !== null &&
            this.state.initBeg <= line.pos &&
            line.pos <= this.state.initEnd
          ) {
            result.push(
              <SpanBlue
                key={this.props.id + '_line_' + line.pos}
                id={this.props.id + '_line_' + line.pos}
              >
                {line.words.map((wordObj, wordIndex) => {
                  return wordObj.word;
                })}
              </SpanBlue>
            );
          } else {
            result.push(
              <SpanPeach
                key={this.props.id + '_line_' + line.pos}
                id={this.props.id + '_line_' + line.pos}
              >
                {line.words.map((wordObj, wordIndex) => {
                  return wordObj.word;
                })}
              </SpanPeach>
            );
          }

          result.push(<br key={this.props.id + '_lineBreak' + y + x} />);
        }
        result.push(<br key={this.props.id + '_blockBreak' + x} />);
      }
    }
    return result;
  }

  handleSelection() {
    this.setState({
      hasBeenChanged: true,
      disabled: false,
      invalid: true,
      initBeg: this.state.beg,
      initEnd: this.state.end,
    });
    if (window.getSelection) {
      if (window.getSelection().empty) {
        // Chrome
        window.getSelection().empty();
      } else if (window.getSelection().removeAllRanges) {
        // Firefox
        window.getSelection().removeAllRanges();
      }
    } else if (document.selection) {
      // IE?
      document.selection.empty();
    }
  }

  render() {
    return (
      <div onKeyDown={(e) => e.stopPropagation()}>
        <Modal
          dialogClassName='border-radius-16-full'
          show={this.props.show}
          centered
          onHide={() => this.handleModalShowHide()}
        >
          <ModalHeader className='noselect'>
            <ModalTitle className='text-center noselect'>
              Highlight the lyrics
            </ModalTitle>

            <div className='noselect' onClick={this.handleModalShowHide}>
              <Cross size='18px' onClick={this.handleModalShowHide} />
            </div>
          </ModalHeader>
          <ModalBody>
            <Instructions className='secondary noselect'>
              Highlight text with your cursor, then click “Use selection” to
              choose the lyrics that are relevant to this question
            </Instructions>
            <Scrollbars
              className='custom-scrollbars'
              renderTrackHorizontal={(props) => (
                <div
                  {...props}
                  style={{ display: 'none' }}
                  className='track-horizontal noselect'
                />
              )}
              renderView={({ style, ...props }) => {
                const finalStyle = {
                  ...style,
                  borderRadius: '0',
                };
                return <div {...props} style={finalStyle} />;
              }}
              renderThumbVertical={({ style, ...props }) => {
                const finalStyle = {
                  ...style,
                  backgroundColor: 'var(--rsSecondary)',
                  cursor: 'pointer',
                };
                return (
                  <div style={finalStyle} {...props} className='noselect' />
                );
              }}
              style={{ borderRadius: '0' }}
            >
              <Lyrics>{this.getContent()}</Lyrics>
            </Scrollbars>
          </ModalBody>
          <ModalFooter className='noselect'>
            <RsButton
              className='noselect'
              onClick={this.handleSelection}
              disabled={this.state.invalid}
            >
              Use selection
            </RsButton>
            <RsButton
              className='noselect'
              onClick={this.submit}
              disabled={!this.state.hasBeenChanged || !this.state.invalid}
            >
              Save
            </RsButton>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default ChooseLyricModal;
