import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ReviewSubmission from '../../../../Classes/Assignments/ReviewSubmission';

const MainTable = styled.table`
  width: 100%;
`;

const Heading = styled.th`
  color: var(--rsPrimary);
  padding-left: 10px;
  padding-right: 10px;
`;

const FirstBody = styled.td`
  color: var(--rsSecondary);
  padding: 10px;
  width: 5%;
  text-align: right;
`;

const Body = styled.td`
  color: ${(props) => (props.isCorrect ? 'var(--rsSecondary)' : 'red')};
  font-weight: ${(props) => (props.isCorrect ? 'normal' : 'bold')};
  border-left: 2px solid var(--rsSecondary);
  border-right: 2px solid var(--rsSecondary);
  padding: 10px;
  width: 28.33333%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 0;
`;

const LastBody = styled.td`
  color: var(--rsSecondary);
  padding: 10px;
  width: 10%;
`;

class ReviewSubmissionTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableContent: [],
    };
  }

  render() {
    const questionList = this.props.submissionObj;
    const rows = [];
    for (let i = 0; i < questionList.getLength(); i++) {
      const q = questionList.getQuestion(i);
      const studentAnswerChoice = q.getChoice(q.getSelected());
      const isCorrect = q.isCorrect();
      const correctAnswerChoice = q.getChoice(q.getCorrect());

      rows.push(
        <tr key={i}>
          <FirstBody>{i + 1}.</FirstBody>
          <Body isCorrect={true}>{q.getQuestion()}</Body>
          <Body isCorrect={isCorrect}>
            {q.isAnswered()
              ? q.getSelected() + '. ' + studentAnswerChoice
              : 'Missing'}
          </Body>
          <Body isCorrect={true}>
            {isCorrect ? null : q.getCorrect() + '. ' + correctAnswerChoice}
          </Body>
          <LastBody>{q.getAttempts()}</LastBody>
        </tr>
      );
    }

    return (
      <MainTable>
        <thead>
          <tr>
            <Heading></Heading>
            <Heading>Question</Heading>
            <Heading>Student's answer</Heading>
            <Heading>Correct answer</Heading>
            <Heading>Attempts</Heading>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </MainTable>
    );
  }
}

ReviewSubmissionTable.propTypes = {
  /**
   * ReviewSubmission object the table is modeled from
   */
  submissionObj: PropTypes.instanceOf(ReviewSubmission),
};

export default ReviewSubmissionTable;
