/**
 * Represents a question used in a review (or related) feature.
 */
class Question {
  /**
   * Creates a new Question object.
   * @param {string} songId
   * @param {string} question
   * @param {string} choiceA
   * @param {string} choiceB
   * @param {string} choiceC
   * @param {string} choiceD
   * @param {string} correct
   * @param {number | null} lineStart
   * @param {number | null} lineEnd
   * @param {string} id
   * @param {number} index
   * @param {string | null} unsplash
   * @param {string | null} unsplashUid
   * @param {string | null} unsplashName
   * @param {string | null} firebaseImg
   * @param {string | null} firebaseImgName
   * @param {string | null} selected
   * @param {number} attempts
   */
  constructor(
    songId,
    question,
    choiceA,
    choiceB,
    choiceC,
    choiceD,
    correct,
    lineStart,
    lineEnd,
    id,
    index,
    unsplash,
    unsplashUid,
    unsplashName,
    firebaseImg,
    firebaseImgName,
    selected,
    attempts
  ) {
    if (lineStart === undefined || lineEnd === undefined) {
      lineStart = null;
      lineEnd = null;
    }

    if (!unsplash) {
      unsplash = null;
      unsplashUid = null;
      unsplashName = null;
    }
    if (!unsplashUid || !unsplashName) {
      unsplashUid = null;
      unsplashName = null;
    }
    if (!firebaseImg) {
      firebaseImg = null;
      firebaseImgName = null;
    }

    this.songId = songId;
    this.question = question;
    this.choiceA = choiceA;
    this.choiceB = choiceB;
    this.choiceC = choiceC;
    this.choiceD = choiceD;
    this.correct = correct;
    this.beg = lineStart;
    this.end = lineEnd;
    this.id = id;
    this.index = index;
    this.unsplash = unsplash;
    this.unsplashUid = unsplashUid;
    this.unsplashName = unsplashName;
    this.firebaseImg = firebaseImg;
    this.firebaseImgName = firebaseImgName;
    // new additions:
    this.selected = selected;
    this.attempts = attempts;
  }

  static questionConverter = {
    toFirestore: function (quest) {
      return {
        songId: quest.getSongId(),
        question: quest.getQuestion(),
        choiceA: quest.getChoice('A'),
        choiceB: quest.getChoice('B'),
        choiceC: quest.getChoice('C'),
        choiceD: quest.getChoice('D'),
        correct: quest.getCorrect(),
        lineEnd: quest.getLineEnd(),
        lineStart: quest.getLineStart(),
        index: quest.getIndex(),
        unsplash: quest.unsplash,
        unsplashUid: quest.unsplashUid,
        unsplashName: quest.unsplashName,
        firebaseImg: quest.firebaseImg,
        firebaseImgName: quest.firebaseImgName,
      };
    },
    fromFirestore: function (snapshot, options) {
      const data = snapshot.data(options);
      return new Question(
        data.songId,
        data.question,
        data.choiceA,
        data.choiceB,
        data.choiceC,
        data.choiceD,
        data.correct,
        data.lineStart,
        data.lineEnd,
        snapshot.id,
        data.index,
        data.unsplash,
        data.unsplashUid,
        data.unsplashName,
        data.firebaseImg,
        data.firebaseImgName,
        null,
        0
      );
    },
  };

  static assignmentConverter = {
    toFirestore: function (quest) {
      return {
        questionData: {
          songId: quest.getSongId(),
          question: quest.getQuestion(),
          choiceA: quest.getChoice('A'),
          choiceB: quest.getChoice('B'),
          choiceC: quest.getChoice('C'),
          choiceD: quest.getChoice('D'),
          correct: quest.getCorrect(),
          lineEnd: quest.getLineEnd(),
          lineStart: quest.getLineStart(),
          index: quest.getIndex(),
          unsplash: quest.unsplash,
          unsplashUid: quest.unsplashUid,
          unsplashName: quest.unsplashName,
          firebaseImg: quest.firebaseImg,
          firebaseImgName: quest.firebaseImgName,
          selected: quest.getSelected(),
          attempts: quest.getAttempts(),
        },
        questionId: quest.getId(),
        songId: quest.getSongId(),
      };
    },
  };

  static assignmentConverterGet(data) {
    const qD = data.questionData;
    return new Question(
      data.songId,
      qD.question,
      qD.choiceA,
      qD.choiceB,
      qD.choiceC,
      qD.choiceD,
      qD.correct,
      qD.lineStart,
      qD.lineEnd,
      data.questionId,
      qD.index,
      qD.unsplash,
      qD.unsplahUid,
      qD.unsplashName,
      qD.firebaseImg,
      qD.firebaseImgName,
      qD.selected,
      qD.attempts
    );
  }

  /**
   * Sets the new id for the Question.
   * @param {string} text
   */
  setId(id) {
    this.assertString(id, 'id');
    this.id = id;
  }

  /**
   * Returns the id for the Question.
   * @returns {string}
   */
  getId() {
    return this.id;
  }

  /**
   * Returns the song id associated with the question.
   * @returns {string}
   */
  getSongId() {
    return this.songId;
  }

  /**
   * Returns the question text.
   */
  getQuestion() {
    return this.question;
  }

  /**
   * Returns the correct answer choice
   */
  getCorrect() {
    return this.correct;
  }

  /**
   * Makes the choice correct and the other choices incorrect.
   *
   * Precondition: choice must be equal to either 'A', 'B', 'C' or 'D'
   * @param {string} choice
   */
  setCorrect(choice) {
    this.assertChoice(choice);
    this.correct = choice;
  }

  /**
   * Sets the image fields to be the unsplash properties. Overwrites the
   * firebase storage fields.
   * @param {string} imgLink
   * @param {string} uid
   * @param {string} name
   */
  setUnsplash(imgLink, uid, name) {
    this.unsplash = imgLink;
    this.unsplashUid = uid;
    this.unsplashName = name;
    this.firebaseImg = null;
    this.firebaseImgName = null;
  }

  /**
   * Sets the image fields to be the firebase upload properties. Overwrites the
   * unsplash fields.
   * @param {string} url
   * @param {string} fileName
   */
  setUpload(url, fileName) {
    this.unsplash = null;
    this.unsplashUid = null;
    this.unsplashName = null;
    this.firebaseImg = url;
    this.firebaseImgName = fileName;
  }

  /**
   * Sets the index field to be the passed index.
   * @param {number} index
   */
  setIndex(index) {
    this.index = index;
  }

  /**
   * Gets the question's index.
   * @return {number}
   */
  getIndex() {
    return this.index;
  }

  /**
   * Removes the current image from the question.
   */
  deleteImage() {
    this.unsplash = null;
    this.unsplashUid = null;
    this.unsplashName = null;
    this.firebaseImg = null;
    this.firebaseImgName = null;
  }

  /**
   * Returns the source url of the current loaded image. If no image is loaded,
   * returns null.
   */
  getSrc() {
    if (this.firebaseImg) {
      // case 2: firebase storage URL
      return this.firebaseImg;
    } else if (this.unsplash) {
      // case 3: unsplash url=
      return this.unsplash;
    } else {
      // case 4: no image
      return null;
    }
  }

  /**
   * Returns the choice corresponding to 'A', 'B', 'C', or 'D'
   * @param {string} letter
   */
  getChoice(letter) {
    if (letter === 'A') {
      return this.choiceA;
    } else if (letter === 'B') {
      return this.choiceB;
    } else if (letter === 'C') {
      return this.choiceC;
    } else if (letter === 'D') {
      return this.choiceD;
    } else {
      return null;
    }
  }

  /**
   * Updates the text for the question at the given choice.
   * @param {string} choice
   * @param {string} text
   */
  setChoice(choice, text) {
    this.assertChoice(choice);
    this.assertString(text, 'text');
    if (choice === 'A') {
      this.choiceA = text;
    } else if (choice === 'B') {
      this.choiceB = text;
    } else if (choice === 'C') {
      this.choiceC = text;
    } else if (choice === 'D') {
      this.choiceD = text;
    }
  }

  /**
   * Updates the text for the main question.
   * @param {string} text
   */
  setQuestion(text) {
    this.assertString(text);
    this.question = text;
  }

  /**
   * Sets the start and end lines.
   * Precondition: Either beg and end are both of type number, or are both null.
   * @param {number | null} beg
   * @param {number | null} end
   */
  setStartEnd(beg, end) {
    var areNumbers = typeof beg === 'number' && typeof end === 'number';
    var areNull = beg == null && end == null;

    if (areNumbers || areNull) {
      this.beg = beg;
      this.end = end;
    } else {
      throw Error('Failed precondition. beg and end are different types.');
    }
  }

  /**
   * Sets the selected answer. If the choice is correct, returns true. Otherwise,
   * returns false.
   * @param {string} choice
   * @return {bool}
   */
  setSelected(choice) {
    if (!['A', 'B', 'C', 'D'].includes(choice))
      throw Error(
        'Invalid choice selected for question. Must be one of A, B, C, or D.'
      );
    this.selected = choice;
    this.attempts += 1;
    return this.isCorrect();
  }

  /**
   * Resets the selected answer.
   */
  resetSelected() {
    this.selected = null;
    this.attempts = 0;
  }

  /**
   * Returns true if the question has been answered, false otherwise.
   * @return {bool}
   */
  isAnswered() {
    return this.selected !== null;
  }

  /**
   * Returns true if the current choice is correct, false otherwise.
   * @return {bool}
   */
  isCorrect() {
    return this.selected === this.correct;
  }

  /**
   * Returns the selected choice, 'A','B','C', or 'D'.
   * @return {string}
   */
  getSelected() {
    return this.selected;
  }

  /**
   * Returns the number of attempts for this question.
   * @return {number}
   */
  getAttempts() {
    return this.attempts;
  }

  /**
   * Returns the start line.
   * @returns {number}
   */
  getLineStart() {
    return this.beg;
  }

  /**
   * Returns the end line.
   * @returns {number}
   */
  getLineEnd() {
    return this.end;
  }

  /**
   * Asserts that the choice is valid.
   * @param {string} choice
   */
  assertChoice(choice) {
    if (
      !(choice === 'A' || choice === 'B' || choice === 'C' || choice === 'D')
    ) {
      throw Error('Failed precondition: choice');
    }
    return;
  }

  /**
   * Asserts that text is indeed a string.
   * @param {any} text
   * @param {string} paramName
   */
  assertString(text, paramName) {
    if (!(typeof text === 'string'))
      throw Error('Failed precondition: "' + paramName + '" is not a string');
  }
}

export default Question;
