/**
 * The data structure should be built to be flexible for lazy-loading. While
 * async operations should not be handled in this file, we should be prepared
 * to lazy-load certain fields and be continuously adding data.
 *
 * If the user starts at the summary page, there is no need to get
 * the individual questions and responses for each individual. Only when (and if)
 * the user clicks on the individual page, should we load in data for individual
 * set submissions.
 *
 * EX:
 * Start at summary page.
 * - get all set submission documents and display
 * Go to individual and click on a set submission
 * - for the current individual and current set, get the responses, store.

 */

import IndivGradeSummary from './IndivGradeSummary';

/**
 * GradeSummary represents the submissions for an assignment, grouped by
 * student.
 *
 * Class Invariant:
 * For each IndivGradeSummary in the dictionary of students, it must have the
 * same set submissions. That is, all students must have an equal number of
 * set submissions, and the set submissions should all correspond to the same
 * exercise id.
 */
class GradesSummary {
  constructor(assignmentId, assignmentName) {
    this._assignmentId = assignmentId;
    this._assignmentName = assignmentName;
    this._students = {
      // studentId : IndivGradeSummary
    };
  }

  /**
   * Returns true if there are no students, false otherwise.
   * @return {bool}
   */
  isEmpty() {
    return Object.keys(this._students).length === 0;
  }

  /**
   * Returns the individual grade summaries, sorted by first name. If there
   * are no summaries, returns the empty array.
   * @return {Array<IndivGradeSummary>}
   */
  getSortedSummaries() {
    if (this.isEmpty()) return [];
    return Object.values(this._students).sort(IndivGradeSummary.nameCompare);
  }

  /**
   * Returns the IndivGradeSummaries that are empty, i.e., do not yet have
   * submissions loaded.
   * @return {Array<IndivGradeSummary>}
   */
  getEmptySummaries() {
    if (this.isEmpty()) return [];
    return Object.values(this._students).filter((indivSumObj) =>
      indivSumObj.isEmpty()
    );
  }

  /**
   * Adds a student's grades to the data structure.
   * @param {IndivGradeSummary} indivSummary
   */
  addStudentGrades(indivSummary) {
    const studentId = indivSummary.getStudentId();
    if (!(indivSummary instanceof IndivGradeSummary)) {
      throw Error(
        'Precondition Invalidated: indivSummary is not a IndivGradeSummary object.'
      );
    }
    if (studentId in this._students) {
      throw Error(
        'Precondition Invalidated: You cannot add a student that already exists.'
      );
    }
    this._students[studentId] = indivSummary;
  }

  /**
   * Returns the IndivGradeSummary for the student.
   * @param {string} studentId
   * @return {IndivGradeSummary}
   */
  getIndivGrades(studentId) {
    if (!(studentId in this._students)) {
      throw Error('Precondition Invalidated: The student id does not exist.');
    }
    return this._students[studentId];
  }

  /**
   * Returns an array of IndivGradeSummary instances.
   * @return {Array<IndivGradeSummary>}
   */
  getAllGrades() {
    return Object.values(this._students);
  }

  /**
   * Returns an array of exercise ids.
   * @return {Array<string>}
   */
  getExerciseIds() {
    if (this.isEmpty()) return [];
    // Because of the class invariant, we choose an arbitrary entry.
    const arbIndiv = Object.values(this._students)[0];
    if (arbIndiv.isEmpty()) return [];
    return arbIndiv.getExerciseIds();
  }
}

export default GradesSummary;
