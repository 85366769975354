import React, { Component } from 'react';
import styled from 'styled-components';

import PlaylistTopMenu from './PlaylistTopMenu';
import ImageInfoBanner from '../../../UI-Library/Containers/ImageInfoBanner';

const Header = styled.h1`
  font-weight: bold;
`;
const Desc = styled.p`
  margin-bottom: 25px;
  margin-top: 10px;
`;

const DateHeader = styled.p`
  margin-bottom: 0;
`;

const AuthorHeader = styled.p`
  margin-bottom: 0;
`;

const FlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 0;
`;

const PlaylistHeader = styled.p`
  color: var(--rsSecondary);
  margin: 0;
`;

/**
 * PlaylistBanner is the header component for the PlaylistView page.
 */
class PlaylistBanner extends Component {
  render() {
    return (
      <ImageInfoBanner
        backgroundColor={this.props.color}
        url={this.props.src}
        imgType={'playlist'}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
          }}
        >
          <div>
            <FlexDiv style={{ marginBottom: 10 }}>
              <PlaylistHeader>Your Playlists</PlaylistHeader>
              {(this.props.isAuthor || this.props.userToken.claims.teacher) && (
                <PlaylistTopMenu
                  isAuthor={this.props.isAuthor}
                  id={this.props.id}
                  userToken={this.props.userToken}
                  changeHeader={this.props.changeHeader}
                  delete={this.props.delete}
                  onSelectColor={this.props.onSelectColor}
                  color={this.props.color}
                  name={this.props.name}
                  desc={this.props.desc}
                  toggleClassShare={this.props.toggleClassShare}
                  setClassShareType={this.props.setClassShareType}
                  logAnalyticsShare={this.props.logAnalyticsShare}
                  copyLink={this.props.copyLink}
                />
              )}
            </FlexDiv>
            <Header>{this.props.name}</Header>
            <Desc>{this.props.desc}</Desc>
          </div>
          <FlexDiv>
            <AuthorHeader className='bold'>
              Made by {this.props.authorName}
            </AuthorHeader>
            <DateHeader className='bold'>Created: {this.props.date}</DateHeader>
          </FlexDiv>
        </div>
      </ImageInfoBanner>
    );
  }
}

export default PlaylistBanner;
