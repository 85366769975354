import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AlbumCoverCarousel from '../../../UI-Library/ImageContainers/AlbumCoverCarousel';
import RsLink from '../../../UI-Library/ButtonsOrLinks/RsLink';
import Caret from '../../Icons/Caret';

const Title = styled.h2`
  font-weight: bold;
  line-height: 21px;
  color: var(--rsPrimary);
  margin: 0;
  margin-right: 5px;
`;

const MainWrapper = styled.div`
  margin-bottom: 20px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
`;

const ScrollButton = styled.div`
  margin-left: 15px;
`;

/**
 * AlbumCoverCarouselController calls the AlbumCoverCarousel component. The
 * child component is not in the Storybook. For details, look at the source
 * code. Currently only supports songs, playlists, and units.
 */
class AlbumCoverCarouselController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currSlide: 0,
      numSlides: 0,
    };
    this.scrollLeft = this.scrollLeft.bind(this);
    this.scrollRight = this.scrollRight.bind(this);
    this.onResize = this.onResize.bind(this);
  }

  scrollLeft() {
    if (this.state.currSlide === 0) return;
    const currSlide = this.state.currSlide;
    this.setState({
      currSlide: currSlide - 1,
    });
  }

  scrollRight() {
    if (this.state.currSlide + 1 >= this.state.numSlides) return;

    const currSlide = this.state.currSlide;
    this.setState({
      currSlide: currSlide + 1,
    });
  }

  onResize(numSlides) {
    this.setState({
      numSlides: numSlides,
    });
  }

  render() {
    const clickableLeft = this.state.currSlide !== 0;
    const clickableRight =
      this.state.numSlides !== 0 &&
      this.state.currSlide !== this.state.numSlides - 1;

    return (
      <MainWrapper>
        <Header>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Title>{this.props.header}</Title>
            {this.props.canViewAll && (
              <RsLink
                secondary={true}
                small={true}
                onClick={this.props.viewAll}
              >
                {'View All >'}
              </RsLink>
            )}
          </div>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ScrollButton clickable={clickableLeft} onClick={this.scrollLeft}>
              <Caret
                disabled={!clickableLeft}
                direction='left'
                onClick={this.scrollLeft}
              />
            </ScrollButton>
            <ScrollButton clickable={clickableRight} onClick={this.scrollRight}>
              <Caret disabled={!clickableRight} direction='right' />
            </ScrollButton>
          </div>
        </Header>

        <AlbumCoverCarousel
          items={this.props.items}
          currSlide={this.state.currSlide}
          onResize={this.onResize}
          rows={this.props.rows}
          itemType={this.props.itemType}
          remove={this.props.remove}
        />
      </MainWrapper>
    );
  }
}

AlbumCoverCarouselController.propTypes = {
  /**
   * The items that will be included in the carousel. See BoxPreview for detail
   * on the required props for that component. Depending on the type, the items
   * need different fields. For playlists: [name] replaces title, [playlistId]
   * replaces [id].
   */
  items: PropTypes.array.isRequired,
  /**
   * The number of rows in the carousel.
   */
  rows: PropTypes.number.isRequired,
  /**
   * The type of the elements, either song, unit, or playlist.
   */
  itemType: PropTypes.oneOf(['song', 'playlist', 'unit']).isRequired,
  /**
   * The function to remove an element from the list.
   */
  remove: PropTypes.func,
  /**
   * The header of the carousel.
   */
  header: PropTypes.string,
  /**
   * Show the view all link?
   */
  canViewAll: PropTypes.bool,
  /**
   * The function fired when the view all link is clicked.
   */
  viewAll: PropTypes.func,
};

export default AlbumCoverCarouselController;
