import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Carousel from 'react-bootstrap/Carousel';
import BoxPreview from '../BoxPreview';
import * as ROUTES from '../../../../constants/routes';

const BoxesContainer = styled.div`
  width: 100%;
  height: auto;
`;

const CarouselItem = styled(Carousel.Item)`
  width: 100%;
`;

/**
 * AlbumCoverCarousel is a Bootstrap React Carousel that displays an array
 * of BoxPreview components in multiple slides. It automatically populates
 * the slides based on the width available. Currently only supports songs,
 * playlists, and units.
 */
class AlbumCoverCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slides: [],
      canFit: 0,
    };
    this.boxRef = React.createRef();
    this.updateDims = this.updateDims.bind(this);
    this.buildBoxes = this.buildBoxes.bind(this);
  }

  componentDidMount() {
    this.updateDims();
    window.addEventListener('resize', this.updateDims);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDims);
  }

  componentDidUpdate() {}

  /**
   * Returns a nested array of songs, where each sub-array has length
   * [props.rows] * canFit.
   * @param {Array} songs
   * @param {number} canFit
   */
  makeStackedSlides(songs, canFit) {
    if (songs.length === 0 || canFit === 0) return [];
    var arrays = [];
    var nested = [];
    var added = 0;

    for (var i = 0; i < songs.length; i++) {
      nested.push(songs[i]);
      added++;
      if (i === songs.length - 1) {
        arrays.push(nested);
      } else if (added % (this.props.rows * canFit) === 0) {
        arrays.push(nested);
        nested = [];
        added = 0;
      }
    }
    return arrays;
  }

  updateDims() {
    if (this.boxRef.current === null) return;

    const coverWidth = 180 + 35; // 180px cover, and 40px margin
    const boxWidth = this.boxRef.current.clientWidth;
    const canFit = Math.floor(boxWidth / coverWidth);

    this.setState(
      {
        canFit: canFit,
      },
      () => {
        if (this.props.onResize) {
          // call onResize to tell the parent component what the new amount of
          // slides is.
          const slides = this.makeStackedSlides(this.props.items, canFit);
          this.props.onResize(slides.length);
        }
      }
    );
  }

  buildBoxes(slides, canFit) {
    if (slides.length === 0) return [];
    var url = '';
    var idFieldName = 'id';
    if (this.props.itemType === 'song') {
      url = ROUTES.KARAOKE + '?songId=';
    } else if (this.props.itemType === 'playlist') {
      url = ROUTES.PLAYLISTVIEW + '/';
      idFieldName = 'playlistId';
    } else if (this.props.itemType === 'unit') {
      url = ROUTES.UNITVIEW + '/';
    }

    return slides.map((slide, sIndex) => (
      <CarouselItem
        key={'carouselItem_' + sIndex.toString() + '_' + canFit.toString()}
      >
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {slide.map((item, index) => {
            var title =
              this.props.itemType === 'song'
                ? item.subtopic.name
                : this.props.itemType === 'playlist'
                ? item.name
                : item.title;
            var subTitle =
              this.props.itemType === 'song'
                ? item.origTitle
                : this.props.itemType === 'playlist'
                ? ''
                : '';
            var bgColor =
              this.props.itemType === 'playlist' && item.color
                ? item.color
                : 'var(--rsBlue)';
            var id = item[idFieldName];
            var src = item.src
              ? item.src
              : item.firebaseImg
              ? item.firebaseImg
              : null;

            return (
              <BoxPreview
                title={title}
                subtitle={subTitle}
                image={src}
                bgColor={bgColor}
                type={this.props.itemType}
                mr={'35px'}
                to={url + id}
                remove={this.props.remove}
                id={id}
                key={
                  'BoxPreview_' +
                  sIndex.toString() +
                  '_' +
                  index.toString() +
                  '_' +
                  canFit.toString()
                }
              />
            );
          })}
        </div>
      </CarouselItem>
    ));
  }

  render() {
    return (
      <BoxesContainer ref={this.boxRef}>
        <Carousel
          interval={null}
          controls={false}
          activeIndex={this.props.currSlide}
          fade={false}
          indicators={false}
          key={this.state.canFit}
        >
          {this.buildBoxes(
            this.makeStackedSlides(this.props.items, this.state.canFit),
            this.state.canFit
          )}
        </Carousel>
      </BoxesContainer>
    );
  }
}

AlbumCoverCarousel.propTypes = {
  /**
   * The type of the elements, either song, unit, or playlist.
   */
  itemType: PropTypes.oneOf(['song', 'playlist', 'unit']).isRequired,
  /**
   * The items that will be included in the carousel. See BoxPreview for detail
   * on the required props for that component. Depending on the type, the items
   * need different fields. For playlists: [name] replaces title, [playlistId]
   * replaces [id].
   */
  items: PropTypes.array.isRequired,
  /**
   * The function to remove an element from the list.
   */
  remove: PropTypes.func,
  /**
   * The current slide number.
   */
  currSlide: PropTypes.number.isRequired,
  /**
   * The number of rows in the carousel.
   */
  rows: PropTypes.number.isRequired,
  /**
   * The function that is called when the window is resized. The function has a
   * single parameter, the number of slides. Optimally, this function changes
   * the passed currSlide dynamically.
   */
  onResize: PropTypes.func.isRequired,
};

export default AlbumCoverCarousel;
