import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
// import { withTheme } from '@material-ui/core';

const MainWrapper = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #485256 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background: #62747b !important;
    transition: all 0.3s ease 0s;
  }
  cursor: pointer;
`;

const InnerBox = styled.div`
  width: 12px;
  height: 12px;
  background: ${(props) =>
    props.selected ? 'var(--rsBlue)' : 'transparent'} !important;
  border-radius: 50%;
`;

/**
 * The standard radio button component. Any logic for radio buttons, such as mutual
 * exclusivity, should be handled by a parent component.
 */
class RsCheckbox extends Component {
  render() {
    return (
      <MainWrapper onClick={this.props.onSelect}>
        <InnerBox selected={this.props.selected} />
      </MainWrapper>
    );
  }
}

RsCheckbox.propTypes = {
  /**
   * Is the checkbox selected?
   */
  selected: PropTypes.bool.isRequired,
  /**
   * The function that fires when the box is clicked.
   */
  onSelect: PropTypes.func.isRequired,
};

export default RsCheckbox;
