import React from 'react';
import styled from 'styled-components';

import LandingSelect from '../../LandingSelect';

import { device } from '../../../../constants/breakpoints';
import Carousel from 'react-bootstrap/Carousel';

const MainWrapper = styled.div`
  max-width: 1440px;
  background: white;
  margin: auto;
  padding-top: 5%;
  padding-bottom: 5%;
  padding-left: 10%;
  padding-right: 5%;

  @media ${device.tablet} {
    padding-top: 10%;
    padding-bottom: 10%;
  }
`;

const Header = styled.h1`
  max-width: 1000px;
  font-size: 36px;
  font-weight: bold;
  color: black;
  text-align: left;
  margin-top: -130px;

  @media ${device.laptop} {
    margin-top: 0;
  }

  @media ${device.tablet} {
    font-size: 30px;
  }
  @media ${device.mobileL} {
    font-size: 24px;
  }
`;

const SubHeader = styled.h1`
  color: black;
  margin-bottom: 30px;
  text-align: left;
  font-weight: 300;
  font-size: 24px;

  @media ${device.tablet} {
    font-size: 18px;
    margin-bottom: 20px;
  }
  @media ${device.mobileL} {
    font-size: 14px;
    margin-bottom: 10px;
  }
`;

const Text = styled.div`
  justify-content: center;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
`;

const CarouselWrap = styled.div`
  max-width: min(1000px, 45vw);
  margin-bottom: 20px;

  @media ${device.tablet} {
    max-height: 60vh;
    max-width: min(1000px, 70vw);
    margin-bottom: 15px;
  }

  @media ${device.mobileL} {
    max-width: 80vw;
    margin-bottom: 10px;
  }
`;

const FeatureImage = styled.img`
  width: 100%;
  height: auto;
`;

const CarouselItem = styled(Carousel.Item)`
  width: 100%;
`;

/**
 * About is the entire About page.
 *
 * This includes 'our story' and a few AboutJumbos for the team.
 */
class LandingFeatures extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: 'Karaoke',
    };
    this.select = this.select.bind(this);
  }

  select(key) {
    this.setState({ selected: key });
  }

  render() {
    const data = {
      Karaoke: {
        index: 0,
        src: '/images/landing/karaoke.png',
      },
      Breakdown: {
        index: 1,
        src: '/images/landing/breakdown.png',
      },
      Blanks: {
        index: 2,
        src: '/images/landing/blanks.png',
      },
      Review: {
        index: 3,
        src: '/images/landing/review.png',
      },
    };
    const keys = Object.keys(data);
    const index = data[this.state.selected].index;
    // const index =
    return (
      <MainWrapper className='row'>
        <Text className='col-lg-6'>
          {/* col-sm-7 col-sm-push-7 */}
          <Header>Music as the medium</Header>
          <SubHeader>
            Students and teachers engage with songs through a variety of
            features on our platform.
          </SubHeader>
        </Text>
        <div
          className='col-lg-6'
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <CarouselWrap>
            <Carousel
              interval={null}
              controls={false}
              activeIndex={index}
              fade={false}
              indicators={false}
            >
              {keys.map((ele) => (
                <CarouselItem key={'LANDINGFEATURES_' + ele}>
                  <FeatureImage
                    src={data[ele].src}
                    key={'LANDINGFEATURESIMG_' + ele}
                  />
                </CarouselItem>
              ))}
            </Carousel>
          </CarouselWrap>
          <LandingSelect
            keys={keys}
            selected={this.state.selected}
            onSelect={this.select}
          />
        </div>

        {/* col-md-5 col-md-push-5 */}
      </MainWrapper>
    );
  }
}

export default LandingFeatures;
