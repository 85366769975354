import React, { Component } from 'react';
import styled from 'styled-components';
import PreviewCard from '../../../../UI-Library/Containers/PreviewCard';
import { withRouter } from 'react-router-dom';

import Person from '../../../../UI-Library/Icons/Person';
import Checklist from '../../../../UI-Library/Icons/Checklist';
import { subjectColors } from '../../../../../constants/colors';

const ClassAssignmentsContainer = styled.div``;

const IconWrapper = styled.div`
  margin-right: 13px;
`;

const FlexMiddle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

const ClassInfo = styled.p`
  margin: 0;
`;

const CardInner = styled.div`
  ${(props) =>
    props.disabled ? `color: var(--rsDisabled);` : 'color:var(--rsSecondary);'}
`;

class Assignments extends Component {
  render() {
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    };

    const complete = [];
    const incomplete = [];

    const sortByField = (arr, field) =>
      arr.slice().sort((a, b) => {
        return a[field] - b[field];
      });

    this.props.assignments.forEach((assignment) => {
      if (this.props.completedSet.has(assignment.ref.id)) {
        complete.push(assignment);
      } else {
        incomplete.push(assignment);
      }
    });

    const sortedComplete = sortByField(complete, this.props.sortBy);

    const sortedIncomplete = sortByField(incomplete, this.props.sortBy);

    const sortedAssignments = sortedIncomplete.concat(sortedComplete);

    return (
      <ClassAssignmentsContainer>
        <h2 className='bold'>{this.props.title}</h2>
        {sortedAssignments.map((assignment) => {
          const cardIsDisabled =
            this.props.disabled ||
            (!this.props.isAdmin &&
              this.props.completedSet.has(assignment.ref.id));
          return (
            <PreviewCard
              disabled={cardIsDisabled}
              key={'ASSIGNMENTPREVIEW_' + assignment.ref.id}
              src={assignment.src}
              color={subjectColors[assignment.subject]}
              title={assignment.name}
              onClick={() =>
                this.props.history.push(
                  this.props.isAdmin
                    ? `/internal/classView/${assignment.classId}/assignments/${assignment.ref.id}/teacher/summary`
                    : `/internal/classView/${assignment.classId}/assignments/${assignment.ref.id}/student/null`
                )
              }
            >
              <CardInner
                disabled={cardIsDisabled}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  flexGrow: 1,
                }}
              >
                <div>
                  {assignment.description && (
                    <p className=' bold' style={{ marginBottom: '5px' }}>
                      {assignment.description}
                    </p>
                  )}
                  <FlexMiddle>
                    <IconWrapper>
                      <Person
                        size={'18px'}
                        hover={false}
                        disabled={cardIsDisabled}
                      />
                    </IconWrapper>

                    <ClassInfo>
                      {this.props.isAdmin ? (
                        `${assignment.studentsCompletedCount} students completed`
                      ) : this.props.completedSet.has(assignment.ref.id) ? (
                        <span>Complete</span>
                      ) : (
                        <span className='major'>Incomplete</span>
                      )}
                    </ClassInfo>
                  </FlexMiddle>
                  <FlexMiddle>
                    <IconWrapper>
                      <Checklist
                        size={'18px'}
                        hover={false}
                        disabled={cardIsDisabled}
                      />
                    </IconWrapper>
                    <ClassInfo>{assignment.numSets} exercises</ClassInfo>
                  </FlexMiddle>
                </div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <p style={{ marginBottom: '0' }}>
                    <span className='bold'>Due:</span>{' '}
                    {assignment.dueDate.toLocaleDateString('en-us', options)}
                  </p>
                  <p style={{ marginBottom: '0' }}>
                    <span className='bold'>Released:</span>{' '}
                    {assignment.releaseDate.toLocaleDateString(
                      'en-us',
                      options
                    )}
                  </p>
                </div>
              </CardInner>
            </PreviewCard>
          );
        })}
      </ClassAssignmentsContainer>
    );
  }
}

export default withRouter(Assignments);
