import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import * as ROUTES from '../../../../constants/routes';
import RsButton from '../../ButtonsOrLinks/RsButton';
import PropTypes from 'prop-types';

import { device } from '../../../../constants/breakpoints';
import DropdownMenu from '../../ButtonsOrLinks/DropdownMenu';

const HeaderPhoto = styled.img`
  height: 80px;
  width: auto;
  margin-bottom: -17px;
  @media screen and (max-width: 576px) {
    margin-left: 5px;
    height: 60px;
  }
`;

const HorNav = styled.nav`
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  margin-top: 0em;
  margin-bottom: 0em;
  background: transparent;
  z-index: 9999;
  padding-right: 2%;
  padding-left: 2%;

  height: 80px;
  width: 100%;

  @media ${device.mobileL} {
    padding-left: 0;
  }
`;

const NavbarLi = styled.li`
  display: inline-block;
  text-align: right;
  @media screen and (max-width: 576px) {
    font-size: 12px;
    padding: 0;
  }
`;

const ItemWrapper = styled.div`
  @media ${device.tablet} {
    display: none;
  }
`;

const DropdownWrapper = styled.div`
  display: none;
  @media ${device.tablet} {
    display: block;
  }
`;

const LinkStyle = {
  color: 'var(--rsPrimary)',
  marginLeft: '3vw',
};

const ActiveStyle = {
  // fontWeight: 'bold',
  // ! Commented out for now because the standard route '/' means that the home link is always active.
};

/**
 * TopNavAuthBase is the component for the TopNav bar when the
 * user is logged out.
 */
class TopNavNonAuth extends Component {
  constructor(props) {
    super(props);
    this.go = this.go.bind(this);
  }

  go(route) {
    this.props.history.push(route);
  }

  render() {
    const menuItems = [
      {
        requiredRole: 'Both',
        desc: 'Home',
        toggleMenuItemAction: () => this.go(ROUTES.LANDING),
      },
      {
        requiredRole: 'Both',
        desc: 'About',
        toggleMenuItemAction: () => this.go(ROUTES.ABOUT),
      },
      {
        requiredRole: 'Both',
        desc: 'Contact',
        toggleMenuItemAction: () => this.go(ROUTES.CONTACT),
      },
      {
        requiredRole: 'Both',
        desc: 'Sign in',
        toggleMenuItemAction: () => this.go(ROUTES.SIGNIN),
      },
      {
        requiredRole: 'Both',
        desc: 'Register',
        toggleMenuItemAction: () => this.go(ROUTES.SIGNUP),
      },
    ];

    return (
      <HorNav>
        <NavLink to={ROUTES.LANDING}>
          <HeaderPhoto src={'/images/logos/rapstudyblue.png'} alt='RapStudy' />
        </NavLink>

        <ItemWrapper>
          <NavbarLi>
            <NavLink
              to={ROUTES.LANDING}
              style={LinkStyle}
              activeStyle={ActiveStyle}
            >
              Home
            </NavLink>
          </NavbarLi>
          <NavbarLi>
            <NavLink
              to={ROUTES.ABOUT}
              style={LinkStyle}
              activeStyle={ActiveStyle}
            >
              About
            </NavLink>
          </NavbarLi>
          <NavbarLi>
            <NavLink
              to={ROUTES.CONTACT}
              style={LinkStyle}
              activeStyle={ActiveStyle}
            >
              Contact
            </NavLink>
          </NavbarLi>
          <NavbarLi>
            <NavLink
              to={ROUTES.SIGNIN}
              style={LinkStyle}
              activeStyle={ActiveStyle}
            >
              Sign in
            </NavLink>
          </NavbarLi>
          <NavbarLi>
            <NavLink
              to={ROUTES.SIGNUP}
              style={LinkStyle}
              activeStyle={ActiveStyle}
            >
              <RsButton>Register</RsButton>
            </NavLink>
          </NavbarLi>
        </ItemWrapper>

        <DropdownWrapper>
          <NavbarLi>
            <DropdownMenu
              direction='left'
              iconSize='large'
              isTeacher={true}
              menuItems={menuItems}
              width={80}
            />
          </NavbarLi>
        </DropdownWrapper>
      </HorNav>
    );
  }
}

class TopNavNonVerified extends Component {
  render() {
    return (
      <HorNav>
        <NavLink to={ROUTES.FREELANDING}>
          <HeaderPhoto src={'/images/logos/rapstudyblue.png'} alt='RapStudy' />
        </NavLink>

        <NavbarLi>
          <NavLink to={''} style={LinkStyle} onClick={this.props.signOut}>
            Sign Out
          </NavLink>
        </NavbarLi>
      </HorNav>
    );
  }
}

/**
 * The navigation bar at the top of the application. If signed in and verified
 * or verification is incomplete, the component is simply null. Built on top of
 * the bootstrap navigation.
 */
class TopNav extends Component {
  render() {
    if (!this.props.verificationDone) {
      return null;
    }
    if (this.props.verified) {
      return null;
    }
    if (this.props.authUser && !this.props.verified) {
      return (
        <TopNavNonVerified
          signOut={this.props.signOut}
          history={this.props.history}
        />
      );
    }
    return (
      <TopNavNonAuth signIn={this.props.signIn} history={this.props.history} />
    );
  }
}

TopNav.propTypes = {
  /**
   * Is the verification process done?
   */
  verificationDone: PropTypes.bool,
  /**
   * Is the user a verified customer?
   */
  verified: PropTypes.bool,
  /**
   * The firebase auth User object.
   */
  authUser: PropTypes.any,
  /**
   * The function to sign the user out.
   */
  signOut: PropTypes.func,
  /**
   * The function to sign the user in.
   */
  signIn: PropTypes.func,
};

export default withRouter(TopNav);
