import React from 'react';
import AboutMain from './AboutMain';
import AboutTeam from './AboutTeam';
import AboutRecap from './AboutRecap';
import AboutNews from './AboutNews';
import AboutTestimonials from './AboutTestimonials';

import LandingFooter from '../LandingFooter';

class About extends React.Component {
  render() {
    return (
      <div style={{ background: 'white' }}>
        <AboutMain />
        <AboutTeam />
        <AboutRecap />
        <AboutNews />
        <AboutTestimonials />
        <LandingFooter />
      </div>
    );
  }
}

export default About;
