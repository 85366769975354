import React, { Component } from 'react';
import styled from 'styled-components';
import LandingFooter from '../LandingFooter';
import MainBlock from './MainBlock';

import FinalBlock from './FinalBlock';
import LandingSongCovers from './LandingSongCovers';
import LandingFeatures from './LandingFeatures';
import LandingMap from './LandingMap';

const MainWrapper = styled.div`
  position: relative;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background: white;
`;

class LandingHome extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    var element = document.getElementById('MainBlockWrapper');
    document.addEventListener(
      'scroll',
      function (e) {
        element.setAttribute('style', 'animation: none !important;');
      },
      { once: true }
    );
  }

  render() {
    return (
      <MainWrapper>
        <MainBlock songArr={this.songArr} />
        <LandingSongCovers />
        <LandingFeatures />
        <LandingMap />
        <FinalBlock />
        <LandingFooter />
      </MainWrapper>
    );
  }
}

export default LandingHome;
