import React, { Component } from 'react';

import PropTypes from 'prop-types';
import ViewHeader from './ViewHeader';
import EditHeader from './EditHeader';

/**
 * This header is used in the song view for each of the features. For karaoke,
 * there is simply a button to toggle the fullscreen mode. For the other
 * features, there is a DropdownMenu to select a different set and a few buttons
 * to toggle the edit mode and delete the current set.
 */
class SelectionHeader extends Component {
  render() {
    if (!this.props.edit) {
      return (
        <ViewHeader
          userSets={this.props.userSets}
          featureName={this.props.featureName}
          fullscreen={this.props.fullscreen}
          title={this.props.title}
          authorName={this.props.authorName}
          selectSet={this.props.selectSet}
          customContentModalToggle={this.props.customContentModalToggle}
          toggleEdit={this.props.toggleEdit}
          clickFullScreen={this.props.clickFullScreen}
          isAuthor={this.props.isAuthor}
          reset={this.props.reset}
        />
      );
    }
    return (
      <EditHeader
        title={this.props.title}
        handleTitleChange={this.props.handleTitleChange}
        deleteModalToggle={this.props.deleteModalToggle}
        discardModalToggle={this.props.discardModalToggle}
        toggleEdit={this.props.toggleEdit}
      />
    );
  }
}

SelectionHeader.propTypes = {
  /**
   * Is the header in edit mode?
   */
  edit: PropTypes.bool.isRequired,
  /**
   * Which feature is this header on: karaoke, breakdown, blanks, or review?
   */
  featureName: PropTypes.oneOf(['Karaoke', 'Breakdown', 'Blanks', 'Review'])
    .isRequired,
  /**
   * Title of the current set
   */
  title: PropTypes.string,
  /**
   * Is the view in fullscreen?
   */
  fullscreen: PropTypes.bool.isRequired,
  /**
   * Function to toggle fullscreen view
   */
  clickFullScreen: PropTypes.func,
  /**
   * An array of objects holding info about the custom user sets
   */
  userSets: PropTypes.any,
  /**
   * Function to create a new set
   */
  createSet: PropTypes.func,
  /**
   * Locally changes title of set in edit mode
   */
  handleTitleChange: PropTypes.func,
  /**
   * Toggle for the delete set modal
   */
  deleteModalToggle: PropTypes.func,
  /**
   * Toggle for discard changes modals
   */
  discardModalToggle: PropTypes.func,
  /**
   * Button to toggle edit state
   */
  toggleEdit: PropTypes.func,
  /**
   * Function to select a different (existing) user set
   */
  selectSet: PropTypes.func,
  /**
   * Function to reset the exercise.
   */
  reset: PropTypes.func,
  /**
   * Is the current user the author of the set?
   */
  isAuthor: PropTypes.bool,
  /**
   * What is the set author's name?
   */
  authorName: PropTypes.string,
};
export default SelectionHeader;
