import Song from './Song';
import StudySet from './StudySet';

// HELPERS
function findWithAttr(array, attr, value) {
  for (var i = 0; i < array.length; i += 1) {
    if (array[i][attr] === value) {
      return i;
    }
  }
  return -1;
}

class SongSetsDict {
  constructor(songId) {
    this.songId = songId;
    this.songData = null;
    this.allSets = {};
    this.reviewSets = [];
    this.breakdownSets = [];
    this.blanksSets = [];
    this.lastAccess = null;
    // This is for assignment creation, where the user needs to select multiple sets
    this.selected = new Set();
  }

  static recentCompare(songDict1, songDict2) {
    return songDict2.lastAccess - songDict1.lastAccess;
  }

  static newTitleCompare(songDict1, songDict2) {
    return songDict1.songData.subtopic.name.localeCompare(
      songDict2.songData.subtopic.name
    );
  }

  static unitCompare(songDict1, songDict2) {
    return songDict1.songData.unit.name.localeCompare(
      songDict2.songData.unit.name
    );
  }

  addReviewSet(setData) {
    if (!(setData instanceof StudySet))
      throw Error(
        'Invalidated Precondition: setData must be a StudySet object'
      );
    this.reviewSets.push(setData);
    this.allSets[setData.setId] = setData;
    var lastDate = setData.getLastModifiedDate();
    if (lastDate > this.lastAccess) {
      this.lastAccess = lastDate;
    }
  }

  addBreakdownSet(setData) {
    if (!(setData instanceof StudySet))
      throw Error(
        'Invalidated Precondition: setData must be a StudySet object'
      );
    this.breakdownSets.push(setData);
    this.allSets[setData.setId] = setData;
    var lastDate = setData.getLastModifiedDate();
    if (lastDate > this.lastAccess) {
      this.lastAccess = lastDate;
    }
  }

  addBlanksSet(setData) {
    if (!(setData instanceof StudySet))
      throw Error(
        'Invalidated Precondition: setData must be a StudySet object'
      );
    this.blanksSets.push(setData);
    this.allSets[setData.setId] = setData;
    var lastDate = setData.getLastModifiedDate();
    if (lastDate > this.lastAccess) {
      this.lastAccess = lastDate;
    }
  }

  setSongData(songData) {
    if (!(songData instanceof Song))
      throw Error('Invalidated Precondition: songData must be a Song object');
    this.songData = songData;
  }

  deleteSet(feature, setId) {
    var arr = null;
    if (feature === 'Review') {
      arr = this.reviewSets;
    } else if (feature === 'Breakdown') {
      arr = this.breakdownSets;
    } else if (feature === 'Blanks') {
      arr = this.blanksSets;
    } else {
      throw Error('Invalid feature name.');
    }
    const indexToDel = findWithAttr(arr, 'setId', setId);
    if (indexToDel > -1) {
      arr.splice(indexToDel, 1);
    } else {
      throw Error('Deletion failed, set could not be found.');
    }
    if (this.selected.has(setId)) {
      this.selected.delete(setId);
    }
    delete this.allSets[setId];
  }

  isEmpty() {
    return (
      this.reviewSets.length === 0 &&
      this.breakdownSets.length === 0 &&
      this.blanksSets.length === 0
    );
  }

  getNumSets() {
    return (
      this.reviewSets.length +
      this.breakdownSets.length +
      this.blanksSets.length
    );
  }

  getNumNonEmptySets() {
    var total = 0;
    for (var set of this.breakdownSets) {
      if (set.getNumItems() > 0) {
        total++;
      }
    }
    for (set of this.blanksSets) {
      if (set.getNumItems() > 0) {
        total++;
      }
    }

    for (set of this.reviewSets) {
      if (set.getNumItems() > 0) {
        total++;
      }
    }
    return total;
  }

  getNonEmptyFeatures() {
    const result = [];
    if (this.breakdownSets.length > 0) {
      result.push('Breakdown');
    }
    if (this.blanksSets.length > 0) {
      result.push('Blanks');
    }
    if (this.reviewSets.length > 0) {
      result.push('Review');
    }
    return result;
  }

  getNonEmptyNonZeroFeatures() {
    const result = [];
    var set;
    if (this.breakdownSets.length > 0) {
      for (set of this.breakdownSets) {
        if (set.getNumItems() > 0) {
          result.push('Breakdown');
          break;
        }
      }
    }
    if (this.blanksSets.length > 0) {
      for (set of this.blanksSets) {
        if (set.getNumItems() > 0) {
          result.push('Blanks');
          break;
        }
      }
    }
    if (this.reviewSets.length > 0) {
      for (set of this.reviewSets) {
        if (set.getNumItems() > 0) {
          result.push('Review');
          break;
        }
      }
    }
    return result;
  }

  toggleSetSelection(setId) {
    if (!this.hasSet(setId)) {
      throw Error('Toggle failed: Set not found in CustomSetDict.');
    }
    if (this.selected.has(setId)) {
      this.selected.delete(setId);
      return false;
    } else {
      this.selected.add(setId);
      return true;
    }
  }

  selectMode(setId, mode) {
    if (!this.hasSet(setId)) {
      throw Error('Toggle failed: Set not found in CustomSetDict.');
    }
    const set = this.getSetData(setId);
    set.setMode(mode);
  }

  hasSet(setId) {
    return setId in this.allSets;
  }

  getSongData() {
    return this.songData;
  }

  getSetData(setId) {
    return this.allSets[setId];
  }

  getSelectedSets() {
    return Array.from(this.selected);
  }

  getAllSets() {
    return this.allSets;
  }

  isSelected(setId) {
    return this.selected.has(setId);
  }
}

export default SongSetsDict;
