import React from 'react';
import styled from 'styled-components';
import { device } from '../../../../constants/breakpoints';

const MainWrapper = styled.div`
  background: white;
  max-width: 1440px;
  margin: auto;
  padding: 10%;
  padding-top: 3%;
`;

const Header = styled.h1`
  max-width: 1000px;
  font-size: 36px;
  font-weight: bold;
  color: black;
  text-align: left;

  @media ${device.tablet} {
    font-size: 30px;
  }
  @media ${device.mobileL} {
    font-size: 24px;
  }
`;

const SubHeader = styled.h1`
  color: black;
  margin-bottom: 30px;
  text-align: left;
  font-weight: 300;
  font-size: 24px;

  @media ${device.tablet} {
    font-size: 18px;
    margin-bottom: 20px;
  }
  @media ${device.mobileL} {
    font-size: 14px;
    margin-bottom: 10px;
  }
`;

const Text = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 5%;
`;

const FeatureImage = styled.img`
  max-width: 100%;
  @media ${device.mobileL} {
    max-width: 80vw;
  }
`;

class LandingFeatures extends React.Component {
  render() {
    return (
      <MainWrapper className='row'>
        <div className='col-lg-6 order-lg-1 order-md-2 order-2 text-center text-lg-left'>
          <FeatureImage
            src={
              'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2FZOOM_SCENE%201.png?alt=media&token=5dcfa983-9c48-484e-b8d9-7d4977ee3b4b'
            }
          />
        </div>
        <Text className='col-lg-6 order-lg-2 order-md-1 order-1'>
          <Header>For students, by students</Header>
          <SubHeader>
            This fall, we partnered with 26 schools in the tri-state area: 19 in
            NJ, 3 in NY and 4 in CT.
          </SubHeader>
        </Text>
      </MainWrapper>
    );
  }
}

export default LandingFeatures;
