import Question from '../../Classes/Question.js';
import QuestionList from '../../Classes/QuestionList.js';

export async function getQuestionSetsForSong(songId) {
  const setsRef = this.db.collection('questionSets');
  const querySnap = await setsRef
    .where('author', '==', this.auth.currentUser.uid)
    .where('songId', '==', songId)
    .orderBy('lastModified', 'desc')
    .get();
  if (querySnap.empty) return [];
  return querySnap.docs.map((docSnap) => {
    const obj = docSnap.data();
    obj.setId = docSnap.id;
    return obj;
  });
}

/** Queries the given song id's questions URL from firestore with a custom set.
 *
 * @param {string} setId
 * @returns a Promise that resolves with an array of Question objects.
 */
export async function getQuestionList(setId) {
  let qList = null;
  if (setId !== 'null') {
    const setDoc = this.db.collection('questionSets').doc(setId);
    qList = new QuestionList();

    // Setup promises
    const setPr = setDoc.get();
    const questionPr = setDoc
      .collection('questions')
      .orderBy('index')
      .withConverter(Question.questionConverter)
      .get();

    // resolve promises
    const resources = await Promise.all([setPr, questionPr]);
    const setSnap = resources[0];

    if (!setSnap.exists) throw this.NotFoundError;

    const questionDocs = resources[1].docs;
    questionDocs.forEach((question) => {
      qList.add(question.data());
    });

    // Metadata handling
    const name = setSnap.get('name');
    const authorId = setSnap.get('author');
    const songId = setSnap.get('songId');
    const authorProfileSnap = await this.db
      .collection('userProfiles')
      .doc(authorId)
      .get();
    const authorName =
      authorProfileSnap.get('first_name') +
      ' ' +
      authorProfileSnap.get('last_name');

    qList.setName(name);
    qList.setAuthorId(authorId);
    qList.setAuthorName(authorName);
    qList.setSongId(songId);
  }
  return qList;
}

/**
 * Updates an existing question set's content
 * @param {string} songId
 * @param {string} set_id
 * @param {string} name
 * @param {QuestionList} qList
 */
export async function updateCustomQuestions(songId, set_id, qList) {
  if (!set_id || !qList) {
    throw Error('Update questions called with invalid parameters.');
  }
  const setRef = this.db.collection('questionSets').doc(set_id);
  const questionsRef = setRef.collection('questions');

  const query = await questionsRef.get();
  const qDocs = query.docs;
  const firebaseRefs = qDocs.map((doc) => {
    return doc.ref;
  }); // array of firebase document references

  // 1. delete refs in firestore but not in local
  await Promise.all(
    firebaseRefs.map(async (ref) => {
      if (!qList.hasId(ref.id)) {
        await ref.delete();
      }
    })
  );

  // 2. for all local refs, set to create new OR overwrite
  const questions = qList.getQuestions();
  await Promise.all(
    Object.values(questions).map(async (question) => {
      await questionsRef
        .doc(question.getId())
        .withConverter(Question.questionConverter)
        .set(question);
    })
  );

  // 3. update name and numitems
  await setRef.update({
    name: qList.getName(),
    numItems: qList.getLength(),
    lastModified: this.firebase.firestore.FieldValue.serverTimestamp(),
  });
  return;
}

/**
 * Creates a new document for question sets content
 * @param {string} songId
 * @param {string} name
 * @returns {string} Firestore id of the set document.
 */
export async function createCustomQuestions(songId, name) {
  const questionSetsRef = this.db.collection('questionSets');
  const setRef = await questionSetsRef.add({
    name: name,
    author: this.auth.currentUser.uid,
    lastModified: this.firebase.firestore.FieldValue.serverTimestamp(),
    created: this.firebase.firestore.FieldValue.serverTimestamp(),
    songId: songId,
  });
  //Log in event 'set_created' [Firebase Analytics]
  this.analytics.logEvent('set_created', {
    feature: 'review',
  });
  return setRef.id;
}

/**
 * Deletes the given question set document.
 * @param {string} songId
 * @param {string} setId
 */
export async function deleteCustomQuestions(songId, setId) {
  const deleteReviewSet = this.functions.httpsCallable(
    'featureSets-deleteFeatureSet'
  );
  await deleteReviewSet({ setId: setId, featureName: 'Review' });
}

export async function setCustomQuestionImg(file, fileName) {
  // TODO Review: case work on what 'file' is
  const type = file.type.split('/')[1];
  if (type === 'jpeg' || type === 'png') {
    const fileRef = this.storage.ref(
      'users/' + this.auth.currentUser.uid + '/images/' + fileName
    );
    await fileRef.put(file);
    const url = await fileRef.getDownloadURL();
    return url;
  }
}
