import React, { Component } from 'react';
import { withFirebase } from '../../../Firebase';

import RsModal from '../RsModal';
import RsButton from '../../ButtonsOrLinks/RsButton';

class ExerciseCompletionBase extends Component {
  constructor(props) {
    super(props);
    this.handleModalShowHide = this.handleModalShowHide.bind(this);
  }

  handleModalShowHide() {
    if (
      this.props.exerciseType === 'blanks' ||
      this.props.exerciseType === 'karaoke'
    ) {
      this.props.reset();
    }
    if (this.props.exerciseType === 'review') {
      this.props.refresh();
    }
  }

  render() {
    const type = this.props.exerciseType;
    var title = '';
    var blurb = '';
    if (type === 'review') {
      title = 'You finished Review!';
      blurb =
        'You know your stuff! Feel free to try these exercises again, or check out another song!';
    } else if (type === 'karaoke') {
      title = 'You finished the song!';
      blurb =
        'Try Breakdown to get a closer look at the lyrics and supercharge your knowledge!';
    } else {
      title = 'You finished the song!';
      blurb =
        "If you think you've mastered the lyrics, hammer down the material with Review!";
    }
    return (
      <RsModal
        show={this.props.show}
        toggle={this.handleModalShowHide}
        title={title}
      >
        <RsModal.Body fx={'col-center'}>{blurb}</RsModal.Body>
        <RsModal.Footer style={{ width: '100%' }}>
          <RsButton
            onClick={this.handleModalShowHide}
            large={true}
            width={'100%'}
          >
            Reset
          </RsButton>
        </RsModal.Footer>
      </RsModal>
    );
  }
}

const ExerciseCompletion = withFirebase(ExerciseCompletionBase);
export default ExerciseCompletion;
