import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Fullscreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    };
    this.handleEnter = this.handleEnter.bind(this);
    this.handleLeave = this.handleLeave.bind(this);
  }

  handleEnter() {
    this.setState({ hover: true });
  }

  handleLeave() {
    this.setState({ hover: false });
  }

  render() {
    const hover =
      typeof this.props.hover !== 'undefined'
        ? this.props.hover
        : this.state.hover;

    const color = this.props.disabled
      ? 'var(--rsDisabled)'
      : hover
      ? 'var(--rsPrimary)'
      : 'var(--rsSecondary)';
    return (
      <div
        onMouseEnter={this.handleEnter}
        onMouseLeave={this.handleLeave}
        onClick={this.props.disabled ? null : this.props.onClick}
        style={{
          cursor:
            this.props.onClick && !this.props.disabled ? 'pointer' : 'default',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {this.props.toEnter ? (
          <svg
            width={this.props.size ? this.props.size : '24px'}
            height={this.props.size ? this.props.size : '24px'}
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 18 18'
          >
            <path
              d='M13.12,2.09h2a.8.8,0,0,1,.8.8V4.7a.79.79,0,0,0,.79.79h0a.79.79,0,0,0,.8-.79V1.3a.8.8,0,0,0-.8-.8H13.12a.8.8,0,0,0-.8.8h0A.79.79,0,0,0,13.12,2.09Z'
              fill={color}
              stroke={color}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M2.09,4.7V2.89a.8.8,0,0,1,.8-.8H4.51a.79.79,0,0,0,.8-.79h0a.8.8,0,0,0-.8-.8H1.3a.8.8,0,0,0-.8.8V4.7a.79.79,0,0,0,.8.79h0A.79.79,0,0,0,2.09,4.7Z'
              fill={color}
              stroke={color}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M4.51,15.91H2.89a.8.8,0,0,1-.8-.8V13.3a.79.79,0,0,0-.79-.79h0a.79.79,0,0,0-.8.79v3.4a.8.8,0,0,0,.8.8H4.51a.8.8,0,0,0,.8-.8h0A.79.79,0,0,0,4.51,15.91Z'
              fill={color}
              stroke={color}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M15.91,13.3v1.81a.8.8,0,0,1-.8.8h-2a.79.79,0,0,0-.8.79h0a.8.8,0,0,0,.8.8H16.7a.8.8,0,0,0,.8-.8V13.3a.79.79,0,0,0-.8-.79h0A.79.79,0,0,0,15.91,13.3Z'
              fill={color}
              stroke={color}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        ) : (
          <svg
            width={this.props.size ? this.props.size : '24px'}
            height={this.props.size ? this.props.size : '24px'}
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 18 18'
          >
            <g opacity='0.78'>
              <polygon
                points='14.1 0.5 14.1 4.05 17.5 4.05 17.5 5.63 12.51 5.63 12.51 0.5 14.1 0.5'
                fill={color}
                stroke={color}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <polygon
                points='0.5 3.86 3.9 3.86 3.9 0.5 5.49 0.5 5.49 5.44 0.5 5.44 0.5 3.86'
                fill={color}
                stroke={color}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <polygon
                points='0.59 14.04 3.81 14.04 3.81 17.41 5.4 17.41 5.4 12.47 0.59 12.47 0.59 14.04'
                fill={color}
                stroke={color}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <polygon
                points='17.5 13.95 14.1 13.95 14.1 17.5 12.51 17.5 12.51 12.38 17.5 12.38 17.5 13.95'
                fill={color}
                stroke={color}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </g>
          </svg>
        )}
      </div>
    );
  }
}

Fullscreen.propTypes = {
  /**
   * Should the icon take on disabled styling?
   */
  disabled: PropTypes.bool,
  /**
   * The function fired when the icon is clicked.
   */
  onClick: PropTypes.func,
  /**
   * The size of the icon. Defaults to 24px.
   */
  size: PropTypes.string,
  /**
   * A hover override from the parent component. If defined, the local
   * state hover is ignored.
   */
  hover: PropTypes.bool,
  /**
   * Does the icon indicate entering fullscreen mode?
   */
  toEnter: PropTypes.bool,
};

export default Fullscreen;
