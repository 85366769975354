import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 850px;
`;

const MainImage = styled.img`
  max-height: 225px;
  max-width: 50%;
`;

const Lead = styled.h1`
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;
`;

/**
 * EmptyContent is the generic container for a screen with no available content.
 */
class EmptyContent extends Component {
  render() {
    return (
      <MainWrapper>
        <MainImage src={this.props.src} />
        <div>
          <Lead>{this.props.lead}</Lead>
          {this.props.children}
        </div>
      </MainWrapper>
    );
  }
}

EmptyContent.propTypes = {
  /**
   * The title text for the container.
   */
  lead: PropTypes.string,
  /**
   * The image path/url.
   */
  src: PropTypes.string,
};

export default EmptyContent;
