import React, { Component } from 'react';
import styled from 'styled-components';

const StyledInput = styled.input`
  background: rgba(255, 255, 255, 0.35);
  border: 3px solid #ffffff;
  border-radius: 8px;
  margin-bottom: ${(props) => (props.mb ? props.mb : '0px')};
  width: 100%;
  min-height: ${(props) => (props.minHeight ? props.minHeight : '52px')};
  color: white;
  padding: 5px;
  font-weight: bold;

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba(255, 255, 255, 0.5);
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: rgba(255, 255, 255, 0.5);
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: rgba(255, 255, 255, 0.5);
  }
`;

const StyledArea = styled.textarea`
  background: rgba(255, 255, 255, 0.35);
  border: 3px solid #ffffff;
  border-radius: 8px;
  margin-bottom: ${(props) => (props.mb ? props.mb : '0px')};
  width: 100%;
  min-height: ${(props) => (props.minHeight ? props.minHeight : '52px')};
  color: white;
  padding: 5px;
  font-weight: bold;

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba(255, 255, 255, 0.5);
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: rgba(255, 255, 255, 0.5);
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: rgba(255, 255, 255, 0.5);
  }
`;

class ContactInput extends Component {
  render() {
    if (this.props.textarea) {
      return (
        <StyledArea
          name={this.props.name}
          placeholder={this.props.placeholder}
          mb={this.props.mb}
          minHeight={this.props.minHeight}
          value={this.props.value}
          onChange={this.props.onChange}
        />
      );
    }

    return (
      <StyledInput
        name={this.props.name}
        placeholder={this.props.placeholder}
        mb={this.props.mb}
        minHeight={this.props.minHeight}
        value={this.props.value}
        onChange={this.props.onChange}
      />
    );
  }
}

export default ContactInput;
