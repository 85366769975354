import React, { Component } from 'react';
import { withFirebase } from '../../../Firebase';
import { withAlert } from 'react-alert';

import RsModal from '../../Modals/RsModal';
import RsButton from '../../ButtonsOrLinks/RsButton';
import RsInput from '../../Forms/RsInput';

export class FeedbackModalBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      feedback: '',
    };
  }

  onChange = (event) => {
    this.setState({ feedback: event.target.value });
  };

  submit = (event) => {
    event.preventDefault();
    try {
      this.props.firebase.setFeedback(
        this.state.feedback,
        this.props.firebase.auth.currentUser.email,
        this.props.firstName,
        this.props.lastName,
        this.props.firebase.auth.currentUser.uid
      );
      if (this.props.alert) {
        this.props.alert.show('Successfully submitted feedback.');
      }
    } catch (err) {
      console.log('setFeedback error. Throwing...');
      throw err;
    }

    this.props.firebase.analytics.logEvent('feedbackReport', {
      bug: this.state.feedback,
    });
    this.props.onHide();
  };

  render() {
    const isInvalid = this.state.feedback.trim() === '';
    return (
      <RsModal
        show={this.props.show}
        toggle={this.props.onHide}
        title={'Give us Feedback'}
        submit={this.submit}
      >
        <RsModal.Body>
          Your Feedback
          <RsInput
            textarea={true}
            onChange={this.onChange}
            value={this.state.feedback}
            height={'144px'}
          />
        </RsModal.Body>
        <RsModal.Footer jContent={'space-between'}>
          <RsButton secondary={true} onClick={this.props.onHide}>
            Cancel
          </RsButton>
          <RsButton onClick={this.submit} disabled={isInvalid}>
            Save
          </RsButton>
        </RsModal.Footer>
      </RsModal>
    );
  }
}

const FeedbackModal = withAlert()(withFirebase(FeedbackModalBase));

export default FeedbackModal;
