import React, { Component } from 'react';
import styled from 'styled-components';
import { device } from '../../../constants/breakpoints';

const Section = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 10px;
  padding-left: 5%;
  padding-right: 5%;
  background: #121212;

  @media ${device.tablet} {
    padding-left: 1%;
    padding-right: 1%;
  }
`;

const FooterPhoto = styled.img`
  height: 80px;
  width: auto;
  margin-right: 62.78%;
  margin-top: 40px;
  margin-bottom: 17px;
  margin-left: -3px;
  @media screen and (max-width: 576px) {
    margin-top: 25px;
  }
`;

const SocialMediaLogo = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 10px;
`;

const Line = styled.div`
  border: 0.5px solid rgba(255, 255, 255, 0.78);
`;

const Info = styled.div`
  font-size: 16px;
  color: rgba(255, 255, 255, 0.78);
  display: flex;
  justify-content: space-between;
  padding-left: 5%;
`;

const RowInfo = styled.div`
  position: relative;
  margin-bottom: 60px;
`;

const Bottom = styled.div`
  margin-top: 40px;
  padding-bottom: 90px;
`;
const TOSLink = styled.a`
  text-decoration: none;
  color: white;
  &:hover {
    color: white;
    text-decoration: none;
  }
  margin-left: 20px;
`;
class LandingFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Section>
        <div>
          <FooterPhoto src={'/images/logos/rapstudyblue.png'} alt='RapStudy' />
        </div>
        <RowInfo>
          <Info className='row'>
            <div className='col-sm-6'>
              <p>
                <b>Contact Us</b>
              </p>
              <p>
                info@rapstudy.com<br></br>(203) 540-9722
              </p>
            </div>

            <div className='col-sm-6'>
              <p>
                <b>Follow Us</b>
              </p>
              <p>
                <a
                  href='https://www.instagram.com/rapstudy/'
                  style={{
                    color: 'rgba(255,255,255,0.78)',
                    textDecoration: 'none',
                  }}
                >
                  <SocialMediaLogo
                    src={'/images/landing/instagram.svg'}
                    alt='https://www.instagram.com/rapstudy/'
                  />
                  Instagram
                </a>
              </p>
              <p>
                <a
                  href='https://www.linkedin.com/company/rapstudy/'
                  style={{
                    color: 'rgba(255,255,255,0.78)',
                    textDecoration: 'none',
                  }}
                >
                  <SocialMediaLogo
                    src={'/images/landing/linkedin.svg'}
                    alt=''
                  />
                  LinkedIn
                </a>
              </p>
              <p>
                <a
                  href='https://www.facebook.com/RapStudy-110562207266080/'
                  style={{
                    color: 'rgba(255,255,255,0.78)',
                    textDecoration: 'none',
                  }}
                >
                  <SocialMediaLogo
                    src={'/images/landing/facebook.svg'}
                    alt=''
                  />
                  Facebook
                </a>
              </p>
            </div>
          </Info>
        </RowInfo>
        <Line />

        <Bottom>
          <Info>
            <p style={{ margin: 0 }}>© 2021 rapStudy Inc.</p>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <TOSLink
                secondary={true}
                href={
                  'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/documents%2F%5BrapStudy%5D%20PP.pdf?alt=media&token=ac72e73b-1f95-4b69-a46b-de02d0bea869'
                }
                download='rapStudy Privacy Policy'
                target='_blank'
              >
                Privacy Policy
              </TOSLink>
              <TOSLink
                secondary={true}
                href={
                  'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/documents%2F%5BrapStudy%5D%20TOU.pdf?alt=media&token=ff00b065-3e73-43c3-8e02-0cf2b338596f'
                }
                download='rapStudy Terms of Use'
                target='_blank'
              >
                Terms of Use
              </TOSLink>
            </div>
          </Info>
        </Bottom>
      </Section>
    );
  }
}

export default LandingFooter;
