import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Speaker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    };
    this.handleEnter = this.handleEnter.bind(this);
    this.handleLeave = this.handleLeave.bind(this);
  }

  handleEnter() {
    this.setState({ hover: true });
  }

  handleLeave() {
    this.setState({ hover: false });
  }

  render() {
    const hover =
      typeof this.props.hover !== 'undefined'
        ? this.props.hover
        : this.state.hover;

    const color = this.props.disabled
      ? 'var(--rsDisabled)'
      : hover
      ? 'var(--rsPrimary)'
      : 'var(--rsSecondary)';
    return (
      <div
        onMouseEnter={this.handleEnter}
        onMouseLeave={this.handleLeave}
        onClick={this.props.disabled ? null : this.props.onClick}
        style={{
          cursor:
            this.props.onClick && !this.props.disabled ? 'pointer' : 'default',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <svg
          width={this.props.size ? this.props.size : '24px'}
          height={this.props.size ? this.props.size : '24px'}
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 18 18'
        >
          <polygon
            points='1.97 5.74 5.23 5.74 9.64 3.19 9.64 13.94 5.23 11.39 1.97 11.39 1.97 5.74'
            fill={color}
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
          />
          <path
            d='M13.82,13A5.37,5.37,0,0,0,14,4.26'
            fill='none'
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
          />
          <path
            d='M12.36,6.47a3.5,3.5,0,0,1,.75,2.17,3.42,3.42,0,0,1-.59,1.94'
            fill='none'
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
          />
        </svg>
      </div>
    );
  }
}

Speaker.propTypes = {
  /**
   * Should the icon take on disabled styling?
   */
  disabled: PropTypes.bool,
  /**
   * The function fired when the icon is clicked.
   */
  onClick: PropTypes.func,
  /**
   * The size of the icon. Defaults to 24px.
   */
  size: PropTypes.string,
  /**
   * A hover override from the parent component. If defined, the local
   * state hover is ignored.
   */
  hover: PropTypes.bool,
};

export default Speaker;
