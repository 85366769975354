import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import StudySet from '../../../../../Classes/StudySet';
import RsRadioButton from '../../../Forms/RsRadioButton';
import RsButton from '../../../ButtonsOrLinks/RsButton';

import { capitalizeFLetter } from '../../../../../utility/stringFunctions';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ChoiceWrap = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0 5px 0;
  cursor: pointer;
`;

const Desc = styled.p`
  margin: 0 0 0 10px;
  font-size: 24px;
`;

const SelectionTitle = styled.p`
  margin: 5px 0 5px 0;
`;

class FeatureStartMode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: null,
    };
    this.submit = this.submit.bind(this);
  }

  submit() {
    this.props.start(this.state.mode);
  }

  componentDidMount() {
    const modes = StudySet.featuresAndModes[this.props.featureName];
    if (!modes || modes.length === 0) return;
    this.setState({ mode: modes[0] });
  }

  render() {
    const { featureName } = this.props;
    const { mode } = this.state;
    const modes = StudySet.featuresAndModes[featureName];

    return (
      <Wrapper>
        {modes.length > 0 ? (
          <div>
            <SelectionTitle className='secondary bold'>
              First, choose a mode!
            </SelectionTitle>
            {modes.map((modeName) => (
              <ChoiceWrap
                key={`featurestartmode_${featureName}_${modeName}`}
                onClick={() => this.setState({ mode: modeName })}
              >
                <RsRadioButton
                  selected={mode === modeName}
                  onSelect={() => null}
                />
                <Desc className='bold'>{capitalizeFLetter(modeName)}</Desc>
              </ChoiceWrap>
            ))}
          </div>
        ) : (
          <div></div>
        )}

        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <RsButton large={true} onClick={this.submit}>
            Start
          </RsButton>
        </div>
      </Wrapper>
    );
  }
}

FeatureStartMode.propTypes = {
  /**
   * The name of the feature.
   */
  featureName: PropTypes.string,
  /**
   * The function to begin the feature. Takes in one parameter, the string|null
   * representing the name of the mode selected.
   */
  start: PropTypes.func,
};

FeatureStartMode.defaultProps = {
  featureName: '',
  start: () => null,
};

export default FeatureStartMode;
