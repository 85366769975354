class Selection {
  /**
   * Returns an Array [number,number] of the start and end word index of a selection object.
   * Returns null if selection is invalid.
   */
  static getStartEndWord(selection) {
    // ? needs more testing on different browsers. Needs better typing in function params

    if (!selection || selection.toString() === '') return null;

    if (!selection.anchorNode || !selection.focusNode) return null;

    var startNode;
    var endNode;

    // ! kind of a hacky solution for firefox.
    if (
      selection.anchorNode.id &&
      selection.anchorNode.id.substring(0, 5) === 'word_'
    ) {
      startNode = selection.anchorNode;
    } else {
      startNode = selection.anchorNode.parentElement;
    }

    if (
      selection.focusNode.id &&
      selection.focusNode.id.substring(0, 5) === 'word_'
    ) {
      endNode = selection.focusNode;
    } else {
      endNode = selection.focusNode.parentElement;
    }

    if (!startNode || !endNode) {
      return null;
    }

    var start = startNode.id;
    var end = endNode.id;
    if (start.substring(0, 5) !== 'word_' || end.substring(0, 5) !== 'word_') {
      return null;
    }

    var ind1 = parseInt(start.substring(5, start.length));
    var ind2 = parseInt(end.substring(5, end.length));

    const startIndex = Math.min(ind1, ind2);
    const endIndex = Math.max(ind1, ind2);
    return [startIndex, endIndex];
  }

  /**
   * Returns an Array [number,number] of the start and end word index of a selection object.
   * Returns null if selection is invalid.
   */
  static getStartEndLine(id, selection) {
    // check for invalid selection, return null
    if (
      !selection.anchorNode ||
      !selection.focusNode ||
      selection.toString() === ''
    ) {
      return null;
    }

    var startNode;
    var endNode;

    // ! kind of a hacky solution for firefox.
    if (!id) {
      console.log('Props id not found... returning.');
      return null;
    }
    const idLength = id.length;

    if (
      selection.anchorNode.id &&
      selection.anchorNode.id.substring(idLength, idLength + 6) === '_line_'
    ) {
      startNode = selection.anchorNode;
    } else {
      startNode = selection.anchorNode.parentElement;
    }
    if (
      selection.focusNode.id &&
      selection.focusNode.id.substring(idLength, idLength + 6) === '_line_'
    ) {
      endNode = selection.focusNode;
    } else {
      endNode = selection.focusNode.parentElement;
    }

    if (!startNode || !endNode) {
      return null;
    }

    const start = startNode.id;
    const end = endNode.id;

    if (
      start.substring(idLength, idLength + 6) !== '_line_' ||
      end.substring(idLength, idLength + 6) !== '_line_'
    ) {
      return null;
    }

    var startInd = parseInt(start.substring(idLength + 6));
    var endInd = parseInt(end.substring(idLength + 6));

    return [Math.min(startInd, endInd), Math.max(startInd, endInd)];
  }
}

export default Selection;
