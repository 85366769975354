import React, { Component } from 'react';
import styled from 'styled-components';
import { withFirebase } from '../../Firebase';
import { withRouter } from 'react-router-dom';
import { withAlert } from 'react-alert';
import Loading from '../../UI-Library/Misc/Loading';
import * as ROUTES from '../../../constants/routes';

import BackArrow from '../../UI-Library/ButtonsOrLinks/BackArrow';
import SimpleBanner from '../../UI-Library/Containers/SimpleBanner';
import CreateAssignmentDetails from './CreateAssignmentDetails';
import CreateAssignmentSets from './CreateAssignmentSets';
import CreateAssignmentFooter from './CreateAssignmentFooter';
import EmptyContent from '../../UI-Library/Misc/EmptyContent';

const PageWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
`;

const MainWrapper = styled.div`
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 2%;
  padding-bottom: 2%;
`;

class CreateAssignmentView extends Component {
  constructor(props) {
    super(props);
    const today = new Date();
    const release = new Date(today.getTime());
    const weekFromToday = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000);
    release.setHours(23);
    release.setMinutes(59);
    release.setMilliseconds(999);
    weekFromToday.setHours(23);
    weekFromToday.setMinutes(59);
    weekFromToday.setMilliseconds(999);
    this.state = {
      ready: false,
      creating: false,
      page: 0,
      assignmentName: '',
      description: '',
      releaseDate: release, // default date is today.
      dueDate: weekFromToday, // default due date is a week from today.
      customSetDict: null,
      err: null,
    };
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleDescChange = this.handleDescChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSelectMode = this.handleSelectMode.bind(this);
    this.next = this.next.bind(this);
    this.back = this.back.bind(this);
    this.submit = this.submit.bind(this);
    this.changeDueDate = this.changeDueDate.bind(this);
    this.changeReleaseDate = this.changeReleaseDate.bind(this);
    this.reorder = this.reorder.bind(this);
  }

  handleNameChange(e) {
    this.setState({ assignmentName: e.target.value });
  }

  handleDescChange(e) {
    this.setState({ description: e.target.value });
  }

  assertDates(release, due) {
    if (!release || !due)
      return {
        success: false,
        msg: 'Invalid dates, please re-enter your dates and try again.',
      };
    if (due < release)
      return {
        success: false,
        msg: 'The assignment cannot be due before it is released.',
      };

    return {
      success: true,
      msg: '',
    };
  }

  nextAllowed() {
    if (this.state.page === 0) {
      return (
        this.assertDates(this.state.releaseDate, this.state.dueDate).success &&
        this.state.assignmentName.trim().length > 0
      );
    }
    if (this.state.page === 1) {
      return (
        this.state.customSetDict &&
        this.state.customSetDict.getSelectedSetsArr().length > 0
      );
    }
  }

  next() {
    if (this.state.page === 0) {
      this.setState({ page: 1 });
    }
  }

  back() {
    if (this.state.page === 1) {
      this.setState({ page: 0 });
    }
  }

  async submit() {
    try {
      this.setState({ creating: true });
      const params = this.props.match.params;
      const classId = params.classId;
      const customSetDict = this.state.customSetDict;
      const selectedSets = customSetDict.getSelectedSetsArr();
      await this.props.firebase.createAssignment(
        selectedSets,
        this.state.assignmentName,
        this.state.description,
        this.state.dueDate,
        this.state.releaseDate,
        classId
      );
      this.props.alert.show('Successfully created assignment.');
      this.props.history.push(
        ROUTES.CLASSVIEW + '/' + classId + '/assignments'
      );
    } catch (err) {
      this.setState({ err: err });
    }
  }

  handleSelect(setId) {
    const customSetDict = this.state.customSetDict;
    customSetDict.toggleSelected(setId);
    this.setState({ customSetDict: customSetDict });
  }

  handleSelectMode(setId, mode) {
    const customSetDict = this.state.customSetDict;
    customSetDict.selectMode(setId, mode);
    this.setState({ customSetDict: customSetDict });
  }

  changeDueDate(date) {
    this.setState({ dueDate: date });
  }

  changeReleaseDate(date) {
    this.setState({ releaseDate: date });
  }

  reorder(result) {
    const { oldIndex, newIndex } = result;
    const customSetDict = this.state.customSetDict;
    customSetDict.reorderSelected(oldIndex, newIndex);
    this.setState({ customSetDict: customSetDict });
  }

  async componentDidMount() {
    try {
      const params = this.props.match.params;
      const classId = params.classId;
      const promises = await Promise.all([
        this.props.firebase.getClass(classId),
        this.props.firebase.getCustomSongs(['questionSets', 'keywordSets']),
      ]);
      const classData = promises[0];
      const customSetDict = promises[1];
      const songSetDicts = customSetDict.getSortedSongListByRecent();
      let isEmpty = true;
      await Promise.all(
        songSetDicts.map(async (songSetDict) => {
          const songObj = songSetDict.getSongData();
          songObj.src = await this.props.firebase.getSongCover(
            songObj.subtopic.subtopicRef.id
          );
          isEmpty =
            isEmpty &&
            (songSetDict.isEmpty() || songSetDict.getNumNonEmptySets() === 0);
        })
      );

      this.setState({
        ready: true,
        customSetDict: customSetDict,
        isEmpty: isEmpty,
        classData: classData,
      });
    } catch (err) {
      this.setState({ err: err });
    }
  }

  render() {
    if (this.state.err) {
      throw this.state.err;
    }
    if (!this.state.ready) return <Loading />;
    const nextAllowed = this.nextAllowed();

    const assertedDates = this.assertDates(
      this.state.releaseDate,
      this.state.dueDate
    );
    return (
      <PageWrapper>
        <SimpleBanner
          lead='New Assignment'
          sub={this.state.classData.title}
          color={this.state.classData.color}
        >
          <BackArrow />
        </SimpleBanner>
        <MainWrapper>
          {this.state.isEmpty ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <EmptyContent
                src='/images/animationAssets/girlSitting.png'
                lead="You don't have any Review study sets yet!"
              >
                <p>
                  Assignments let you share your study sets with your students
                  and track their progress. To make an assignment, first go to
                  your favorite song, and click
                  <span className='major'> Review</span> in the banner
                  navigation. Then, come back to this page and you'll be able to
                  make your assignment. Support for our other features is coming
                  soon!
                </p>
              </EmptyContent>
            </div>
          ) : (
            <div>
              {this.state.page === 0 && (
                <CreateAssignmentDetails
                  dueDate={this.state.dueDate}
                  releaseDate={this.state.releaseDate}
                  assignmentName={this.state.assignmentName}
                  description={this.state.description}
                  handleNameChange={this.handleNameChange}
                  handleDescChange={this.handleDescChange}
                  changeDueDate={this.changeDueDate}
                  changeReleaseDate={this.changeReleaseDate}
                  assertedDates={assertedDates}
                />
              )}
              {this.state.page === 1 && (
                <CreateAssignmentSets
                  dueDate={this.state.dueDate}
                  releaseDate={this.state.releaseDate}
                  assignmentName={this.state.assignmentName}
                  description={this.state.description}
                  customSetDict={this.state.customSetDict}
                  handleSelect={this.handleSelect}
                  handleSelectMode={this.handleSelectMode}
                  reorder={this.reorder}
                />
              )}
              <CreateAssignmentFooter
                page={this.state.page}
                disabledNext={!nextAllowed}
                creating={this.state.creating}
                back={this.back}
                next={this.next}
                submit={this.submit}
              />
            </div>
          )}
        </MainWrapper>
      </PageWrapper>
    );
  }
}

export default withAlert()(withRouter(withFirebase(CreateAssignmentView)));
