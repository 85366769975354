import React, { Component } from 'react';
import styled from 'styled-components';
import { withFirebase } from '../../../../Firebase';
import UserInitial from '../../../../UI-Library/Misc/UserInitial';
import ActionButton from '../ActionButton';

const StudentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 15px;
`;

const MainWrapper = styled.div`
  border-radius: 16px;
  background: var(--rsSurface);
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
`;

const Name = styled.p`
  margin: 0;
  font-weight: bold;
  margin-left: 15px;
`;

class ActiveStudents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
  }

  render() {
    if (!this.props.totalStudents || this.props.totalStudents.length === 0)
      return null;

    return (
      <div>
        <h2>Enrolled Students</h2>
        <p className='secondary'>
          {this.props.totalStudents} student
          {this.props.totalStudents > 1 ? 's' : ''}
        </p>

        <MainWrapper>
          {this.props.students.map((student) => {
            return (
              <StudentWrapper key={student.uid}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <UserInitial
                    letter={student.first_name.charAt(0)}
                    large={false}
                    color={student.color}
                  />
                  <Name>
                    {student.first_name} {student.last_name}
                  </Name>
                </div>
                {this.props.isAdmin && (
                  <div>
                    <ActionButton
                      onClick={() => this.props.removeStudent(student)}
                    >
                      Remove
                    </ActionButton>
                  </div>
                )}
              </StudentWrapper>
            );
          })}
        </MainWrapper>
      </div>
    );
  }
}

export default withFirebase(ActiveStudents);
