import React, { Component } from 'react';
import styled from 'styled-components';

import { device } from '../../../../constants/breakpoints';

const MainWrapper = styled.div`
  background: white;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding-top: 5%;
`;

const Header = styled.h1`
  max-width: 1000px;
  font-size: 36px;
  font-weight: bold;
  color: black;
  text-align: left;
  margin-bottom: 20px;
  @media ${device.tablet} {
    font-size: 30px;
  }
  @media ${device.mobileL} {
    font-size: 24px;
  }
`;

const SubHeader = styled.h1`
  color: black;
  margin-bottom: 30px;
  text-align: left;
  font-weight: 300;
  font-size: 24px;

  @media ${device.tablet} {
    font-size: 18px;
  }
  @media ${device.mobileL} {
    font-size: 14px;
  }
`;

const AlbumCovers = styled.img`
  height: 80vh;
  margin-left: -100px;
  @media ${device.laptop} {
    height: 70vh;
  }
  @media ${device.tablet} {
    margin-left: -250px;
  }
  @media ${device.mobileL} {
    height: 70vh;
    margin-left: -300px;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flexdirection: column;
  justifycontent: center;
  alignitems: flex-start;
  maxwidth: 53vw;
  margin-left: 80px;
  padding-right: 15%;
  @media ${device.laptop} {
    maxwidth: 80vw;
    padding-right: 5%;
  }
  @media ${device.tablet} {
    padding-right: 5%;
    margin-left: 5px;
  }
`;

class LandingSongCovers extends Component {
  render() {
    return (
      <MainWrapper>
        <TextWrapper
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            maxWidth: '53vw',
          }}
        >
          <Header>A song for every subject</Header>
          <SubHeader>
            We have hundreds of Pre K-8 standards aligned songs, sourced from
            teachers and rewritten to popular songs.
          </SubHeader>
        </TextWrapper>
        <AlbumCovers
          src={
            'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Flanding%20page%20album%20covers.png?alt=media&token=51d3478e-f8bf-4279-a52d-ee57ce558168'
          }
        />
      </MainWrapper>
    );
  }
}

export default LandingSongCovers;
