import React, { Component } from 'react';
import ReviewView from '../../../../UI-Library/Features/ReviewView';

class QuestionsSetView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hint: null,
      questionNum: 0,
      endTime: null,
    };
    this.hintDone = this.hintDone.bind(this);
    this.nextQuestionHandler = this.nextQuestionHandler.bind(this);
    this.previousQuestionHandler = this.previousQuestionHandler.bind(this);
    this.questionChoice = this.questionChoice.bind(this);
    this.clickedHint = this.clickedHint.bind(this);
    this.getStartAndEndTime = this.getStartAndEndTime.bind(this);
  }

  questionChoice(choice) {
    const qList = this.props.reviewSubmission;
    qList.setSelectedAtIndex(this.state.questionNum, choice);
    qList.setStatus('in progress');
    this.props.saveSubmissionAndRerender(qList, false);
  }

  clickedHint() {
    const times = this.getStartAndEndTime();
    this.setState({ hint: true, endTime: times[1] }, () => {
      this.props.setTime(times[0]);
      this.props.play();
    });
  }

  hintDone() {
    this.props.pause();
    this.setState({ endTime: this.props.duration });
  }

  componentDidUpdate() {
    if (this.state.hint) {
      if (this.props.playing) {
        if (this.props.currentTime >= this.state.endTime) {
          this.hintDone();
        }
      }
    }
  }

  nextQuestionHandler() {
    const qList = this.props.reviewSubmission;
    this.props.pause();
    if (this.state.questionNum + 1 >= qList.getLength()) {
      this.props.submitExercise(qList);
      return;
    } else if (!qList.isEmpty()) {
      this.setState({
        hint: false,
        questionNum: this.state.questionNum + 1,
      });
    }
  }

  previousQuestionHandler() {
    this.props.pause();
    if (this.state.questionNum === 0) {
      return;
    } else if (!this.props.reviewSubmission.isEmpty()) {
      this.setState({
        hint: false,
        questionNum: this.state.questionNum - 1,
      });
    }
  }

  getStartAndEndTime() {
    var currentQuest = this.props.reviewSubmission.index(
      this.state.questionNum
    );
    var begLine = currentQuest.beg + 1;
    var endLine = currentQuest.end + 1;
    return this.props.lrc.getTimeLine(begLine, endLine);
  }

  render() {
    return (
      <ReviewView
        qList={this.props.reviewSubmission}
        currentTime={this.props.currentTime}
        lrc={this.props.lrc}
        questionNum={this.state.questionNum}
        questionChoice={this.questionChoice}
        hint={this.state.hint}
        clickedHint={this.clickedHint}
        previousQuestion={this.previousQuestionHandler}
        nextQuestion={this.nextQuestionHandler}
        maxAttempts={this.props.reviewSubmission.getMaxAttempts()}
      />
    );
  }
}

export default QuestionsSetView;
