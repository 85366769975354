import React from 'react';
import styled from 'styled-components';

import LandingSelect from '../../LandingSelect';
import ProfileGrid from './ProfileGrid';

import { device } from '../../../../constants/breakpoints';

const MainWrapper = styled.div`
  padding: 7%;
  padding-bottom: 0;
  background: white;
`;

const Header = styled.h1`
  max-width: 1000px;
  font-size: 36px;
  font-weight: bold;
  color: black;
  text-align: left;
  margin-bottom: 10px;

  @media ${device.tablet} {
    font-size: 30px;
  }
  @media ${device.mobileL} {
    font-size: 24px;
  }
`;

/**
 * About is the entire About page.
 *
 * This includes 'our story' and a few AboutJumbos for the team.
 */
class AboutMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: 'Core',
    };
    this.select = this.select.bind(this);
  }

  select(key) {
    this.setState({ selected: key });
  }

  render() {
    const data = {
      Core: [
        {
          name: 'Drew Speckman',
          pos: 'CEO, Co-Founder',
          src:
            'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Fdrewspeckman.jpeg?alt=media&token=a37d7525-52d6-4f3a-8673-9b2eb0743de0',
        },
        {
          name: 'Cosimo Fabrizio',
          pos: 'President, Co-Founder',
          src:
            'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Fcosimofabrizio.jpeg?alt=media&token=dba72623-0328-4d7e-9ccc-08adb7bdbd28',
        },
        {
          name: 'Adrian Lee',
          pos: 'Head of Product',
          src:
            'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Fadrianlee.JPG?alt=media&token=6ab3de08-ac0e-41c4-bc60-22324a680b6b',
        },
        {
          name: 'Reza Madhavan',
          pos: 'Head of Tech',
          src:
            'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Frezamadhavan.jpeg?alt=media&token=a9438486-1ead-4627-8069-22582db3101d',
        },
        {
          name: 'Jake McEvoy',
          pos: 'Head of Music',
          src:
            'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Fjakemcevoy.jpeg?alt=media&token=18b97fd0-e355-4dbe-84ad-a113e1817119',
        },
        {
          name: 'Claire Choi',
          pos: 'Head of Design',
          src:
            'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Fclairechoi.JPG?alt=media&token=279ee0a6-6b57-4a31-abe7-0fe307bd3801',
        },
      ],
      Design: [
        {
          name: 'Alyssa Zhang',
          pos: 'UX Designer',
          src:
            'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Falyssazhang.jpeg?alt=media&token=d3ce578c-c909-4687-8a40-086bcf599e11',
        },
        {
          name: 'Annabelle Davy',
          pos: 'Visual Brand Designer',
          src:
            'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Fannabelledavy.JPG?alt=media&token=d4d03ace-6d5e-43b8-86a0-dd5e27174b03',
        },
        {
          name: 'Ava DeBartolomeis',
          pos: 'UX Designer',
          src:
            'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Favadebart.jpeg?alt=media&token=615446ea-e1e2-4075-b011-c358921c92c8',
        },
        {
          name: 'Devin Chin',
          pos: 'UX Designer',
          src:
            'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Fdevinchin.JPG?alt=media&token=60c5339e-bcb3-43cc-ac6f-2fb782ce4f9a',
        },
        {
          name: 'Emily Jacobsson',
          pos: 'UX Designer',
          src:
            'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Femilyjacobsson.JPG?alt=media&token=3ddff1cd-7740-4428-b942-f86539876d4e',
        },
        {
          name: 'Emily Kam',
          pos: 'UX Designer',
          src:
            'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Femilykam.JPG?alt=media&token=639e82d2-8e91-4c59-9f2d-df2b88dcd7bb',
        },
        {
          name: 'Lauren Song',
          pos: 'Visual Brand Designer',
          src:
            'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Flaurensong.JPG?alt=media&token=415bcaf8-16ff-44ed-b265-377982dc8554',
        },
        {
          name: 'Matt Canabarro',
          pos: 'UX Designer',
          src:
            'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Fmatthewcanabarro.JPG?alt=media&token=61b76273-1579-453d-90dc-6f2b298d6613',
        },
        {
          name: 'Vivian Shiu',
          pos: 'UX Designer',
          src:
            'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Fvivianshiu.JPG?alt=media&token=099722e5-d5bb-4a67-81c1-7793dde534ea',
        },
      ],
      Engineering: [
        {
          name: 'Akimasa Ihara',
          pos: 'Web Developer',
          src:
            'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Fakimasaihara.jpeg?alt=media&token=11fed68b-5983-4863-9132-04c8f5edf492',
        },
        {
          name: 'Ashley Park',
          pos: 'Web Developer',
          src:
            'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Fashleypark.JPG?alt=media&token=5b995237-1d40-45ed-9ef0-09344f1629f4',
        },
        {
          name: 'Emily Wang',
          pos: 'Web Developer',
          src:
            'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Femilywang.jpeg?alt=media&token=c0370916-19a3-40b0-a82e-10ec67221fef',
        },
        {
          name: 'Ethan Ma',
          pos: 'Mobile Developer',
          src:
            'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Fethanma.jpeg?alt=media&token=388134b5-cce0-48a8-9acd-64df1b533232',
        },
        {
          name: 'Jonathan Duque',
          pos: 'Web Developer',
          src:
            'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Fjonathanduque.JPG?alt=media&token=81e8c53c-a771-48d8-aae0-8e1d9acb3259',
        },
        {
          name: 'Katie Zhang',
          pos: 'Web Developer',
          src:
            'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Fkatiezhang.jpeg?alt=media&token=cf831a83-fdf2-45d1-aef4-795d376768e9',
        },
        {
          name: 'Richard Lin',
          pos: 'Web Developer',
          src:
            'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Frichardlin.JPG?alt=media&token=9055cfee-477a-4486-bb75-d657ae1de204',
        },
        {
          name: 'Sean Yu',
          pos: 'Mobile Developer',
          src:
            'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Fseanyu.jpeg?alt=media&token=efc00a07-cd54-4d2b-9cb3-aa04ccc8b599',
        },
        {
          name: 'Sofia Yoon',
          pos: 'Web Developer',
          src:
            'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Fsofiayoon.jpeg?alt=media&token=87b4e198-5e2c-4fa5-9043-8f90ee3fd1ff',
        },
        {
          name: 'Unnathi Uptal Kumar',
          pos: 'Mobile Developer',
          src:
            'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Funnathiutpalkumar.JPG?alt=media&token=4ae830cf-33b0-480e-b44a-4814d305755f',
        },
      ],
      Music: [
        {
          name: 'Alejandro Dávila',
          pos: 'Singer',
          src:
            'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Falejandroda%CC%81vila.jpeg?alt=media&token=e853a8e5-db99-4ddb-a698-f7419b9c5cea',
        },
        {
          name: 'Anna Scholfield',
          pos: 'Songwriter',
          src:
            'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Fannascholfield.jpeg?alt=media&token=20199aa4-726b-4102-bce0-f3a696f47e17',
        },
        {
          name: 'Ella Collier',
          pos: 'Singer',
          src:
            'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Fellacollier.jpeg?alt=media&token=f8d244de-4268-4e7f-a141-36e633868a7f',
        },
        {
          name: 'Greta Pasqua',
          pos: 'Singer',
          src:
            'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Fgretapasqua.jpeg?alt=media&token=4e96b2c7-4dc8-4c2c-b0e2-9f8d8dff9bfd',
        },
        {
          name: 'Hannah Abrahim',
          pos: 'Singer',
          src:
            'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Fhannahabrahim.jpeg?alt=media&token=ae616017-651b-452d-a3b0-c030d6a5e851',
        },
        {
          name: 'Izzy Brown',
          pos: 'Singer',
          src:
            'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Fizzybrown.jpeg?alt=media&token=363233a9-b524-4237-925d-a9369d79eab2',
        },
        {
          name: 'Jack Ingram',
          pos: 'Songwriter',
          src:
            'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Fjackingram.jpeg?alt=media&token=595ec50b-cb63-4f49-be67-793b2d1d3e5e',
        },
        {
          name: 'Jordyn Simone',
          pos: 'Singer',
          src:
            'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Fjordynsimone.jpeg?alt=media&token=bbf780ef-265e-41b3-823f-8493fa89534b',
        },
        {
          name: 'Kate Grahn',
          pos: 'Singer',
          src:
            'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Fkategrahn.jpeg?alt=media&token=c140a5f3-978d-4fa4-a590-d46d2c825ec6',
        },
        {
          name: 'Katie Kivinski',
          pos: 'Songwriter',
          src:
            'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Fkatiekivinski.jpeg?alt=media&token=8a39dabf-99f3-4d6c-8ca0-40b3943df9c7',
        },
        {
          name: 'Kyle Tolbert',
          pos: 'Singer',
          src:
            'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Fkylelux.jpeg?alt=media&token=a3a3fe41-c34c-4272-a778-b050a6b5f39a',
        },
        {
          name: 'Luca Moretti',
          pos: 'Producer',
          src:
            'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Flucamoretti.jpeg?alt=media&token=f5b5d230-633e-4b1a-ab95-4f23f0b72242',
        },
        {
          name: 'Martin Gutierrez',
          pos: 'Producer',
          src:
            'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Fmartingutierrez.jpeg?alt=media&token=6820c521-053c-4826-8b50-e36ea9dac1c5',
        },
        {
          name: 'Michael Shofi',
          pos: 'Producer',
          src:
            'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Fmichaelshofi.jpeg?alt=media&token=416be1bf-0395-48e9-b746-252918930bba',
        },
        {
          name: 'Tabon Ward',
          pos: 'Singer',
          src:
            'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Ftabonward.jpeg?alt=media&token=99cf612b-514b-427b-a023-0f429b0ca1c7',
        },
        {
          name: 'Via Savage',
          pos: 'Songwriter',
          src:
            'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Fviasavage.jpeg?alt=media&token=ca21e2ea-9740-49bf-b383-3a79921a3d9d',
        },
        {
          name: 'Zoe Benson',
          pos: 'Songwriter',
          src:
            'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Fzoebenson.jpeg?alt=media&token=15707f90-86a9-4faa-9bfa-d62ed2a02834',
        },
      ],
      Operations: [
        {
          name: 'Hannah Drexler',
          pos: 'Education',
          src:
            'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Fhannahdrexler.jpeg?alt=media&token=c558cc68-a051-46a9-9a25-87eafa3ebd38',
        },
        {
          name: 'Julia Davolio',
          pos: 'Supply Chain',
          src:
            'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Fjuliadavolio.jpeg?alt=media&token=c9ba1a40-8a71-4d3d-89f6-254569946110',
        },
        {
          name: 'Matthew Gavieta',
          pos: 'IP Analyst',
          src:
            'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Fmattgavieta.jpeg?alt=media&token=cf257d96-b0b7-4a16-ad4f-aa25cbc284b1',
        },
        {
          name: 'Swastik Chaki',
          pos: 'Education',
          src:
            'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fteam%2Fswastikchaki.jpeg?alt=media&token=01fb3c55-f4c1-4289-90a7-78efc20d4105',
        },
      ],
    };
    const keys = Object.keys(data);
    return (
      <MainWrapper>
        <Header>Our team</Header>
        <LandingSelect
          keys={keys}
          selected={this.state.selected}
          onSelect={this.select}
        />
        <ProfileGrid data={data[this.state.selected]} />
      </MainWrapper>
    );
  }
}

export default AboutMain;
