import React, { Component } from 'react';
import styled from 'styled-components';
import { withFirebase } from '../../../Firebase';
import * as ROUTES from '../../../../constants/routes';
import Lrc from '../../../../Classes/Lrc.js';
import KeywordDict from '../../../../Classes/KeywordDict.js';

// eslint-disable-next-line no-unused-vars
import * as Scroll from 'react-scroll';
// eslint-disable-next-line no-unused-vars
import { animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';

import Loading from '../../../UI-Library/Misc/Loading';

import SelectionHeader from '../../../UI-Library/Features/SelectionHeader';

import DeleteConfirmModal from '../../../UI-Library/Modals/DeleteConfirmModal';
import DiscardConfirmModal from '../../../UI-Library/Modals/DiscardConfirmModal';
import CreateSetModal from '../../../UI-Library/Modals/CreateSetModal';
import FeatureStart from '../../../UI-Library/Features/FeatureStart';

import {
  selectSet,
  createSet,
  deleteSet,
  findSetInArray,
} from '../setFunctions.js';
import ExerciseCompletion from '../../../UI-Library/Modals/ExerciseCompletionModal';

import { withAlert } from 'react-alert';

import NoSet from '../NoSet';
import BlanksEdit from './BlanksEdit';
import BlanksView from '../../../UI-Library/Features/BlanksView';

const MainWrapper = styled.div`
  position: relative;
  margin-top: 30px;
  height: 100%;
  padding-left: 5%;
  padding-right: 5%;
  ${({ fullscreen }) =>
    fullscreen &&
    `
  height: calc(100% - 10vh - 80px);
  margin-top: 5%;
  margin-bottom: 5%;

  display: flex;
  justify-content: center;
  align-items: center;

`}
`;

export class BlanksBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      elrc: null,
      keywordDict: new KeywordDict(),
      ready: false,
      setId: null,
      userSets: null,
      error: null,
      json: null,
      edit: false,
      showDiscard: false,
      showDelete: false,
      showCreate: false,
      noSet: false,
      showTutorial: this.props.firstBlanks,
      isAuthor: false,
      author: null,
      authorName: null,
      hasReset: false,
      started: false,
      mode: null,
    };
    this.createSet = createSet(
      ROUTES.BLANKS,
      this.props.firebase.createCustomKeywords.bind(this.props.firebase)
    ).bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.deleteSet = deleteSet(
      ROUTES.BLANKS,
      this.props.firebase.deleteCustomKeywords.bind(this.props.firebase)
    ).bind(this);
    this.toggleWord = this.toggleWord.bind(this);
    this.setPassed = this.setPassed.bind(this);
    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.deleteModalToggle = this.deleteModalToggle.bind(this);
    this.discardModalToggle = this.discardModalToggle.bind(this);
    this.customContentModalToggle = this.customContentModalToggle.bind(this);
    this.selectSet = selectSet(ROUTES.BLANKS).bind(this);
    this.toggleShowTutorial = this.toggleShowTutorial.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.props.firebase.analytics.logEvent('page_view');
    this.reset = this.reset.bind(this);
    this.giveUp = this.giveUp.bind(this);
    this.start = this.start.bind(this);
  }

  async getResources(setId) {
    try {
      this.setState({ ready: false });

      let keywordDict = await this.props.firebase.getKeywordDict(setId);

      const keywordSets = await this.props.firebase.getKeywordSetsForSong(
        this.props.id
      );

      this.setState({ userSets: keywordSets });

      if (keywordSets.length === 0 && keywordDict === null) {
        // show the create button
        this.props.history.replace(ROUTES.BLANKS + '?songId=' + this.props.id);
        this.setState({
          noSet: true,
          ready: true,
        });
        return;
      } else if (setId === 'null') {
        // get first set from keywordSet
        setId = keywordSets[0].setId;
        this.props.history.replace(
          ROUTES.BLANKS + '?songId=' + this.props.id + '&setId=' + setId
        );
        keywordDict = await this.props.firebase.getKeywordDict(setId);
      }

      this.props.reset();
      this.setState(
        {
          elrc: new Lrc(this.props.json),
          json: this.props.json,
          keywordDict: keywordDict,
          setId: setId,
          ready: true,
          author: keywordDict.getAuthorId(),
          authorName: keywordDict.getAuthorName(),
          isAuthor:
            keywordDict.getAuthorId() ===
            this.props.firebase.auth.currentUser.uid,
          mode: null,
        },
        () => {
          this.props.updateCurrSetId(setId, 'Blanks');
        }
      );
    } catch (err) {
      this.setState({ error: err });
    }
  }

  async componentDidMount() {
    this.props.updateCurrSetId(null, 'Blanks');

    const urlParams = new URLSearchParams(this.props.location.search);

    const name = String(urlParams.get('name'));
    if (!(name === '' || name === 'null')) {
      await this.createSet(name, '');
      return;
    }
    let setId = urlParams.get('setId');
    setId = String(setId);
    this.setState({ setId: setId });
    await this.getResources(setId);
  }

  componentWillUnmount() {
    if (this.state.noSet || this.state.hasReset) return;
    this.logAnalytics();
  }

  logAnalytics() {
    // [Analytics] Log blanks activity
    const length = this.state.keywordDict.getLength();
    const completed = this.state.keywordDict.countPassed();

    this.props.firebase.analytics.logEvent('blanks', {
      songId: this.props.id,
      length: length,
      completed: completed,
      mode: this.state.mode,
    });
  }

  handleTitleChange(e) {
    const keywordDict = this.state.keywordDict;
    const title = e.target.value;
    keywordDict.setName(title);
    this.setState({ keywordDict: keywordDict });
  }

  arrayEqual(array1, array2) {
    array1.sort();
    array2.sort();
    if (array1 === array2) return true;
    if (array1 == null || array2 == null) return false;
    if (array1.length !== array2.length) return false;

    for (let i = 0; i < array1.length; ++i) {
      if (array1[i] !== array2[i]) return false;
    }
    return true;
  }

  /**
   * Toggles between edit mode and view mode.
   */
  async toggleEdit() {
    // if edit mode, change to view mode, and save the keywords to firestore by calling updateCustomKeywords.
    // if view mode, change to edit mode UNLESS the current set is the default rapstudy set.
    if (!this.state.edit && this.state.setId !== 'null') {
      this.setState({
        edit: !this.state.edit,
        ready: true,
      });
    } else if (this.state.edit) {
      this.setState({ ready: false });

      await this.props.firebase.updateCustomKeywords(
        this.state.setId,
        this.state.keywordDict
      );

      const sets = this.state.userSets;
      const toChangeName = findSetInArray(sets, 'setId', this.state.setId);
      toChangeName.name = this.state.keywordDict.getName();

      this.setState({
        userSets: sets,
        edit: !this.state.edit,
        ready: true,
      });
    }
  }

  /**
   * Adds / removes the keyword from the state's set of keywords.
   */
  toggleWord(numInLrc, word) {
    const keywordDict = this.state.keywordDict;
    const isKeyword = keywordDict.isKeyword(numInLrc);
    if (isKeyword) {
      keywordDict.deleteKeyword(numInLrc);
    } else {
      // use random collection to get a random firestore document id
      keywordDict.addNewKeyword(
        numInLrc,
        word,
        this.props.firebase.db.collection('random').doc().id
      );
    }
    this.setState({
      keywordDict: keywordDict,
    });
  }

  /**
   * Selects a word for a keyword.
   */
  onSelect(numInLrc, word) {
    const keywordDict = this.state.keywordDict;
    const kObj = keywordDict.getKeyword(numInLrc);
    const maxAttempts = this.state.mode === 'free recall' ? 2 : 1;
    const correct = keywordDict.makeAttempt(numInLrc, word);
    if (kObj.isCorrect() || kObj.getAttempts() === maxAttempts) {
      setTimeout(() => {
        this.props.playOrPause();
      }, 500);
      keywordDict.setPassed(numInLrc);
    }
    this.setState({ keywordDict: keywordDict });
    return correct;
  }

  deleteModalToggle() {
    this.setState({ showDelete: !this.state.showDelete });
  }

  discardModalToggle() {
    this.setState({ showDiscard: !this.state.showDiscard });
  }

  customContentModalToggle() {
    this.setState({ showCreate: !this.state.showCreate });
  }

  toggleShowTutorial() {
    this.setState({ showTutorial: !this.state.showTutorial });
  }

  setPassed(numInLrc, makeCorrect) {
    const keywordDict = this.state.keywordDict;
    keywordDict.setPassed(numInLrc);
    if (makeCorrect) keywordDict.makeCorrect(numInLrc);
    this.setState({ keywordDict: keywordDict });
  }

  giveUp(numInLrc) {
    const keywordDict = this.state.keywordDict;
    keywordDict.setPassed(numInLrc);
    keywordDict.makeCorrect(numInLrc);
    setTimeout(() => {
      this.props.playOrPause();
    }, 500);
    this.setState({ keywordDict: keywordDict });
  }

  reset() {
    this.props.reset();
    this.logAnalytics();
    const keywordDict = this.state.keywordDict;
    keywordDict.resetAll();
    this.setState({
      keywordDict: keywordDict,
      mode: null,
      hasReset: true,
      started: false,
    });
  }

  start(mode) {
    if (!this.props.play) {
      this.props.playOrPause();
    }
    this.setState({ mode: mode });
  }

  render() {
    if (this.state.error) {
      throw this.state.error;
    }

    // check for null topic
    let topicName = null;
    if (this.props.topic) {
      topicName = this.props.topic.name;
    }

    if (!this.state.ready) return <Loading />;

    if (this.state.noSet)
      return (
        <NoSet
          show={this.state.showTutorial}
          toggleShowTutorial={this.toggleShowTutorial}
          firstTime={this.props.firstBlanks}
          complete={this.props.completeFirstBlanks}
          createSet={this.createSet}
          topicName={topicName}
          newTitle={this.props.newTitle}
          featureName='Blanks'
        />
      );

    if (!this.state.mode && !this.state.edit)
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '50px',
          }}
        >
          <FeatureStart
            userSets={this.state.userSets}
            selectSet={this.selectSet}
            toggleEdit={this.toggleEdit}
            customContentModalToggle={this.customContentModalToggle}
            title={this.state.keywordDict.getName()}
            isAuthor={this.state.isAuthor}
            author={this.state.author}
            authorName={this.state.authorName}
            length={this.state.keywordDict.getLength()}
            featureName={'Blanks'}
            start={this.start}
          />
          <CreateSetModal
            show={this.state.showCreate}
            create={this.createSet}
            topicName={topicName}
            songName={this.props.newTitle}
            featureName={'Breakdown'}
            toggle={this.customContentModalToggle}
          />
        </div>
      );

    const currentWord = this.state.elrc.getCurrentWord(this.props.currentTime);
    return (
      <MainWrapper fullscreen={this.props.fullscreen}>
        <SelectionHeader
          newTitle={this.props.newTitle}
          fullscreen={this.props.fullscreen}
          edit={this.state.edit}
          title={this.state.keywordDict.getName()}
          topicName={topicName}
          userSets={this.state.userSets}
          setId={this.state.setId}
          createSet={this.createSet}
          handleTitleChange={this.handleTitleChange}
          deleteModalToggle={this.deleteModalToggle}
          discardModalToggle={this.discardModalToggle}
          toggleEdit={this.toggleEdit}
          selectSet={this.selectSet}
          clickFullScreen={this.props.clickFullScreen}
          featureName={'Blanks'}
          isAuthor={this.state.isAuthor}
          author={this.state.author}
          authorName={this.state.authorName}
          customContentModalToggle={this.customContentModalToggle}
          reset={this.reset}
        />
        <CreateSetModal
          show={this.state.showCreate}
          create={this.createSet}
          topicName={topicName}
          songName={this.props.newTitle}
          featureName={'Blanks'}
          toggle={this.customContentModalToggle}
        />
        {this.state.edit ? (
          <div>
            <DeleteConfirmModal
              title={'Delete Set?'}
              delete={() => {
                this.deleteSet(this.state.setId);
              }}
              show={this.state.showDelete}
              toggle={this.deleteModalToggle}
            />
            <DiscardConfirmModal
              discard={() => {
                this.selectSet(this.state.setId);
              }}
              show={this.state.showDiscard}
              toggle={this.discardModalToggle}
            />
          </div>
        ) : null}
        {this.state.edit ? (
          <BlanksEdit
            elrc={this.state.elrc}
            keywordDict={this.state.keywordDict}
            toggleWord={this.toggleWord}
          />
        ) : (
          <>
            <BlanksView
              mode={this.state.mode}
              elrc={this.state.elrc}
              currentWord={currentWord}
              keywordDict={this.state.keywordDict}
              play={this.props.play}
              pause={this.props.pause}
              playOrPause={this.props.playOrPause}
              onSelect={this.onSelect}
              setPassed={this.setPassed}
              giveUp={this.giveUp}
            />
            <ExerciseCompletion
              show={this.props.ended}
              reset={this.reset}
              id={this.props.id}
              exerciseType='blanks'
            />
          </>
        )}
      </MainWrapper>
    );
  }
}

const Blanks = withAlert()(withFirebase(BlanksBase));
export default Blanks;
