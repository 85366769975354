import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import * as ROUTES from '../../../constants/routes';

const PublicRoute = ({
  component: Component,
  path,
  authUser,
  verified,
  verificationDone,
  ...rest
}) => (
  <Route
    {...rest}
    path={verified ? ROUTES.DASHBOARD : path}
    render={(props) => {
      if (!verificationDone) {
        return null;
      }
      if (authUser && !verified) {
        return <Redirect to={ROUTES.FREELANDING} />;
      }
      if (verified) {
        return <Redirect to={ROUTES.DASHBOARD} props={props} />;
      }
      return <Component {...props} {...rest} />;
    }}
  />
);

export default PublicRoute;
