import React, { Component } from 'react';
import styled from 'styled-components';
import { withFirebase } from '../../../Firebase';
import { NavLink } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';

const PlaylistHeader = styled.p`
  vertical-align: middle;
  display: inline-block;
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 0;
  color: var(--rsTertiary);
  font-size: 14px;
  transition: color 0.3s ease;
  &:hover {
    color: var(--rsPrimary);
  }
`;

const PlaylistLink = {
  verticalAlign: 'middle',
  color: 'var(--rsSecondary)',
  textDecoration: 'none',
  marginLeft: '26px',
  marginTop: '5px',
  marginBottom: '5px',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  display: 'block',
  width: '80%',
};

const Caret = styled.img`
  margin-top: -4px;
  transform: ${(props) => (props.open ? 'rotate(0deg)' : 'rotate(-90deg)')};
`;

const Toggler = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const MainWrapper = styled.div``;

/**
 * YourPlaylists component is simply the component for the title of
 * the playlists section in the vertical nav bar.
 */
class YourPlaylists extends Component {
  render() {
    return (
      <Toggler
        className='noPadMar ghost'
        onClick={this.props.toggle}
        aria-controls='playlists'
        aria-expanded={this.props.open}
      >
        <PlaylistHeader>YOUR PLAYLISTS</PlaylistHeader>
        <Caret
          src='/images/icons/utility/caretgrey.png'
          open={this.props.open}
        ></Caret>
      </Toggler>
    );
  }
}

/**
 * Playlists is the component for the "Playlist" <ul> in VerticalNav. It contains
 * a list of the user custom playlists. Uses the firebase function
 * getPlaylists to get the list of playlist names.
 */
class PlaylistsBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
    };
    this.toggle = this.toggle.bind(this);
    this.renderLinks = this.renderLinks.bind(this);
  }

  toggle() {
    this.setState({ open: !this.state.open });
  }

  renderLinks() {
    if (!this.props.playlists) return null;
    const playlistDivs = this.props.playlists.map(function (playlist, index) {
      return (
        <NavLink
          className='navitem vertNav-link'
          style={PlaylistLink}
          key={index}
          to={'/internal/playlistView/' + playlist.playlistId}
        >
          {playlist.name}
        </NavLink>
      );
    });
    return playlistDivs;
  }

  render() {
    return (
      <MainWrapper>
        <YourPlaylists open={this.state.open} toggle={this.toggle} />
        <Collapse in={this.state.open} id='playlists'>
          <div>{this.renderLinks()}</div>
        </Collapse>
      </MainWrapper>
    );
  }
}

const Playlists = withFirebase(PlaylistsBase);

export default Playlists;
