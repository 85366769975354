import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

import RsButton from '../UI-Library/ButtonsOrLinks/RsButton';

const ForbiddenJumbo = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 75vh;
  text-align: center;
  background: var(--rsBackground);
`;

const JumboHR = styled.hr`
  border: 1px solid var(--rsPrimary);
`;

class ForbiddenBase extends Component {
  render() {
    return (
      <ForbiddenJumbo className='jumbotron'>
        <h2 className='major'>Oops! You don't have access.</h2>

        <JumboHR />
        <p className='lead container' style={{ textAlign: 'center' }}>
          If your school is partnered with rapStudy, log in! Otherwise, we hope
          to bring rapStudy to you soon!
        </p>

        <Link to={ROUTES.SIGNIN}>
          <RsButton>Sign in</RsButton>
        </Link>
      </ForbiddenJumbo>
    );
  }
}

export default withFirebase(ForbiddenBase);
