import React, { Component } from 'react';
import styled from 'styled-components';
import AudioBar from '../AudioBar';

import { device } from '../../../../constants/breakpoints';

const MainWrapper = styled.div`
  display: flex;
  background: rgba(85, 7, 45, 0.7);
  align-items: center;
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 3px;
  padding-bottom: 3px;
  height: 60px;
  @media ${device.tablet} {
    justify-content: space-between;
    padding-left: 1%;
    padding-right: 1%;
  }
`;

const Desc = styled.p`
  margin-bottom: 0;
  margin-right: 10px;

  font-size: 18px;
  @media ${device.laptop} {
    font-size: 14px;
    margin-right: 0px;
  }
  @media ${device.mobileL} {
    font-size: 12px;
  }
`;

const SubDesc = styled.p`
  margin-bottom: 0;
  font-size: 14px;
  @media ${device.laptop} {
    font-size: 12px;
  }
  @media ${device.mobileL} {
    font-size: 10px;
  }
`;

const DescWrapper = styled.div`
  display: flex;
  align-items: center;
  @media ${device.laptop} {
    display: block;
  }
`;

class SquarePlayer extends Component {
  constructor(props) {
    super(props);

    const hasSongs = this.props.songArr && this.props.songArr.length !== 0;

    this.state = {
      play: false,
      audioLoaded: false,
      hasSongs: hasSongs,
      currentSong: hasSongs ? this.props.songArr[0] : null,
      songIndex: hasSongs ? 0 : null,
      duration: 0,
      currentTime: 0,
    };
    this.audio = React.createRef();
    this.playOrPause = this.playOrPause.bind(this);
    this.nextSong = this.nextSong.bind(this);
    this.prevSong = this.prevSong.bind(this);

    this.play = this.play.bind(this);
    this.pause = this.pause.bind(this);
    this.handleProgressChange = this.handleProgressChange.bind(this);
    this.setTime = this.setTime.bind(this);
  }

  componentDidMount() {
    this.audio.current.addEventListener('ended', () => {
      this.setState({
        play: false,
      });
    });
    this.audio.current.load();

    this.audio.current.addEventListener('canplay', (event) => {
      this.setState({
        audioLoaded: true,
        duration: this.audio.current.duration,
      });
    });

    // this.setState({ audioLoaded: true, duration: this.audio.current.duration });
  }

  /**
   * Play and pause audio toggle.
   */
  playOrPause() {
    if (this.state.play) {
      this.pause();
    } else {
      this.play();
    }
  }

  play() {
    this.updateTime = setInterval(() => {
      if (!this.audio.current) {
        console.log('audio short circuit');
        clearInterval(this.updateTime);
        this.setState({ time: null, play: false });
        return;
      }

      this.setState({
        currentTime: this.audio.current.currentTime,
        play: true,
      });
    }, 100);
    this.audio.current.play();
  }

  pause() {
    clearInterval(this.updateTime);
    this.setState({ play: false });
    this.audio.current.pause();
  }

  /**
   * Advances the currentSource to the next song in the songArr
   */
  nextSong() {
    if (!this.state.hasSongs) return;
    this.pause();
    this.setState({ audioLoaded: false, currentTime: 0 });

    const currIndex = this.state.songIndex;
    const lastSong = currIndex === this.props.songArr.length - 1;

    if (lastSong) {
      this.setState(
        {
          songIndex: 0,
          currentSong: this.props.songArr[0],
        },
        () => {
          this.audio.current.load();
          this.setState({
            audioLoaded: true,
            // duration: this.audio.current.duration,
          });
        }
      );
    } else {
      this.setState(
        {
          songIndex: currIndex + 1,
          currentSong: this.props.songArr[currIndex + 1],
        },
        () => {
          this.audio.current.load();
          this.setState({
            audioLoaded: true,
            // duration: this.audio.current.duration,
          });
        }
      );
    }
  }

  /**
   * Decrements the currentSource to the previous song in the songArr
   */
  prevSong() {
    if (!this.state.hasSongs) return;

    this.pause();
    this.setState({ audioLoaded: false, currentTime: 0 });

    const currIndex = this.state.songIndex;
    const firstSong = currIndex === 0;
    const lastIndex = this.props.songArr.length - 1;

    if (firstSong) {
      this.setState(
        {
          songIndex: lastIndex,
          currentSong: this.props.songArr[lastIndex],
        },
        () => {
          this.audio.current.load();
          this.setState({
            audioLoaded: true,
            // duration: this.audio.current.duration,
          });
        }
      );
    } else {
      this.setState(
        {
          songIndex: currIndex - 1,
          currentSong: this.props.songArr[currIndex - 1],
        },
        () => {
          this.audio.current.load();
          this.setState({
            audioLoaded: true,
            // duration: this.audio.current.duration,
          });
        }
      );
    }
  }

  handleProgressChange = (event, value) => {
    this.setTime((value / 100) * this.state.duration);
  };

  setTime(time) {
    this.audio.current.currentTime = time;
    this.setState({ currentTime: this.audio.current.currentTime });
  }

  render() {
    if (!this.state.hasSongs) {
      return null;
    }
    return (
      <MainWrapper>
        <div style={{ minWidth: '40%' }}>
          <audio src={this.state.currentSong.src} ref={this.audio} />

          <DescWrapper>
            <Desc className=' bold'>{this.state.currentSong.title}</Desc>
            <SubDesc className='secondary bold'>
              based on "{this.state.currentSong.origTitle}" by{' '}
              {this.state.currentSong.origArtist[0]}
            </SubDesc>
          </DescWrapper>
        </div>

        <AudioBar
          audioLoaded={this.state.audioLoaded}
          play={this.state.play}
          playOrPause={this.playOrPause}
          showNavigation={true}
          showProgress
          prevSong={this.prevSong}
          nextSong={this.nextSong}
          duration={this.state.duration}
          currentTime={this.state.currentTime}
          handleProgressChange={this.handleProgressChange}
          maxWidth={'600px'}
        />
      </MainWrapper>
    );
  }
}

export default SquarePlayer;
