import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Circle = styled.span`
  height: 12px;
  width: 12px;
  margin-right: 10px;
  background: ${(props) => props.color};
  border-radius: 50%;
  display: inline-block;
`;

/**
 * Colored Bullet component
 */

 class ColorBullet extends Component {
    render() {
        return (
            <Circle color={this.props.color}/>
        )
    }
 }

 ColorBullet.propTypes = {
   /**
    * Color of the bullet
    */
   color: PropTypes.string,
 }

 export default ColorBullet;