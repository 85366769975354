import React, { Component } from 'react';
import styled from 'styled-components';
import Loading from '../../UI-Library/Misc/Loading';
import DeleteConfirmModal from '../../UI-Library/Modals/DeleteConfirmModal';

import ClassPreviewCard from './ClassPreviewCard';
import CreateJoinClass from '../../UI-Library/Modals/CreateJoinClass';
import BackArrow from '../../UI-Library/ButtonsOrLinks/BackArrow';

import { withFirebase } from '../../Firebase';
import SimpleBanner from '../../UI-Library/Containers/SimpleBanner';
// import PendingClasses from './PendingClasses';
import EmptyClasses from './EmptyClasses';

const PageWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
`;

const MainWrapper = styled.div`
  padding-top: 20px;
  padding-left: 5%;
  padding-right: 5%;
  width: 100%;
  height: 100%;
  padding-bottom: 10px;
`;

const Title = styled.h1`
  font-weight: bold;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

class Classes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      classes: [],
      showDeleteClassConfirmModal: false,
      classDeleteId: null,
      classDeleteTitle: null,
      activeAssignments: null,
      ready: false,
      error: null,
    };

    this.toggleDeleteClass = this.toggleDeleteClass.bind(this);
    this.deleteClass = this.deleteClass.bind(this);
    this.toggleOffDeleteClassModal = this.toggleOffDeleteClassModal.bind(this);
  }

  updateClassesData(querySnap, role) {
    var classesData = querySnap.docs.map((doc) => {
      const classData = doc.data();
      classData.classId = doc.id;
      return classData;
    });

    this.setState({ classes: classesData, ready: true });
  }

  async componentDidMount() {
    try {
      const isTeacher = this.props.userToken.claims.teacher
        ? this.props.userToken.claims.teacher
        : false;

      if (isTeacher) {
        const activeAssignmentsQuery = await this.props.firebase.db
          .collection('assignments')
          .where('createdBy', '==', this.props.firebase.auth.currentUser.uid)
          .where('dueDate', '>=', new Date())
          .get();
        const activeAssignmentsFiltered = activeAssignmentsQuery.docs
          .map((docSnap) => docSnap.data())
          .filter((asData) => asData.releaseDate.toDate() < new Date());
        this.setState({ activeAssignments: activeAssignmentsFiltered });

        this.adminClassesListener = this.props.firebase.db
          .collection('classes')
          .where('admin', '==', this.props.firebase.auth.currentUser.uid)
          .onSnapshot(
            (querySnap) => {
              this.updateClassesData(querySnap, 'admin');
            },
            (err) => {
              console.log(err);
            }
          );
      } else {
        this.membershipClassesListener = this.props.firebase.db
          .collection('classes')
          .where(
            'students',
            'array-contains',
            this.props.firebase.auth.currentUser.uid
          )
          .onSnapshot(
            (querySnap) => {
              this.updateClassesData(querySnap, 'member');
            },
            (err) => {
              console.log(err);
            }
          );
        // pendingClasses = await this.props.firebase.getPendingClasses();
      }
    } catch (err) {
      this.setState({ error: err });
    }
  }

  async componentWillUnmount() {
    if (this.props.userToken.claims.teacher) {
      this.adminClassesListener();
    } else {
      this.membershipClassesListener();
    }
  }

  toggleDeleteClass(classId, classTitle) {
    this.setState({
      showDeleteClassConfirmModal: true,
      classDeleteId: classId,
      classDeleteTitle: classTitle,
    });
  }

  toggleOffDeleteClassModal() {
    this.setState({
      showDeleteClassConfirmModal: false,
    });
  }

  deleteClass() {
    //Update UI
    this.props.firebase.deleteClass(this.state.classDeleteId);
  }

  render() {
    if (this.state.error) {
      throw this.state.error;
    }
    if (!this.state.ready) {
      return <Loading />;
    }
    return (
      <PageWrapper>
        <SimpleBanner color={'var(--rsBlue)'}>
          <BackArrow />
          <Header>
            <Title>Your Classes</Title>
            <CreateJoinClass userToken={this.props.userToken} />
          </Header>
        </SimpleBanner>
        {this.state.classes.length === 0 ? (
          <EmptyClasses userToken={this.props.userToken} />
        ) : (
          <MainWrapper>
            <DeleteConfirmModal
              title={`Are you sure you want to delete ${this.state.classDeleteTitle}?`}
              show={this.state.showDeleteClassConfirmModal}
              delete={this.deleteClass}
              toggle={this.toggleOffDeleteClassModal}
            />
            {this.state.classes.map((enrolledClass) => {
              return (
                <ClassPreviewCard
                  userToken={this.props.userToken}
                  key={enrolledClass.classId}
                  classInfo={enrolledClass}
                  deleteClassToggle={this.toggleDeleteClass}
                  assignmentsFollowed={this.props.assignmentsFollowed}
                  activeAssignments={this.state.activeAssignments}
                ></ClassPreviewCard>
              );
            })}

            {/* <PendingClassesContainer>
          <PendingClasses pendingClasses={this.state.pendingClasses} />
        </PendingClassesContainer> */}
          </MainWrapper>
        )}
      </PageWrapper>
    );
  }
}

export default withFirebase(Classes);
