import React, { Component } from 'react';
import styled from 'styled-components';
import ChooseImageModal from '../../../../UI-Library/Modals/ChooseImageModal';
import UnsplashLink from '../../../../UI-Library/ButtonsOrLinks/UnsplashLink';
import RemoveImageButton from '../../../../UI-Library/ButtonsOrLinks/RemoveImageButton';

import RsButton from '../../../../UI-Library/ButtonsOrLinks/RsButton';
import RsInput from '../../../../UI-Library/Forms/RsInput';
import Trash from '../../../../UI-Library/Icons/Trash';
import Tooltip from '../../../../UI-Library/Misc/Tooltip';

const Annotations = styled.div`
  max-height: 60vh;
  text-align: left;
  display: flex;
  flex-direction: column;
  border: none;
  background-color: var(--rsSurface);
  box-shadow: 5px 5px 15px black;
  border-radius: 16px;
  padding: 40px;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }
`;

const Image = styled.img`
  margin-top: 10px;
  margin-bottom: 10px;
  max-width: 100%;
  max-height: 20vh;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
`;

/**
 * Props passed:
 * - {Object} annotation
 * - {string} id
 * - {boolean} show
 */
class GeniusEditPopUp extends Component {
  render() {
    if (
      !this.props.annotation ||
      this.props.annotation.desc === null ||
      !this.props.show
    ) {
      return <div>{null}</div>;
    } else {
      return (
        <Annotations>
          <RsInput
            value={this.props.annotation.desc}
            onChange={(e) => {
              this.props.handleDescChange(this.props.id, e);
            }}
            height={'20vh'}
            max-height={'200px'}
            textarea={true}
          ></RsInput>
          {this.props.annotation.firebaseImg === null ? null : (
            <div className='text-center' style={{ height: '50%' }}>
              <RemoveImageButton
                onClickFn={this.props.removeImage}
                onClickArg={this.props.id}
              />
              <Image src={this.props.annotation.firebaseImg}></Image>
            </div>
          )}
          {this.props.annotation.unsplash === null ? null : (
            <div className='text-center' style={{ height: '50%' }}>
              <RemoveImageButton
                onClickFn={this.props.removeImage}
                onClickArg={this.props.id}
              />
              <Image src={this.props.annotation.unsplash}></Image>
              <UnsplashLink
                uid={this.props.annotation.unsplashUid}
                name={this.props.annotation.unsplashName}
              />
            </div>
          )}
          <Footer>
            <Tooltip placement='bottom' text='Delete annotation'>
              <Trash
                onClick={() => {
                  this.props.deleteAnnotation(this.props.id);
                }}
              />
            </Tooltip>

            <RsButton onClick={this.props.toggleChooseImageModal}>
              Choose Image
            </RsButton>
          </Footer>
          <ChooseImageModal
            index={null}
            selectImage={this.props.selectImage}
            firebaseQImage={this.props.firebaseImage}
            toggle={this.props.toggleChooseImageModal}
            show={this.props.showChooseImageModal}
          />
        </Annotations>
      );
    }
  }
}

export default GeniusEditPopUp;
