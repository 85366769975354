import React, { Component } from 'react';
import styled from 'styled-components';
import BrowseSearchResult from './BrowseSearchResult';
import { withFirebase } from '../../../Firebase';
import { withRouter } from 'react-router-dom';

import SearchError from './SearchError';
import SearchEmpty from './SearchEmpty';
import Loading from '../../../UI-Library/Misc/Loading';

const UnitImg = styled.img`
  height: 180px;
  margin-right: 20px;
`;
UnitImg.displayName = 'UnitImg';

const UnitImgRow = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  flex-wrap: wrap;

  ${({ nowrap }) =>
    nowrap &&
    `
    flex-wrap: nowrap;
    overflow-x: hidden;
    scroll-behavior: smooth;
`}
`;
UnitImgRow.displayName = 'UnitImgRow';

const SubjectHeader = styled.div`
  margin-top: 30px;
  font-weight: bold;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;
SubjectHeader.displayName = 'SubjectHeader';

/**
 * type : string
 * query : string
 * subdata : Array
 */
class BrowseSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      error: null,
      searchResult: null,
      searchQuery: '',
      lastQuery: '',
      numSearches: 0,
      searchErr: false,
    };
    this.runSearch = this.runSearch.bind(this);
  }

  async componentDidMount() {
    try {
      const params = this.props.match.params;
      const query = params.query;
      await this.runSearch(query);
    } catch (err) {
      this.setState({ error: err });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    try {
      const params = this.props.match.params;
      const query = params.query;
      if (!this.props.match) return;
      if (query !== prevProps.match.params.query) {
        await this.runSearch(query);
      }
    } catch (err) {
      this.setState({ error: err });
    }
  }

  async runSearch(query) {
    try {
      if (
        !query ||
        query == null ||
        query.length === 0 ||
        query.trim().length === 0
      ) {
        return;
      }
      this.setState({ ready: false, lastQuery: query });
      const result = await this.props.firebase.cloudSearchAlgolia(query);
      this.setState({
        searchResult: result,
        ready: true,
        searchErr: false,
        numSearches: this.state.numSearches + 1,
      });
    } catch (err) {
      this.setState({
        searchResult: null,
        ready: true,
        searchErr: true,
      });
    }

    //Log in event 'search_executed' [Firebase Analytics]
    this.props.firebase.analytics.logEvent('search_executed', {
      query: query,
    });
  }

  render() {
    if (this.state.error) {
      throw this.state.error;
    }
    if (!this.state.ready) return <Loading />;
    if (this.state.searchErr) return <SearchError />;
    if (
      !this.state.searchResult ||
      !Object.keys(this.state.searchResult).length
    )
      return <SearchEmpty />;
    const params = this.props.match.params;
    const query = params.query;
    const data = this.state.searchResult;
    return (
      <div>
        <BrowseSearchResult type={'Unit'} query={query} subdata={data.units} />
        <BrowseSearchResult
          type={'Topic'}
          query={query}
          subdata={data.topics}
        />
        <BrowseSearchResult type={'Song'} query={query} subdata={data.songs} />
      </div>
    );
  }
}

export default withRouter(withFirebase(BrowseSearch));
