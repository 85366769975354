import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Carousel from 'react-bootstrap/Carousel';
import { subjectColors } from '../../../../../constants/colors';
import ScorePill from '../../../../UI-Library/ButtonsOrLinks/ScorePill';
import ExerciseThumbnail from '../../../../UI-Library/ItemDisplays/ExerciseThumbnail';

const BoxesContainer = styled.div`
  width: 100%;
  height: auto;
`;

const CarouselItem = styled(Carousel.Item)`
  width: 100%;
`;

class ThumbnailCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canFit: 0,
    };
    this.boxRef = React.createRef();
    this.updateDims = this.updateDims.bind(this);
    this.buildBoxes = this.buildBoxes.bind(this);
    this.calcActiveSlide = this.calcActiveSlide.bind(this);
  }

  componentDidMount() {
    this.updateDims();
    window.addEventListener('resize', this.updateDims);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDims);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.activeExerciseId !== prevProps.activeExerciseId) {
      this.calcActiveSlide();
    }
  }

  calcActiveSlide() {
    if (this.state.canFit === 0) return;
    const activeExerciseId = this.props.activeExerciseId;
    this.props.submissions.forEach((sub, index) => {
      if (sub.getExerciseId() === activeExerciseId) {
        const newSlideNum = Math.ceil((index + 1) / this.state.canFit) - 1;
        this.props.overrideSlideNum(newSlideNum);
        return;
      }
    });
  }

  /**
   * Returns a nested array of submissions, where each sub-array has length
   * canFit.
   * @param {Array} submissions
   * @param {number} canFit
   */
  makeStackedSlides(submissions, canFit) {
    if (submissions.length === 0 || canFit === 0) return [];
    var arrays = [];
    var nested = [];
    var added = 0;

    for (var i = 0; i < submissions.length; i++) {
      nested.push(submissions[i]);
      added++;
      if (i === submissions.length - 1) {
        arrays.push(nested);
      } else if (added % canFit === 0) {
        arrays.push(nested);
        nested = [];
        added = 0;
      }
    }
    return arrays;
  }

  updateDims() {
    if (this.boxRef.current === null) return;

    const coverWidth = 304 + 20; // 304px cover, and 20px margin
    const boxWidth = this.boxRef.current.clientWidth;
    const canFit = Math.floor(boxWidth / coverWidth);

    this.setState(
      {
        canFit: canFit,
      },
      () => {
        const slides = this.makeStackedSlides(this.props.submissions, canFit);
        this.calcActiveSlide();
        this.props.onResize(slides.length);
      }
    );
  }

  buildBoxes(slides, canFit) {
    if (slides.length === 0) return [];

    return slides.map((slide, sIndex) => (
      <CarouselItem
        key={'carouselItem_' + sIndex.toString() + '_' + canFit.toString()}
      >
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-end',
          }}
        >
          {slide.map((submission, index) => {
            return (
              <div
                style={{ marginLeft: '20px' }}
                key={'SUBMISSIONTHUMBNAIL_' + submission.getSubmissionId()}
              >
                <ExerciseThumbnail
                  active={
                    !this.props.isDone &&
                    submission.getExerciseId() === this.props.activeExerciseId
                  }
                  src={submission.getSongData().src}
                  featureName={submission.getFeatureName()}
                  songName={submission.getSongData().subtopic.name}
                  songId={submission.getSongData().id}
                  setId={submission.getSetId()}
                  setName={submission.getName()}
                  backgroundColor={
                    subjectColors[submission.getSongData().subject.name]
                  }
                  onClick={() =>
                    this.props.switchExercise(submission.getExerciseId())
                  }
                  clickable={submission.getStatus() !== 'complete'}
                  disabled={submission.getStatus() === 'complete'}
                >
                  <ScorePill
                    status={submission.getStatus()}
                    score={submission.getScore()}
                    total={submission.getLength()}
                    clickable={false}
                  />
                </ExerciseThumbnail>
              </div>
            );
          })}
        </div>
      </CarouselItem>
    ));
  }

  render() {
    return (
      <BoxesContainer ref={this.boxRef}>
        <Carousel
          interval={null}
          controls={false}
          activeIndex={this.props.currSlide}
          fade={false}
          indicators={false}
          key={this.state.canFit}
        >
          {this.buildBoxes(
            this.makeStackedSlides(this.props.submissions, this.state.canFit),
            this.state.canFit
          )}
        </Carousel>
      </BoxesContainer>
    );
  }
}

ThumbnailCarousel.propTypes = {
  /**
   * The items that will be included in the carousel.
   */
  submissions: PropTypes.array.isRequired,
  /**
   * The current slide number.
   */
  currSlide: PropTypes.number.isRequired,
  /**
   * The function that is called when the window is resized. The function has a
   * single parameter, the number of slides. Optimally, this function changes
   * the passed currSlide dynamically.
   */
  onResize: PropTypes.func.isRequired,
  /**
   * The function fired when a thumbnail is clicked.
   */
  switchExercise: PropTypes.func.isRequired,
  /**
   * The active exercise.
   */
  activeExerciseId: PropTypes.string,
  /**
   * The function to override the slide number.
   */
  overrideSlideNum: PropTypes.func,
};

export default ThumbnailCarousel;
