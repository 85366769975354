import React, { Component } from 'react';
import styled from 'styled-components';
import DropdownMenu from '../../../ButtonsOrLinks/DropdownMenu';
import Create from '../../../Icons/Create';
import Fullscreen from '../../../Icons/Fullscreen';
import List from '../../../Icons/List';
import Pencil from '../../../Icons/Pencil';
import Tooltip from '../../../Misc/Tooltip';
import Reset from '../../../Icons/Reset';

const MainWrapper = styled.div`
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ fullscreen }) =>
    fullscreen &&
    `
    padding: 0;
`}
`;

const IconWrap = styled.div`
  margin-right: 20px;
`;

const FullscreenWrap = styled.div`
  ${({ fullscreen }) =>
    fullscreen &&
    `
    position: fixed;
    top: 5%;
    right: 10%;
`}
`;

class ViewHeader extends Component {
  render() {
    const setMenuItems =
      this.props.featureName === 'Karaoke'
        ? null
        : this.props.userSets.map((set) => ({
            desc: set.name,
            toggleMenuItemAction: () => this.props.selectSet(set.setId),
            requiredRole: 'Both',
          }));

    return (
      <MainWrapper fullscreen={this.props.fullscreen}>
        <div>
          {this.props.featureName !== 'Karaoke' && !this.props.fullscreen ? (
            <div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '10px',
                }}
              >
                <h1 className='bold' style={{ margin: 0 }}>
                  {this.props.title}
                </h1>
                <p
                  className='secondary'
                  style={{ fontSize: '14px', margin: 0, marginLeft: '10px' }}
                >
                  by {this.props.authorName}
                </p>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {this.props.featureName !== 'Breakdown' && (
                  <IconWrap>
                    <Tooltip placement='bottom' text='Reset'>
                      <Reset onClick={this.props.reset} />
                    </Tooltip>
                  </IconWrap>
                )}

                {this.props.isAuthor && (
                  <IconWrap>
                    <Tooltip placement='bottom' text='Edit'>
                      <Pencil onClick={this.props.toggleEdit} />
                    </Tooltip>
                  </IconWrap>
                )}
                <IconWrap>
                  <Tooltip placement='bottom' text='Create a set'>
                    <Create onClick={this.props.customContentModalToggle} />
                  </Tooltip>
                </IconWrap>
                <IconWrap>
                  <DropdownMenu menuItems={setMenuItems} direction={'center'}>
                    <Tooltip placement='bottom' text='Change set'>
                      <List onClick={() => null} />
                    </Tooltip>
                  </DropdownMenu>
                </IconWrap>
              </div>
            </div>
          ) : null}
        </div>
        <FullscreenWrap fullscreen={this.props.fullscreen}>
          {this.props.fullscreen ? (
            <Fullscreen toEnter={false} onClick={this.props.clickFullScreen} />
          ) : (
            <Tooltip placement='bottom' text='Enter fullscreen'>
              <Fullscreen toEnter={true} onClick={this.props.clickFullScreen} />
            </Tooltip>
          )}
        </FullscreenWrap>
      </MainWrapper>
    );
  }
}

export default ViewHeader;
