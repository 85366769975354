import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';
import { BrowserRouter as Router } from 'react-router-dom';
import Firebase, { FirebaseContext } from './components/Firebase';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import CustomAlert from './components/CustomAlert';
import Maintenance from './maintenance';

const alertOptions = {
	position: positions.BOTTOM_CENTER,
	timeout: 2750,
	offset: '30px',
	transition: transitions.FADE,
};

Sentry.init({
	dsn: process.env.REACT_APP_SENTRY_DSN,
	environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
	release: 'rapstudy@' + process.env.REACT_APP_VERSION,
});

ReactDOM.render(
	<React.StrictMode>
		<Router>
			<AlertProvider template={CustomAlert} {...alertOptions}>
				<FirebaseContext.Provider value={new Firebase()}>
					{/* <App /> */}
					{/* Uncomment above and remove Maintenance to restore original pages */}
					<Maintenance />
				</FirebaseContext.Provider>
			</AlertProvider>
		</Router>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
