import React from 'react';
import styled from 'styled-components';

import { device } from '../../../../constants/breakpoints';

const MainWrapper = styled.div`
  padding: 7%;
  padding-top: 3%;
  background: white;
`;

const Header = styled.h1`
  max-width: 1000px;
  font-size: 36px;
  font-weight: bold;
  color: black;
  text-align: left;

  @media ${device.tablet} {
    font-size: 30px;
  }
  @media ${device.mobileL} {
    font-size: 24px;
  }
`;

const SubHeader = styled.h1`
  color: black;
  margin-bottom: 30px;
  text-align: left;
  font-weight: 300;
  font-size: 24px;

  @media ${device.tablet} {
    font-size: 18px;
    margin-bottom: 20px;
  }
  @media ${device.mobileL} {
    font-size: 14px;
    margin-bottom: 10px;
  }
`;

const Thumbnail = styled.img`
  width: auto;
  height: 200px;
  background: grey;
  margin-bottom: 5px;
  border-radius: 8px;
  @media ${device.mobileL} {
    height: 150px;
  }
`;

const Date = styled.p`
  color: black;
  margin-bottom: 5px;
  font-size: 24px;
  @media ${device.tablet} {
    font-size: 14px;
  }
  @media ${device.mobileL} {
    font-size: 12px;
  }
`;

const Desc = styled.p`
  color: black;
  margin-bottom: 0;
  font-weight: 300;
  font-size: 24px;
  @media ${device.tablet} {
    font-size: 14px;
  }
  @media ${device.mobileL} {
    font-size: 12px;
  }
`;

const ReadMore = styled.a`
  color: black;
  text-decoration: underline;
  text-decoration-color: black !important;
  &:hover {
    color: black;
    text-decoration: underline;
    cursor: pointer;
  }
  font-size: 18px;
  @media ${device.tablet} {
    font-size: 14px;
  }
  @media ${device.mobileL} {
    font-size: 12px;
  }
`;

class AboutNews extends React.Component {
  render() {
    const data = [
      {
        thumbSrc:
          'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fchronicle.jpg?alt=media&token=2047b9cd-ca43-4ac3-aeab-38c1c271d331',
        date: 'Nov. 3, 2020',
        publisher: 'Cornell Chronicle',
        desc: "Undergrads promote children's learning with rapStudy",
        link:
          'https://news.cornell.edu/stories/2020/11/undergrads-promote-childrens-learning-rapstudy',
      },
      {
        thumbSrc:
          'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Ffox40-2.png?alt=media&token=4de5283b-b44c-48ce-92c7-072ecdbd6015',
        date: 'Nov. 13, 2020',
        publisher: 'Fox 40',
        desc: 'Cornell Student-Run Company Turns Pop Songs Into Teaching Tools',
        link:
          'https://www.wicz.com/story/42909901/cornell-studentrun-company-turns-pop-songs-into-teaching-tools',
      },
    ];

    return (
      <MainWrapper>
        <Header>In the news</Header>
        <SubHeader>What's going on in the world of rapStudy</SubHeader>
        <div style={{ maxWidth: '1200px', margin: 'auto' }}>
          {data.map((ele, index) => (
            <div
              className='row'
              style={{ marginBottom: '60px' }}
              key={'NEWS_' + index}
            >
              <div className='col-lg-auto'>
                <a href={ele.link} target='_blank' rel='noopener noreferrer'>
                  <Thumbnail src={ele.thumbSrc} />
                </a>
              </div>
              <div
                className='col-lg'
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <Date className='bold'>{ele.date}</Date>
                <Desc className='bold'>{ele.publisher}</Desc>
                <Desc>{ele.desc}</Desc>
                <ReadMore href={ele.link} target='_blank'>
                  Read more
                </ReadMore>
              </div>
            </div>
          ))}
        </div>
      </MainWrapper>
    );
  }
}

export default AboutNews;
