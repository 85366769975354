import React, { Component } from 'react';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import Rating from '../../Forms/Rating';
import RsButton from '../../ButtonsOrLinks/RsButton';
import RsInput from '../../Forms/RsInput';
import Cross from '../../Icons/Cross';

const Container = styled.div`
  padding-top: 29px;
  padding-right: 30px;
  padding-left: 34px;
`;

const ModalHeader = styled(Modal.Header)`
  align-items: center;
`;

const titleStyle = {
  fontWeight: 'bold',
  fontSize: '30px',
};

const bodyStyle = {
  textAlign: 'left',
  color: 'rgba(255, 255, 255, 0.78)',
};

export class SongFeedbackModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showHide: this.props.show,
      feedback: '',
      rating: null,
    };
    this.handleFeedbackChange = this.handleFeedbackChange.bind(this);
    this.handleModalShowHide = this.handleModalShowHide.bind(this);
    this.submit = this.submit.bind(this);
    this.handleRatingClick = this.handleRatingClick.bind(this);
  }

  handleModalShowHide() {
    this.props.reset();
    this.setState({
      showHide: !this.state.showHide,
      rating: null,
      feedback: '',
    });
  }

  handleFeedbackChange(event) {
    this.setState({ feedback: event.target.value });
  }

  submit() {
    // Hide modal
    this.handleModalShowHide();
    this.props.completeFeedback(
      this.props.id,
      this.state.rating,
      this.state.feedback,
      this.props.userToken.claims.teacher
    );
  }

  handleRatingClick(rating) {
    this.setState({ rating: rating });
  }

  render() {
    return (
      <div>
        <Modal
          dialogClassName='border-radius-16-wide'
          show={this.props.show}
          onHide={this.handleModalShowHide}
          centered
          animation={false}
        >
          <Container>
            <ModalHeader>
              <Modal.Title style={titleStyle}>
                Did your class enjoy this song?
              </Modal.Title>
              <Cross size='18px' onClick={this.handleModalShowHide} />
            </ModalHeader>

            <Modal.Body style={bodyStyle}>
              Your feedback is important to us!
              <Rating
                rating={this.state.rating}
                handleRatingClick={this.handleRatingClick}
              />
              If you want to give us more precise feedback, you can type it in
              below. (Optional)
              <RsInput
                onChange={this.handleFeedbackChange}
                value={this.state.feedback}
                textarea={true}
              ></RsInput>
            </Modal.Body>
            <Modal.Footer
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <RsButton secondary={true} onClick={this.handleModalShowHide}>
                Ask me later
              </RsButton>
              <RsButton
                disabled={this.state.rating == null}
                onClick={this.submit}
              >
                Submit
              </RsButton>
            </Modal.Footer>
          </Container>
        </Modal>
      </div>
    );
  }
}

export default SongFeedbackModal;
