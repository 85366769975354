import React, { Component } from 'react';
import styled from 'styled-components';
import { withFirebase } from '../../../Firebase';
import { withAlert } from 'react-alert';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';
import * as ROUTES from '../../../../constants/routes';
import Loading from '../../../UI-Library/Misc/Loading';
import IndivGradeSummary from '../../../../Classes/Assignments/IndivGradeSummary';
import GradesSummary from '../../../../Classes/Assignments/GradesSummary';

import AssignmentViewTeacherSummary from './AssignmentViewTeacherSummary';
import AssignmentViewTeacherIndiv from './AssignmentViewTeacherIndiv';
import SimpleBanner from '../../../UI-Library/Containers/SimpleBanner';
import BannerNav from '../../../UI-Library/Navs/BannerNav';
import BackArrow from '../../../UI-Library/ButtonsOrLinks/BackArrow';
import { subjectColors } from '../../../../constants/colors';
import AssignmentViewTeacherPreview from './AssignmentViewTeacherPreview';
import AssignmentViewTeacherEdit from './AssignmentViewTeacherEdit';
import ThumbnailScroll from '../ThumbnailScroll';
import DropdownMenu from '../../../UI-Library/ButtonsOrLinks/DropdownMenu';
import DeleteConfirmModal from '../../../UI-Library/Modals/DeleteConfirmModal';
import Copy from '../../../UI-Library/Icons/Copy';
import Tooltip from '../../../UI-Library/Misc/Tooltip';

import GoogleShareToClassRoom from 'google-classroom-share';

const MainWrapper = styled.div``;

class AssignmentViewTeacher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gradesSummary: null,
      allSubmissions: null,
      ready: false,
      error: null,
      // For deletion
      showDelete: false,
      // For preview
      activeExerciseId: null,
      switchExercise: null,
      assignmentSets: null,
      preview: false,
    };
    this.updateCarousel = this.updateCarousel.bind(this);
    this.removeCarousel = this.removeCarousel.bind(this);
    this.editDetails = this.editDetails.bind(this);
    this.delete = this.delete.bind(this);
    this.toggleDelete = this.toggleDelete.bind(this);
    this.copyAssignmentLink = this.copyAssignmentLink.bind(this);
  }

  async componentDidMount() {
    try {
      const submissions = await this.props.firebase.getSubmissionsForAssignment(
        this.props.assignmentId
      );
      const uidSet = new Set();
      const subtopicIds = new Set();
      submissions.forEach((sub) => {
        uidSet.add(sub.getStudentId());
        subtopicIds.add(sub.getSongData().subtopic.subtopicRef.id);
      });
      const studentIds = Array.from(uidSet);
      const userProfiles = await Promise.all(
        studentIds.map(
          async (id) => await this.props.firebase.getUserProfile(id)
        )
      );
      const subtopicSrcs = {};
      await Promise.all(
        Array.from(subtopicIds).map(async (subtopicId) => {
          const src = await this.props.firebase.getSongCover(subtopicId);
          subtopicSrcs[subtopicId] = src;
        })
      );

      const indivSummaries = userProfiles.map(
        (userProfile) =>
          new IndivGradeSummary(
            userProfile.uid,
            userProfile.first_name + ' ' + userProfile.last_name,
            this.props.assignmentId
          )
      );

      const gradesSummary = new GradesSummary(
        this.props.assignmentId,
        this.props.assignmentData.name
      );

      indivSummaries.forEach((indiv) => gradesSummary.addStudentGrades(indiv));
      submissions.forEach((sub) => {
        const songObj = sub.getSongData();
        const subtopicId = songObj.subtopic.subtopicRef.id;
        songObj.src = subtopicSrcs[subtopicId];
        const uid = sub.getStudentId();
        const indiv = gradesSummary.getIndivGrades(uid);
        indiv.addSubmission(sub);
      });

      this.setState({
        gradesSummary: gradesSummary,
        allSubmissions: submissions,
        ready: true,
      });
    } catch (err) {
      this.setState({ error: err });
    }
  }

  updateCarousel(sets, switcher, activeId) {
    this.setState({
      preview: true,
      assignmentSets: sets,
      switchExercise: switcher,
      activeExerciseId: activeId,
    });
  }

  removeCarousel() {
    this.setState({
      preview: false,
    });
  }

  async editDetails(name, desc, releaseDate, dueDate) {
    try {
      await this.props.editDetails(name, desc, releaseDate, dueDate);
      const allSubmissions = this.state.allSubmissions;
      allSubmissions.forEach((sub) => {
        sub.setReleaseDate(releaseDate);
        sub.setDueDate(dueDate);
      });
    } catch (err) {
      this.setState({ error: err });
    }
  }

  async delete() {
    try {
      await this.props.firebase.deleteAssignment(this.props.assignmentId);
      this.props.alert.show('Successfully deleted assignment.');
      this.props.history.push(
        `/internal/classView/${this.props.classId}/assignments`
      );
    } catch (err) {
      this.setState({ error: err });
    }
  }

  toggleDelete() {
    this.setState({ showDelete: !this.state.showDelete });
  }

  copyAssignmentLink() {
    navigator.clipboard.writeText(
      `${window.location.origin}/internal/classView/${this.props.classId}/assignments/${this.props.assignmentId}`
    );
    this.props.alert.show('Assignment link copied to clipboard.');
  }

  render() {
    if (this.state.error) throw this.state.error;
    if (!this.state.ready) return <Loading />;
    const assignmentData = this.props.assignmentData;
    const subject = assignmentData.subject;
    const gradesSummary = this.state.gradesSummary;
    let isDone = true;

    if (this.state.preview) {
      const submissions = this.state.assignmentSets;
      submissions.forEach(
        (sub) => (isDone = isDone && sub.getStatus() === 'complete')
      );
    }

    const bannerItems = [
      {
        icon: null,
        to: `/internal/classView/${this.props.classId}/assignments/${this.props.assignmentId}/teacher/summary`,
        name: 'Summary',
      },
    ];
    if (!gradesSummary.isEmpty()) {
      bannerItems.push({
        icon: null,
        to: `/internal/classView/${this.props.classId}/assignments/${
          this.props.assignmentId
        }/teacher/individual/${gradesSummary
          .getSortedSummaries()[0]
          .getStudentId()}`,
        name: 'Individual',
      });
    }
    bannerItems.push({
      icon: null,
      to: `/internal/classView/${this.props.classId}/assignments/${this.props.assignmentId}/teacher/preview`,
      name: 'Preview',
    });

    return (
      <MainWrapper>
        <SimpleBanner color={subjectColors[subject]}>
          <BackArrow />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: '100%',
              paddingTop: '25px',
              paddingBottom: '25px',
            }}
          >
            <div
              style={{
                width: '50%',
                paddingRight: '10px',
              }}
            >
              <h1 className='bold ellipsisWrap'>{assignmentData.name}</h1>
              <p className='bold secondary'>{assignmentData.description}</p>
              <BannerNav replace={true} items={bannerItems} />
            </div>
            {this.state.preview ? (
              <div style={{ width: '50%' }}>
                <ThumbnailScroll
                  submissions={this.state.assignmentSets}
                  isDone={isDone}
                  switchExercise={this.state.switchExercise}
                  activeExerciseId={this.state.activeExerciseId}
                />
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  height: '100%',
                }}
              >
                <span style={{ marginRight: '10px' }}>
                  <Tooltip placement='bottom' text='Share to Google Classroom'>
                    <GoogleShareToClassRoom
                      body={this.props.assignmentData.description}
                      itemType='assignment'
                      url={`${window.location.origin}/internal/classView/${this.props.classId}/assignments/${this.props.assignmentId}`}
                      size={32}
                      title={'rapStudy: ' + this.props.assignmentData.name}
                      theme='dark'
                      onShare={(type) =>
                        console.log(`GoogleShareToClassRoom:${type}`)
                      }
                      onShareComplete={() => {
                        this.props.firebase.analytics.logEvent(
                          'share-to-classroom',
                          { contentType: 'assignment' }
                        );
                      }}
                      onShareStart={() =>
                        console.log('GoogleShareToClassRoom:onShareStart')
                      }
                    />
                  </Tooltip>
                </span>

                <Tooltip placement='bottom' text='Copy assignment link'>
                  <Copy size='32px' onClick={this.copyAssignmentLink} />
                </Tooltip>
                <span style={{ marginLeft: '10px' }}>
                  <DropdownMenu
                    iconSize='large'
                    direction='left'
                    isTeacher={true}
                    menuItems={[
                      {
                        toggleMenuItemAction: () => {
                          this.props.history.replace(
                            `/internal/classView/${this.props.classId}/assignments/${this.props.assignmentId}/teacher/edit`
                          );
                        },
                        requiredRole: 'Both',
                        desc: 'Edit details',
                      },
                      {
                        toggleMenuItemAction: this.toggleDelete,
                        requiredRole: 'Both',
                        desc: 'Delete assignment',
                      },
                    ]}
                  />
                </span>
              </div>
            )}
          </div>
        </SimpleBanner>
        <Switch>
          <Route
            exact
            path={ROUTES.ASSIGNMENTTEACHERSUMMARY}
            render={(props) => (
              <AssignmentViewTeacherSummary
                {...props}
                classData={this.props.classData}
                assignmentData={assignmentData}
                assignmentId={this.props.assignmentId}
                classId={this.props.classId}
                gradesSummary={this.state.gradesSummary}
              />
            )}
          />

          <Route
            path={ROUTES.ASSIGNMENTTEACHERINDIV}
            render={(props) => (
              <AssignmentViewTeacherIndiv
                {...props}
                classData={this.props.classData}
                assignmentData={this.props.assignmentData}
                assignmentId={this.props.assignmentId}
                classId={this.props.classId}
                gradesSummary={this.state.gradesSummary}
              />
            )}
          />

          <Route
            path={ROUTES.ASSIGNMENTTEACHERPREVIEW}
            render={(props) => (
              <AssignmentViewTeacherPreview
                {...props}
                classData={this.props.classData}
                assignmentData={this.props.assignmentData}
                assignmentId={this.props.assignmentId}
                classId={this.props.classId}
                gradesSummary={this.state.gradesSummary}
                updateCarousel={this.updateCarousel}
                removeCarousel={this.removeCarousel}
              />
            )}
          />

          <Route
            path={ROUTES.ASSIGNMENTTEACHEREDIT}
            render={(props) => (
              <AssignmentViewTeacherEdit
                {...props}
                classData={this.props.classData}
                assignmentData={this.props.assignmentData}
                assignmentId={this.props.assignmentId}
                classId={this.props.classId}
                editDetails={this.editDetails}
              />
            )}
          />

          <Route
            render={() => (
              <Redirect
                to={`/internal/classView/${this.props.classId}/assignments/${this.props.assignmentId}/teacher/summary`}
              />
            )}
          />
        </Switch>

        <DeleteConfirmModal
          title='Delete assignment?'
          show={this.state.showDelete}
          toggle={this.toggleDelete}
          delete={this.delete}
        />
      </MainWrapper>
    );
  }
}

export default withAlert()(withRouter(withFirebase(AssignmentViewTeacher)));
