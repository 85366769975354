import React, { Component } from 'react';

import styled from 'styled-components';

import AudioBar from '../../../UI-Library/AudioOrLyrics/AudioBar';

import SongNav from './SongNav';

import 'rc-slider/assets/index.css';
import { withRouter } from 'react-router-dom';

import AddToPlaylistModal from '../../../UI-Library/Modals/AddToPlaylistModal';
import ShareToClassModal from '../../../UI-Library/Modals/ShareToClassModal';
import DropdownMenu from '../../../UI-Library/ButtonsOrLinks/DropdownMenu';
import BackArrow from '../../../UI-Library/ButtonsOrLinks/BackArrow';
import TextToggler from '../../../UI-Library/ButtonsOrLinks/TextToggler';

import Tooltip from '../../../UI-Library/Misc/Tooltip';
import Copy from '../../../UI-Library/Icons/Copy';
import GoogleShareToClassRoom from 'google-classroom-share';

const BannerWrapper = styled.div`
  position: relative;
  height: 225px;
  padding-left: 5%;
  padding-right: 5%;
  margin: 0;

  background: linear-gradient(
    0deg,
    var(--rsBackground) 0%,
    ${(props) => props.backgroundColor} 100%
  );

  ${({ fullscreen }) =>
    fullscreen &&
    `
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10vh;
    background: var(--rsSurface);
    display:flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
  `}
`;

const BannerRow = styled.div`
  padding-top: 25px;
  padding-bottom: 25px;
  height: 100%;

  display: flex;
  flex-wrap: nowrap;
  padding-left: 0;
  padding-right: 0;
  margin: 0;

  ${({ fullscreen }) =>
    fullscreen &&
    `
    padding-top: 10px;
    margin-bottom: 0;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    width: 100%;
    padding-right: 30px;
  `}
`;

const ImageColumn = styled.div`
  display: flex;
  @media screen and (max-width: 1000px) {
    display: none;
  }
`;

const BoxImage = styled.img`
  margin: 0;
  @media screen and (max-width: 1152px) {
    height: 150px;
    width: 150px;
  }
`;

const InfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 5px;
  padding-right: 0;
  ${({ fullscreen }) =>
    fullscreen &&
    `
    flex-direction: row;
    align-items: center;

  `}
  margin: 0;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
`;

const TitleP = styled.p`
  font-size: 32px;
  margin-bottom: 0;
  @media screen and (max-width: 1120px) {
    font-size: 24px;
  }
`;

const FullScreenTitle = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const EllipsisHeader = styled.p`
  padding-left: 10%;
  padding-right: 10%;
  font-size: 24px;
  margin-bottom: 0;
  @media screen and (max-width: 1200px) {
    font-size: 20px;
  }
`;

const Artist = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: white;
  @media screen and (max-width: 1120px) {
    font-size: 14px;
  }
`;

const IconWrapper = styled.span`
  margin: 0;
  margin-right: 10px;
  height: 32px;
`;

class SongBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      playback: 'Normal',
      sliderValue: 0,
      volumeLevel: 50,
      showAddToPlaylist: false,
      showShareSongToClass: false,
      showShareSetToClass: false,
    };
    this.variantRef = React.createRef();
    this.toggleAddToPlaylistModal = this.toggleAddToPlaylistModal.bind(this);
    this.toggleShareSetToClassModal = this.toggleShareSetToClassModal.bind(
      this
    );
    this.toggleShareSongToClassModal = this.toggleShareSongToClassModal.bind(
      this
    );
    this.changePlayback = this.changePlayback.bind(this);
  }

  changePlayback(text, speed) {
    this.setState({ playback: text });
    this.props.playback(speed);
  }

  handleVolumeChange = (event, value) => {
    this.props.setVolume({ value });
    this.setState({ volumeLevel: value });
  };

  handleProgressChange = (event, value) => {
    this.props.setTime((value / 100) * this.props.duration);
  };

  toggleAddToPlaylistModal() {
    this.setState({ showAddToPlaylist: !this.state.showAddToPlaylist });
  }

  toggleShareSetToClassModal() {
    this.setState({ showShareSetToClass: !this.state.showShareSetToClass });
  }
  toggleShareSongToClassModal() {
    this.setState({ showShareSongToClass: !this.state.showShareSongToClass });
  }

  render() {
    const hasSibs = this.props.songArr && this.props.songArr.length > 1;
    const menuItems = [
      {
        toggleMenuItemAction: this.toggleAddToPlaylistModal,
        requiredRole: 'Both',
        desc: 'Add to playlist',
      },
      {
        toggleMenuItemAction: this.toggleShareSongToClassModal,
        requiredRole: 'Teacher',
        desc: 'Add song to class',
      },
    ];
    const siblingMenuItems = hasSibs
      ? this.props.songArr.map((item) => {
          return {
            desc: '"' + item.origTitle + '" by ' + item.origArtist,
            requiredRole: 'Both',
            toggleMenuItemAction: () => this.props.selectVariation(item.id),
          };
        })
      : [];
    if (
      this.props.currSetId &&
      this.props.currSetId !== null &&
      this.props.currSetId !== 'null'
    )
      menuItems.push({
        toggleMenuItemAction: this.toggleShareSetToClassModal,
        requiredRole: 'Teacher',
        desc: 'Add set to class',
      });
    return (
      <BannerWrapper
        backgroundColor={this.props.backgroundColor}
        fullscreen={this.props.fullscreen}
      >
        {!this.props.fullscreen ? <BackArrow /> : null}

        <BannerRow className='row fillVW' fullscreen={this.props.fullscreen}>
          {!this.props.fullscreen ? (
            <ImageColumn className='col-auto' style={{ paddingLeft: 0 }}>
              <BoxImage src={this.props.imgSrc} className='albumCover' />
            </ImageColumn>
          ) : (
            <FullScreenTitle className='col-4'>
              <EllipsisHeader className='bold ellipsisWrap'>
                {this.props.newTitle}
              </EllipsisHeader>
            </FullScreenTitle>
          )}
          <InfoColumn className='col' fullscreen={this.props.fullscreen}>
            {!this.props.fullscreen ? (
              <div>
                <Title className='justify-content-between'>
                  <TitleP className='bold ellipsisWrap'>
                    {this.props.newTitle}
                  </TitleP>
                  <DropdownMenu
                    menuItems={siblingMenuItems}
                    isTeacher={false}
                    direction={'center'}
                    width={350}
                  >
                    <TextToggler
                      clickable={hasSibs}
                      pulse={
                        hasSibs &&
                        !this.props.hasClickedPlay &&
                        this.props.currFeature === 'Karaoke'
                      }
                    >
                      <Artist className='ellipsisWrap'>
                        <span className='bold'>
                          "{this.props.origTitle}" by{' '}
                        </span>{' '}
                        {this.props.origArtist.map((item, index) => {
                          if (index === this.props.origArtist.length - 1) {
                            return (
                              <span className='bold' key={'ORGARTIST_' + index}>
                                {item}
                              </span>
                            );
                          } else {
                            return (
                              <span className='bold' key={'ORGARTIST_' + index}>
                                {item + ', '}
                              </span>
                            );
                          }
                        })}
                      </Artist>
                      {hasSibs && (
                        <img
                          src='/images/icons/utility/caretgrey.png'
                          style={{ marginLeft: '5px' }}
                          alt=''
                        />
                      )}
                    </TextToggler>
                  </DropdownMenu>
                </Title>
                <div
                  style={{ marginTop: '5px', display: 'flex' }}
                  className='justify-content-between'
                >
                  <p
                    className='ellipsisWrap'
                    style={{
                      marginBottom: '0',
                      marginTop: '5px',
                      fontSize: '18px',
                    }}
                  >
                    {this.props.subject.name}, {this.props.unit.name},{' '}
                    {this.props.topic.name}
                  </p>
                  <div style={{ display: 'flex' }}>
                    <IconWrapper>
                      <Tooltip
                        placement='bottom'
                        text={`Share ${this.props.currFeature} to Google Classroom`}
                      >
                        <GoogleShareToClassRoom
                          key={
                            window.location.href + '_' + this.props.currFeature
                          }
                          itemType='material'
                          url={window.location.href}
                          title={`${this.props.currFeature}: ${this.props.newTitle} based on ${this.props.origTitle}`}
                          theme='dark'
                          onShare={(type) =>
                            console.log(`GoogleShareToClassRoom:${type}`)
                          }
                          onShareComplete={() => {
                            console.log(
                              'GoogleShareToClassRoom:onShareComplete:' +
                                this.props.currFeature
                            );
                            this.props.logAnalyticsShare();
                          }}
                          onShareStart={() =>
                            console.log('GoogleShareToClassRoom:onShareStart')
                          }
                        />
                      </Tooltip>
                    </IconWrapper>
                    <IconWrapper>
                      <Tooltip
                        placement='bottom'
                        text={`Copy link to ${this.props.currFeature}`}
                      >
                        <Copy size={'32px'} onClick={this.props.copyLink} />
                      </Tooltip>
                    </IconWrapper>
                    <DropdownMenu
                      iconSize='large'
                      isTeacher={this.props.userToken.claims.teacher}
                      menuItems={menuItems}
                      direction={'left'}
                    />
                  </div>
                </div>
              </div>
            ) : null}
            <AudioBar
              play={this.props.play}
              audioLoaded={this.props.audioLoaded}
              currentTime={this.props.currentTime}
              duration={this.props.duration}
              playback={this.state.playback}
              volumeLevel={this.state.volumeLevel}
              playOrPause={this.props.playOrPause}
              handleProgressChange={this.handleProgressChange}
              changePlayback={this.changePlayback}
              handleVolumeChange={this.handleVolumeChange}
              showProgress={true}
              showSpeed={true}
              showVolume={true}
              maxWidth={'600px'}
            />

            {!this.props.fullscreen ? <SongNav id={this.props.id} /> : null}
          </InfoColumn>
          <AddToPlaylistModal
            id={this.props.id}
            toggle={this.toggleAddToPlaylistModal}
            show={this.state.showAddToPlaylist}
          />

          <ShareToClassModal
            show={this.state.showShareSongToClass}
            toggle={this.toggleShareSongToClassModal}
            contentType={'Song'}
            contentId={this.props.id}
          />

          <ShareToClassModal
            show={this.state.showShareSetToClass}
            toggle={this.toggleShareSetToClassModal}
            contentType={this.props.currFeature}
            contentId={this.props.currSetId}
          />
        </BannerRow>
      </BannerWrapper>
    );
  }
}

export default withRouter(SongBanner);
