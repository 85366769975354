import React, { Component } from 'react';
import * as ROUTES from '../../../../../constants/routes';
import BannerNav from '../../../../UI-Library/Navs/BannerNav';

import Note from '../../../../UI-Library/Icons/Note';
import Lightning from '../../../../UI-Library/Icons/Lightning';
import TextLines from '../../../../UI-Library/Icons/TextLines';
import PenAndPaper from '../../../../UI-Library/Icons/PenAndPaper';

class SongNav extends Component {
  render() {
    const items = [
      {
        icon: Note,
        to: ROUTES.KARAOKE + '?songId=' + this.props.id,
        name: 'Karaoke',
      },
      {
        icon: Lightning,
        to: ROUTES.GENIUS + '?songId=' + this.props.id,
        name: 'Breakdown',
      },
      {
        icon: TextLines,
        to: ROUTES.BLANKS + '?songId=' + this.props.id,
        name: 'Blanks',
      },
      {
        icon: PenAndPaper,
        to: ROUTES.REVIEW + '?songId=' + this.props.id,
        name: 'Review',
      },
    ];
    return <BannerNav items={items} replace={true} />;
  }
}

export default SongNav;
