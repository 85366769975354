import React, { Component } from 'react';
import styled from 'styled-components';

import RsInput from '../../UI-Library/Forms/RsInput';
import RsButton from '../../UI-Library/ButtonsOrLinks/RsButton';

import { withFirebase } from '../../Firebase';
import { withAlert } from 'react-alert';

import { device } from '../../../constants/breakpoints';

const EmailInput = styled.div`
  max-width: 800px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const ButtonWrap = styled.div`
  margin-left: 20px;
  @media ${device.tablet} {
    flex-basis: 100%;
    margin-top: 10px;
    margin-left: 0px;
    text-align: center;
  }
`;

class UpdatesSignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  handleChange(e) {
    this.setState({ email: e.target.value });
  }

  submit() {
    const email = this.state.email;
    this.props.firebase.doSignUpContact(email).then(() => {
      // this.props.history.push(ROUTES.LANDING);
      if (this.props.alert) {
        this.props.alert.show(email + ' has signed up for email updates!');
      }
    });
    this.setState({ email: '' });
  }

  render() {
    return (
      <EmailInput>
        <RsInput
          value={this.state.email}
          onChange={this.handleChange}
          width={'70%'}
          placeholder='Enter your email...'
        />
        <ButtonWrap>
          <RsButton
            // Regex pattern from: https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
            disabled={!/\S+@\S+\.\S+/.test(this.state.email)}
            width={'170px'}
            onClick={this.submit}
            height={'45px'}
            landing={true}
          >
            Get Updates
          </RsButton>
        </ButtonWrap>
      </EmailInput>
    );
  }
}

export default withAlert()(withFirebase(UpdatesSignUp));
