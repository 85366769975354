import React, { Component } from 'react';
import styled from 'styled-components';
import SongShare from '../SongShare';
import SetShare from '../SetShare';
import PlaylistShare from '../PlaylistShare';
import EmptyContent from '../../../../UI-Library/Misc/EmptyContent';

const Spacing = styled.div`
  width: 100%;
  margin-top: 30px;
`;

const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const NonEmptyWrapper = styled.div`
  width: 100%;
`;

class EmptyShared extends Component {
  render() {
    const allEmpty =
      this.props.emptySets &&
      this.props.emptyPlaylists &&
      this.props.emptySongs;

    if (this.props.isAdmin && allEmpty) {
      return (
        <MainWrapper>
          <EmptyContent
            src='/images/animationAssets/teacher1.png'
            lead='Share content with your students'
          >
            <p>
              You can share <span className='major'>Songs</span> ,{' '}
              <span className='major'>Playlists</span>, and{' '}
              <span className='major'>Study Sets</span> with your students.
              <br />
              <br />
              <span className='bold'>Tips:</span> <br />
              1. To add content, go to the song/playlist/study set, click on the{' '}
              <img
                src={'/images/icons/utility/dropdowndots.svg'}
                style={{ width: '18px', height: '18px' }}
                alt=' dots '
              />{' '}
              icon in the top right, and select{' '}
              <span className='major'>Share to Class</span>.
              <br />
              2. To quickly add multiple study sets, go to the{' '}
              <span className='major'>Your Study Sets</span> page and click on
              the{' '}
              <img
                src={'/images/icons/utility/dropdowndots.svg'}
                style={{ width: '18px', height: '18px' }}
                alt=' dots '
              />{' '}
              icon next to the ones you want to add.
            </p>
          </EmptyContent>
        </MainWrapper>
      );
    } else if (allEmpty) {
      return (
        <MainWrapper>
          <EmptyContent
            src='/images/animationAssets/teacher1.png'
            lead='Nothing here yet...'
          >
            Ask your teacher to add their favorites!
          </EmptyContent>
        </MainWrapper>
      );
    } else if (this.props.isAdmin) {
      return (
        <NonEmptyWrapper>
          {this.props.emptySongs && (
            <div>
              <SongShare
                songs={[]}
                remove={null}
                isTeacher={this.props.isTeacher}
                isAdmin={this.props.isAdmin}
                id={this.props.id}
              />
              <Spacing />
            </div>
          )}
          {this.props.emptyPlaylists && (
            <div>
              <PlaylistShare
                playlists={[]}
                remove={null}
                isTeacher={this.props.isTeacher}
                isAdmin={this.props.isAdmin}
                id={this.props.id}
              />
              <Spacing />
            </div>
          )}
          {this.props.emptySets && (
            <div>
              <SetShare
                sets={[]}
                remove={null}
                isTeacher={this.props.isTeacher}
                isAdmin={this.props.isAdmin}
                id={this.props.id}
              />
              <Spacing />
            </div>
          )}
        </NonEmptyWrapper>
      );
    } else return null;
  }
}

export default EmptyShared;
