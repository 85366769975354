import React from 'react';
import styled from 'styled-components';
import { device } from '../../../../constants/breakpoints';
import UpdatesSignUp from '../../UpdatesSignUp';

const MainWrapper = styled.div`
  height: calc(100vh - 200px);
  max-height: 50vh;
  min-height: 250px;
  background: white;
`;

const Jumbo1 = styled.div`
  background: linear-gradient(360deg, #4831d4 0%, rgba(255, 255, 255, 0) 100%),
    #37dbf6;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 5%;
  padding-right 5%;
`;

const Header = styled.h1`
  max-width: 679px;
  font-size: 36px;
  font-weight: bold;
  color: white;

  @media ${device.tablet} {
    font-size: 24px;
  }
  @media ${device.mobileL} {
    font-size: 18px;
    text-align: center;
  }
  @media ${device.mobileS} {
    font-size: 14px;
  }
  margin-bottom: 6%;
`;

class AboutTestimonials extends React.Component {
  render() {
    return (
      <MainWrapper>
        <Jumbo1>
          <Header>
            Through the power of music, rapStudy <br />
            is paying forward the gift of education.
          </Header>

          <UpdatesSignUp />
        </Jumbo1>
      </MainWrapper>
    );
  }
}

export default AboutTestimonials;
