import React, { Component } from 'react';
import styled from 'styled-components';

import RsModal from '../RsModal';

const Pin = styled.p`
  font-size: 66px;
  text-align: center;
`;

export class PinFocusModal extends Component {
  render() {
    return (
      <RsModal
        show={this.props.show}
        toggle={this.props.toggle}
        title={'Hi, Students!'}
      >
        <RsModal.Body>
          <p style={{ fontSize: 24 }}>
            1. Go to the <span className='major'>Your Classes</span> page.{' '}
            <br />
            2. Click the <span className='major'>Join Class</span> button.{' '}
            <br />
            3. Enter the unique pin:{' '}
          </p>
          <Pin className='major'>{this.props.pin}</Pin>
        </RsModal.Body>
      </RsModal>
    );
  }
}

export default PinFocusModal;
