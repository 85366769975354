import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Lightbulb extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    };
    this.handleEnter = this.handleEnter.bind(this);
    this.handleLeave = this.handleLeave.bind(this);
  }

  handleEnter() {
    this.setState({ hover: true });
  }

  handleLeave() {
    this.setState({ hover: false });
  }

  render() {
    const hover =
      typeof this.props.hover !== 'undefined'
        ? this.props.hover
        : this.state.hover;

    const color = this.props.disabled
      ? 'var(--rsDisabled)'
      : hover
      ? 'var(--rsPrimary)'
      : 'var(--rsSecondary)';
    return (
      <div
        onMouseEnter={this.handleEnter}
        onMouseLeave={this.handleLeave}
        onClick={this.props.disabled ? null : this.props.onClick}
        style={{
          cursor:
            this.props.onClick && !this.props.disabled ? 'pointer' : 'default',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <svg
          width={this.props.size ? this.props.size : '24px'}
          height={this.props.size ? this.props.size : '24px'}
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 18 18'
        >
          <path
            d='M7.72,12.78a3.82,3.82,0,1,1,2.66,0'
            fill='none'
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
          />
          <polyline
            points='7.97 15.07 7.72 15.07 7.72 12.78'
            fill='none'
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
          />
          <line
            x1='10.13'
            y1='15.03'
            x2='7.97'
            y2='15.03'
            fill='none'
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
          />
          <polyline
            points='10.38 12.78 10.38 15.13 10.13 15.13'
            fill='none'
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
          />
          <path
            d='M10.13,15.45a1.08,1.08,0,0,1-2.16,0'
            fill='none'
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
          />
          <line
            x1='9.05'
            y1='1.68'
            x2='9.05'
            y2='2.87'
            fill='none'
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
          />
          <line
            x1='12.47'
            y1='3.87'
            x2='13.06'
            y2='2.83'
            fill='none'
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
          />
          <line
            x1='14.51'
            y1='6.01'
            x2='15.59'
            y2='5.51'
            fill='none'
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
          />
          <line
            x1='15.38'
            y1='9.08'
            x2='16.57'
            y2='9.08'
            fill='none'
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
          />
          <line
            x1='15.73'
            y1='12.62'
            x2='14.7'
            y2='12.03'
            fill='none'
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
          />
          <line
            x1='5.62'
            y1='3.87'
            x2='5.03'
            y2='2.83'
            fill='none'
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
          />
          <line
            x1='3.58'
            y1='6.01'
            x2='2.5'
            y2='5.51'
            fill='none'
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
          />
          <line
            x1='2.94'
            y1='9.08'
            x2='1.75'
            y2='9.08'
            fill='none'
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
          />
          <line
            x1='2.36'
            y1='12.62'
            x2='3.39'
            y2='12.03'
            fill='none'
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
          />
        </svg>
      </div>
    );
  }
}

Lightbulb.propTypes = {
  /**
   * Should the icon take on disabled styling?
   */
  disabled: PropTypes.bool,
  /**
   * The function fired when the icon is clicked.
   */
  onClick: PropTypes.func,
  /**
   * The size of the icon. Defaults to 24px.
   */
  size: PropTypes.string,
  /**
   * A hover override from the parent component. If defined, the local
   * state hover is ignored.
   */
  hover: PropTypes.bool,
};

export default Lightbulb;
