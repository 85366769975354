import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../../../Firebase';
import { withAlert } from 'react-alert';

import SimpleBanner from '../../../UI-Library/Containers/SimpleBanner';
import CreateJoinClass from '../../../UI-Library/Modals/CreateJoinClass';
import RsButton from '../../../UI-Library/ButtonsOrLinks/RsButton';

export class HomeBannerBase extends Component {
  render() {
    const isTeacher = this.props.userToken.claims.teacher;
    if (isTeacher)
      return (
        <SimpleBanner src='/images/banners/assignment-announce.png'>
          <p className='bold' style={{ marginBottom: 0 }}>
            We have a new feature...
          </p>
          <h1 style={{ fontSize: '48px', fontWeight: 900 }}>Assignments!</h1>
          <p className='bold secondary'>
            Share your Review and Blanks study sets with your students and track
            their progress.
          </p>
          <div>
            <RsButton
              onClick={() => {
                this.props.firebase.analytics.logEvent(
                  'link_open_home_banner',
                  {
                    link_name: 'assignments announcement',
                  }
                );
                window.open(
                  'https://support-rapstudy1.web.app/#/article/creating-an-assignment',
                  '_blank'
                );
              }}
            >
              Learn More
            </RsButton>
          </div>
        </SimpleBanner>
      );

    return (
      <SimpleBanner
        lead={'Hi, ' + this.props.userData.first_name + '!'}
        sub={'Welcome to rapStudy.'}
        color={'var(--rsBlue)'}
      >
        <CreateJoinClass userToken={this.props.userToken} />
      </SimpleBanner>
    );
  }
}

export default withAlert()(withRouter(withFirebase(HomeBannerBase)));
