import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../../../constants/routes';
import styled from 'styled-components';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import RsButton from '../../../UI-Library/ButtonsOrLinks/RsButton';
import DropdownMenu from '../../../UI-Library/ButtonsOrLinks/DropdownMenu';
import EmptyContent from '../../../UI-Library/Misc/EmptyContent';
import Create from '../../../UI-Library/Icons/Create';
import DragHandle from '../../../UI-Library/Icons/DragHandle';

const SongRow = styled.div`
  width: 100%;
  padding-bottom: 10px;
  padding-top: 10px;
  vertical-align: middle;
  margin-left: 0;
  margin-right: 0;

  border-radius: 16px;
  color: var(--rsSecondary);
  text-decoration: none !important;

  &:hover {
    background: var(--rsSurfaceHover);
    color: var(--rsPrimary);
  }
`;

const RecentRow = styled.div`
  padding-bottom: 10px;
  padding-top: 10px;
  vertical-align: middle;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
`;

const MainBox = styled.div`
  padding: 5%;
  padding-top: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const SongBox = styled.div`
  border-radius: 16px;
  background-color: var(--rsSurface);
  margin-bottom: 10px;
  width: 100%;
`;

const SongBoxHeader = styled.div`
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 10px;
`;

const RecentsBoxHeader = styled.div`
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 20px;
  margin-bottom: 10px;
  width: 100%;
`;

const SongField = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #dddddd;
`;

const FirstField = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: var(--rsSecondary);
`;

const Icon = styled.div`
  margin-left: auto;
  cursor: pointer;
`;

const IconWrapper = styled.div`
  padding-left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const LinkWrapper = styled.div`
  text-align: center;
`;

const grid = 0.5;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid,
  margin: `0 0 ${grid}px 0`,
  paddingLeft: '0',
  paddingRight: '0',
  borderRadius: '16px',

  // change background colour if dragging
  background: isDragging ? 'grey' : 'var(--rsSurface)',

  // styles we need to apply on draggables
  ...draggableStyle,
});

/**
 * PlaylistContent displays the contents of the current playlist.
 */
export class PlaylistContent extends Component {
  constructor(props) {
    super(props);
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  async onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = this.props.reorder(
      result.source.index,
      result.destination.index
    );

    this.setState({
      items,
    });
  }

  renderRecents() {
    var recentResult = null;
    var modifiedRecents = null;

    if (this.props.recentSongs) {
      if (this.props.songs == null) {
        modifiedRecents = this.props.recentSongs;
      } else {
        if (this.props.recentSongs != null) {
          modifiedRecents = [];
          for (var i = 0; i < this.props.recentSongs.length; i++) {
            var exists = false;
            for (var j = 0; j < this.props.songs.length; j++) {
              if (this.props.songs[j].id === this.props.recentSongs[i].id) {
                exists = true;
                break;
              }
            }
            if (!exists) {
              modifiedRecents.push(this.props.recentSongs[i]);
            }
          }
        }
      }
      recentResult = modifiedRecents.map((song, index) => (
        <RecentSong
          song={song}
          key={song.id}
          addSong={this.props.addSong}
        ></RecentSong>
      ));
    }
    return recentResult;
  }

  render() {
    const isTeacher = this.props.userToken.claims.teacher;
    const recentResult = this.renderRecents();
    const isEmpty = !this.props.songs || this.props.songs.length === 0;
    const isAuthor = this.props.isAuthor;
    const validRecents = recentResult && recentResult.length > 0;

    return (
      <MainBox>
        {isEmpty ? null : (
          <div style={{ width: '100%' }}>
            <SongBoxHeader className='row'>
              <div className='col-4 bold'>Song</div>
              <div className='col-3 bold'>Unit</div>
              <div className='col-5 bold'>Based on</div>
            </SongBoxHeader>
            <SongBox>
              <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId='droppable'>
                  {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {this.props.songs.map((item, index) => (
                        <Draggable
                          key={'draggable_' + item.id}
                          draggableId={item.id}
                          index={index}
                          isDragDisabled={!isAuthor}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <Song
                                song={item}
                                key={index}
                                removeSong={this.props.removeSong}
                                isAuthor={isAuthor}
                                isTeacher={isTeacher}
                                toggleAddToPlaylist={
                                  this.props.toggleAddToPlaylist
                                }
                                toggleClassShare={this.props.toggleClassShare}
                                setClassShareType={this.props.setClassShareType}
                                dragProvided={provided}
                              ></Song>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </SongBox>
          </div>
        )}

        {isAuthor && isEmpty ? (
          <EmptyContent
            src='/images/animationAssets/girlWalk.png'
            lead='Start building your playlist!'
          >
            <p>
              <span className='major'>Playlists</span> help you save and
              organize songs that you like. You can also share created playlists
              with your students by adding it to a class!{' '}
            </p>
            <p>
              <span className='bold'>Tip: </span>
              To add a song, navigate to the song, click on the{' '}
              <img
                src={'/images/icons/utility/dropdowndots.svg'}
                style={{ width: '18px', height: '18px' }}
                alt=' dots '
              />{' '}
              icon in the top left, and select{' '}
              <span className='major'>Add to Playlist</span>. <br />
            </p>
          </EmptyContent>
        ) : null}

        {!isAuthor && isEmpty ? (
          <EmptyContent
            src='/images/animationAssets/girlWalk.png'
            lead='Nothing here!'
          >
            <p>The author of this playlist has not added any songs yet.</p>
          </EmptyContent>
        ) : null}

        {isAuthor && validRecents && (
          <RecentsBoxHeader className='row'>
            <div className='col-4 bold'>Recent songs</div>
          </RecentsBoxHeader>
        )}

        {isAuthor && validRecents && <SongBox>{recentResult}</SongBox>}

        {isAuthor && (
          <LinkWrapper>
            <Link to={ROUTES.BROWSE_SUBJECTS}>
              <RsButton mb={'20px'} mt={'10px'} large={true}>
                Browse
              </RsButton>
            </Link>
          </LinkWrapper>
        )}
      </MainBox>
    );
  }
}

export class Song extends Component {
  constructor(props) {
    super(props);
    this.handleAddToClassModalShowHide = this.handleAddToClassModalShowHide.bind(
      this
    );
  }

  handleAddToClassModalShowHide() {
    this.props.setClassShareType('Song', this.props.song.id);
    this.props.toggleClassShare();
  }

  render() {
    var menuItems = [
      {
        desc: 'Add to playlist',
        requiredRole: 'Both',
        toggleMenuItemAction: () =>
          this.props.toggleAddToPlaylist(this.props.song.id),
      },
    ];
    if (this.props.isAuthor) {
      menuItems.push({
        desc: 'Remove from playlist',
        requiredRole: 'Both',
        toggleMenuItemAction: () => this.props.removeSong(this.props.song),
      });
    }
    menuItems.push({
      desc: 'Add song to class',
      requiredRole: 'Teacher',
      toggleMenuItemAction: this.handleAddToClassModalShowHide,
    });

    return (
      <SongRow className='row'>
        <Link
          to={ROUTES.SONGVIEW + '/karaoke?songId=' + this.props.song.id}
          className='noDecoration  col-4'
        >
          <FirstField>{this.props.song.subtopic.name}</FirstField>
        </Link>
        <Link
          to={ROUTES.SONGVIEW + '/karaoke?songId=' + this.props.song.id}
          className='noDecoration  col-3'
        >
          <SongField>{this.props.song.unit.name}</SongField>
        </Link>
        <Link
          to={ROUTES.SONGVIEW + '/karaoke?songId=' + this.props.song.id}
          className='noDecoration  col-3'
        >
          <SongField>{this.props.song.origTitle}</SongField>
        </Link>
        <IconWrapper className='col-2'>
          {this.props.isAuthor && (
            <Icon className='mr-4' {...this.props.dragProvided.dragHandleProps}>
              <DragHandle size={'24px'} />
            </Icon>
          )}
          <DropdownMenu
            menuItems={menuItems}
            isTeacher={this.props.isTeacher}
            direction={'left'}
            iconSize={'small'}
          />
        </IconWrapper>
      </SongRow>
    );
  }
}

export class RecentSong extends Component {
  render() {
    return (
      <RecentRow className='row'>
        <FirstField className='col-4 secondary'>
          {this.props.song.subtopic.name}
        </FirstField>
        <SongField className='col-3'>{this.props.song.unit.name}</SongField>
        <SongField className='col-3'>{this.props.song.origTitle}</SongField>
        <IconWrapper className='col-2'>
          <Create
            onClick={() => {
              this.props.addSong(this.props.song);
            }}
          />
        </IconWrapper>
      </RecentRow>
    );
  }
}

export default PlaylistContent;
