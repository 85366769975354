class SubtopicDict{
  constructor(
    src,
    imgName,
    grade,
    songArr,
    standard,
    name,
    ref,
    id
    ){
      this.src = src;
      this.imgName = imgName;
      this.grade = grade;
      this.songArr = songArr; //[]
      this.standard = standard;
      this.name = name;
      this.songId = this.getFirstSongId();   
      this.ref = ref;
      this.id = id;   
  }

  /**
   * Gets id of the first song in the subtopic. 
   * @return {string}
   */
  getFirstSongId() {
    if (!this.songArr || this.songArr.length===0) return '';
    return this.songArr[0].id;
  }
  
  /**
   * Returns a copy of the current SubtopicDict
   * @return {SubtopicDict}
   */
  copySubtopic() {
    return new SubtopicDict(
      this.src,
      this.imgName,
      this.grade,
      this.songArr,
      this.standard,
      this.name,
      this.ref,
      this.id
    )

  }
  
  /**
   * Gets name of the subtopic
   * @return {string}
   */
  getName(){
    return this.name;
  }

  /**
   * Gets id of the subtopic reference
   * @return {string}
   */
  getId(){
    return this.id;
  }

  /**
   * Gets the grade of the subtopic.
   * @return {string}
   */
  getGrade() {
    return this.grade;
  }

  /**
   * Gets the standard of the subtopic.
   * @return {string}
   */
  getStandard() {
    return this.standard;
  }

  static subtopicConverter = {
    fromFirestore: (snapshot, options) => {
      const data = snapshot.data(options);
      return new SubtopicDict(
        data.firebaseImgName,
        data.imgName,
        data.grade,
        data.songArr,
        data.standard,
        data.name,
        snapshot.ref,
        snapshot.ref.id
      )
    }
  }
}

export default SubtopicDict;