import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { withFirebase } from '../../../Firebase';

import RsButton from '../../ButtonsOrLinks/RsButton';
import RsInput from '../../Forms/RsInput';
import RsModal from '../RsModal';

const ErrorMsg = styled.p`
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0;
`;

export class CreatePlaylistModalBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showHide: false,
      name: '',
      description: '',
      disabled: true,
      errorMsg: false,
      creating: false,
    };
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleModalShowHide = this.handleModalShowHide.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.create = this.create.bind(this);
  }

  handleModalShowHide() {
    this.setState({
      name: '',
      description: '',
      disabled: true,
      creating: false,
    });
    this.props.toggle();
  }

  handleNameChange(e) {
    this.setState({ name: e.target.value });
    if (!e.target.value || e.target.value.trim().length === 0) {
      this.setState({ disabled: true, errorMsg: false });
    } else {
      this.setState({ disabled: false, errorMsg: false });
    }
  }

  handleDescriptionChange(e) {
    this.setState({ description: e.target.value });
  }

  async create() {
    this.setState({ creating: true });
    var playlistRef = await this.props.firebase.addPlaylist(
      this.state.name,
      this.state.description
    );
    var name = this.state.name;
    this.setState({ name: '', desc: '' });
    if (this.props.willRedirect) {
      this.props.history.push('/internal/playlistView/' + playlistRef.id);
    } else {
      this.props.returnPlaylist({ name: name, playlistId: playlistRef.id });
    }
    this.setState({ creating: false, disabled: true });

    this.handleModalShowHide();
  }

  render() {
    let error;
    if (this.state.errorMsg) {
      error = <p> You already have a playlist with this title </p>;
    }
    return (
      <RsModal
        show={this.props.show}
        toggle={this.handleModalShowHide}
        title={'Create New Playlist'}
        submit={this.create}
      >
        <RsModal.Body>
          <p style={{ marginBottom: 0 }}>Name</p>
          <RsInput
            value={this.state.name}
            onChange={this.handleNameChange}
            mb={'10px'}
          />

          <p style={{ marginBottom: 0 }}>Description</p>
          <RsInput
            value={this.state.description}
            onChange={this.handleDescriptionChange}
          />
          <div>
            <ErrorMsg>{error}</ErrorMsg>
          </div>
        </RsModal.Body>
        <RsModal.Footer>
          <RsButton
            onClick={this.create}
            disabled={this.state.disabled || this.state.creating}
          >
            Create
          </RsButton>
        </RsModal.Footer>
      </RsModal>
    );
  }
}

const CreatePlaylistModal = withRouter(withFirebase(CreatePlaylistModalBase));
export default CreatePlaylistModal;
