import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Circle = styled.div`
  vertical-align: middle;
  display: relative;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  font-size: ${(props) => props.fontSize};
  text-align: center;
  line-height: ${(props) => props.size};
`;

/**
 * The icon to display a user's initial.
 */
class UserInitial extends Component {
  render() {
    var letter = this.props.letter;
    var size = this.props.large ? '100px' : '35px';
    var fontSize = this.props.large ? '80px' : '24px';
    var color = this.props.color ? this.props.color : 'var(--rsBlue)';
    return (
      <Circle size={size} fontSize={fontSize} color={color}>
        <p className='bold' style={{ margin: 0 }}>
          {letter}
        </p>
      </Circle>
    );
  }
}

UserInitial.propTypes = {
  /**
   * The initial to be represented inside the circle.
   */
  letter: PropTypes.string,
  /**
   * Whether or not the icon is large.
   */
  large: PropTypes.bool,
  /**
   * The color of the circle. Defaults to rapStudy blue if not defined.
   */
  color: PropTypes.string,
};

export default UserInitial;
