import React, { Component } from 'react';
import styled from 'styled-components';
import CreatePlaylistModal from '../CreatePlaylistModal';

const MainWrapper = styled.div`
  background-color: transparent !important;
`;

const TogglerModal = styled.a`
  :hover {
    cursor: pointer;
    text-decoration: none;
  }
  background-color: transparent;
  color: var(--rsSecondary) !important;
  text-decoration: none;
`;

export class CreatePlaylistModalToggle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showHide: false,
    };
    this.handleModalShowHide = this.handleModalShowHide.bind(this);
  }

  handleModalShowHide() {
    this.setState({
      showHide: !this.state.showHide,
    });
  }

  render() {
    return (
      <MainWrapper>
        <TogglerModal
          onClick={this.handleModalShowHide}
          className='vertNav-link'
        >
          Create Playlist
        </TogglerModal>

        <CreatePlaylistModal
          willRedirect={this.props.willRedirect}
          show={this.state.showHide}
          toggle={this.handleModalShowHide}
        />
      </MainWrapper>
    );
  }
}

export default CreatePlaylistModalToggle;
