import React, { Component } from 'react';
import styled, { css, keyframes } from 'styled-components';
import PropTypes from 'prop-types';

const pulseAnimation = keyframes`
  0%   { box-shadow: 0 0 6 white; }
  50%  { box-shadow: 0 0 10px white; }
  100% { box-shadow: 0 0 6 white; }
`;

const StyledButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 15px;
  padding-top: 0px;
  padding-bottom: 0px;
  background: var(--rsSurface);
  border: 2px solid transparent;
  border-radius: 20px;
  max-width: 300px;
  // margin-left: 10px;
  font-size: 14px;
  height: 30px;

  ${({ clickable }) => !clickable && ` cursor: default !important;`}

  ${({ clickable }) =>
    clickable &&
    `&:hover {
      border: 2px solid white;
      box-shadow: 1px 1px 5px white;
      }
      border: 2px solid white;
      `}

  animation: ${(props) =>
    props.pulse
      ? css`
          ${pulseAnimation} 1s ease-in-out infinite forwards
        `
      : ''} ;
  
  @media screen and (max-width: 1200px) {
    font-size: 12px;
  }
`;

/**
 * The text toggle component for use in conjunction with dropdowns.
 */
class TextToggler extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hovered: false,
    };
    this.onMouseEnter = this.onMouseEnter.bind(this);
  }

  onMouseEnter() {
    this.setState({ hovered: true });
  }

  render() {
    return (
      <StyledButton
        clickable={this.props.clickable}
        pulse={this.props.pulse && !this.state.hovered}
        onMouseEnter={this.onMouseEnter}
      >
        {this.props.children}
      </StyledButton>
    );
  }
}

TextToggler.propTypes = {
  /**
   * Is the toggler clickable?
   */
  clickable: PropTypes.bool,
  /**
   * Is the toggler pulsing?
   */
  pulse: PropTypes.bool,
};

export default TextToggler;
