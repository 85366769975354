import React, { Component } from 'react';
import { withFirebase } from '../../Firebase';
import Loading from '../../UI-Library/Misc/Loading';
import DeleteConfirmModal from '../../UI-Library/Modals/DeleteConfirmModal';
import * as ROUTES from '../../../constants/routes';
import { Route } from 'react-router-dom';
import ClassMembers from './ClassMembers';
import SharedContent from './SharedContent';
import ClassBanner from './ClassBanner';
import styled from 'styled-components';
import ColorPickerModal from '../../UI-Library/Modals/ColorPickerModal';
import FormModal from '../../UI-Library/Modals/FormModal';

import { rowsPaletteList } from '../../../constants/colors';
import ClassAssignments from './ClassAssignments';

const MainWrapper = styled.div`
  height: 100%;
  padding-bottom: 10vh;
`;

class ClassView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      error: null,
      id: '',
      title: '',
      grade: '',
      subject: '',
      pin: '',
      totalStudents: 0,
      adminRef: null,
      adminId: '',
      description: '',
      sharedSongs: [],
      isAdmin: false,
      students: [],
      pending: [],
      color: null,
      showDeleteClassConfirmModal: false,
      colorShowHide: false,
      showEditClassModal: false,
    };

    this.toggleDeleteConfirmModal = this.toggleDeleteConfirmModal.bind(this);
    this.toggleEditClassModal = this.toggleEditClassModal.bind(this);
    this.deleteClass = this.deleteClass.bind(this);
    this.toggleColorModal = this.toggleColorModal.bind(this);
    this.onSelectColor = this.onSelectColor.bind(this);
    this.updateClassColor = this.updateClassColor.bind(this);
    this.onSubmitDetails = this.onSubmitDetails.bind(this);
    this.logAnalyticsPinShare = this.logAnalyticsPinShare.bind(this);
  }

  async componentDidMount() {
    try {
      if (!this.props.match) return;

      const params = this.props.match.params;
      const classId = params.classId;
      this.classOnSnapshot = this.props.firebase.db
        .collection('classes')
        .doc(classId)
        .onSnapshot(
          (docSnap) => {
            const classData = docSnap.data();
            if (!classData) {
              return;
            }
            this.setState({
              ready: true,
              id: classId,
              title: classData.title,
              grade: classData.grade,
              subject: classData.subject,
              pin: classData.pin,
              totalStudents: classData.totalStudents,
              adminId: classData.admin,
              description: classData.description,
              sharedSongs: classData.sharedSongs,
              students: classData.students,
              pending: classData.pending,
              isAdmin: classData.admin === this.props.userToken.claims.user_id,
              color: classData.color ? classData.color : '#37dbf6', // defaults to rS blue if undefined.
            });
          },
          (error) => {
            this.setState({ error: error });
          }
        );
    } catch (err) {
      this.setState({ error: err });
    }
  }

  componentWillUnmount() {
    this.classOnSnapshot();
  }

  async changePin() {
    try {
      // TODO Class Tech Demo: This should call a function from the firebase.js context
      throw Error('Unimplemented.');
    } catch (err) {
      this.setState({ error: err });
    }
  }

  async deleteClass() {
    await this.props.firebase.deleteClass(this.state.id);
    this.props.history.push('/internal/classes');
  }

  toggleDeleteConfirmModal() {
    this.setState({
      showDeleteClassConfirmModal: !this.state.showDeleteClassConfirmModal,
    });
  }

  toggleColorModal() {
    this.setState({ colorShowHide: !this.state.colorShowHide });
  }

  toggleEditClassModal() {
    this.setState({
      showEditClassModal: !this.state.showEditClassModal,
    });
  }

  async updateClassColor(hex) {
    if (!hex || !(typeof hex === 'string')) {
      throw Error('Invalidated Precondition: Hex is not a string.');
    }
    this.setState({ color: hex });
    await this.props.firebase.changeClassColor(this.state.id, hex);
  }

  onSelectColor(hex) {
    this.updateClassColor(hex);
    this.toggleColorModal();
  }

  onSubmitDetails(title, desc) {
    if (!title) throw Error('Invalidated Precondition: Title is falsy.');
    this.props.firebase.editClassDetails(this.state.id, title, desc);
  }

  logAnalyticsPinShare() {
    this.props.firebase.analytics.logEvent('share-to-classroom', {
      contentType: 'class-pin',
    });
  }

  render() {
    if (this.state.error) {
      throw this.state.error;
    }
    if (!this.state.ready) {
      return <Loading />;
    }
    return (
      <MainWrapper>
        <div style={{ height: '100%' }}>
          <ClassBanner
            color={this.state.color}
            title={this.state.title}
            pin={this.state.pin}
            description={this.state.description}
            classId={this.props.match.params.classId}
            isAdmin={this.state.isAdmin}
            userToken={this.props.userToken}
            deleteClassToggle={this.toggleDeleteConfirmModal}
            colorPickerToggle={this.toggleColorModal}
            toggleEditClassModal={this.toggleEditClassModal}
            logAnalyticsPinShare={this.logAnalyticsPinShare}
            history={this.props.history}
          />
          <DeleteConfirmModal
            title={`Are you sure you want to delete ${this.state.title}?`}
            show={this.state.showDeleteClassConfirmModal}
            delete={this.deleteClass}
            toggle={this.toggleDeleteConfirmModal}
          />

          <ColorPickerModal
            hexList={rowsPaletteList}
            toggle={this.toggleColorModal}
            onSelect={this.onSelectColor}
            show={this.state.colorShowHide}
            selected={this.state.color}
          />

          <FormModal
            submit={this.onSubmitDetails}
            toggle={this.toggleEditClassModal}
            show={this.state.showEditClassModal}
            name={this.state.title}
            desc={this.state.description}
          />

          <Route
            exact
            path={ROUTES.MEMBERS}
            render={(props) => (
              <ClassMembers
                {...props}
                id={this.state.id}
                userToken={this.props.userToken}
                adminId={this.state.adminId}
                students={this.state.students}
                pending={this.state.pending}
                totalStudents={this.state.totalStudents}
                isAdmin={this.state.isAdmin}
              />
            )}
          />

          <Route
            exact
            path={ROUTES.ASSIGNMENTS}
            render={(props) => (
              <ClassAssignments
                {...props}
                userToken={this.props.userToken}
                id={this.state.id}
                isAdmin={this.state.isAdmin}
                assignmentsFollowed={this.props.assignmentsFollowed}
              ></ClassAssignments>
            )}
          />

          <Route
            exact
            path={ROUTES.SHAREDCONTENT}
            render={(props) => (
              <SharedContent
                {...props}
                userToken={this.props.userToken}
                id={this.state.id}
                sharedSongs={this.state.sharedSongs}
                isAdmin={this.state.isAdmin}
              ></SharedContent>
            )}
          />
        </div>
      </MainWrapper>
    );
  }
}

export default withFirebase(ClassView);
