import React, { Component } from 'react';
import styled from 'styled-components';

const Link = styled.a`
  color: var(--rsSecondary);
  text-decoration-color: var(--rsSecondary);
  &:hover {
    color: var(--rsSecondary);
    text-decoration-color: var(--rsSecondary);
  }
`;

/**
 * Props:
 * - name
 * - id
 */
class UnsplashLink extends Component {
  render() {
    return (
      <p className='secondary' style={{ fontSize: '11px' }}>
        Photo by{' '}
        <Link
          href={
            'https://unsplash.com/@' +
            this.props.uid +
            '?utm_source=rapStudy&utm_medium=referral'
          }
          target='_blank'
        >
          {this.props.name}
        </Link>{' '}
        on{' '}
        <Link
          href='https://unsplash.com/?utm_source=rapStudy&utm_medium=referral'
          target='_blank'
        >
          Unsplash
        </Link>
      </p>
    );
  }
}

export default UnsplashLink;
