import React, { Component } from 'react';

import PropTypes from 'prop-types';

class Correct extends Component {
  render() {
    return (
      <svg
        width={this.props.size ? this.props.size : '24px'}
        height={this.props.size ? this.props.size : '24px'}
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 18 18'
      >
        <rect
          x='10.63'
          y='1'
          width='1.44'
          height='15.97'
          transform='translate(9.67 -5.39) rotate(45)'
          fill='#3dbf40'
          stroke='#3dbf40'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <rect
          x='3.11'
          y='7.85'
          width='1.44'
          height='7.98'
          transform='translate(-7.25 6.18) rotate(-45)'
          fill='#3dbf40'
          stroke='#3dbf40'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    );
  }
}

Correct.propTypes = {
  /**
   * The size of the icon. Defaults to 24px.
   */
  size: PropTypes.string,
};

export default Correct;
