export async function getBrowse() {
  // add queries
  var query = this.db.collectionGroup('units');

  // execute query
  var querySnap;

  querySnap = await query.orderBy('subject').get();
  if (!querySnap || querySnap.docs.length === 0) return null;

  // get image sources for units
  var datas = querySnap.docs.map((item) => {
    return item.data();
  });
  await Promise.all(
    datas.map(async (item) => {
      // Defined in unitview.js
      item.src = await this.getUnitImageURL(item.imgName);
    })
  );

  // sort into subjects
  var result = {};
  var tempArr = [];
  var currSubj = datas[0].subject;
  datas.forEach((data) => {
    if (data.subject === currSubj) {
      // base case - same subject
      tempArr.push(data);
    } else {
      // new subject case
      result[currSubj] = tempArr;
      tempArr = [];
      currSubj = data.subject;
      tempArr.push(data);
    }
  });
  result[currSubj] = tempArr;
  return result;
}

export async function cloudSearchAlgolia(searchQuery) {
  // ! if this function fails or the callable throws an https error, it will be caught by browse.
  // ! We need to manually log an error to Sentry if it fails, and then throw the exception again.
  try {
    if (!searchQuery || searchQuery === '' || searchQuery === ' ') return;
    const algoliaSearch = this.functions.httpsCallable('algolia-algoliaSearch');
    const json = await algoliaSearch({ query: searchQuery });
    const data = json.data;

    var result = {};
    await Promise.all(
      data.results.map(async (item) => {
        if (item.nbHits > 0) {
          var type = item.index;
          var hits = item.hits;
          var hitsWithImage = [];
          // song case
          if (type === process.env.REACT_APP_ALGOLIA_SUBTOPIC_ENV_NAME) {
            await Promise.all(
              hits.map(async (song) => {
                var obj = song;

                var src = await this.getSongCover(song.subtopicId);
                obj.src = src;
                hitsWithImage.push(obj);
              })
            );
            result['songs'] = hitsWithImage
          }
          // unit case
          if (type === 'units') {
            await Promise.all(
              hits.map(async (unit) => {
                var obj = unit;

                // ! string concat workaround for now. We need to standardize the image storing
                var src = await this.getUnitImageURL(unit.objectID + '.png');
                obj.src = src;
                hitsWithImage.push(obj);
              })
            );
            result['units'] = hitsWithImage
          }

          if (type === 'topics') {
            await Promise.all(
              hits.map(async (topic) => {
                var obj = topic;
                // Defined in unitview.js
                var src = await this.getTopicImageURL(topic.objectID + '.png');
                obj.src = src;
                hitsWithImage.push(obj);
              })
            );
            result['topics'] = hitsWithImage
          }
        }
      })
    );
    return result;
  } catch (err) {
    // TODO ErrorHandling: Log error to Sentry.
    console.log(err);
    throw err;
  }
}
