import React, { Component } from 'react';
import styled from 'styled-components';
import * as ROUTES from '../../../../constants/routes';
import RsLink from '../../../UI-Library/ButtonsOrLinks/RsLink';
import ColorBullet from '../../../UI-Library/Misc/ColorBullet';

import PropTypes from 'prop-types';

const SongNav = styled.ul`
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
`;

class SubtopicList extends Component {
  render() {
    // sort to enforce display order: elementary -> middle
    var gradesSorted = this.props.grades.sort();

    var subtopicDivsList = gradesSorted.map((grade) => {
      var subtopicToList = this.props.subtopics[grade];

      return subtopicToList.map((item, index) => (
        <div key={'LINK_' + item.getId()}>
          <ColorBullet color={this.props.backgroundColor} />
          <RsLink
            key={'LINK_' + item.getId()}
            to={ROUTES.KARAOKE + '?songId=' + item.getFirstSongId()}
          >
            {item.getName()}
          </RsLink>
        </div>
      ));
    });

    return (
      <div>
        {subtopicDivsList.map((subtopicDiv, index) => {
          return (
            <SongNav
              key={
                this.props.topicId + '_' + this.props.grades[index] + '_SongNav'
              }
            >
              <p
                className='bold'
                style={{
                  marginBottom: '5px',
                  marginTop: '5px',
                  fontSize: '14px',
                }}
              >
                {this.props.grades[index]}
              </p>
              {subtopicDiv}
            </SongNav>
          );
        })}
      </div>
    );
  }
}

SubtopicList.propTypes = {
  /**
   * The identifier for the topic
   */
  topicId: PropTypes.string,
  /**
   * List of subtopics
   */
  subtopics: PropTypes.any,
  /**
   * Grades the topic is suitable for
   */
  grades: PropTypes.array,
  /**
   * The color of the bullet
   */
  backgroundColor: PropTypes.string,
};

export default SubtopicList;
