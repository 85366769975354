import React from 'react';

import PropTypes from 'prop-types';

import 'rc-slider/assets/index.css';
import Slider from '@material-ui/core/Slider';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    color: 'var(--rsTertiary)',
    height: 8,
    width: (props) => props.width,
    marginLeft: (props) => props.ml,
    maxWidth: (props) => (props.maxWidth ? props.maxWidth : null),
  },
  thumb: {
    height: 16,
    width: 16,
    backgroundColor: '#ffffff',
    border: 'none',
    marginTop: -4,
    marginLeft: -12,
    '&:focus, &:hover': {
      boxShadow: 'none',
    },
  },
  track: {
    color: (props) =>
      props.secondary ? 'var(--rsSecondary)' : 'var(--rsBlue)',
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
});

/**
 * The standard slider component.
 */
const RsSlider = (props) => {
  const classes = useStyles(props);
  return (
    <Slider
      onChange={props.onChange}
      aria-label={props.label + ' slider'}
      value={props.percentage}
      classes={classes}
    />
  );
};

RsSlider.propTypes = {
  /**
   * The function called when the slider is moved.
   */
  onChange: PropTypes.func.isRequired,
  /**
   * The aria label. For instance, when the component is supposed to be used
   * for the volume slider, the label should be 'volume'.
   */
  label: PropTypes.string.isRequired,
  /**
   * The progress of the slider. A number between 0-100.
   */
  percentage: PropTypes.number.isRequired,
  /**
   * The width of the slider. Defaults to 100% of parent.
   */
  width: PropTypes.string,
  /**
   * The left margin.
   */
  ml: PropTypes.string,
  /**
   * The maximum width. Optional.
   */
  maxWidth: PropTypes.string,
  /**
   * Is the color secondary?
   */
  secondary: PropTypes.bool,
};

export default RsSlider;
