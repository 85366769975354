import React, { Component } from 'react';
import * as ROUTES from '../../../../../constants/routes';
import styled from 'styled-components';
import BoxPreview from '../../../../UI-Library/ImageContainers/BoxPreview';

const UnitImg = styled.img`
  height: 180px;
  margin-right: 20px;
`;
UnitImg.displayName = 'UnitImg';

const UnitImgRow = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  flex-wrap: wrap;

  ${({ nowrap }) =>
    nowrap &&
    `
    flex-wrap: nowrap;
    overflow-x: hidden;
    scroll-behavior: smooth;
`}
`;
UnitImgRow.displayName = 'UnitImgRow';

const SubjectHeader = styled.div`
  margin-top: 30px;
  font-weight: bold;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;
SubjectHeader.displayName = 'SubjectHeader';

/**
 * type : string
 * query : string
 * subdata : Array
 */
class BrowseSearchResult extends Component {
  render() {
    if (!this.props.subdata) return null;
    var links = {
      Song: ROUTES.KARAOKE + '?songId=',
      Unit: ROUTES.UNITVIEW + '/',
      // ! Does not consider the '#' (scroll to property)
      Topic: ROUTES.UNITVIEW + '/',
    };

    var headerText =
      this.props.type + 's related to "' + this.props.query + '"';

    return (
      <div key={'SEARCHRESULT_' + this.props.type}>
        <SubjectHeader>{headerText}</SubjectHeader>
        <UnitImgRow nowrap={false}>
          {this.props.subdata.map((item, index) => {
            const linkId = item.unitId ? item.unitId : item.objectID;
            const subtitle =
              this.props.type === 'Unit'
                ? item.subject
                : this.props.type === 'Topic'
                ? item.unit
                : item.origTitle;
            return (
              <BoxPreview
                to={links[this.props.type] + linkId}
                key={'SEARCHRESULT_' + this.props.type + '_' + item.objectID}
                image={item.src}
                title={item.title}
                subtitle={subtitle}
              />
            );
          })}
        </UnitImgRow>
      </div>
    );
  }
}

export default BrowseSearchResult;
