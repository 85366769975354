/**
 * Signs out user with Firebase authentication.
 */
export async function doSignOut() {
  await this.auth.signOut();
}

/**
 * Returns a Promise that resolves when User is logged in successfully
 * with proper authentication and permissions. Return false when User does not have
 * necessary permissions.
 *
 * If they are an existing user, they MUST have custom claims.
 *
 * EDGE CASE: NEW USER, NOT VERIFIED. NEED TO LOG HIM/HER OUT.
 *
 */

// Google Sign in
export async function doSignInGoogle() {
  return new Promise((resolve, reject) => {
    var provider = new this.firebase.auth.GoogleAuthProvider();
    this.auth
      .signInWithRedirect(provider)
      .then(() => {
        resolve();
      })
      .catch((err) => {
        console.log(err);
        reject();
      });
  });
}

// call handleSignIn
// modal popup -
export async function doSignInEmail(email, password) {
  await this.auth.signInWithEmailAndPassword(email, password);
}

export async function createUserWithEmail(email, password) {
  await this.auth.createUserWithEmailAndPassword(email, password);
  //returns UserCredential obj
  const currentUser = this.auth.currentUser;
  // currentUser is a User obj
  const result = currentUser.sendEmailVerification({
    url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
  });
  return result;
}

export async function resendVerification() {
  const currentUser = this.auth.currentUser;
  await currentUser.sendEmailVerification({
    url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
  });
  return;
}

export async function authStateChangedListener(authUser) {
  const result = {
    authUser: authUser,
    needEmailVerification: false,
    verified: false,
    userToken: null,
  };

  // User: https://firebase.google.com/docs/reference/js/firebase.User
  if (!authUser) return result;

  const userToken = await this.auth.currentUser.getIdTokenResult(true);
  result.userToken = userToken;

  if (
    authUser.providerData[0].providerId === 'password' &&
    !authUser.emailVerified
  ) {
    result.needEmailVerification = true;
    return result;
  }

  if (userToken.claims.customer) {
    // BASE CASE: IS EXISTING USER, CUSTOMER
    result.verified = true;
    return result;
  }

  return result;
}

export async function emailSubscriberCheck() {
  const verifyEmailSubscription = this.functions.httpsCallable(
    'auth-verifyEmailSubscription'
  );
  const isEmailSubscribed = await verifyEmailSubscription();
  if (isEmailSubscribed.data) {
    const userToken = await this.auth.currentUser.getIdTokenResult(true);
    return {
      verified: true,
      userToken: userToken,
    };
  }
  return {
    verified: false,
    userToken: null,
  };
}

export async function codeSubscriberCheck(code) {
  const verifyCodeSubscription = this.functions.httpsCallable(
    'auth-verifyCodeSubscription'
  );
  const isCodeSubscribed = await verifyCodeSubscription({ code: code });

  if (isCodeSubscribed.data) {
    const userToken = await this.auth.currentUser.getIdTokenResult(true);
    return {
      verified: true,
      userToken: userToken,
    };
  }
  return {
    verified: false,
    userToken: null,
  };
}

export async function resetPassword(email) {
  try {
    await this.auth.sendPasswordResetEmail(email);
    return true;
  } catch (err) {
    return false;
  }
}

/**
 * Returns a Promise that resolves with an object of the user's custom claims.
 */
export async function getUserToken() {
  if (this.auth.currentUser == null) return null;
  var tokenResult = await this.auth.currentUser.getIdTokenResult(true);
  return tokenResult.claims;
}
