import React from 'react';
import styled from 'styled-components';

const MainWrapper = styled.div`
  background: var(--rsSurface);
  width: 90%;
  padding: 25px;
  border-radius: 25px;
  margin-bottom: 30px;
`;

const Title = styled.h2`
  font-weight: bold;
  margin-bottom: 0px;
  color: ${(props) =>
    props.disabled ? 'var(--rsSecondary)' : 'var(--rsPrimary)'};
  transition: all 0.3s ease 0s;
`;

const Sub = styled.p`
  color: ${(props) =>
    props.disabled ? 'var(--rsTertiary)' : 'var(--rsSecondary)'};
  font-size: 14px;
  margin: 5px 0 5px 0;
  transition: all 0.3s ease 0s;
`;

const Hr = styled.hr`
  border: 1px solid
    ${(props) => (props.disabled ? 'var(--rsTertiary)' : 'var(--rsSecondary)')};
  margin: 5px 0px 5px 0px;
  transition: all 0.3s ease 0s;
`;

export default function BlanksSideDiv({ disabled, title, sub, children }) {
  return (
    <MainWrapper>
      <Title disabled={disabled}>{title}</Title>
      <Sub disabled={disabled}>{sub}</Sub>
      <Hr disabled={disabled} />
      {children}
    </MainWrapper>
  );
}

BlanksSideDiv.propTypes = {};
