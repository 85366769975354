import React, { Component } from 'react';
import styled from 'styled-components';

import RsModal from '../RsModal';
import RsButton from '../../ButtonsOrLinks/RsButton';
import RsInput from '../../Forms/RsInput';

import PropTypes from 'prop-types';

const Context = styled.p`
  font-size: 14px;
`;

/**
 * The modal component for creating a new feature Set. This is a reusable component for all features.
 *
 */
export class CreateSetModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      disabled: true,
    };
    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  handleTitleChange(e) {
    this.setState({ title: e.target.value });
    if (!e.target.value || e.target.value === '' || e.target.value === ' ') {
      this.setState({ disabled: true });
    } else if (this.state.disabled) {
      this.setState({ disabled: false });
    }
  }

  submit() {
    this.props.create(this.state.title);
    this.props.toggle();
  }

  render() {
    return (
      <RsModal
        show={this.props.show}
        title={'Create a new set'}
        toggle={this.props.toggle}
        submit={this.submit}
      >
        <RsModal.Body>
          <Context className='secondary'>
            {this.props.topicName} {' > '} {this.props.songName} {' > '}
            {this.props.featureName}
          </Context>

          <p className='secondary' style={{ marginBottom: 0 }}>
            Title
          </p>
          <RsInput value={this.state.title} onChange={this.handleTitleChange} />
        </RsModal.Body>
        <RsModal.Footer>
          <RsButton onClick={this.submit} disabled={this.state.disabled}>
            Create
          </RsButton>
        </RsModal.Footer>
      </RsModal>
    );
  }
}

CreateSetModal.propTypes = {
  /**
   * The topic name of the associated song.
   */
  topicName: PropTypes.string.isRequired,
  /**
   * The rapStudy song name of the associated song.
   */
  songName: PropTypes.string.isRequired,
  /**
   * The name of the study set's feature.
   */
  featureName: PropTypes.oneOf(['Review', 'Breakdown', 'Blanks']),
  /**
   * The function to toggle the showing of the modal.
   */
  toggle: PropTypes.func.isRequired,
  /**
   * The create function for submission.
   */
  create: PropTypes.func.isRequired,
};

export default CreateSetModal;
