import React, { Component } from 'react';
import styled from 'styled-components';
import DropdownMenu from '../../../../UI-Library/ButtonsOrLinks/DropdownMenu';
import TextToggler from '../../../../UI-Library/ButtonsOrLinks/TextToggler';
import GradeCollapse from '../../../../UI-Library/ItemDisplays/GradeCollapse';
import ReviewSubmissionTable from '../../../../UI-Library/Tables/ReviewSubmissionTable';
import { withRouter } from 'react-router-dom';
import BlanksSubmissionTable from '../../../../UI-Library/Tables/BlanksSubmissionTable';

const MainWrapper = styled.div`
  padding: 5%;
  padding-top: 2%;
  padding-bottom: 2%;
`;

class AssignmentViewTeacherIndiv extends Component {
  render() {
    const gradesSummary = this.props.gradesSummary;
    if (gradesSummary.isEmpty()) {
      return (
        <MainWrapper>
          <h2>Individual Grades</h2>No students!
        </MainWrapper>
      );
    }
    const route = `/internal/classView/${this.props.classId}/assignments/${this.props.assignmentId}/teacher/individual/`;
    const indivSummaries = gradesSummary.getSortedSummaries();
    const params = this.props.match.params;
    let studentId = params.studentId;
    if (!studentId || studentId === 'null') {
      studentId = indivSummaries[0].getStudentId();
      this.props.history.replace(route + studentId);
    }
    const studentChoices = indivSummaries.map((indiv) => {
      return {
        requiredRole: 'Both',
        desc: indiv.getStudentName(),
        toggleMenuItemAction: () =>
          this.props.history.replace(route + indiv.getStudentId()),
      };
    });

    const currentSummary = gradesSummary.getIndivGrades(studentId);
    const exIds = currentSummary.getExerciseIds();
    return (
      <MainWrapper>
        <h2>Individual Grades</h2>
        <DropdownMenu
          menuItems={studentChoices}
          direction='center'
          isTeacher={true}
        >
          <TextToggler clickable={!gradesSummary.isEmpty()}>
            <span style={{ color: 'var(--rsPrimary)', fontWeight: 'bold' }}>
              {currentSummary.getStudentName()}
            </span>
            <img
              src='/images/icons/utility/caretgrey.png'
              style={{ marginLeft: '5px' }}
              alt=''
            />
          </TextToggler>
        </DropdownMenu>
        <div>
          {exIds.map((exId) => {
            const sub = currentSummary.getSubmissionById(exId);
            const songData = sub.getSongData();
            return (
              <div
                key={'COLLAPSEDIV_' + studentId + '_' + exId}
                style={{ marginTop: '20px' }}
              >
                <GradeCollapse
                  mode={sub.getMode()}
                  score={sub.getScore()}
                  total={sub.getLength()}
                  status={sub.getStatus()}
                  src={songData.src}
                  unitName={songData.unit.name}
                  topicName={songData.topic.name}
                  subTopicName={songData.subtopic.name}
                  featureName={sub.getFeatureName()}
                  setName={sub.getName()}
                  onToggle={() => null}
                >
                  {sub.getFeatureName() === 'Blanks' ? (
                    <BlanksSubmissionTable submissionObj={sub} />
                  ) : (
                    <ReviewSubmissionTable submissionObj={sub} />
                  )}
                </GradeCollapse>
              </div>
            );
          })}
        </div>
      </MainWrapper>
    );
  }
}

export default withRouter(AssignmentViewTeacherIndiv);
