import React from 'react';
import styled from 'styled-components';
import { device } from '../../../../constants/breakpoints';

const MainWrapper = styled.div`
  background: white;
`;

const Jumbo1 = styled.div`
  background: linear-gradient(180deg, #4831d4 0%, rgba(255, 255, 255, 0) 100%),
    #37dbf6;
  min-height: 700px;
  height: calc(100vh - 80px);
  @media ${device.tablet} {
    height: calc(60vh);
    min-height: 600px;
  }

  padding-left: 10%;
  padding-right: 2%;

  padding-top: max(80px, 12%);
`;

const Header = styled.h1`
  font-size: 48px;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  @media ${device.tablet} {
    font-size: 28px;
  }
  @media ${device.mobileL} {
    font-size: 24px;
  }
`;

const VideoWrapper = styled.div`
  display: flex;
  padding-left: 5%;
  padding-right: 5%;
  justify-content: flex-end;
  margin-top: 100px;
  align-items: flex-end;
  // margin-top: -350px;
  @media ${device.laptop} {
    // margin-top: -375px;
  }
  @media ${device.tablet} {
    justify-content: center;
    // margin-top: -270px;
  }
  @media ${device.mobileM} {
    // margin-top: -250px;
    padding-left: 1%;
    padding-right: 1%;
  }
`;

const VideoFrame = styled.div`
  width: 648.16px;
  height: 392.99px;
  background: grey;
  @media ${device.laptop} {
    width: 600px;
    height: 320px;
  }
  @media ${device.tablet} {
    width: 400px;
    height: 250px;
  }
  @media ${device.mobileL} {
    width: 280px;
    height: 180px;
  }
`;

class AboutMain extends React.Component {
  render() {
    return (
      <MainWrapper>
        <Jumbo1>
          <Header>
            We’re transforming the relationship kids have with education through
            the music they know and love.
          </Header>
          <VideoWrapper>
            <VideoFrame>
              <iframe
                height='100%'
                width='100%'
                src='https://www.youtube.com/embed/NruUB7FGpW8'
                frameBorder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
                title='video'
              ></iframe>
            </VideoFrame>
          </VideoWrapper>
        </Jumbo1>
      </MainWrapper>
    );
  }
}

export default AboutMain;
