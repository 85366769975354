import React, { Component } from 'react';
import styled from 'styled-components';
import Loading from '../../../UI-Library/Misc/Loading';
import { withFirebase } from '../../../Firebase';
import { withRouter } from 'react-router-dom';
import QuestionsSetView from './QuestionsSetView';
import KeywordsSetView from './KeywordsSetView';
import Lrc from '../../../../Classes/Lrc';
import AudioBar from '../../../UI-Library/AudioOrLyrics/AudioBar';
import EmptyContent from '../../../UI-Library/Misc/EmptyContent';
import RsButton from '../../../UI-Library/ButtonsOrLinks/RsButton';

const AssignmentSetViewWrapper = styled.div`
  padding: 5%;
  padding-top: 2%;
`;

const MainWrapper = styled.div``;

const ABarWrap = styled.div`
  padding: 10px;
  background: var(--rsSurface);
  border-radius: 15px;
  display: flex;
  align-items: center;
  padding-right: 30px;
  padding-left: 30px;
  margin-bottom: 30px;
`;

const Desc = styled.p`
  margin-bottom: 0;
  margin-right: 10px;

  font-size: 18px;
`;

const SubDesc = styled.p`
  margin-bottom: 0;
  font-size: 14px;
`;

const DescWrapper = styled.div``;

class AssignmentSetView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      play: false,
      ended: false,
      duration: 0,
      audioLoaded: false,
      src: '',
      time: 0,
      speed: 1.0,
      timeTracker: 0,
    };
    this.getSetView = this.getSetView.bind(this);
    this.audio = React.createRef();

    // audio function bindings
    this.play = this.play.bind(this);
    this.pause = this.pause.bind(this);
    this.playOrPause = this.playOrPause.bind(this);
    this.playback = this.playback.bind(this);
    this.setTime = this.setTime.bind(this);
    this.reset = this.reset.bind(this);
    this.toNextIncomplete = this.toNextIncomplete.bind(this);
  }

  getCurrentSubmission() {
    const submissionFilter = this.props.submissions.filter(
      (sub) => sub.getExerciseId() === this.props.activeExerciseId
    );
    if (submissionFilter.length === 0)
      throw Error(
        'Submission not found for the exerciseId ' + this.props.activeExerciseId
      );
    return submissionFilter[0];
  }

  toNextIncomplete() {
    const submissions = this.props.submissions;
    const incompleteFilter = submissions.filter(
      (sub) => sub.getStatus() !== 'complete'
    );
    if (incompleteFilter.length > 0) {
      const nextExId = incompleteFilter[0].getExerciseId();
      this.props.switchExercise(nextExId);
    }
  }

  async fetchData() {
    this.setState({
      audioLoaded: false,
      duration: 0,
      ready: false,
      src: null,
      lrc: null,
      time: 0,
      timeTracker: 0,
    });
    const submission = this.getCurrentSubmission();
    const songId = submission.getSongId();

    const assets = await Promise.all([
      this.props.firebase.getMp3(songId),
      this.props.firebase.getJson(songId),
    ]);

    this.setState(
      {
        ready: true,
        src: assets[0],
        lrc: new Lrc(assets[1]),
      },
      () => {
        if (this.audio.current) {
          this.audio.current.pause();
          this.audio.current.load();

          // ? Below is unnecessary if canplay has full support, but we've had
          // ? audio compatability issues in the past.
          // this.setState({
          //   audioLoaded: true,
          //   duration: this.audio.current.duration,
          // });
        }
      }
    );
  }

  async componentDidMount() {
    this.audio.current.addEventListener('canplay', (event) => {
      this.setState({
        audioLoaded: true,
        duration: this.audio.current.duration,
      });
    });

    this.audio.current.addEventListener('ended', () => {
      clearInterval(this.updateTime);
      this.setState({ play: false, ended: true });
    });
    await this.fetchData();
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.props.activeExerciseId !== prevProps.activeExerciseId) {
      this.pause();
      await this.fetchData();
    }
  }

  componentWillUnmount() {
    this.audio.current.removeEventListener('ended', () =>
      this.setState({ play: false })
    );
    this.audio.current.removeEventListener('canplay');
    clearInterval(this.updateTime);
  }

  /**
   * Plays the audio and updates state.
   */
  play() {
    this.updateTime = setInterval(() => {
      if (!this.audio.current) {
        console.log('audio short circuit');
        clearInterval(this.updateTime);
        this.setState({ time: null, play: false });
        return;
      }
      this.setState({
        time: this.audio.current.currentTime,
        play: true,
        timeTracker: this.state.timeTracker + 75,
      });
    }, 75);
    this.audio.current.play();
  }

  /**
   * Pauses the audio and updates state.
   */
  pause() {
    clearInterval(this.updateTime);
    this.setState({ play: false });
    this.audio.current.pause();
  }

  /**
   * Play and pause audio toggle.
   */
  playOrPause() {
    if (this.state.play) {
      this.pause();
    } else {
      this.play();
    }
  }

  playback(speed) {
    this.audio.current.playbackRate = speed;
  }

  setTime(time) {
    this.audio.current.currentTime = time;
    this.setState({ time: this.audio.current.currentTime });
  }

  reset() {
    this.audio.current.currentTime = 0;
    this.pause();
    this.setState({
      ended: false,
      time: this.audio.current.currentTime,
    });
  }

  getSetView() {
    const submission = this.getCurrentSubmission();

    if (submission.getFeatureName() === 'Blanks') {
      return (
        <KeywordsSetView
          blanksSubmission={submission}
          lrc={this.state.lrc}
          currentTime={this.state.time}
          play={this.state.play}
          pause={this.pause}
          playOrPause={this.playOrPause}
          saveSubmissionAndRerender={this.props.saveSubmissionAndRerender}
          submitExercise={this.props.submitExercise}
        />
      );
    } else if (submission.getFeatureName() === 'Review') {
      return (
        <QuestionsSetView
          reviewSubmission={submission}
          currentTime={this.state.time}
          setTime={this.setTime}
          play={this.play}
          duration={this.state.duration}
          pause={this.pause}
          playing={this.state.play}
          saveSubmissionAndRerender={this.props.saveSubmissionAndRerender}
          submitExercise={this.props.submitExercise}
          lrc={this.state.lrc}
        />
      );
    }
  }

  render() {
    const submission = this.getCurrentSubmission();
    const songObj = submission.getSongData();
    const featureName = submission.getFeatureName();
    const currentWord =
      this.state.lrc && this.state.time
        ? this.state.lrc.getCurrentWord(this.state.time)
        : null;

    // disable audio bar playing if the current word corresponds to a keyword,
    // and the mode is not standard mode.
    const disabledPlay =
      featureName === 'Blanks' &&
      submission.getMode() !== 'standard' &&
      currentWord &&
      submission.isKeyword(currentWord);
    return (
      <MainWrapper>
        <audio src={this.state.src} ref={this.audio} />

        {this.state.ready ? (
          submission.getStatus() === 'complete' ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '40px',
                marginBottom: '40px',
              }}
            >
              <EmptyContent
                lead='Congratulations!'
                src='/images/animationAssets/boysLaptop.png'
              >
                <div className='text-center'>
                  You just completed "{submission.getName()}" for "
                  {this.props.assignmentData.name}". Your grade was saved and
                  sent to your teacher.
                  <br />
                  <RsButton
                    mt={'30px'}
                    large={true}
                    onClick={this.toNextIncomplete}
                  >
                    Next Exercise
                  </RsButton>
                </div>
              </EmptyContent>
            </div>
          ) : (
            <AssignmentSetViewWrapper>
              <ABarWrap>
                <div style={{ minWidth: '30%', marginRight: '20px' }}>
                  <DescWrapper>
                    <Desc className=' bold'>{songObj.subtopic.name}</Desc>
                    <SubDesc className='secondary bold'>
                      based on "{songObj.origTitle}" by {songObj.origArtist[0]}
                    </SubDesc>
                  </DescWrapper>
                </div>
                <AudioBar
                  audioLoaded={this.state.audioLoaded}
                  currentTime={this.state.time}
                  duration={this.state.duration}
                  play={this.state.play}
                  playOrPause={disabledPlay ? () => null : this.playOrPause}
                  showProgress={true}
                  handleProgressChange={() => null}
                />
              </ABarWrap>
              <p style={{ margin: '0 0 5px 0' }} className='secondary small'>
                {featureName}:{' '}
                {featureName === 'Blanks'
                  ? 'Keywords are hidden. Click play to begin.'
                  : 'Click a choice underneath the question.'}
              </p>

              {this.getSetView()}
            </AssignmentSetViewWrapper>
          )
        ) : (
          <Loading />
        )}
      </MainWrapper>
    );
  }
}

export default withRouter(withFirebase(AssignmentSetView));
