import React, { Component } from 'react';
import * as ROUTES from '../../../../constants/routes';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import AlbumCoverCarouselController from '../../../UI-Library/ImageContainers/AlbumCoverCarouselController';

import RsButton from '../../../UI-Library/ButtonsOrLinks/RsButton';

const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Lead = styled.p`
  font-size: 24px;
`;

/**
 * InProgress is the "Continue Learning" section on the Home page.
 *
 * This will display multiple BoxPreviews filled in with Topics that the
 * user is currently working on from Firebase.
 *
 */
class InProgress extends Component {
  renderEmpty() {
    return (
      <EmptyWrapper>
        <Lead className='secondary'>
          We’re happy to have you on board! Find a topic that you want to learn
          and start jamming.
        </Lead>
        <Link to={ROUTES.BROWSE_SUBJECTS}>
          <RsButton>Browse</RsButton>
        </Link>
      </EmptyWrapper>
    );
  }

  render() {
    if (!this.props.recentExists) {
      return null;
      // return this.renderEmpty();
    }

    return (
      <AlbumCoverCarouselController
        header={'Your Recently Played'}
        items={this.props.recents}
        itemType={'song'}
        rows={1}
      />
    );
  }
}
export default InProgress;
