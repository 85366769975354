import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import BannerNavLink from '../../ButtonsOrLinks/BannerNavLink';

const NavWrapper = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 1050px) {
    font-size: 14px;
  }
`;

/**
 * A horizontal navigation bar for use in banners. Renders a list of objects,
 * with options for a icon src, route, and text. Only the text is
 * mandatory. When the screen size is less than 1050px, the font size decreases
 * to 14px.
 */
class BannerNav extends Component {
  render() {
    return (
      <NavWrapper>
        {this.props.items.map((ele, index) => (
          <BannerNavLink
            key={'BANNERNAV_' + index}
            replace={this.props.replace}
            to={ele.to}
            icon={ele.icon}
            name={ele.name}
          />
        ))}
      </NavWrapper>
    );
  }
}

BannerNav.propTypes = {
  /**
   * The items that go in the banner.
   * `icon` is the icon component.
   * `to` is the linked route.
   * `name` is the displayed text.
   */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      to: PropTypes.string,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  /**
   * Do the links replace the items in the history stack?
   */
  replace: PropTypes.bool,
};

export default BannerNav;
