import React, { Component } from 'react';
import { withFirebase } from '../../../../Firebase';
import { withRouter } from 'react-router-dom';

import RsButton from '../../../ButtonsOrLinks/RsButton';
import RsInput from '../../../Forms/RsInput';
import RsModal from '../../RsModal';

class RegisterClassModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      classTitle: '',
      grade: '',
      disabled: true,
      errorMsg: null,
      loading: false,
      subject: '',
      description: '',
    };

    this.handleClassTitleChange = this.handleClassTitleChange.bind(this);
    this.handleModalShowHide = this.handleModalShowHide.bind(this);
    this.createClass = this.createClass.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
  }

  handleClassTitleChange(e) {
    this.setState({ classTitle: e.target.value });

    if (!e.target.value || e.target.value.trim().length === 0) {
      this.setState({ disabled: true });
    } else {
      this.setState({ disabled: false });
    }
  }

  handleDescriptionChange(e) {
    this.setState({ description: e.target.value });
  }

  handleModalShowHide() {
    this.props.toggle();
    this.setState({
      classTitle: '',
      description: '',
      grade: '',
      subject: '',
      errorMsg: null,
      disabled: true,
      loading: false,
    });
  }

  async createClass() {
    try {
      this.setState({ loading: true, disabled: true });
      const createdClassObj = await this.props.firebase.createClass(
        this.state.classTitle,
        this.state.grade,
        this.state.subject,
        this.state.description
      );
      if (this.props.returnClass) {
        this.props.returnClass({
          admin: true,
          classId: createdClassObj.classId,
          description: this.state.description,
          grade: this.state.grade,
          title: this.state.classTitle,
        });
        this.handleModalShowHide();
      } else {
        this.props.history.push(
          '/internal/classView/' + createdClassObj.classId + '/members'
        );
        this.handleModalShowHide();
      }
    } catch (err) {
      console.log(err);
    }

    //Log in event 'class_created' [Firebase Analytics]
    this.props.firebase.analytics.logEvent('class_created');
  }

  render() {
    return (
      <RsModal
        show={this.props.show}
        toggle={this.handleModalShowHide}
        submit={this.createClass}
        title={'Create a Class'}
      >
        <RsModal.Body>
          <p style={{ marginBottom: 0 }}>Class Title</p>
          <RsInput
            value={this.state.classTitle}
            onChange={this.handleClassTitleChange}
            mb={'10px'}
          />

          <p style={{ marginBottom: 0 }}>Description</p>
          <RsInput
            value={this.state.description}
            onChange={this.handleDescriptionChange}
          />
        </RsModal.Body>
        <RsModal.Footer>
          <RsButton disabled={this.state.disabled} onClick={this.createClass}>
            {this.state.loading ? 'Creating...' : 'Submit'}
          </RsButton>
        </RsModal.Footer>
      </RsModal>
    );
  }
}

export default withRouter(withFirebase(RegisterClassModal));
