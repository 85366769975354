import React, { Component } from 'react';
import styled from 'styled-components';
import DefaultImg from '../DefaultImg';
import PropTypes from 'prop-types';
import RsLink from '../../ButtonsOrLinks/RsLink';
import Trash from '../../Icons/Trash';

const BoxTitle = styled.p`
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 160px;
`;

const BoxSubTitle = styled.p`
  width: 180px;
  margin: 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: var(--rsSecondary);
`;

const BoxWrap = styled.div`
  width: 180px;
  margin-bottom: 20px;
  margin-right: ${(props) => (props.mr ? props.mr : '40px')};
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 5px;
`;

/**
 * BoxPreview is a square image with a background, a title, and a description
 * below. A "remove" function can be passed as a prop to allow for removing the
 * item from the parent component.
 *
 * Uses: DefaultImg
 */
class BoxPreview extends Component {
  render() {
    return (
      <BoxWrap mr={this.props.mr}>
        <DefaultImg
          color={this.props.bgColor}
          type={this.props.type}
          isClickable={this.props.to ? true : false}
          src={this.props.image}
          to={this.props.to}
        />
        <TitleWrapper>
          {this.props.to ? (
            <RsLink to={this.props.to} small={true} bold={true}>
              <BoxTitle>{this.props.title}</BoxTitle>
            </RsLink>
          ) : (
            <BoxTitle>{this.props.title}</BoxTitle>
          )}

          {this.props.remove && (
            <Trash
              size='18px'
              onClick={() => this.props.remove(this.props.id)}
            />
          )}
        </TitleWrapper>
        <BoxSubTitle className='pt-0'>{this.props.subtitle}</BoxSubTitle>
      </BoxWrap>
    );
  }
}

BoxPreview.propTypes = {
  /**
   * The optional source for the image to be used. The image will be
   * resized to be contained in the square.
   */
  image: PropTypes.string,
  /**
   * The route to redirect to upon clicking.
   */
  to: PropTypes.string,
  /**
   * The main title underneath the image.
   */
  title: PropTypes.string,
  /**
   * The subtitle.
   */
  subtitle: PropTypes.string,
  /**
   * The type of the item being passed. This is only necessary if the element
   * is either a playlist or class, as these are the only elements with default
   * icons to be passed into the DefaultImg child component.
   */
  type: PropTypes.string,
  /**
   * The background color for the image if the [image] prop is not defined.
   */
  bgColor: PropTypes.string,
  /**
   * The identifier for the passed element. Ex: the song id, or the playlist id.
   */
  id: PropTypes.string,
  /**
   * The function that removes the element. It must take a single parameter,
   * which is the the id of the element [id].
   */
  remove: PropTypes.func,
  /**
   * An optional override for the right margin of the component. Defaults to
   * 40px.
   */
  mr: PropTypes.string,
};

export default BoxPreview;
