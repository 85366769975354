import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import * as ROUTES from '../../../../constants/routes';
import styled from 'styled-components';
import House from '../../../UI-Library/Icons/House';
import Magnifying from '../../../UI-Library/Icons/Magnifying';
import Wrench from '../../../UI-Library/Icons/Wrench';
import Group from '../../../UI-Library/Icons/Group';
import NotificationsPending from '../../../UI-Library/ButtonsOrLinks/NotificationsPending';

const MainIcon = styled.div`
  vertical-align: middle;
  margin-right: 13px;
  margin-left: 20px;
`;

const LinkStyle = {
  verticalAlign: 'middle',
  fontWeight: 'bold',
  color: 'var(--rsSecondary)',
  textDecoration: 'none',
  display: 'flex',
  alignItems: 'center',
};

const IconLinkWrapper = styled.li`
  margin-bottom: 10px;
  margin-left: -20px;
  padding-left: 0;
`;

const UlWrapper = styled.ul`
  display: block;
  margin-top: 20px;
`;

const ActiveLinkStyle = {
  borderLeft: '5px solid var(--rsMajor)',
};

/**
 * Main is the component for the "Main" <ul> in VerticalNav. It contains
 * the links for "Home", "Browse", "Search", and "Your Liked Songs".
 */
class Main extends Component {
  render() {
    const notifications = this.props.assignmentsFollowed
      ? this.props.assignmentsFollowed.filter(
          (assignmentFollow) =>
            assignmentFollow.releaseDate.toDate() < new Date() &&
            assignmentFollow.dueDate.toDate() > new Date() &&
            !assignmentFollow.complete
        ).length
      : 0;
    return (
      <UlWrapper className='nav flex-column'>
        <MainLink to={ROUTES.DASHBOARD} wrapped={House} text={'Home'} />
        <MainLink
          to={ROUTES.BROWSE_SUBJECTS}
          wrapped={Magnifying}
          text={'Browse'}
        />
        <MainLink
          to={ROUTES.CONTENTMANAGER}
          wrapped={Wrench}
          text={'Your Study Sets'}
        />
        <MainLink
          to={ROUTES.CLASSES}
          wrapped={Group}
          text={'Your Classes'}
          notifications={notifications}
        />
      </UlWrapper>
    );
  }
}

class MainLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    };
    this.handleEnter = this.handleEnter.bind(this);
    this.handleLeave = this.handleLeave.bind(this);
  }

  handleEnter() {
    this.setState({ hover: true });
  }

  handleLeave() {
    this.setState({ hover: false });
  }

  render() {
    const Wrapped = this.props.wrapped;
    return (
      <IconLinkWrapper
        className='nav-item'
        onMouseEnter={this.handleEnter}
        onMouseLeave={this.handleLeave}
      >
        <NavLink
          to={this.props.to}
          style={LinkStyle}
          activeStyle={ActiveLinkStyle}
          className='vertNav-link'
        >
          <MainIcon>
            <Wrapped
              size='18px'
              hover={this.state.hover}
              onClick={() => null}
            />
          </MainIcon>
          {this.props.text}
          <span style={{ marginLeft: '10px' }}>
            {this.props.notifications > 0 && (
              <NotificationsPending number={this.props.notifications} />
            )}
          </span>
        </NavLink>
      </IconLinkWrapper>
    );
  }
}

export default Main;
