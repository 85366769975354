import React, { Component } from 'react';
import styled from 'styled-components';
import { withFirebase } from '../../../Firebase';
import { withAlert } from 'react-alert';

import * as ROUTES from '../../../../constants/routes';

import Lrc from '../../../../Classes/Lrc.js';

import Loading from '../../../UI-Library/Misc/Loading';

import SelectionHeader from '../../../UI-Library/Features/SelectionHeader';
import CreateSetModal from '../../../UI-Library/Modals/CreateSetModal';

import {
  selectSet,
  createSet,
  deleteSet,
  findSetInArray,
} from '../setFunctions.js';

import ReviewView from '../../../UI-Library/Features/ReviewView';
import ReviewEdit from './ReviewEdit';
import ExerciseCompletion from '../../../UI-Library/Modals/ExerciseCompletionModal';

import NoSet from '../NoSet';
import QuestionList from '../../../../Classes/QuestionList';
import FeatureStart from '../../../UI-Library/Features/FeatureStart';

/**
 * Review should control the review exercise for any given song
 * Implement according to design on figma
 * Questions should be pulled from firebase
 * Should work for any given songId (which can be hard-coded to test for now)
 * Should use questionNum in state to update question/answer-choices accordingly
 */

const MainWrapper = styled.div`
  position: relative;
  padding-left: 5%;
  padding-right: 5%;
  margin-top: 30px;
  padding-bottom: 50px;
  width: 100%;
  ${({ fullscreen }) =>
    fullscreen &&
    `
    padding-bottom: 20px;
    height: calc(100% - 10vh - 80px);
    margin-top: 5%;

    display: flex;
      justify-content: center;
      align-items: center;
    `}
`;

export class ReviewBase extends Component {
  constructor(props) {
    super(props);
    this.orgState = {
      songId: this.props.id,
      questionNum: 0,
      qList: new QuestionList(),
      hint: null,
      ready: false,
      error: null,
      edit: false,
      elrc: null,
      userSets: null,
      setId: 'null',
      noSet: false,
      showChooseLyricModal: false,
      activeQuestion: null,
      showChooseImageModal: false,
      showDiscard: false,
      showDelete: false,
      showCreate: false,
      showTutorial: this.props.firstReview,
      isAuthor: false,
      author: null,
      authorName: null,
      hasReset: false,
      furthestIndex: 0,
      mode: null,
      maxAttempts: 0,
    };
    this.props.firebase.analytics.logEvent('page_view');

    this.state = this.orgState;

    this.startTime = 0;
    this.endTime = 0;
    this.hintDone = this.hintDone.bind(this);
    this.nextQuestionHandler = this.nextQuestionHandler.bind(this);
    this.previousQuestionHandler = this.previousQuestionHandler.bind(this);
    this.questionChoice = this.questionChoice.bind(this);
    this.clickedHint = this.clickedHint.bind(this);
    this.refresh = this.refresh.bind(this);
    // edit mode functions
    this.reorderQuestions = this.reorderQuestions.bind(this);
    this.makeChoiceTrue = this.makeChoiceTrue.bind(this);
    this.handleChoiceChange = this.handleChoiceChange.bind(this);
    this.handleQuestionChange = this.handleQuestionChange.bind(this);
    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.createSet = createSet(
      ROUTES.REVIEW,
      this.props.firebase.createCustomQuestions.bind(this.props.firebase)
    ).bind(this);
    this.selectSet = selectSet(ROUTES.REVIEW).bind(this);
    this.deleteSet = deleteSet(
      ROUTES.REVIEW,
      this.props.firebase.deleteCustomQuestions.bind(this.props.firebase)
    ).bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.createQuestion = this.createQuestion.bind(this);
    this.deleteQuestion = this.deleteQuestion.bind(this);
    this.selectImage = this.selectImage.bind(this);
    this.firebaseQImage = this.firebaseQImage.bind(this);
    this.setStartEnd = this.setStartEnd.bind(this);
    this.toggleChooseLyricModal = this.toggleChooseLyricModal.bind(this);
    this.toggleChooseImageModal = this.toggleChooseImageModal.bind(this);
    this.deleteModalToggle = this.deleteModalToggle.bind(this);
    this.discardModalToggle = this.discardModalToggle.bind(this);
    this.removeImage = this.removeImage.bind(this);
    this.toggleShowTutorial = this.toggleShowTutorial.bind(this);
    this.customContentModalToggle = this.customContentModalToggle.bind(this);
    this.start = this.start.bind(this);
  }

  async getResources(setId) {
    try {
      this.setState({ ready: false });
      let qList = await this.props.firebase.getQuestionList(setId);
      const sets = await this.props.firebase.getQuestionSetsForSong(
        this.props.id
      );

      this.setState({ userSets: sets });
      if (sets.length === 0 && qList === null) {
        this.props.history.replace(ROUTES.REVIEW + '?songId=' + this.props.id);
        // show the create button
        this.setState({
          noSet: true,
          ready: true,
        });
        return;
      } else if (setId === 'null') {
        // get first set from keywordSet
        setId = sets[0].setId;
        this.props.history.replace(
          ROUTES.REVIEW + '?songId=' + this.props.id + '&setId=' + setId
        );
        qList = await this.props.firebase.getQuestionList(setId);
      }

      this.props.reset();
      this.setState(
        {
          setId: setId,
          userSets: sets,
          edit: false,
          qList: qList,
          elrc: new Lrc(this.props.json, null),
          ready: true,
          author: qList.getAuthorId(),
          authorName: qList.getAuthorName(),
          isAuthor:
            qList.getAuthorId() === this.props.firebase.auth.currentUser.uid,
        },
        () => {
          this.props.updateCurrSetId(setId, 'Review');
        }
      );
    } catch (err) {
      this.setState({ error: err });
    }
  }

  async componentDidMount() {
    try {
      this.props.updateCurrSetId(null, 'Review');
      // get setId
      const urlParams = new URLSearchParams(this.props.location.search);
      const name = String(urlParams.get('name'));
      if (!(name === '' || name === 'null')) {
        await this.createSet(name, '');
        return;
      }
      let setId = urlParams.get('setId');
      setId = String(setId);
      await this.getResources(setId);
    } catch (err) {
      this.setState({ error: err });
    }
  }

  componentDidUpdate() {
    if (this.state.hint) {
      if (this.props.playing) {
        if (this.props.currentTime >= this.endTime) {
          this.hintDone();
        }
      }
    }
  }

  componentWillUnmount() {
    if (this.state.noSet || this.state.hasReset) return;
    this.logAnalytics();
  }

  logAnalytics() {
    // [Analytics] Log blanks activity
    const length = this.state.qList.getLength();
    const completed = this.state.furthestIndex;
    this.props.firebase.analytics.logEvent('review', {
      songId: this.props.id,
      length: length,
      completed: completed,
    });
  }

  // TODO Review: Combine with previous question handler to make one f`un`ction
  nextQuestionHandler() {
    try {
      this.props.pause();
      if (this.state.questionNum + 1 > this.state.qList.getLength()) {
        return;
      } else if (!this.state.qList.isEmpty()) {
        if (this.state.questionNum + 1 > this.state.furthestIndex) {
          this.setState({ furthestIndex: this.state.furthestIndex + 1 });
        }
        this.setState({
          hint: false,
          questionNum: this.state.questionNum + 1,
        });
      }
    } catch (err) {
      this.setState({ error: err });
    }
  }

  // TODO Review: See TODO for nextQuestionHandler() above
  previousQuestionHandler() {
    try {
      this.props.pause();
      if (this.state.questionNum === 0) {
        return;
      } else if (!this.state.qList.isEmpty()) {
        this.setState({
          hint: false,
          questionNum: this.state.questionNum - 1,
        });
      }
    } catch (err) {
      this.setState({ error: err });
    }
  }

  getStartAndEndTime() {
    const currentQuest = this.state.qList.index(this.state.questionNum);
    const begLine = currentQuest.getLineStart() + 1;
    const endLine = currentQuest.getLineEnd() + 1;
    const times = this.state.elrc.getTimeLine(begLine, endLine);
    this.startTime = times[0];
    this.endTime = times[1];
    return this.startTime;
  }

  toggleChooseLyricModal(index) {
    this.setState({
      showChooseLyricModal: !this.state.showChooseLyricModal,
      activeQuestion: index,
    });
  }

  toggleChooseImageModal(index) {
    this.setState({
      showChooseImageModal: !this.state.showChooseImageModal,
      activeQuestion: index,
    });
  }

  deleteModalToggle() {
    this.setState({ showDelete: !this.state.showDelete });
  }

  discardModalToggle() {
    this.setState({ showDiscard: !this.state.showDiscard });
  }

  customContentModalToggle() {
    this.setState({ showCreate: !this.state.showCreate });
  }

  questionChoice(choice) {
    const qList = this.state.qList;
    qList.setSelectedAtIndex(this.state.questionNum, choice);
    this.setState({ qList: qList });
  }

  clickedHint() {
    this.setState({ hint: true }, () => {
      this.props.setTime(this.getStartAndEndTime());
      this.props.play();
    });
  }

  hintDone() {
    this.props.pause();
    this.endTime = this.props.duration;
  }

  /**
   * Toggles between edit mode and view mode.
   */
  // firebaseImg : File object (before saving)
  // after save -> this.state.questionArr.map( q-> q.firebaseImg: await addToStorage(file object))
  // then, await updatecustomquestions
  async toggleEdit() {
    try {
      // if edit mode, change to view mode, and save the questions to firestore by calling updateCustomQuestions.
      // if view mode, change to edit mode UNLESS the current set is the default rapstudy set.
      if (!this.state.setId || this.state.setId === 'null') return;

      if (!this.state.edit) {
        this.setState({
          edit: true,
          ready: true,
        });
      } else {
        this.setState({ ready: false });
        await this.props.firebase.updateCustomQuestions(
          this.props.id,
          this.state.setId,
          this.state.qList
        );

        const sets = this.state.userSets;
        const toChangeName = findSetInArray(sets, 'setId', this.state.setId);
        toChangeName.name = this.state.qList.getName();

        this.setState({
          userSets: sets,
          hint: null,
          questionNum: 0,
          edit: false,
          ready: true,
        });
      }
      const qList = this.state.qList;
      qList.resetSelected();
      this.setState({
        qList: qList,
      });
    } catch (err) {
      this.setState({ error: err });
    }
  }

  /**
   * Reorders the qList state.
   */
  reorderQuestions(startIndex, endIndex) {
    const res = this.state.qList;
    res.reorder(startIndex, endIndex);
    this.setState({ qList: res });
  }

  /**
   * Creates a new Question object and appends it to the end of the qList state.
   */
  createQuestion() {
    const res = this.state.qList;
    const uniqueId = this.props.firebase.db.collection('any').doc().id; // generating random id
    res.addEmpty(uniqueId);
    this.setState({
      qList: res,
    });
  }

  /**
   * Removes an element from the qList state.
   */
  async deleteQuestion(index) {
    const res = this.state.qList;
    res.deleteQuestion(index);
    this.setState({ qList: res });
  }

  refresh() {
    const qList = this.state.qList;
    qList.resetSelected();
    this.logAnalytics();
    this.setState({
      qList: qList,
      questionNum: 0,
      hint: null,
      edit: false,
      showChooseLyricModal: false,
      activeQuestion: null,
      showChooseImageModal: false,
      showDiscard: false,
      showDelete: false,
      hasReset: true,
      mode: null,
      ready: true,
      started: false,
    });
  }

  /**
   * Makes the choice at the indexed question correct and the other choices
   * incorrect.
   * @param {number} index
   * @param {string} choice
   */
  makeChoiceTrue(index, choice) {
    const res = this.state.qList;
    const q = res.index(index);
    q.setCorrect(choice);
    this.setState({ qList: res });
  }

  selectImage(index, imgLink, uid, name) {
    const res = this.state.qList;
    const q = res.index(index);
    q.setUnsplash(imgLink, uid, name);
    this.setState({ qList: res });
  }

  async firebaseQImage(index, imgFile) {
    const type = imgFile.type.split('/')[1];
    const fileName = new Date().getTime().toString() + '.' + type;
    const url = await this.props.firebase.setCustomQuestionImg(
      imgFile,
      fileName
    );
    const res = this.state.qList;
    const q = res.index(index);
    q.setUpload(url, fileName);
    this.setState({ qList: res });
  }

  handleChoiceChange(index, choice, e) {
    const res = this.state.qList;
    const q = res.index(index);
    q.setChoice(choice, e.target.value);
    this.setState({ qList: res });
  }

  handleQuestionChange(index, e) {
    const res = this.state.qList;
    const q = res.index(index);
    q.setQuestion(e.target.value);
    this.setState({ qList: res });
  }

  handleTitleChange(e) {
    const res = this.state.qList;
    res.setName(e.target.value);
    this.setState({ qList: res });
  }

  async removeImage(index) {
    try {
      const res = this.state.qList;
      const q = res.index(index);
      q.deleteImage();
      this.setState({ qList: res });
    } catch (err) {
      this.setState({ error: err });
    }
  }

  setStartEnd(index, beg, end) {
    const res = this.state.qList;
    const q = res.index(index);
    q.setStartEnd(beg, end);
    this.setState({ qList: res });
  }

  toggleShowTutorial() {
    this.setState({ showTutorial: !this.state.showTutorial });
  }

  // * ================ RENDER METHODS ======================

  renderEditQuestions() {
    return (
      <ReviewEdit
        qList={this.state.qList}
        activeQuestion={this.state.activeQuestion}
        lrc={this.state.elrc}
        setId={this.state.setId}
        showChooseLyricModal={this.state.showChooseLyricModal}
        showChooseImageModal={this.state.showChooseImageModal}
        showDelete={this.state.showDelete}
        showDiscard={this.state.showDiscard}
        reorderQuestions={this.reorderQuestions}
        makeChoiceTrue={this.makeChoiceTrue}
        handleChoiceChange={this.handleChoiceChange}
        handleQuestionChange={this.handleQuestionChange}
        createQuestion={this.createQuestion}
        deleteQuestion={this.deleteQuestion}
        selectImage={this.selectImage}
        removeImage={this.removeImage}
        setStartEnd={this.setStartEnd}
        toggleChooseLyricModal={this.toggleChooseLyricModal}
        toggleChooseImageModal={this.toggleChooseImageModal}
        deleteModalToggle={this.deleteModalToggle}
        discardModalToggle={this.discardModalToggle}
        deleteSet={this.deleteSet}
        selectSet={this.selectSet}
        firebaseQImage={this.firebaseQImage}
      />
    );
  }

  renderNormalView(validQuestions) {
    if (!validQuestions) {
      return null;
    }
    return (
      <ReviewView
        currentTime={this.props.currentTime}
        hint={this.state.hint}
        lrc={this.state.elrc}
        qList={this.state.qList}
        questionNum={this.state.questionNum}
        isEnded={this.state.isEnded}
        isValid={this.state.isValid}
        isFullscreen={this.state.isFullscreen}
        previousQuestion={this.previousQuestionHandler}
        nextQuestion={this.nextQuestionHandler}
        clickedHint={this.clickedHint}
        questionChoice={this.questionChoice}
        fullscreen={this.props.fullscreen}
        maxAttempts={this.state.maxAttempts}
      />
    );
  }

  start(mode) {
    if (!mode) return;
    const attemptDict = {
      easy: 0,
      medium: 2,
      hard: 1,
    };
    this.setState({ mode: mode, maxAttempts: attemptDict[mode] });
  }

  render() {
    if (this.state.error) {
      throw this.state.error;
    }
    if (!this.state.ready) return <Loading />;
    // setup booleans for conditional rendering
    let topicName = null;
    if (this.props.topic) {
      topicName = this.props.topic.name;
    }
    if (!this.state.mode && !this.state.edit)
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '50px',
          }}
        >
          <FeatureStart
            userSets={this.state.userSets}
            selectSet={this.selectSet}
            toggleEdit={this.toggleEdit}
            customContentModalToggle={this.customContentModalToggle}
            title={this.state.qList.getName()}
            isAuthor={this.state.isAuthor}
            author={this.state.author}
            authorName={this.state.authorName}
            length={this.state.qList.getLength()}
            featureName={'Review'}
            start={this.start}
          />
          <CreateSetModal
            show={this.state.showCreate}
            create={this.createSet}
            topicName={topicName}
            songName={this.props.newTitle}
            featureName={'Breakdown'}
            toggle={this.customContentModalToggle}
          />
        </div>
      );

    const validQuestions =
      this.state.qList !== null && !this.state.qList.isEmpty();
    let isEnded = false;

    if (validQuestions) {
      isEnded =
        this.state.questionNum >= this.state.qList.getLength() &&
        !this.state.edit;
    }

    // ! bad practice to have side effects in render.
    if (isEnded && this.props.fullscreen) {
      this.props.clickFullScreen();
    }

    // Case: No sets available
    if (this.state.noSet)
      return (
        <NoSet
          show={this.state.showTutorial}
          toggleShowTutorial={this.toggleShowTutorial}
          firstTime={this.props.firstReview}
          complete={this.props.completeFirstReview}
          createSet={this.createSet}
          topicName={topicName}
          newTitle={this.props.newTitle}
          featureName='Review'
        />
      );

    // Case: Exercise is ended
    if (validQuestions && isEnded) {
      return (
        <ExerciseCompletion
          show={true}
          reset={this.props.reset}
          id={this.props.id}
          unit={this.props.unit}
          refresh={this.refresh}
          exerciseType='review'
        />
      );
    }

    // Case: base case
    return (
      <MainWrapper fullscreen={this.props.fullscreen}>
        <SelectionHeader
          newTitle={this.props.newTitle}
          fullscreen={this.props.fullscreen}
          edit={this.state.edit}
          title={this.state.qList.getName()}
          topicName={topicName}
          userSets={this.state.userSets}
          setId={this.state.setId}
          createSet={this.createSet}
          handleTitleChange={this.handleTitleChange}
          deleteModalToggle={this.deleteModalToggle}
          discardModalToggle={this.discardModalToggle}
          toggleEdit={this.toggleEdit}
          clickFullScreen={this.props.clickFullScreen}
          selectSet={this.selectSet}
          featureName={'Review'}
          isAuthor={this.state.isAuthor}
          author={this.state.author}
          authorName={this.state.authorName}
          customContentModalToggle={this.customContentModalToggle}
          reset={this.refresh}
        />

        <CreateSetModal
          show={this.state.showCreate}
          create={this.createSet}
          topicName={topicName}
          songName={this.props.newTitle}
          featureName={'Review'}
          toggle={this.customContentModalToggle}
        />

        {this.state.edit
          ? this.renderEditQuestions()
          : this.renderNormalView(validQuestions)}
      </MainWrapper>
    );
  }
}

const Review = withAlert()(withFirebase(ReviewBase));
export default Review;
