import React, { Component } from 'react';
import styled from 'styled-components';
import DropdownMenu from '../../../ButtonsOrLinks/DropdownMenu';
import PropTypes from 'prop-types';

const Playback = styled.span`
  font-size: 18px;
  line-height: 32px;
  color: white;
  @media screen and (max-width: 1090px) {
    font-size: 14px;
  }
  margin-left: 10px;
  margin-right: 10px;
`;

/**
 * The dropdown for changing the speed playback. Based on the DropdownMenu
 * component.
 */
class SpeedDropdown extends Component {
  render() {
    const menuItems = [
      {
        desc: 'Normal',
        toggleMenuItemAction: () => this.props.changeValue('Normal', 1),
        requiredRole: 'Both',
      },
      {
        desc: 'Slower',
        toggleMenuItemAction: () => this.props.changeValue('Slower', 0.8),
        requiredRole: 'Both',
      },
      {
        desc: 'Slowest',
        toggleMenuItemAction: () => this.props.changeValue('Slowest', 0.6),
        requiredRole: 'Both',
      },
    ];
    return (
      <DropdownMenu
        isTeacher={true}
        iconSize={'small'}
        menuItems={menuItems}
        direction={'center'}
        width={80}
      >
        <Playback>{this.props.playback}</Playback>
      </DropdownMenu>
    );
  }
}

SpeedDropdown.propTypes = {
  /**
   * The string of the playback speed. Ex. 'Slower', 'Slowest', etc.
   */
  playback: PropTypes.oneOf(['Normal', 'Slower', 'Slowest']),
  /**
   * The function to change the speed of the audio. Must take two parameters,
   * the string of the speed, and the playback number.
   */
  changeValue: PropTypes.func,
};

export default SpeedDropdown;
