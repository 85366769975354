import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import * as ROUTES from '../../constants/routes';

import { withFirebase } from '../Firebase';

import RsButton from '../UI-Library/ButtonsOrLinks/RsButton';
import RsInput from '../UI-Library/Forms/RsInput';

const PageWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 2%;
  padding-right: 2%;
`;

const MainWrapper = styled.div`
  max-width: 600px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  text-align: left;
  width: 100%;
  margin-bottom: 10px;
`;

const ProviderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const InputHeader = styled.p`
  color: var(--rsSecondary);
  text-align: left;
  margin-bottom: 2px;
`;

const InputWrapper = styled.div`
  width: 100%;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
`;

const ErrMsg = styled.div`
  width: 100%;
`;

const Err = styled.p`
  color: red;
  font-size: 14px;
  text-align: center;
  margin-top: 15px;
`;

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
    };
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangePass = this.handleChangePass.bind(this);
    this.signInEmail = this.signInEmail.bind(this);
  }

  handleChangeEmail(e) {
    this.setState({ email: e.target.value });
  }

  handleChangePass(e) {
    this.setState({ password: e.target.value });
  }

  async signInEmail() {
    try {
      this.setState({ signingIn: true });
      await this.props.signInEmail(this.state.email, this.state.password);
      //Log in event 'login' [Firebase Analytics]
      this.props.firebase.analytics.logEvent('login');
    } catch (err) {
      console.log(err);
      this.setState({ errMsg: 'bad request.' });
    }
    this.setState({ signingIn: false });
  }

  render() {
    return (
      <PageWrapper>
        <MainWrapper>
          <Title>
            <h1 className='bold'>Sign In</h1>
          </Title>
          <p
            className='secondary'
            style={{ fontSize: '14px', marginBottom: '20px' }}
          >
            Were you a teacher in our Fall Pilot? With the addition of student
            accounts, you'll have to register your account again. Please use the
            same email as the one you used in the fall!
          </p>
          <ProviderWrapper>
            <RsButton
              large={true}
              mb={'10px'}
              width={'100%'}
              onClick={() => {
                //Log in event 'login' [Firebase Analytics]
                this.props.firebase.analytics.logEvent('login');
                this.props.signInGoogle();
              }}
            >
              Sign in with Google
            </RsButton>

            <p className='bold secondary'>OR</p>
          </ProviderWrapper>
          <ProviderWrapper>
            <InputWrapper>
              <InputHeader>Email</InputHeader>
              <RsInput
                mb={'10px'}
                name='email'
                value={this.state.email}
                onChange={this.handleChangeEmail}
              ></RsInput>
            </InputWrapper>

            <InputWrapper>
              <InputHeader>Password</InputHeader>
              <RsInput
                mb={'10px'}
                name='password'
                value={this.state.password}
                onChange={this.handleChangePass}
              ></RsInput>
            </InputWrapper>

            <ButtonWrapper>
              <Link to={ROUTES.RESETPASSWORD}>
                <RsButton secondary={true} paddingHor={'5px'}>
                  Forgot password?
                </RsButton>
              </Link>
              <RsButton
                disabled={this.state.signingIn}
                onClick={this.signInEmail}
                paddingHor={'5px'}
              >
                {this.state.signingIn ? 'Working...' : 'Sign in'}
              </RsButton>
            </ButtonWrapper>
            <ErrMsg>
              {this.state.errMsg && (
                <Err>
                  Sign in failed. Check your credentials and try again. Are you
                  sure this account exists?
                </Err>
              )}
            </ErrMsg>
          </ProviderWrapper>
        </MainWrapper>
      </PageWrapper>
    );
  }
}

export default withFirebase(SignIn);
