import React, { Component } from 'react';
import TutorialModal from '../../../UI-Library/Modals/TutorialModal';
import CreateSetPrompt from './CreateSetPrompt';

class NoSet extends Component {
  render() {
    return (
      <div>
        <TutorialModal
          show={this.props.show}
          feature={this.props.featureName}
          onHide={this.props.toggleShowTutorial}
          firstTime={this.props.firstTime}
          complete={this.props.complete}
          showFooter={true}
        />
        <CreateSetPrompt
          create={this.props.createSet}
          topicName={this.props.topicName}
          newTitle={this.props.newTitle}
          featureName={this.props.featureName}
        />
      </div>
    );
  }
}

export default NoSet;
