import React, { Component } from 'react';

import PropTypes from 'prop-types';

class DragHandle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    };
    this.handleEnter = this.handleEnter.bind(this);
    this.handleLeave = this.handleLeave.bind(this);
  }

  handleEnter() {
    this.setState({ hover: true });
  }

  handleLeave() {
    this.setState({ hover: false });
  }

  render() {
    const hover =
      typeof this.props.hover !== 'undefined'
        ? this.props.hover
        : this.state.hover;

    const color = this.props.disabled
      ? 'var(--rsDisabled)'
      : hover
      ? 'var(--rsPrimary)'
      : 'var(--rsSecondary)';
    return (
      <div
        onMouseEnter={this.handleEnter}
        onMouseLeave={this.handleLeave}
        onClick={this.props.disabled ? null : this.props.onClick}
        style={{
          cursor: this.props.disabled ? 'default' : 'grab',
          display: 'flex',
          alignItems: 'center',
          width: 'fit-content',
          height: 'fit-content',
        }}
      >
        <svg
          width={
            this.props.size
              ? 'calc(' + this.props.size + ' / 16 * 10)'
              : 'calc( 18px / 16 * 10)'
          } // the svg aspect ratio
          height={this.props.size ? this.props.size : '18px'}
          viewBox='0 0 10 16'
          fill={color}
          xmlns='http://www.w3.org/2000/svg'
        >
          <circle cx='2' cy='2' r='2' />
          <circle cx='2' cy='8' r='2' />
          <circle cx='2' cy='14' r='2' />
          <circle cx='8' cy='2' r='2' />
          <circle cx='8' cy='8' r='2' />
          <circle cx='8' cy='14' r='2' />
        </svg>
      </div>
    );
  }
}

DragHandle.propTypes = {
  /**
   * Should the icon take on disabled styling?
   */
  disabled: PropTypes.bool,
  /**
   * The function fired when the icon is clicked.
   */
  onClick: PropTypes.func,
  /**
   * The size of the icon. Defaults to 24px.
   */
  size: PropTypes.string,
  /**
   * A hover override from the parent component. If defined, the local
   * state hover is ignored.
   */
  hover: PropTypes.bool,
};

export default DragHandle;
