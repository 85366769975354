import React, { Component } from 'react';
import styled from 'styled-components';

import { device } from '../../../../../constants/breakpoints';

const ProfileWrapper = styled.div`
  width: 200px;
  height: 200px;
  background-image: url(${(props) => props.src});
  background-size: contain;
  margin-right: 30px;
  margin-bottom: 30px;
  border-radius: 8px;

  @media ${device.laptop} {
    width: 150px;
    height: 150px;
    margin-right: 25px;
    margin-bottom: 25px;
  }

  @media ${device.tablet} {
    width: 150px;
    height: 150px;
    margin-right: 20px;
    margin-bottom: 20px;
  }

  @media ${device.mobileL} {
    width: 100px;
    height: 100px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
`;

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 8px;
`;

const Name = styled.p`
  font-size: 18px;

  @media ${device.tablet} {
    font-size: 14px;
  }

  @media ${device.mobileL} {
    font-size: 8px;
  }
`;

const Pos = styled.p`
  margin-bottom: 0;

  font-size: 14px;

  @media ${device.tablet} {
    font-size: 12px;
  }

  @media ${device.mobileL} {
    font-size: 6px;
  }
`;

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    };
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
  }

  handleMouseEnter() {
    this.setState({ hover: true });
  }

  handleMouseLeave() {
    this.setState({ hover: false });
  }

  render() {
    return (
      <ProfileWrapper
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        src={this.props.src}
      >
        {this.state.hover && (
          <Overlay>
            <Name style={{ marginBottom: 0 }} className='text-center'>
              {this.props.name}
            </Name>
            <Pos style={{ marginBottom: 0 }} className='text-center'>
              {this.props.pos}
            </Pos>
          </Overlay>
        )}
      </ProfileWrapper>
    );
  }
}

const MainWrapper = styled.div`
  width: 100%;
  max-width: 1500px;
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  margin-top: 40px;
`;

class ProfileGrid extends Component {
  render() {
    return (
      <MainWrapper>
        {this.props.data.map((ele, index) => (
          <Profile
            src={ele.src}
            name={ele.name}
            pos={ele.pos}
            key={ele.name + index}
          />
        ))}
      </MainWrapper>
    );
  }
}

export default ProfileGrid;
