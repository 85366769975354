import React, { Component } from 'react';
import styled from 'styled-components';

import QuestionView from './QuestionView';
import RectangleProgress from '../../Misc/RectangleProgress';
import RsButton from '../../ButtonsOrLinks/RsButton';

import QuestionList from '../../../../Classes/QuestionList';

import PropTypes from 'prop-types';
import Lrc from '../../../../Classes/Lrc';

const AppWrapper = styled.div`
  width: 100%;
  position: relative;
`;

const QuestionBox = styled.div`
  display: flex;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 30px;
`;

/**
 * The main component of Review.
 */
class ReviewView extends Component {
  renderQuestions() {
    return (
      <QuestionBox>
        <QuestionView
          currentTime={this.props.currentTime}
          lrc={this.props.lrc}
          question={this.props.qList.index(this.props.questionNum)}
          questionChoice={this.props.questionChoice}
          hint={this.props.hint}
          clickedHint={this.props.clickedHint}
          qList={this.props.qList}
          questionNum={this.props.questionNum}
          maxAttempts={this.props.maxAttempts}
        />
      </QuestionBox>
    );
  }

  render() {
    if (this.props.qList.isEmpty()) {
      return null;
    }
    const currentQuest = this.props.qList.index(this.props.questionNum);
    const start = this.props.questionNum === 0;
    const chosenExists = currentQuest.isAnswered();
    const last = this.props.questionNum + 1 === this.props.qList.getLength();

    return (
      <AppWrapper>
        <RectangleProgress
          length={this.props.qList.getLength()}
          activeNum={this.props.questionNum}
        />
        <div>{this.renderQuestions()}</div>
        <ButtonWrapper>
          {start ? (
            <div></div>
          ) : (
            <RsButton onClick={this.props.previousQuestion} secondary={true}>
              Previous Question
            </RsButton>
          )}

          {chosenExists ? (
            <RsButton onClick={this.props.nextQuestion}>
              {last ? 'Submit' : 'Next Question'}
            </RsButton>
          ) : (
            <div></div>
          )}
        </ButtonWrapper>
      </AppWrapper>
    );
  }
}

ReviewView.propTypes = {
  /**
   * The QuestionList object that is the main data source of the exercise.
   */
  qList: PropTypes.instanceOf(QuestionList),
  /**
   * The current audio time.
   */
  currentTime: PropTypes.number,
  /**
   * The Lrc object that provides the lyrics for the song.
   */
  lrc: PropTypes.instanceOf(Lrc),
  /**
   * The current question number.
   */
  questionNum: PropTypes.number,
  /**
   * The function fired when a choice is selected. Takes one parameter, the letter
   * of the choice. For instance, "A" or "B".
   */
  questionChoice: PropTypes.func,
  /**
   * Is the hint shown?
   */
  hint: PropTypes.bool,
  /**
   * The function fired when the "Show Hint" button is clicked.
   */
  clickedHint: PropTypes.func,
  /**
   * The function fired when the "Previous" button is clicked.
   */
  previousQuestion: PropTypes.func,
  /**
   * The function fired when the "Next" button is clicked.
   */
  nextQuestion: PropTypes.func,
  /**
   * The maximum number of attempts the user has to answer the question.
   * If `maxAttempts` is 0, there are unlimited attempts.
   */
  maxAttempts: PropTypes.number,
};

export default ReviewView;
