import React, { Component } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

const NavItem = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-right: 10px;
`;

const LinkStyle = {
  color: 'white',
  textDecoration: 'none',
  marginRight: '20px',
  borderBottom: '2px solid transparent',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  marginBottom: 0,
};

const ActiveLinkStyle = {
  borderBottom: '2px solid var(--rsBlue)',
};

const FeatureDiv = styled.div`
  margin-right: 10px;
  marginbottom: 2px;
`;

class BannerNavLink extends Component {
  render() {
    const WrappedIcon = this.props.icon;
    return (
      <NavItem>
        {this.props.icon && (
          <FeatureDiv>
            <WrappedIcon size='18px' hover={true} />
          </FeatureDiv>
        )}

        <NavLink
          to={this.props.to}
          style={LinkStyle}
          activeStyle={ActiveLinkStyle}
          className='songBannerLink'
          replace={this.props.replace}
        >
          {this.props.name}
        </NavLink>
      </NavItem>
    );
  }
}

BannerNavLink.propTypes = {
  /**
   * The icon component used.
   */
  icon: PropTypes.any,
  /**
   * The route to link to.
   */
  to: PropTypes.string.isRequired,
  /**
   * The text displayed in the link.
   */
  name: PropTypes.string.isRequired,
  /**
   * Does the link exhibit replace behavior?
   */
  replace: PropTypes.bool,
};

export default BannerNavLink;
