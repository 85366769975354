import React, { Component } from 'react';
import styled from 'styled-components';
import EmptyContent from '../../../../UI-Library/Misc/EmptyContent';

const MainWrapper = styled.div`
  padding: 5%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

class EmptyStudents extends Component {
  render() {
    return (
      <MainWrapper>
        <EmptyContent
          src='/images/animationAssets/girlsLaptop.png'
          lead='Ask your students to join'
        >
          <p>
            To enter, your students need to click the{' '}
            <span className='major'>Join Class</span> button under the{' '}
            <span className='major'>Your Classes</span> page, then enter the
            unique pin in the top right of the screen. <br />
            <br />
            <span className='bold'>Tips:</span> <br />
            1. To copy the pin, click the icon to the left of it in the top
            banner. <br />
            2. If you're sharing on a projector or on zoom, click the pin to
            open a pop up with instructions for your students.
          </p>
        </EmptyContent>
      </MainWrapper>
    );
  }
}

export default EmptyStudents;
