import React, { Component } from 'react';
import styled from 'styled-components';
import LandingPlayer from '../../../UI-Library/AudioOrLyrics/LandingPlayer';

import { device } from '../../../../constants/breakpoints';

const MainWrapper = styled.div`
  min-height: 100vh;
  background: white;
  display: flex;
  flex-direction: column;
  animation-duration: 2s;
  animation-delay: 4s;
  animation-iteration-count: 2;
  animation-name: bounce-3;
  animation-timing-function: ease;

  @keyframes bounce-3 {
    0% {
      transform: translateY(0);
    }
    30% {
      transform: translateY(-40px);
    }
    50% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(0);
    }
  }
`;

const Jumbo1 = styled.div`
  background: linear-gradient(180deg, #4831d4 0%, rgba(255, 255, 255, 0) 100%),
    #37dbf6;
  padding-top: max(80px, 15%);
  padding-left: 7%;
  padding-right: 10%;
  display: flex;
  height: auto;
  flex-grow: 1;
  @media ${device.mobileL} {
    padding-left: 5%;
    padding-right: 5%;
  }
`;

const Header = styled.h1`
  max-width: 1000px;
  font-size: 48px;
  font-weight: 900;
  margin-top: 50px;
  @media ${device.tablet} {
    margin-top: 60px;
    font-size: 44px;
  }
  @media ${device.mobileL} {
    margin-top: 50px;
    font-size: 40px;
  }
  @media ${device.mobileM} {
    font-size: 30px;
  }
`;

const Subtitle = styled.p`
  font-size: 24px;
  @media ${device.tablet} {
    font-size: 20px;
  }
  @media ${device.mobileM} {
    font-size: 18px;
  }
`;

const Graphic = styled.img`
  max-width: 100%;
  margin-top: auto;

  @media ${device.tablet} {
    max-height: 30vh;
  }
  z-index: 999;
`;

const Seat = styled.div`
  position: absolute;
  width: 100%;
  height: 12vh;
  background: rgba(140, 33, 85, 0.5);
  border-radius: 10px 10px 0px 0px;
  @media ${device.laptop} {
    height: 9vh;
  }
  @media ${device.tablet} {
    height: 6.5vh;
  }
  z-index: 998;
`;

class MainBlock extends Component {
  render() {
    const songArr = [
      {
        title: 'The Electoral College',
        origTitle: '7 rings',
        origArtist: ['Ariana Grande'],
        src:
          'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fd86Demo.mp3?alt=media&token=e47ed545-7386-4dff-a770-b2e93f37858d',
      },
      {
        title: 'Scientific Method',
        origTitle: 'bad guy',
        origArtist: ['Billie Eilish'],
        src:
          'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fd181Demo.mp3?alt=media&token=fa080d1d-9d9e-46c3-9cc2-e3216234e807',
      },
      {
        title: 'COVID Safety',
        origTitle: 'HIGHEST IN THE ROOM',
        origArtist: ['Travis Scott'],
        src:
          'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fd202Demo.mp3?alt=media&token=051f0d86-f34d-4a8b-a8ea-3adb8c62e6f5',
      },
      {
        title: '1s, 2s, and 10s Times Tables',
        origTitle: 'New Rules',
        origArtist: ['Dua Lipa'],
        src:
          'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2Fd136Demo.mp3?alt=media&token=66780d8f-564f-421c-95fc-7520eeb3216e',
      },
    ];
    return (
      <MainWrapper id='MainBlockWrapper'>
        <Jumbo1>
          <div
            className='row'
            style={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }}
          >
            <div className='col-lg-6'>
              <Header>Learn Lyrically</Header>
              <Subtitle>
                Kids everywhere listen to their favorite music. <br />
                Now they can learn through it too.
              </Subtitle>
            </div>
            <div
              className='col-lg-6 text-right'
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'center',
                position: 'relative',
              }}
            >
              <Seat></Seat>
              <Graphic
                src={
                  'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/images%2Flanding%2FThree_Girls_On_Couch%201.png?alt=media&token=9781afca-e00c-420f-8b92-9f035dfba7f3'
                }
              />
            </div>
          </div>
        </Jumbo1>
        <LandingPlayer songArr={songArr} />
      </MainWrapper>
    );
  }
}

export default MainBlock;
