import React from 'react';
import styled from 'styled-components';

import { device } from '../../../../constants/breakpoints';

const MainWrapper = styled.div`
  padding: 7%;
  padding-bottom: 0;
  padding-top: 4%;

  background: white;
`;

const Header = styled.h1`
  max-width: 1000px;
  font-size: 36px;
  font-weight: bold;
  color: black;
  text-align: left;

  @media ${device.tablet} {
    font-size: 30px;
  }
  @media ${device.mobileL} {
    font-size: 24px;
  }
`;

const SubHeader = styled.h1`
  color: black;
  margin-bottom: 70px;
  text-align: left;
  font-weight: 300;
  font-size: 24px;

  @media ${device.tablet} {
    font-size: 18px;
    margin-bottom: 80px;
  }
  @media ${device.mobileL} {
    font-size: 14px;
    margin-bottom: 50px;
  }
`;

const CircleWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1440px;
  margin: auto;
`;

const Circle = styled.div`
  height: 200px;
  width: 200px;
  background: ${(props) => props.gradient};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 50px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);

  @media ${device.mobileM} {
    height: 150px;
    width: 150px;
  }
`;

const Highlight = styled.span`
  font-size: 24px;
  @media ${device.mobileL} {
    font-size: 18px;
  }
`;

const Desc = styled.div`
  font-size: 18px;
  margin-bottom: 0;
  text-align: center;
  @media ${device.mobileL} {
    font-size: 14px;
  }
`;

class AboutRecap extends React.Component {
  render() {
    return (
      <MainWrapper>
        <Header>2020 recap</Header>
        <SubHeader>Milestones of the year</SubHeader>
        <CircleWrapper>
          <Circle
            gradient={
              'linear-gradient(217.1deg, #09BCAB 14.44%, #CEA9FF 85.83%)'
            }
          >
            <Desc>
              <Highlight className='bold'>300+</Highlight> Partner
            </Desc>
            <Desc>Educators</Desc>
          </Circle>
          <Circle
            gradient={
              'linear-gradient(217.1deg, #D354DE 14.44%, #FFC952 85.83%)'
            }
          >
            <Desc>
              <Highlight className='bold'>25</Highlight> Schools
            </Desc>
          </Circle>
          <Circle
            gradient={
              'linear-gradient(217.1deg, #3F42CF 14.44%, #00BAA8 85.83%)'
            }
          >
            <Desc>
              <Highlight className='bold'>200+</Highlight> Songs
            </Desc>
          </Circle>
          <Circle
            gradient={
              'linear-gradient(233.91deg, #FF7A55 15.59%, #FFC952 85.64%)'
            }
          >
            <Desc>
              <Highlight className='bold'>45</Highlight> Team Members
            </Desc>
          </Circle>
        </CircleWrapper>
      </MainWrapper>
    );
  }
}

export default AboutRecap;
