import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import * as ROUTES from '../../../constants/routes';

const ProtectedRoute = ({
  component: Component,
  path,
  authUser,
  verified,
  verificationDone,
  ...rest
}) => (
  <Route
    {...rest}
    path={path}
    render={(props) => {
      if (!verificationDone) {
        return null;
      }
      if (authUser && !verified) {
        return <Redirect to={ROUTES.FREELANDING} />;
      }
      if (!authUser) {
        return <Redirect to={ROUTES.FORBIDDEN} />;
      }
      return <Component {...props} {...rest} />;
    }}
  />
);

export default ProtectedRoute;
