import Keyword from './Keyword.js';

class KeywordDict {
  /**
   * Creates a new KeywordDict.
   * @param {boolean} readOnly
   * @param {number} score
   * @param {number} length
   * @param {string} name
   * @param {string} authorId
   * @param {string} authorName
   * @param {string} songId
   */
  constructor(
    readOnly = false,
    score = 0,
    length = 0,
    name = '',
    authorId = '',
    authorName = '',
    songId = ''
  ) {
    this.keywords = {};
    this.songId = songId;
    this.name = name;
    this.keywordIds = new Set();
    this.authorId = authorId;
    this.authorName = authorName;
    this.length = length;
    // for assignments
    this.score = score;
    this.readyOnly = readOnly;
  }

  /**
   * Returns the number of passed Keywords in the dict.
   * @returns {number}
   */
  resetAll() {
    this.assertWritable();
    if (this.isEmpty()) return;
    this.score = 0;
    for (const numInLrc in this.keywords) {
      const keyword = this.getKeyword(numInLrc);
      keyword.reset();
    }
    return;
  }

  /**
   * Returns the number of passed Keywords in the dict.
   * @returns {number}
   */
  countPassed() {
    let result = 0;
    for (const numInLrc in this.keywords) {
      const intNumInLrc = parseInt(numInLrc); // Need to convert to number.
      if (this.isPassed(intNumInLrc)) {
        result += 1;
      }
    }
    return result;
  }

  /**
   * Raises an exception if the numInLrc is not an integer.
   * @param {number} numInLrc
   */
  numInLrcCheck(numInLrc) {
    if (!numInLrc || !Number.isInteger(numInLrc))
      throw Error('Invalid argument: numInLrc');
  }

  /**
   * Returns true if the keyword dict is empty, false otherwise.
   * @returns {boolean}
   */
  isEmpty() {
    return Object.keys(this.keywords).length === 0;
  }

  /**
   * Returns true if a Keyword exists at that numInLrc index, false otherwise.
   * @param {number} numInLrc
   * @returns {boolean}
   */
  isKeyword(numInLrc) {
    this.numInLrcCheck(numInLrc);
    return this.keywords.hasOwnProperty(String(numInLrc));
  }

  /**
   * Returns true if the Keyword is passed at the `numInLrc`, false otherwise.
   * @param {number} numInLrc
   * @returns {boolean}
   */
  isPassed(numInLrc) {
    this.isKeyword(numInLrc);
    return this.keywords[String(numInLrc)].isPassed();
  }

  /**
   * Returns an array of keyword ids.
   * @returns {Array<string>}
   */
  getIds() {
    return Array.from(this.keywordIds);
  }

  /**
   * Returns true if the keywordId is in the dictionary, false otherwise.
   * @param {string} keywordId
   * @returns {boolean}
   */
  hasId(keywordId) {
    return this.keywordIds.has(keywordId);
  }

  /**
   * Returns the Keyword object associated with the numInLrc.
   * @param {number} numInLrc
   * @returns {Keyword}
   */
  getKeyword(numInLrc) {
    return this.keywords[numInLrc.toString()];
  }

  /**
   * Returns an object, with keys numInLrc as strings, and Keyword objects
   * as values.
   * @returns {any}
   */
  getKeywords() {
    return this.keywords;
  }

  /**
   * Returns an array of Keywords.
   * @returns {Array<Keyword>}
   */
  getKeywordsArray() {
    return Object.values(this.keywords);
  }

  /**
   * Sets the Keyword at `numInLrc` to passed.
   * @param {number} numInLrc
   */
  setPassed(numInLrc) {
    this.assertWritable();
    if (!this.isKeyword(numInLrc))
      throw Error(
        'Invalidated Precondition: cannot set passed for numinlrc that does not exist.'
      );
    this.keywords[String(numInLrc)].setPassed(true);
  }

  /**
   * Makes an attempt an answering the Keyword.
   * @param {number} numInLrc
   * @param {string} str
   */
  makeAttempt(numInLrc, str) {
    this.numInLrcCheck(numInLrc);
    const correct = this.keywords[String(numInLrc)].makeAttempt(str);
    if (correct) this.score++;
    return correct;
  }

  /**
   * Forces the Keyword at the numInLrc to be correct.
   * @param {number} numInLrc
   */
  makeCorrect(numInLrc) {
    this.numInLrcCheck(numInLrc);
    this.keywords[String(numInLrc)].makeCorrect();
  }

  /**
   * Makes the Keyword unpassed at the numInLrc location.
   * @param {number} numInLrc
   */
  setUnPassed(numInLrc) {
    this.assertWritable();
    if (!this.isKeyword(numInLrc))
      throw Error(
        'Invalidated Precondition: cannot set passed for numinlrc that does not exist.'
      );
    this.keywords[String(numInLrc)].setPassed(false);
  }

  /**
   * Sets the name of the set. The set name must contain at least one non
   * whitespace character.
   * @param {string} name
   */
  setName(name) {
    this.assertWritable();
    if (!(typeof name === 'string')) {
      throw Error('Failed precondition: "name" is not a string');
    }
    this.name = name;
  }

  /**
   * Returns the name of the set.
   * @returns {string}
   */
  getName() {
    return this.name;
  }

  /**
   * Returns the author id for the set.
   * @returns {string}
   */
  getAuthorId() {
    return this.authorId;
  }

  /**
   * Sets the author id.
   * @param {string} id
   */
  setAuthorId(id) {
    this.assertWritable();
    if (!id) throw Error('Author id was falsy');
    this.authorId = id;
  }

  /**
   * Returns the author name for the set.
   * @returns {string} name
   */
  getAuthorName() {
    return this.authorName;
  }

  /**
   * Sets the author name for the set.
   * @param {string} name
   */
  setAuthorName(name) {
    this.assertWritable();
    if (!name) throw Error('Author name was falsy');
    this.authorName = name;
  }

  /**
   * Sets the song id for this keyword dict.
   * @param {string} songId
   */
  setSongId(songId) {
    this.assertWritable();
    if (!songId) throw Error('Invalid argument: songId');
    this.songId = songId;
  }

  /**
   * Adds a new keyword to the dictionary.
   * @param {number} numInLrc
   * @param {string} word
   * @param {string} keywordId
   */
  addNewKeyword(numInLrc, word, keywordId) {
    this.assertWritable();
    this.numInLrcCheck(numInLrc);
    if (this.isKeyword(numInLrc))
      throw Error(
        'Precondition Invalidated: Attempted to add a keyword but one already exists for that numInLrc'
      );
    const strNum = String(numInLrc);
    const cleaned = Keyword.cleanWord(word);
    this.keywords[strNum] = new Keyword(
      this.songId,
      cleaned,
      null,
      null,
      null,
      null,
      null,
      null,
      false,
      keywordId,
      numInLrc,
      0,
      []
    );
    this.keywordIds.add(keywordId);
    this.length++;
  }

  /**
   * Adds a Keyword object to the dictionary.
   * Precondition: a Keyword must not already exist at that numInLrc.
   * @param {Keyword} keywordInst
   */
  addKeywordInstance(keywordInst) {
    if (!keywordInst || !keywordInst.getNumInLrc())
      throw Error('Invalid argument: keywordInst');
    if (this.isKeyword(keywordInst.getNumInLrc()))
      throw Error(
        'Invalidated Precondition: Keyword object already exists at numInLrc.'
      );
    this.keywordIds.add(keywordInst.getId());
    this.keywords[String(keywordInst.getNumInLrc())] = keywordInst;
    this.length++;
    if (keywordInst.isCorrect()) this.score++;
  }

  /**
   * A bulk operation to add Keyword objects to the dictionary. Does not modify
   * the score or length parameters - intended to aid lazy loading scenarios.
   * @param {Array<Keyword>} lst
   */
  addKeywordList(lst) {
    if (!lst || lst.length === 0) return;
    let score = 0;
    lst.forEach((kObj) => {
      this.keywordIds.add(kObj.getId());
      this.keywords[kObj.getNumInLrc()] = kObj;
      if (kObj.isCorrect()) score++;
    });
    this.empty = false;
    this.score = score;
    this.length = lst.length;
  }

  /**
   * Deletes the keyword corresponding to the `numInLrc`.
   * Precondition: a Keyword must exist at that numInLrc.
   * @param {number} numInLrc
   */
  deleteKeyword(numInLrc) {
    this.assertWritable();
    this.numInLrcCheck(numInLrc);
    if (!this.isKeyword(numInLrc))
      throw Error(
        'Precondition Invalidated: cannot delete a keyword that does not exist.'
      );
    this.keywordIds.delete(this.keywords[String(numInLrc)].getId());
    delete this.keywords[String(numInLrc)];
    this.length--;
  }

  /**
   * Returns the length of the keyword dict.
   * @returns {number}
   */
  getLength() {
    return this.length;
  }

  /**
   * Returns the score for the set.
   * @returns {number}
   */
  getScore() {
    return this.score;
  }

  /**
   * Throws an exception if the KeywordDict is in read only mode.
   */
  assertWritable() {
    if (this.readOnly)
      throw Error(
        'Precondition Invalidated: KeywordDict instance is read-only. Only add operations for existing Keyword instances are allowed.'
      );
  }
}

export default KeywordDict;
