import React, { Component } from 'react';
import styled from 'styled-components';
import { withFirebase } from '../../../../Firebase';
import UserInitial from '../../../../UI-Library/Misc/UserInitial';
import ActionButton from '../ActionButton';

const StudentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 15px;
`;

const MainWrapper = styled.div`
  border-radius: 16px;
  background: var(--rsSurface);
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
`;

const Name = styled.p`
  margin: 0;
  font-weight: bold;
  margin-left: 15px;
`;

class PendingStudents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
  }

  render() {
    if (!this.props.isAdmin) {
      return null;
    }

    if (!this.props.students || this.props.students.length === 0) {
      return null;
    }
    return (
      <div style={{ marginBottom: '30px' }}>
        <h2>Pending Students</h2>
        <p>These students are requesting to be in your class.</p>
        <MainWrapper>
          {this.props.students.map((student) => {
            return (
              <StudentWrapper key={student.uid}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <UserInitial
                    letter={student.first_name.charAt(0)}
                    color={student.color}
                    large={false}
                  />
                  <Name>
                    {student.first_name} {student.last_name}
                  </Name>
                </div>
                <ButtonSet
                  accept={() => this.props.acceptStudent(student)}
                  reject={() => this.props.rejectStudent(student)}
                />
              </StudentWrapper>
            );
          })}
        </MainWrapper>
      </div>
    );
  }
}

class ButtonSet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clicked: false,
    };
    this.reject = this.reject.bind(this);
    this.accept = this.accept.bind(this);
  }

  reject() {
    this.props.reject();
    this.setState({ clicked: true });
  }

  accept() {
    this.props.accept();
    this.setState({ clicked: true });
  }

  render() {
    return (
      <div>
        <ActionButton disabled={this.state.clicked} onClick={this.reject}>
          Reject
        </ActionButton>
        <ActionButton disabled={this.state.clicked} onClick={this.accept}>
          Accept
        </ActionButton>
      </div>
    );
  }
}

export default withFirebase(PendingStudents);
