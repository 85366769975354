import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Magnifying extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    };
    this.handleEnter = this.handleEnter.bind(this);
    this.handleLeave = this.handleLeave.bind(this);
  }

  handleEnter() {
    this.setState({ hover: true });
  }

  handleLeave() {
    this.setState({ hover: false });
  }

  render() {
    const hover =
      typeof this.props.hover !== 'undefined'
        ? this.props.hover
        : this.state.hover;

    const color = this.props.disabled
      ? 'var(--rsDisabled)'
      : hover
      ? 'var(--rsPrimary)'
      : 'var(--rsSecondary)';
    return (
      <div
        onMouseEnter={this.handleEnter}
        onMouseLeave={this.handleLeave}
        onClick={this.props.disabled ? null : this.props.onClick}
        style={{
          cursor:
            this.props.onClick && !this.props.disabled ? 'pointer' : 'default',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <svg
          width={this.props.size ? this.props.size : '24px'}
          height={this.props.size ? this.props.size : '24px'}
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 18 18'
        >
          <ellipse
            cx='7.38'
            cy='7.35'
            rx='6.38'
            ry='6.46'
            fill='none'
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
          />
          <path
            d='M16.89,17h0a.39.39,0,0,1-.55,0l-3.95-4a.39.39,0,0,1,0-.55h0a.39.39,0,0,1,.55,0l4,4A.39.39,0,0,1,16.89,17Z'
            fill='none'
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
          />
          <path
            d='M3.3,7.35A4.14,4.14,0,0,1,4.49,4.43'
            fill='none'
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
          />
        </svg>
      </div>
    );
  }
}

Magnifying.propTypes = {
  /**
   * Should the icon take on disabled styling?
   */
  disabled: PropTypes.bool,
  /**
   * The function fired when the icon is clicked.
   */
  onClick: PropTypes.func,
  /**
   * The size of the icon. Defaults to 24px.
   */
  size: PropTypes.string,
  /**
   * A hover override from the parent component. If defined, the local
   * state hover is ignored.
   */
  hover: PropTypes.bool,
};

export default Magnifying;
