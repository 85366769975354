import { dark } from './theme';
import styled from 'styled-components';
import { ThemeProvider } from 'styled-components';
import React, { Component } from 'react';

const HeaderPhoto = styled.img`
	display: block;
	height: 200px;
	width: auto;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	top: 35vh;
`;
const MainWrapper = styled.div`
	width: 100%;
	height: 100vh;
	background-color: #121212;
`;

class Maintenance extends Component {
	render() {
		return (
			<MainWrapper>
				<HeaderPhoto src={'/images/logos/rapstudyblue.png'} alt='RapStudy' />
			</MainWrapper>
		);
	}
}

export default Maintenance;
