import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import List from '../../../Icons/List';
import Info from '../../../Icons/Info';
import Pencil from '../../../Icons/Pencil';
import Tooltip from '../../../Misc/Tooltip';
import DropdownMenu from '../../../ButtonsOrLinks/DropdownMenu';
import Create from '../../../Icons/Create';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Sub = styled.p`
  margin: 0;
  color: var(--rsSecondary);
  font-size: 14px;
`;

const IconWrap = styled.div`
  margin-right: ${(props) => (props.mr ? props.mr : '20px')};
`;

export default function FeatureStartTools({
  userSets,
  selectSet,
  toggleEdit,
  customContentModalToggle,
  title,
  authorName,
  length,
  featureName,
  isAuthor,
}) {
  const featureInfo = {
    Breakdown: {
      eleName: 'annotations',
      link: 'https://support-rapstudy1.web.app/#/article/breakdown',
    },
    Blanks: {
      eleName: 'keywords',
      link: 'https://support-rapstudy1.web.app/#/article/blanks',
    },
    Review: {
      eleName: 'questions',
      link: 'https://support-rapstudy1.web.app/#/article/review',
    },
  };

  const setMenuItems =
    featureName === 'Karaoke'
      ? null
      : userSets.map((set) => ({
          desc: set.name,
          toggleMenuItemAction: () => selectSet(set.setId),
          requiredRole: 'Both',
        }));
  return (
    <Wrapper>
      <div>
        <h2 className='bold'>
          {featureName}: {title}
        </h2>
        <Sub>by: {authorName}</Sub>
        <Sub>{`${length} ${featureInfo[featureName].eleName}`}</Sub>
      </div>
      <div style={{ display: 'flex' }}>
        {isAuthor && (
          <IconWrap>
            <Tooltip placement='bottom' text='Edit'>
              <Pencil onClick={toggleEdit} />
            </Tooltip>
          </IconWrap>
        )}
        <IconWrap>
          <Tooltip placement='bottom' text='Create a set'>
            <Create onClick={customContentModalToggle} />
          </Tooltip>
        </IconWrap>
        <IconWrap>
          <DropdownMenu menuItems={setMenuItems} direction={'center'}>
            <Tooltip placement='bottom' text='Change set'>
              <List onClick={() => null} />
            </Tooltip>
          </DropdownMenu>
        </IconWrap>
        <IconWrap mr={'0px'}>
          <Tooltip placement='bottom' text='View tutorial'>
            <a
              href={featureInfo[featureName].link}
              rel='noopener noreferrer'
              target='_blank'
            >
              <Info onClick={() => null} />
            </a>
          </Tooltip>
        </IconWrap>
      </div>
    </Wrapper>
  );
}

FeatureStartTools.propTypes = {
  /**
   * The array of study sets for this feature.
   */
  userSets: PropTypes.array,
  /**
   * The function to select a set from the dropdown.
   */
  selectSet: PropTypes.func,
  /**
   * The function fired when the pencil icon is clicked.
   */
  toggleEdit: PropTypes.func,
  /**
   * The function fired when the plus icon is clicked.
   */
  customContentModalToggle: PropTypes.func,
  /**
   * The title of the active set.
   */
  title: PropTypes.string,
  /**
   * The author name of the active set.
   */
  authorName: PropTypes.string,
  /**
   * The length of the active set.
   */
  length: PropTypes.number,
  /**
   * The feature name.
   */
  featureName: PropTypes.string,
  /**
   * `true` if the user is the author of the set, `false` otherwise.
   */
  isAuthor: PropTypes.bool,
};

FeatureStartTools.defaultProps = {
  userSets: [],
  selectSet: () => null,
  toggleEdit: () => null,
  customContentModalToggle: () => null,
  title: '',
  authorName: '',
  length: 0,
  featureName: '',
  isAuthor: false,
};
