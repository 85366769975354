import React, { Component } from 'react';
import styled from 'styled-components';
import { withFirebase } from '../../../../Firebase';
import { withRouter, Link } from 'react-router-dom';
import Loading from '../../../../UI-Library/Misc/Loading';
import AssignmentSetView from '../../AssignmentSetView';
import EmptyContent from '../../../../UI-Library/Misc/EmptyContent';
import RsButton from '../../../../UI-Library/ButtonsOrLinks/RsButton';

const MainWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

class AssignmentViewStudent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submissions: null,
      ready: false,
      activeExerciseId: null,
      error: null,
    };
    this.switchExercise = this.switchExercise.bind(this);
    this.submitExercise = this.submitExercise.bind(this);
    this.saveSubmissionAndRerender = this.saveSubmissionAndRerender.bind(this);
  }

  async componentDidMount() {
    try {
      const submissions = await this.props.firebase.getSetsForAssignment(
        this.props.assignmentId
      );
      const exerciseId = submissions[0].getExerciseId();

      await Promise.all(
        submissions.map(async (sub) => {
          const songObj = sub.getSongData();
          songObj.src = await this.props.firebase.getSongCover(
            songObj.subtopic.subtopicRef.id
          );
        })
      );

      this.props.updateCarousel(submissions, this.switchExercise, exerciseId);

      this.setState({
        submissions: submissions,
        ready: true,
        activeExerciseId: exerciseId,
      });
    } catch (err) {
      this.setState({ error: err });
    }
  }

  componentWillUnmount() {
    this.props.removeCarousel();
  }

  isDone() {
    let isDone = true;
    const submissions = this.state.submissions;
    submissions.forEach(
      (sub) => (isDone = isDone && sub.getStatus() === 'complete')
    );
    return isDone;
  }

  switchExercise(exerciseId) {
    this.props.updateCarousel(
      this.state.submissions,
      this.switchExercise,
      exerciseId
    );
    this.setState({ activeExerciseId: exerciseId });
  }

  submitExercise(submission) {
    try {
      submission.setStatus('complete');
      this.saveSubmissionAndRerender();
    } catch (err) {
      this.setState({ error: err });
    }
  }

  saveSubmissionAndRerender() {
    try {
      this.props.updateCarousel(
        this.state.submissions,
        this.switchExercise,
        this.state.activeExerciseId
      );
      this.setState({ submissions: this.state.submissions });
      return;
    } catch (err) {
      this.setState({ error: err });
    }
  }

  render() {
    if (this.state.error) throw this.state.error;
    if (!this.state.ready) return <Loading />;
    const isDone = this.isDone();
    const submissions = this.state.submissions;
    return (
      <MainWrapper>
        {isDone ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '40px',
            }}
          >
            <EmptyContent
              lead='Congratulations!'
              src='/images/animationAssets/girlsLaptop.png'
            >
              <div className='text-center'>
                You have completed "{this.props.assignmentData.name}". Give
                yourself a pat on the back, you deserve it!
                <br />
                <Link
                  to={
                    '/internal/classView/' +
                    this.props.classId +
                    '/assignments/' +
                    this.props.assignmentId +
                    '/teacher/summary'
                  }
                >
                  <RsButton mt={'30px'} large={true}>
                    Back to Summary
                  </RsButton>
                </Link>
              </div>
            </EmptyContent>
          </div>
        ) : (
          <AssignmentSetView
            submissions={submissions}
            assignmentId={this.props.assignmentId}
            assignmentData={this.props.assignmentData}
            classId={this.props.classId}
            activeExerciseId={this.state.activeExerciseId}
            saveSubmissionAndRerender={this.saveSubmissionAndRerender}
            submitExercise={this.submitExercise}
            switchExercise={this.switchExercise}
          />
        )}
      </MainWrapper>
    );
  }
}

export default withRouter(withFirebase(AssignmentViewStudent));
