import React, { Component } from 'react';
import styled from 'styled-components';
import { withFirebase } from '../../../Firebase';

import TutorialModal from '../../../UI-Library/Modals/TutorialModal';

// eslint-disable-next-line no-unused-vars
import * as Scroll from 'react-scroll';
// eslint-disable-next-line no-unused-vars
import { animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import ScrollSpan from '../../../UI-Library/AudioOrLyrics/ScrollSpan';

import Loading from '../../../UI-Library/Misc/Loading';

import SongFeedbackModal from '../../../UI-Library/Modals/SongFeedbackModal';

import SelectionHeader from '../../../UI-Library/Features/SelectionHeader';

import Lrc from '../../../../Classes/Lrc.js';
import ExerciseCompletion from '../../../UI-Library/Modals/ExerciseCompletionModal';

export const Block = styled.div`
  margin-bottom: 3em;
  text-align: center;
`;

const Line = styled.p`
  margin-bottom: 5px;
`;

const MainWrapper = styled.div`
  position: relative;
  margin-top: 30px;
  height: 100%;
  padding-left: 5%;
  padding-right: 5%;
  ${({ fullscreen }) =>
    fullscreen &&
    `
    height: calc(100% - 10vh - 80px);
    margin-top: 5%;
    margin-bottom: 5%;

    display: flex;
    justify-content: center;
    align-items: center;

  `}
`;

const AppWrapper = styled.div`
  height: 60vh;
  margin-top: 15px;

  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */

  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }
`;

const Span = styled.span`
  font-size: 32px;
  font-weight: bold;
`;

Span.displayName = 'Span';

export class KaraokeBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      elrc: null,
      ready: false,
      error: null,
      hover: null,
      hasSubmittedFeedback: false,
      showTutorial: this.props.firstKaraoke,
    };
    this.props.firebase.analytics.logEvent('page_view');

    this.result = null;
    this.currentWord = null;
    this.appWrapper = React.createRef();
    this.completeFeedback = this.completeFeedback.bind(this);
    this.toggleShowTutorial = this.toggleShowTutorial.bind(this);
  }

  completeFeedback(id, rating, message, isTeacher) {
    this.setState({
      hasSubmittedFeedback: true,
    });
    this.props.firebase.completeFeedback(id, rating, message, isTeacher);
  }

  async componentDidMount() {
    this.props.updateCurrSetId(null, 'Karaoke');
    this.setState({
      ready: true,
      elrc: new Lrc(this.props.json, null),
    });
  }

  componentDidUpdate() {
    if (this.state.ready) {
      if (this.props.play && this.currentWord !== null) {
        var appWrapHeight = this.appWrapper.current.clientHeight;
        var offset = (appWrapHeight / 3) * -1;
        scroller.scrollTo('currentWord', {
          duration: 100,
          delay: 0,
          smooth: true,
          containerId: 'AppWrapper',
          // ! need dynamic solution
          offset: offset,
        });
      }
    }
  }

  getContent() {
    if (!this.state.elrc) return;
    var result = null;
    result = this.state.elrc.blocks.map((blockObj, blockIndex) => {
      return (
        <Block key={blockIndex}>
          {blockObj.lines.map((lineObj, lineIndex) => {
            return (
              <Line key={lineObj.pos}>
                {lineObj.words.map((wordObj, wordIndex) => {
                  return this.currentWord === wordObj.numInLrc ? (
                    <ScrollSpan
                      className='major'
                      id='currentWord'
                      key={wordObj.numInLrc}
                    >
                      {wordObj.word}
                    </ScrollSpan>
                  ) : (
                    <Span key={wordObj.numInLrc}>{wordObj.word}</Span>
                  );
                })}
              </Line>
            );
          })}
        </Block>
      );
    });

    return result;
  }

  toggleShowTutorial() {
    this.setState({ showTutorial: !this.state.showTutorial });
  }

  render() {
    if (this.state.error) {
      throw this.state.error;
    }

    if (!this.state.ready) {
      return <Loading />;
    }
    this.currentWord = this.state.elrc.getCurrentWord(this.props.currentTime);
    this.result = this.getContent();

    return (
      <MainWrapper fullscreen={this.props.fullscreen}>
        <SelectionHeader
          newTitle={this.props.newTitle}
          fullscreen={this.props.fullscreen}
          edit={false}
          title={this.props.newTitle}
          topicName={null}
          userSets={null}
          createSet={null}
          handleTitleChange={null}
          deleteModalToggle={null}
          discardModalToggle={null}
          toggleEdit={null}
          selectSet={this.selectSet}
          clickFullScreen={this.props.clickFullScreen}
          featureName={'Karaoke'}
        ></SelectionHeader>
        <AppWrapper
          id='AppWrapper'
          fullscreen={this.props.fullscreen}
          ref={this.appWrapper}
        >
          <div id='LyricWrapper'>{this.result}</div>

          {this.props.completedFeedback || this.state.hasSubmittedFeedback ? (
            <ExerciseCompletion
              show={this.props.ended}
              reset={this.props.reset}
              id={this.props.id}
              exerciseType='karaoke'
            />
          ) : (
            <SongFeedbackModal
              show={this.props.ended}
              reset={this.props.reset}
              id={this.props.id}
              completeFeedback={this.completeFeedback}
              userToken={this.props.userToken}
            />
          )}
        </AppWrapper>
        <TutorialModal
          show={this.state.showTutorial}
          feature={'Karaoke'}
          onHide={this.toggleShowTutorial}
          firstTime={this.props.firstKaraoke}
          complete={this.props.completeFirstKaraoke}
          showCross={false}
          showFooter={true}
        />
      </MainWrapper>
    );
  }
}

const Karaoke = withFirebase(KaraokeBase);
export default Karaoke;
