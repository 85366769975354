import React, { Component } from 'react';
import styled from 'styled-components';

import PropTypes from 'prop-types';
import Lrc from '../../../../Classes/Lrc';
import KeywordDict from '../../../../Classes/KeywordDict';

import BlanksCenteredLyrics from './BlanksCenteredLyrics';
import BlanksLeftLyrics from './BlanksLeftLyrics';
import WordBank from './WordBank';
import WordInput from './WordInput';
import RectangleProgress from '../../Misc/RectangleProgress';

const AbsoluteRight = styled.div`
  width: 50%;
  height: 100%;
  position: relative;
`;

const LeftRightWrapper = styled.div`
  height: 60vh;
  position: relative;
  margin-top: 15px;
  width: 100%;
  display: flex;
`;

const EleWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

class BlanksView extends Component {
  render() {
    const keywordDict = this.props.keywordDict;
    const mode = this.props.mode;
    let currWordIsKeyword = false;
    let isPassed = false;
    if (
      this.props.currentWord &&
      keywordDict.isKeyword(this.props.currentWord)
    ) {
      currWordIsKeyword = true;
      isPassed = keywordDict.isPassed(this.props.currentWord);
    }

    if (mode === 'standard') {
      return (
        <div style={{ height: '100%' }}>
          <RectangleProgress
            length={keywordDict.getLength()}
            activeNum={keywordDict.countPassed() - 1}
          />
          <BlanksCenteredLyrics
            elrc={this.props.elrc}
            keywordDict={keywordDict}
            currentWord={this.props.currentWord}
            play={this.props.play}
            pause={this.props.pause}
            setPassed={this.props.setPassed}
            playOrPause={this.props.playOrPause}
          />
        </div>
      );
    } else if (mode === 'free recall') {
      return (
        <LeftRightWrapper>
          <LeftContainer>
            <RectangleProgress
              length={keywordDict.getLength()}
              activeNum={keywordDict.countPassed() - 1}
            />
            <BlanksLeftLyrics
              elrc={this.props.elrc}
              keywordDict={keywordDict}
              currentWord={this.props.currentWord}
              play={this.props.play}
              pause={this.props.pause}
              setPassed={this.props.setPassed}
            />
          </LeftContainer>

          <AbsoluteRight>
            <EleWrap>
              <WordInput
                keywordDict={keywordDict}
                onSelect={this.props.onSelect}
                giveUp={this.props.giveUp}
                disabled={!currWordIsKeyword || (currWordIsKeyword && isPassed)}
                currentWord={this.props.currentWord}
                maxAttempts={2}
              />
            </EleWrap>
          </AbsoluteRight>
        </LeftRightWrapper>
      );
    } else if (mode === 'word bank') {
      return (
        <LeftRightWrapper>
          <LeftContainer>
            <RectangleProgress
              length={keywordDict.getLength()}
              activeNum={keywordDict.countPassed() - 1}
            />
            <BlanksLeftLyrics
              elrc={this.props.elrc}
              keywordDict={keywordDict}
              currentWord={this.props.currentWord}
              play={this.props.play}
              pause={this.props.pause}
              setPassed={this.props.setPassed}
            />
          </LeftContainer>
          <AbsoluteRight>
            <EleWrap>
              <WordBank
                keywordDict={keywordDict}
                onSelect={this.props.onSelect}
                disabled={!currWordIsKeyword || (currWordIsKeyword && isPassed)}
                currentWord={this.props.currentWord}
              />
            </EleWrap>
          </AbsoluteRight>
        </LeftRightWrapper>
      );
    }
    return null;
  }
}

BlanksView.propTypes = {
  /**
   * The lyric driver Lrc object.
   */
  elrc: PropTypes.instanceOf(Lrc),
  /**
   * The KeywordDict representing the keywords used.
   */
  keywordDict: PropTypes.instanceOf(KeywordDict),
  /**
   * The current numInLrc number playing. Can be `null`.
   */
  currentWord: PropTypes.number,
  /**
   * Is the song playing?
   */
  play: PropTypes.bool,
  /**
   * The function fired when a keyword is met.
   */
  pause: PropTypes.func,
  /**
   * The function to play or pause the audio.
   */
  playOrPause: PropTypes.func,
  /**
   * The function fired when a keyword is completed, or passed.
   */
  setPassed: PropTypes.func,
  /**
   * The function fired when a choice is selected. Takes two parameters,
   * the number representing the numInLrc of the keyword, and the string
   * representing the actual text of the keyword.
   */
  onSelect: PropTypes.func,
  /**
   * The function to give up on the current keyword (Only applicable to
   * free recall mode).
   */
  giveUp: PropTypes.func,
  /**
   * The mode of the current set.
   */
  mode: PropTypes.oneOf(['standard', 'free recall', 'word bank']),
};

export default BlanksView;
