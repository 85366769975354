import React, { Component } from 'react';
import styled from 'styled-components';

import RsSlider from '../../../Forms/RsSlider';
import PropTypes from 'prop-types';
import Speaker from '../../../Icons/Speaker';

const VolumeDiv = styled.div`
  display: flex;
  align-items: center;
`;

/**
 * The volume icon, with a slider that appears on hover.
 */
class Volume extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showVolume: false,
    };
    this.toggleVolume = this.toggleVolume.bind(this);
  }

  toggleVolume() {
    this.setState({
      showVolume: !this.state.showVolume,
    });
  }

  render() {
    return (
      <VolumeDiv
        onMouseEnter={this.toggleVolume}
        onMouseLeave={this.toggleVolume}
      >
        <Speaker hover={this.state.showVolume} />
        {this.state.showVolume && (
          <RsSlider
            percentage={this.props.value}
            width={'150px'}
            ml={'10px'}
            label='volume'
            value={this.props.value}
            onChange={this.props.onChange}
            secondary={true}
          />
        )}
      </VolumeDiv>
    );
  }
}

Volume.propTypes = {
  /**
   * A number between 1 and 100.
   */
  value: PropTypes.number,
  /**
   * The function that fires when the volume slider is moved.
   */
  onChange: PropTypes.func,
};

export default Volume;
