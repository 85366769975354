export async function getRecentSongs(limit) {
  var userDoc = this.db.collection('users').doc(this.auth.currentUser.uid);
  var querySnap = await userDoc
    .collection('songs')
    .orderBy('lastAccess', 'desc')
    .limit(limit)
    .get();
  if (querySnap.empty) return [];
  var result = querySnap.docs.map((item) => {
    var obj = item.data();
    obj.id = item.id;
    obj.ref = item.ref;
    return obj;
  });
  return result;
}

export async function getNewReleases() {
  var colRef = this.db.collection('songs');
  var querySnap = await colRef.orderBy('uploaded', 'desc').limit(20).get();
  if (querySnap.empty) return [];
  var result = querySnap.docs.map((item) => {
    var obj = item.data();
    obj.id = item.id;
    obj.ref = item.ref;
    return obj;
  });
  return result;
}
