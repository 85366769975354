import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import Loading from '../UI-Library/Misc/Loading';

import TopNav from '../UI-Library/Navs/TopNav';
import SignUp from '../SignUp';
import SignIn from '../SignIn';
import LandingHome from '../Landing/LandingHome';
import ResetPassword from '../ResetPassword';
import FreeLanding from '../FreeLanding';
import Internal from '../Internal';
import Forbidden from '../Forbidden';
import NotFound from '../404';
import About from '../Landing/About';

import * as ROUTES from '../../constants/routes';
import { withFirebase } from '../Firebase';
import ProtectedRoute from './ProtectedRoute';
import PublicRoute from './PublicRoute';

import styled from 'styled-components';

import { withAlert } from 'react-alert';
import FreemiumRoute from './FreemiumRoute';
import PendingVerification from '../PendingVerification';
import { isMobile } from 'react-device-detect';

import { ThemeProvider } from 'styled-components';
import { dark } from '../../theme';
import { GlobalStyles } from '../../global';
import ContactUs from '../Landing/ContactUs';

const Spacing = styled.div`
  // height: 100%;
`;
/**
 * App component to control entirety of web application.
 * Holds auth state as part of its state to be passed to children.
 */
class AppBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      verificationDone: false,
      authUser: null,
      verified: false,
      userToken: null,
      theme: dark,
    };
    this.signOut = this.signOut.bind(this);
    this.signInGoogle = this.signInGoogle.bind(this);
    this.signInEmail = this.signInEmail.bind(this);
    this.createUser = this.createUser.bind(this);
    this.changeAuthState = this.changeAuthState.bind(this);
  }

  /**
   * Sets up event listener for auth state changes and updates state of
   * component accordingly.
   */
  async componentDidMount() {
    this.onAuthStateListener = this.props.firebase.auth.onAuthStateChanged(
      async (authUser) => {
        const result = await this.props.firebase.authStateChangedListener(
          authUser
        );

        this.setState({
          verificationDone: true,
          authUser: result.authUser,
          verified: result.verified,
          userToken: result.userToken,
        });

        if (!authUser) return;
        const dev =
          result.userToken &&
          result.userToken.claims &&
          result.userToken.claims.dev
            ? result.userToken.claims.dev
            : false;

        if (result.needEmailVerification) {
          this.props.history.push(ROUTES.PENDINGVERIFICATION);
          return;
        }
        if (!result.verified) {
          this.props.history.push(ROUTES.FREELANDING);
          return;
        }

        //Set User Property verified, schoolName, orderName [Firebase Analytics]
        if (result.verified) {
          const doc = await this.props.firebase.getUserDoc();
          const data = doc.data();
          const schoolName = data.schoolName;
          const orderName = data.orderName;
          this.props.firebase.analytics.setUserProperties({
            verified: 'true',
            schoolName: schoolName,
            orderName: orderName,
          });
        } else {
          this.props.firebase.analytics.setUserProperties({
            verified: 'false',
          });
        }

        //Set User Property isDev [Firebase Analytics]
        if (dev) {
          this.props.firebase.analytics.setUserProperties({
            isDev: 'true',
          });
        } else {
          this.props.firebase.analytics.setUserProperties({
            isDev: 'false',
          });
        }

        //Set User Property isTeacher [Firebase Analytics]
        if (result.userToken.claims.teacher) {
          this.props.firebase.analytics.setUserProperties({
            isTeacher: 'true',
          });
        } else {
          this.props.firebase.analytics.setUserProperties({
            isTeacher: 'false',
          });
        }

        //Set User Property isMobile [Firebase Analytics]
        if (isMobile) {
          this.props.firebase.analytics.setUserProperties({
            isMobile: 'true',
          });
        } else {
          this.props.firebase.analytics.setUserProperties({
            isMobile: 'false',
          });
        }

        await this.props.firebase.updateLastAccess();
      }
    );
  }

  changeAuthState(result) {
    this.setState({
      verified: result.verified,
      userToken: result.userToken,
    });
  }

  /**
   * Removes event listener when page is closed.
   */
  componentWillUnmount() {
    this.onAuthStateListener();
  }

  async signOut() {
    await this.props.firebase.doSignOut();
    window.location.replace('/');
    this.setState(
      {
        authUser: null,
        verificationDone: false,
        verified: false,
      },
      () => {
        return;
      }
    );
  }

  async createUser(email, password) {
    // this.setState({ verificationDone: false });
    await this.props.firebase.createUserWithEmail(email, password);
    // this.setState({ verificationDone: true });
  }

  async signInEmail(email, password) {
    // this.setState({ verificationDone: false });
    await this.props.firebase.doSignInEmail(email, password);
    // this.setState({ verificationDone: true });
  }

  async signInGoogle() {
    this.setState({ verificationDone: false });
    await this.props.firebase.doSignInGoogle();
    this.setState({ verificationDone: true });
  }

  /**
   * Renders Navigation component and sets up routes for non auth pages
   * as well as Internal which will handle auth routing.
   */
  render() {
    if (!this.state.verificationDone) {
      return (
        <ThemeProvider theme={this.state.theme}>
          <GlobalStyles />
          <Loading />
        </ThemeProvider>
      );
    } else {
      return (
        <ThemeProvider theme={this.state.theme}>
          <GlobalStyles />

          <TopNav
            authUser={this.state.authUser}
            signOut={this.signOut}
            verificationDone={this.state.verificationDone}
            verified={this.state.verified}
            userToken={this.state.userToken}
          />
          <Spacing>
            <Switch>
              <PublicRoute
                exact
                path={ROUTES.LANDING}
                authUser={this.state.authUser}
                verified={this.state.verified}
                verificationDone={this.state.verificationDone}
                component={LandingHome}
              />
              <PublicRoute
                exact
                path={ROUTES.ABOUT}
                authUser={this.state.authUser}
                verified={this.state.verified}
                verificationDone={this.state.verificationDone}
                component={About}
              />
              <PublicRoute
                exact
                path={ROUTES.CONTACT}
                authUser={this.state.authUser}
                verified={this.state.verified}
                verificationDone={this.state.verificationDone}
                component={ContactUs}
              />
              <PublicRoute
                exact
                path={ROUTES.SIGNUP}
                authUser={this.state.authUser}
                verified={this.state.verified}
                verificationDone={this.state.verificationDone}
                signInGoogle={this.signInGoogle}
                createUser={this.createUser}
                component={SignUp}
              />
              <PublicRoute
                exact
                path={ROUTES.SIGNIN}
                authUser={this.state.authUser}
                verified={this.state.verified}
                verificationDone={this.state.verificationDone}
                signInGoogle={this.signInGoogle}
                signInEmail={this.signInEmail}
                createUser={this.createUser}
                component={SignIn}
              />
              <PublicRoute
                exact
                path={ROUTES.RESETPASSWORD}
                authUser={this.state.authUser}
                verified={this.state.verified}
                verificationDone={this.state.verificationDone}
                component={ResetPassword}
              />

              <FreemiumRoute
                exact
                path={ROUTES.PENDINGVERIFICATION}
                authUser={this.state.authUser}
                verified={this.state.verified}
                verificationDone={this.state.verificationDone}
                userToken={this.state.userToken}
                component={PendingVerification}
              />
              <FreemiumRoute
                exact
                path={ROUTES.FREELANDING}
                authUser={this.state.authUser}
                verified={this.state.verified}
                verificationDone={this.state.verificationDone}
                userToken={this.state.userToken}
                signOut={this.signOut}
                changeAuthState={this.changeAuthState}
                component={FreeLanding}
              />
              <ProtectedRoute
                path={ROUTES.INTERNAL}
                authUser={this.state.authUser}
                verified={this.state.verified}
                verificationDone={this.state.verificationDone}
                userToken={this.state.userToken}
                signOut={this.signOut}
                component={Internal}
              />
              <PublicRoute
                path={ROUTES.FORBIDDEN}
                authUser={this.state.authUser}
                verified={this.state.verified}
                verificationDone={this.state.verificationDone}
                component={Forbidden}
              />
              <Route
                authUser={this.state.authUser}
                verified={this.state.verified}
                verificationDone={this.state.verificationDone}
                component={NotFound}
              />
            </Switch>
          </Spacing>
        </ThemeProvider>
      );
    }
  }
}

const App = withAlert()(withRouter(withFirebase(AppBase)));
export default App;
