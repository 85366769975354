import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import RsButton from '../UI-Library/ButtonsOrLinks/RsButton';

const NotFoundJumbo = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 75vh;
  text-align: center;
  background: var(--rsBackground);
`;

const JumboHR = styled.hr`
  border: 1px solid var(--rsPrimary);
`;

class NotFound extends Component {
  renderContent() {
    if (this.props.verified) {
      return (
        <Link to='/internal/dashboard'>
          <RsButton>Back to Safety</RsButton>
        </Link>
      );
    }
    return (
      <Link to='/'>
        <RsButton>Back to safety</RsButton>
      </Link>
    );
  }

  render() {
    return (
      <NotFoundJumbo className='jumbotron'>
        <h2 className='major'>Oops! Looks like this page doesn't exist.</h2>
        <JumboHR />
        {this.renderContent()}
      </NotFoundJumbo>
    );
  }
}

export default NotFound;
