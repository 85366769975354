import React, { Component } from 'react';
import styled from 'styled-components';
import { withFirebase } from '../../Firebase';

import InProgress from './InProgress';
import HomeBanner from './HomeBanner';
import Loading from '../../UI-Library/Misc/Loading';
import AlbumCoverCarouselController from '../../UI-Library/ImageContainers/AlbumCoverCarouselController';

const MainBody = styled.div`
  margin: 0;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 2%;
`;

/**
 * Home is the component for the entire Home page. It will
 * display the InProgress and HeavyRot components.
 */
export class HomeBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recents: [],
      recentExists: false,
      ready: false,
      error: null,
    };
  }

  async componentDidMount() {
    try {
      const recentsPr = this.props.firebase.getRecentSongs(20);
      const newReleasesPr = this.props.firebase.getNewReleases();
      const rsPlaylistsPr = this.props.firebase.getAuthoredPlaylists(
        process.env.REACT_APP_RAPSTUDY_UID
      );

      const resolved = await Promise.all([
        recentsPr,
        newReleasesPr,
        rsPlaylistsPr,
      ]);

      const recentsDocs = resolved[0];
      const newReleasesDocs = resolved[1];
      const rsPlaylists = resolved[2];

      const withImagesPrs = [];
      var recentsExists = false;

      if (recentsDocs.length !== 0) {
        recentsExists = true;
        withImagesPrs.push(
          Promise.all(
            recentsDocs.map(async (item) => {
              var data = item;
              data.src = await this.props.firebase.getSongCover(
                item.subtopic.subtopicRef.id
              );
              return data;
            })
          )
        );
      } else {
        withImagesPrs.push([]);
      }

      withImagesPrs.push(
        Promise.all(
          newReleasesDocs.map(async (item) => {
            var data = item;
            data.src = await this.props.firebase.getSongCover(
              item.subtopic.subtopicRef.id
            );
            return data;
          })
        )
      );

      const resolved2 = await Promise.all(withImagesPrs);
      var recents = resolved2[0];
      const newReleases = resolved2[1];

      var seen = {};
      recents = recents.filter(function (song) {
        var subtopic = song.subtopic.name;
        return seen.hasOwnProperty(subtopic) ? false : (seen[subtopic] = true);
      });

      this.setState({
        recents: recents,
        newReleases: newReleases,
        rsPlaylists: rsPlaylists,
        recentExists: recentsExists,
        ready: true,
      });
    } catch (err) {
      this.setState({ error: err });
    }
  }

  render() {
    if (this.state.error) {
      console.log(this.state.error);
      throw this.state.error;
    }
    if (!this.state.ready) {
      // this.setState({ hidden: true });
      return <Loading />;
    }

    return (
      <div>
        <HomeBanner
          userData={this.props.userData}
          userToken={this.props.userToken}
        />
        <MainBody>
          <AlbumCoverCarouselController
            header={'New Releases'}
            items={this.state.newReleases}
            itemType={'song'}
            rows={1}
          />
          <InProgress
            recents={this.state.recents}
            recentExists={this.state.recentExists}
          />
          <AlbumCoverCarouselController
            header={'rapStudy Favorites'}
            items={this.state.rsPlaylists}
            itemType={'playlist'}
            rows={1}
          />
        </MainBody>
      </div>
    );
  }
}

export default withFirebase(HomeBase);
