import React from 'react';
import * as ROUTES from '../../../../constants/routes';
import styled from 'styled-components';
import BoxPreview from '../../../UI-Library/ImageContainers/BoxPreview';

import { withRouter } from 'react-router-dom';

const UnitImg = styled.img`
  height: 180px;
  margin-right: 20px;
`;
UnitImg.displayName = 'UnitImg';

const UnitImgRow = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  flex-wrap: wrap;

  ${({ nowrap }) =>
    nowrap &&
    `
    flex-wrap: nowrap;
    overflow-x: hidden;
    scroll-behavior: smooth;
`}
`;
UnitImgRow.displayName = 'UnitImgRow';

const SubjectHeader = styled.div`
  margin-top: 30px;
  font-weight: bold;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;
SubjectHeader.displayName = 'SubjectHeader';

class BrowseUnits extends React.Component {
  render() {
    const params = this.props.match.params;
    const subject = params.subject;
    const data = this.props.data;
    const unitArr = data[subject];
    if (!unitArr) {
      return null;
    }
    return (
      <div key={'SUBJECTUNITSDIV_' + subject}>
        <SubjectHeader>All units for {subject}:</SubjectHeader>
        <UnitImgRow nowrap={false}>
          {unitArr.map((unit, index) => {
            return (
              <BoxPreview
                to={ROUTES.UNITVIEW + '/' + unit.id}
                key={unit.subject + '_' + unit.title}
                image={unit.src}
                title={unit.title}
                subtitle={null}
              />
            );
          })}
        </UnitImgRow>
      </div>
    );
  }
}

export default withRouter(BrowseUnits);
