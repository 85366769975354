import React, { Component } from 'react';
import styled from 'styled-components';
import { withFirebase } from '../../../Firebase';
import Assignments from './Assignments';
import { Link } from 'react-router-dom';
import RsButton from '../../../UI-Library/ButtonsOrLinks/RsButton';

import * as ROUTES from '../../../../constants/routes';
import EmptyContent from '../../../UI-Library/Misc/EmptyContent';
import Loading from '../../../UI-Library/Misc/Loading';

const ClassAssignmentsWrapper = styled.div`
  padding: 5%;
  padding-top: 2%;
  padding-bottom: 2%;
`;

class ClassAssignments extends Component {
  constructor(props) {
    super(props);
    this.state = { assignments: [], ready: false };
    this.componentDidMount = this.componentDidMount.bind(this);
    this.concatFollowed = this.concatFollowed.bind(this);
  }

  async componentDidMount() {
    const params = this.props.match.params;
    const classId = params.classId;

    const assignmentsFollowedPr = this.props.isAdmin
      ? new Promise((resolve, reject) => resolve([]))
      : this.props.firebase.getPastAssignmentsFollowed();

    const prs = await Promise.all([
      this.props.firebase.getAllClassAssignments(classId),
      assignmentsFollowedPr,
    ]);
    const classAssignments = prs[0];
    const pastAssignments = prs[1];

    this.setState({
      assignments: classAssignments,
      pastAssignments: pastAssignments,
      ready: true,
    });
  }

  concatFollowed(fromProps, fromGet) {
    const newFollowed = fromProps.concat(fromGet);
    return newFollowed;
  }

  render() {
    if (!this.state.ready) return <Loading />;
    const past = [];
    const active = [];
    const upcoming = [];
    const completedSet = new Set();

    if (!this.props.isAdmin) {
      const assignmentsFollowed = this.concatFollowed(
        this.props.assignmentsFollowed,
        this.state.pastAssignments
      );
      assignmentsFollowed.forEach((ele) => {
        if (ele.complete) completedSet.add(ele.assignmentId);
      });
    }

    this.state.assignments.forEach((assignmentData) => {
      const releaseDate = assignmentData.releaseDate;
      const dueDate = assignmentData.dueDate;
      if (dueDate < new Date()) {
        past.push(assignmentData);
      } else if (releaseDate > new Date()) {
        upcoming.push(assignmentData);
      } else {
        active.push(assignmentData);
      }
    });
    const params = this.props.match.params;
    const classId = params.classId;
    const isEmpty = active.length + past.length + upcoming.length === 0;
    return (
      <ClassAssignmentsWrapper>
        {isEmpty && (
          <div
            style={{ display: 'flex', width: '100%', justifyContent: 'center' }}
          >
            <EmptyContent
              src='/images/animationAssets/girlWaving.png'
              lead={
                this.props.isAdmin
                  ? "You haven't made any assignments yet!"
                  : "Your teacher hasn't made any assignments yet!"
              }
            >
              {this.props.isAdmin ? (
                <p>
                  An assignment lets you share study sets with your students and
                  track their progress.
                </p>
              ) : null}
            </EmptyContent>
          </div>
        )}
        {active.length > 0 && (
          <Assignments
            isAdmin={this.props.isAdmin}
            title={'Active'}
            assignments={active}
            disabled={false}
            completedSet={completedSet}
            sortBy='dueDate'
          />
        )}
        {upcoming.length > 0 && (
          <Assignments
            isAdmin={this.props.isAdmin}
            title={'Upcoming'}
            assignments={upcoming}
            disabled={!this.props.isAdmin}
            completedSet={completedSet}
            sortBy='releaseDate'
          />
        )}
        {past.length > 0 && (
          <Assignments
            isAdmin={this.props.isAdmin}
            title={'Past'}
            assignments={past}
            disabled={!this.props.isAdmin}
            completedSet={completedSet}
            sortBy='dueDate'
          />
        )}
        <div
          style={{
            marginBottom: '20px',
            width: '100%',
            textAlign: 'center',
          }}
        >
          {this.props.isAdmin && (
            <Link to={ROUTES.CLASSVIEW + '/' + classId + '/createAssignment'}>
              <RsButton mt={'20px'} large={true}>
                Create Assignment
              </RsButton>
            </Link>
          )}
        </div>
      </ClassAssignmentsWrapper>
    );
  }
}

export default withFirebase(ClassAssignments);
