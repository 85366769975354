/**
 * @param {string} songId
 * @param {number} rating
 * @param {string} message
 */
export async function completeFeedback(songId, rating, message, isTeacher) {
  const userDoc = this.db.collection('users').doc(this.auth.currentUser.uid);
  const songDoc = await userDoc.collection('songs').doc(songId).get();
  if (!songDoc.exists) throw this.NotFoundError;
  songDoc.ref.update({ completedFeedback: true });
  this.db
    .collection('songFeedback')
    .doc()
    .set({
      userId: this.auth.currentUser.uid,
      date: this.firebase.firestore.FieldValue.serverTimestamp(),
      songId: songId,
      rating: rating,
      message: message,
      position: isTeacher ? 'teacher' : 'student',
    });
}
