import React, { Component } from 'react';
import styled from 'styled-components';
import * as ROUTES from '../../../constants/routes';
import { withFirebase } from '../../Firebase';
import { withRouter, NavLink, Link } from 'react-router-dom';

import Main from './Main';
import Library from './Library';
import CreatePlaylistToggle from '../../UI-Library/Modals/CreatePlaylistToggle';
import Playlists from './Playlists';

import { Scrollbars } from 'react-custom-scrollbars';
import Create from '../../UI-Library/Icons/Create';
import Info from '../../UI-Library/Icons/Info';
import Gear from '../../UI-Library/Icons/Gear';
import RsLink from '../../UI-Library/ButtonsOrLinks/RsLink';

const HeaderPhoto = styled.img`
  height: 80px;
  width: auto;
  margin-bottom: -17px;
  @media screen and (max-width: 576px) {
    margin-left: 5px;
    height: 60px;
  }
`;

const VertNav = styled.div`
  width: 225px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  height: 100vh;
  background-color: var(--rsSurface);
  padding-top: 0;
  padding-bottom: 50px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
`;

const ActiveLinkStyle = {};

const LinkStyle = {
  verticalAlign: 'middle',
  color: 'var(--rsSecondary)',
  textDecoration: 'none',
  backgroundColor: 'transparent',
  border: 'none',
  outline: 'none',
  padding: 0,
};

const IconWrapper = styled.div`
  margin-right: 13px;
`;

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Divider = styled.hr`
  border: 1px solid var(--rsSecondary);
  width: 99%;
  margin: 0;
`;

const Padded = styled.div`
  padding-left: 20px;
  padding-right: 20px;
`;

/**
 * VerticalNav is the vertical navigation bar on the left side of the
 * internal pages.
 *
 * The components that it renders are each their own <ul>.
 */
export class VerticalNavBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      followed: null,
      created: null,
      library: null,
    };
  }

  componentDidMount() {
    const uid = this.props.firebase.auth.currentUser.uid;
    const userRef = this.props.firebase.db.collection('users').doc(uid);
    const playlistFollowedRef = this.props.firebase.db.collection(
      'playlistsFollowed'
    );
    const playlistsRef = this.props.firebase.db.collection('playlists');

    // listener for followed playlists
    this.playlistFollowedListener = playlistFollowedRef
      .where('follower', '==', uid)
      .where('author', '!=', uid)
      .onSnapshot(
        (querySnapshot) => {
          this.updateFollowedPlaylists(querySnapshot);
        },
        (err) => {
          console.log(err);
        }
      );

    // listener for created playlists
    this.playlistCreatedListener = playlistsRef
      .where('author', '==', uid)
      .onSnapshot(
        (querySnapshot) => {
          this.updateCreatedPlaylists(querySnapshot);
        },
        (err) => {
          console.log(err);
        }
      );

    // listener for library
    this.libraryListener = userRef
      .collection('library')
      .orderBy('subject')
      .onSnapshot(
        (snapshot) => {
          this.updateLibrary(snapshot);
        },
        (err) => {
          console.log(err);
        }
      );
  }

  componentWillUnmount() {
    this.libraryListener();
    this.playlistFollowedListener();
  }

  async updateFollowedPlaylists(querySnapshot) {
    var playlistArray = await this.props.firebase.playlistsOfPlaylistsFollowed(
      querySnapshot
    );
    if (!playlistArray || playlistArray.length === 0) {
      this.setState({ followed: null });
      return;
    }
    this.setState({ followed: playlistArray });
  }

  async updateCreatedPlaylists(querySnapshot) {
    const playlists = querySnapshot.docs.map((docSnap) => {
      const obj = docSnap.data();
      obj.playlistId = docSnap.id;
      return obj;
    });

    if (!playlists || playlists.length === 0) {
      this.setState({ created: null });
      return;
    }
    this.setState({ created: playlists });
  }

  updateLibrary(snapshot) {
    const library = this.props.firebase.sortLibrary(snapshot);
    this.setState({ library: library });
  }

  render() {
    var playlists = [];
    playlists =
      this.state.created === null
        ? playlists
        : playlists.concat(this.state.created);
    playlists =
      this.state.followed === null
        ? playlists
        : playlists.concat(this.state.followed);
    return (
      <VertNav>
        <div>
          <Padded style={{ height: '250px' }}>
            <Link to={ROUTES.DASHBOARD} className='navbar-brand'>
              <HeaderPhoto
                src={'/images/logos/rapstudyblue.png'}
                alt='RapStudy'
              />
            </Link>
            <Main assignmentsFollowed={this.props.assignmentsFollowed} />
          </Padded>
          <Divider />
        </div>

        <div
          style={{ height: 'calc(100vh - 250px - 150px)', paddingTop: '20px' }}
        >
          <Padded>
            <Scrollbars
              style={{
                height: 'calc(100vh - 270px - 150px)',
                paddingBottom: '20px',
              }}
              className='custom-scrollbars'
              renderTrackHorizontal={(props) => (
                <div
                  {...props}
                  style={{ display: 'none' }}
                  className='track-horizontal'
                />
              )}
            >
              <Library library={this.state.library} />
              <Playlists playlists={playlists} />
            </Scrollbars>
          </Padded>
        </div>

        <div style={{ height: '150px' }}>
          <Divider />
          <Padded style={{ marginTop: '20px' }}>
            <LinkWrapper>
              <IconWrapper>
                <Create size='18px' hover={false} />
              </IconWrapper>
              <CreatePlaylistToggle willRedirect={true} />
            </LinkWrapper>
            <LinkWrapper>
              <IconWrapper>
                <Gear size='18px' hover={false} />
              </IconWrapper>
              <NavLink
                to={ROUTES.SETTINGS}
                style={LinkStyle}
                activeStyle={ActiveLinkStyle}
                className='vertNav-link'
              >
                Settings
              </NavLink>
            </LinkWrapper>

            <LinkWrapper>
              <IconWrapper>
                <Info size='18px' hover={false} />
              </IconWrapper>
              <a
                style={LinkStyle}
                href='https://support-rapstudy1.web.app/'
                target='_blank'
                rel='noopener noreferrer'
              >
                Help
              </a>
            </LinkWrapper>
            <div style={{ marginTop: '0px' }}>
              <RsLink
                onClick={this.props.signOut}
                secondary={true}
                small={true}
              >
                Log Out
              </RsLink>
            </div>
          </Padded>
        </div>
      </VertNav>
    );
  }
}

const VerticalNav = withFirebase(withRouter(VerticalNavBase));
export default VerticalNav;
