import React, { Component } from 'react';
import SongSetsDict from '../../../../Classes/SongSetsDict';

import { Card, Accordion, Row } from 'react-bootstrap';
import FeatureColumn from './FeatureColumn';
import PropTypes from 'prop-types';
import RsButton from '../../ButtonsOrLinks/RsButton';

const CardBodyStyle = {
  width: '100%',
  border: '0',
  margin: '0',
  marginTop: '5px',
  marginBottom: '5px',
  padding: '0',
  paddingTop: '20px',
  paddingBottom: '20px',
  borderTop: '2px solid var(--rsSecondary)',
};

const RowStyle = {
  textAlign: 'left',
  margin: 0,
  marginBottom: '15px',
};

const ToggleStyle = {
  width: '100%',
  color: 'white',
  padding: '0',
  margin: '0',
};

/**
 * The card that displays information about a song and its sets. This component
 * uses the DropdownMenu basic component and the FeatureColumn composite
 * component.
 *
 * (The storybook rendering is buggy at the moment. See contentmanager or
 * shared content in classview for a visual example.)
 */
class SongCard extends Component {
  constructor(props) {
    super(props);
    this.state = { hover: false, open: false };
  }

  render() {
    var features;
    if (this.props.hideEmpty) {
      features = this.props.song.getNonEmptyNonZeroFeatures();
      if (features.length === 0) {
        return null;
      }
    } else {
      features = this.props.song.getNonEmptyFeatures();
    }

    return (
      <Card
        style={{
          backgroundColor: this.state.hover
            ? 'var(--rsSurfaceHover)'
            : 'var(--rsSurface)',
          borderRadius: '16px',
          marginBottom: '10px',
          marginTop: '10px',
          paddingLeft: '30px',
          paddingRight: '30px',
        }}
      >
        <Card.Header
          key={'cardHeader_' + this.props.song.songId}
          style={{
            border: 'none',
            display: 'flex',
            padding: '0',
            paddingTop: '10px',
            paddingBottom: '10px',
          }}
        >
          <Accordion.Toggle
            key={'accorToggle_' + this.props.song.songId}
            eventKey={'accorToggle_' + this.props.song.songId}
            style={ToggleStyle}
            className='noPadMar ghost'
            onMouseEnter={() => this.setState({ hover: true })}
            onMouseLeave={() => this.setState({ hover: false })}
          >
            <div>
              <p className='text-left bold' style={{ marginBottom: 0 }}>
                {this.props.song.songData.subtopic.name}
              </p>
              <p
                className='text-left bold secondary'
                style={{ fontSize: '14px' }}
              >
                {this.props.song.songData.origTitle}
              </p>
            </div>

            <div
              className='secondary text-left'
              style={{
                fontSize: '14px',
                marginBottom: 0,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div>
                {this.props.song.songData.subject.name} {' > '}
                {this.props.song.songData.unit.name} {' > '}
                {this.props.song.songData.topic.name}
              </div>

              <div>
                {this.props.hideEmpty
                  ? this.props.song.getNumNonEmptySets()
                  : this.props.song.getNumSets()}{' '}
                study set(s)
              </div>
            </div>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey={'accorToggle_' + this.props.song.songId}>
          <Card.Body style={CardBodyStyle}>
            <Row style={RowStyle}>
              {features.map((ele, index) => (
                <FeatureColumn
                  song={this.props.song}
                  borderRight={index !== features.length - 1}
                  featureName={ele}
                  showDropdown={this.props.showDropdown}
                  showRemove={this.props.showRemove}
                  showCheckbox={this.props.showCheckbox}
                  onSelectCheckbox={this.props.onSelectCheckbox}
                  isTeacher={this.props.isTeacher}
                  shareModalToggle={this.props.shareModalToggle}
                  deleteModalToggle={this.props.deleteModalToggle}
                  remove={this.props.remove}
                  key={'FEATURECOLUMN_' + this.props.song.songId + '_' + ele}
                  hideEmpty={this.props.hideEmpty}
                />
              ))}
            </Row>
            {this.props.showCreate && (
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <RsButton
                  mb={'5px'}
                  onClick={() => {
                    this.props.setCustomContentTypes(
                      this.props.featureName,
                      this.props.song.songId,
                      this.props.song.songData.topic.name,
                      this.props.song.songData.subtopic.name
                    );
                    this.props.customContentModalToggle();
                  }}
                >
                  + New Set
                </RsButton>
              </div>
            )}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  }
}

SongCard.propTypes = {
  /**
   * The song to list. In addition to the usual song fields, it requires arrays
   * of the study sets for Review, Blanks, and Breakdown.
   */
  song: PropTypes.instanceOf(SongSetsDict),
  /**
   * Shows the checkbox next to the items.
   */
  showCheckbox: PropTypes.bool,
  /**
   * The function fired when a checkbox is clicked. Best used in conjunction with
   * the `toggleSelected` method of CustomSetDict. The function must have one
   * parameter, a string `setId`.
   */
  onSelectCheckbox: PropTypes.func,
  /**
   * Shows the DropdownMenu that contains the remove and add to class options.
   */
  showDropdown: PropTypes.bool,
  /**
   * Shows the remove button.
   */
  showRemove: PropTypes.bool,
  /**
   * Shows the create option.
   */
  showCreate: PropTypes.bool,
  /**
   * Is the user a teacher? Only necessary if showDropdown is true.
   */
  isTeacher: PropTypes.bool,
  /**
   * The function to toggle the share to class modal (not included in this
   * component). The function must take two parameters: the `featureName` and
   * the `setId`. The function should then ideally set the props to the sibling
   * modal component. To be used in conjunction with `ShareToClassModal`.
   */
  shareModalToggle: PropTypes.func,
  /**
   * The function to toggle the deletion modal (not included in this component).
   * The function must take three parameters: the `featureName`, the `songId`,
   * and the `setId`. To be used in conjunction with `DeleteConfirmModal`.
   */
  deleteModalToggle: PropTypes.func,
  /**
   * The function to toggle the custom content modal (to create a new set).
   * This function should not take in any parameters and should only have the
   * side effect of toggling the show/hide behavior of the modal. To set values
   * in modal, use `setCustomContentTypes` function. To be used in conjunction
   * with `CreateSetModal`.
   */
  customContentModalToggle: PropTypes.func,
  /**
   * The function to set correct props in a sibling `CreateSetModal`.
   * Must take four parameters: `featureName`, `songId`, `topicName`, and
   * `songName`.
   */
  setCustomContentTypes: PropTypes.func,
  /**
   * The function to remove a given study set. The function must take three
   * parameters: the `featureName`, one of `"Breakdown"`, `"Blanks"`, or `"Review"`;
   * the song id, and the set id.
   */
  remove: PropTypes.func,
  /**
   * Hides empty sets (sets with no questions, blanks, or keywords).
   */
  hideEmpty: PropTypes.bool,
};

export default SongCard;
