import TopicDict from "./TopicDict";
class UnitDict {
  constructor(
    name,
    id,
    src,
    imgName,
    subtitle,
    subject,
    description,
    ref
    
  ){
    this.name = name;
    this.id = id;
    this.src = src;
    this.imgName = imgName;
    this.subtitle = subtitle;
    this.subject = subject;
    this.description = description;
    this.ref = ref;
    this.grades = new Set();
    this.standards = new Set();
    this.topics = []; // Array <Topics>
  }

  /**
   * Adds a topic to this UnitDict
   * @param {TopicDict} topicDict 
   */
  addTopic(topicDict){
    // TODO: type check topicDict. if fail, throw Error('Failed precondition: topicDict must be a TopicDict')
    if(!(topicDict instanceof TopicDict))
      throw Error('addTopic: input is not a topic object.');
  
    this.topics.push(topicDict);
    //add gradeSet in the topic to gradeSet in UnitDict
    topicDict.getGrades().forEach((grade)=>this.grades.add(grade));
    //do the same for standardSet
    topicDict.getStandards().forEach((standard)=>this.standards.add(standard));
    return;
  }

  // getters

  /**
   * Gets the name of the unit.
   * @return {string}
   */
  getName() {
    return this.name;
  }

  /**
   * Gets the array of grades for the unit.
   * @return {Array}
   */
  getGrades(){
    return Array.from(this.grades);
  }

  /**
   * Gets the array of standards for the unit.
   * @return {Array}
   */
  getStandards(){
    return Array.from(this.standards);
  }

  /**
   * Gets the subject for the unit.
   * @return {string}
   */
  getSubject() {
    return this.subject;
  }

  /**
   * Gets the ref for the unit.
   * @return {string}
   */
  getRef() {
    return this.ref;
  }

  /**
   * Gets the id for the unit.
   * @return {string}
   */
  getId() {
    return this.id;
  }

  /**
   * Gets the src of the image for the unit.
   */
  getSrc() {
    return this.src;
  }

  /**
   * Sets the src of the image for the unit.
   * @param {string} src 
   */
  setSrc(src) {
    this.src = src;
  }

  /**
   * Filters the topics by given grades and standards. Returns an array of topics.
   * @param {Array} grades
   * @param {Array} standards
   * @return {Array}
   */
  getTopics(grades=[], standards=[]) {
    // if grades and standards are empty, return unfiltered. Otherwise, filter based on inputs
    if(grades.length===0 && standards.length===0) {
      return this.topics;
    }
    var filteredTopics = [];

    this.topics.forEach((TopicDictObj)=>{
      const tempTopicDict = TopicDictObj.getFiltered(grades, standards);
      var count = 0;
      for(var key in tempTopicDict.subtopics){
        count += tempTopicDict.subtopics[key].length;
      }
      if(count > 0){
        filteredTopics.push(TopicDictObj.getFiltered(grades, standards));
      }
    });
    return filteredTopics;
  }

  static unitConverter = {
    fromFirestore: (snapshot, options) => {
      const data = snapshot.data(options);
      
      return new UnitDict(
        data.title,
        snapshot.ref.id,
        data.firebaseImgName,
        data.imgName,
        data.subtitle,
        data.subject,
        data.description,
        snapshot.ref,
      )
    }
  }
}

export default UnitDict