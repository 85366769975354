import React, { Component } from 'react';
import styled from 'styled-components';
import { withFirebase } from '../../Firebase';
// eslint-disable-next-line no-unused-vars
import { BrowserRouter as Router, Route } from 'react-router-dom';
import * as ROUTES from '../../../constants/routes';
import Loading from '../../UI-Library/Misc/Loading';

import BrowseUnits from './BrowseUnits';
import SearchBar from './SearchBar';
import BrowseSubjects from './BrowseSubjects';

import BrowseSearch from './BrowseSearch';
import SimpleBanner from '../../UI-Library/Containers/SimpleBanner';
import BackArrow from '../../UI-Library/ButtonsOrLinks/BackArrow';

const PageWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const MainWrapper = styled.div`
  height: 100%;
  width: 100%;
  padding-top: 0;
  padding-left: 5%;
  padding-right: 5%;
`;

/**
 * Browse is the component for the "Browse" page in Internal.
 */
export class BrowseBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      error: null,
      result: null,
    };
  }

  async componentDidMount() {
    try {
      const queryResult = await this.props.firebase.getBrowse();
      this.setState({ ready: true, result: queryResult });
    } catch (err) {
      this.setState({ error: err });
    }
  }

  render() {
    if (this.state.error) {
      throw this.state.error;
    }
    if (!this.state.ready) {
      return <Loading />;
    }
    return (
      <PageWrapper>
        <SimpleBanner lead={'Browse'} color={'var(--rsBlue)'}>
          <BackArrow />
          <SearchBar />
        </SimpleBanner>
        <MainWrapper>
          <Route
            exact
            path={ROUTES.BROWSE_SUBJECTS}
            render={(props) => <BrowseSubjects data={this.state.result} />}
          />
          <Route
            exact
            path={ROUTES.BROWSE_UNITS + '/:subject'}
            render={(props) => <BrowseUnits data={this.state.result} />}
          />
          <Route
            exact
            path={ROUTES.BROWSE_SEARCH + '/:query'}
            render={(props) => <BrowseSearch />}
          />
        </MainWrapper>
      </PageWrapper>
    );
  }
}

const Browse = withFirebase(BrowseBase);
export default Browse;
