import React, { Component } from 'react';
import styled from 'styled-components';

import * as ROUTES from '../../../../constants/routes';
import { withRouter } from 'react-router-dom';

import RsButton from '../../../UI-Library/ButtonsOrLinks/RsButton';
import RsInput from '../../../UI-Library/Forms/RsInput';

const Search = styled.div`
  width: 100%;
  display: flex;
`;

/**
 * Returns the JSX that displays the search bar.
 * @param {string} query
 */
export class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = { searchQuery: '' };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.runSearch = this.runSearch.bind(this);
  }

  handleKeyPress(e) {
    if (e.which === 13 && this.state.searchQuery.trim() !== '') {
      this.runSearch(this.state.searchQuery);
    }
  }

  handleInputChange(e) {
    this.setState({ searchQuery: e.target.value });
  }

  runSearch() {
    this.props.history.push(
      ROUTES.BROWSE_SEARCH + '/' + this.state.searchQuery
    );
  }

  render() {
    return (
      <Search>
        <RsInput
          value={this.state.searchQuery}
          onChange={this.handleInputChange}
          handleKeyPress={this.handleKeyPress}
          width={'60%'}
        />
        <RsButton
          ml={'15px'}
          onClick={this.runSearch}
          disabled={this.state.searchQuery.trim() === ''}
        >
          Search
        </RsButton>
      </Search>
    );
  }
}

export default withRouter(SearchBar);
