import React, { Component } from 'react';
// eslint-disable-next-line no-unused-vars
import { BrowserRouter as Router, Route } from 'react-router-dom';
import * as ROUTES from '../../../constants/routes';
import styled from 'styled-components';
import { withFirebase } from '../../Firebase';
import { withAlert } from 'react-alert';

import SongBanner from './SongBanner';

import Karaoke from './Karaoke';
import Review from './Review';
import Blanks from './Blanks';
import Genius from './Genius';

import Loading from '../../UI-Library/Misc/Loading';
import { subjectColors } from '../../../constants/colors';

import { isChrome, isFirefox } from 'react-device-detect';

const MainWrapper = styled.div`
  height: auto;
`;

const FullscreenBackground = styled.img`
  position: fixed;
  left: 0;
  right: 0;
  z-index: -999;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  /* Handling blurring across browsers */
  -webkit-filter: blur(50px);
  -moz-filter: blur(50px);
  -o-filter: blur(50px);
  -ms-filter: blur(50px);
  filter: blur(50px);
  z-index: -99;
`;

/**
 * SongView is the component for the entire SongView page.
 */
class SongViewBase extends Component {
  constructor(props) {
    super(props);

    this.playOrPause = this.playOrPause.bind(this);
    this.pause = this.pause.bind(this);
    this.setTime = this.setTime.bind(this);
    this.playback = this.playback.bind(this);
    this.setVolume = this.setVolume.bind(this);
    this.reset = this.reset.bind(this);
    this.play = this.play.bind(this);

    this.completeFirstBreakdown = this.completeFirstBreakdown.bind(this);
    this.completeFirstBlanks = this.completeFirstBlanks.bind(this);
    this.completeFirstReview = this.completeFirstReview.bind(this);
    this.completeFirstKaraoke = this.completeFirstKaraoke.bind(this);
    this.clickFullScreen = this.clickFullScreen.bind(this);
    this.selectVariation = this.selectVariation.bind(this);

    this.updateCurrSetId = this.updateCurrSetId.bind(this);
    this.logAnalyticsShare = this.logAnalyticsShare.bind(this);
    this.copyLink = this.copyLink.bind(this);

    this.state = {
      id: null,
      src: null,
      time: null,
      speed: null,
      volume: null,
      counter: 0,
      duration: 0,
      newTitle: '',
      unit: { name: '', unitRef: '' },
      subject: { name: '', subjectRef: '' },
      topic: { name: '', topicRef: '' },
      ended: false,
      ready: false,
      inLib: null,
      error: null,
      fullscreen: false,
      audioLoaded: false,
      firstBreakdown: false,
      firstBlanks: false,
      firstReview: false,
      firstKaraoke: false,
      origArtist: null,
      hasClickedPlay: false,
      completedFeedback: true,
      currSetId: null,
      currFeature: '',
      timeTracker: 0, //milliseconds
    };

    this.audio = React.createRef();
    this.myRef = React.createRef();
    this.nextSongId = null;
  }

  async fetchData(id) {
    try {
      const song = this.props.firebase.getSong(id);
      const json = this.props.firebase.getJson(id);
      var res = await Promise.all([song, json]);

      const subtopicRef = res[0].data().subtopic.subtopicRef;
      const subtopicPr = subtopicRef.get();
      const mp3 = this.props.firebase.getMp3(id);
      const img = this.props.firebase.getSongCover(subtopicRef.id);
      const userData = this.props.userData;

      var resStorage = await Promise.all([mp3, img, subtopicPr]);
      const subtopicDoc = resStorage[2].data();

      var state = {
        ...{
          play: false,
          ended: false,
          duration: 0,
          audioLoaded: false,
          id: id,
          src: resStorage[0],
          time: 0,
          speed: 1.0,
          volume: 1.0,
          img: resStorage[1],
          ready: true,
          firstBreakdown: userData.firstBreakdown,
          firstBlanks: userData.firstBlanks,
          firstReview: userData.firstReview,
          firstKaraoke: userData.firstKaraoke,
          backgroundColor: subjectColors[res[0].data().subject.name],
          json: res[1],
          songArr: subtopicDoc.songArr,
        },
        // we can safely do this because if this .data() was not available the error would have
        // been caught in firebase.js.
        // ? do we need another check though? what if there is a discrepancy in data between user song doc and song doc?

        ...res[0].data(),
      };
      this.setState(state, () => {
        if (this.audio.current) {
          this.audio.current.pause();
          this.audio.current.src = this.state.src;
          this.audio.current.load();
        }
      });

      // TODO SongView: Abstract into helper
      [
        'fullscreenchange',
        'mozfullscreenchange',
        'webkitfullscreenchange',
      ].forEach((evt) =>
        this.myRef.current.addEventListener(evt, () => {
          if (!document.fullscreenElement) {
            this.setState({ fullscreen: false });
          } else {
            this.setState({ fullscreen: true });
          }
        })
      );
    } catch (err) {
      this.setState({ error: err });
    }
  }

  async componentDidMount() {
    try {
      const urlParams = new URLSearchParams(this.props.location.search);
      const songId = urlParams.get('songId');

      await this.fetchData(songId);

      this.audio.current.addEventListener('canplay', (event) => {
        this.setState({
          audioLoaded: true,
          duration: this.audio.current.duration,
        });
      });

      this.audio.current.addEventListener('ended', () => {
        clearInterval(this.updateTime);
        if (this.state.fullscreen) {
          this.clickFullScreen();
        }
        this.setState({ play: false, ended: true });
      });

      this.props.firebase.analytics.logEvent('new song loaded', {
        songId: songId,
        from_variation_dropdown: false,
      });
    } catch (err) {
      this.setState({ error: err });
    }
  }

  componentWillUnmount() {
    try {
      //Log in event 'karaoke_played' [Firebase Analytics]
      this.props.firebase.analytics.logEvent('karaoke_played', {
        time: this.state.timeTracker / 1000,
        song_id: this.state.id,
      });

      this.audio.current.removeEventListener('ended', () =>
        this.setState({ play: false })
      );
      this.audio.current.removeEventListener('canplay', () => {
        return;
      });
      clearInterval(this.updateTime);
    } catch (err) {
      console.log('ERROR ON SONGVIEW UNMOUNT: ', err);
    }
  }

  async selectVariation(id) {
    try {
      this.props.history.push(ROUTES.KARAOKE + '?songId=' + id);
      this.pause();
      this.setState({ ready: false }, async () => {
        await this.fetchData(id);
        this.props.firebase.analytics.logEvent('new song loaded', {
          songId: id,
          from_variation_dropdown: true,
        });
      });
    } catch (err) {
      this.setState({ error: err });
    }
  }

  /**
   * Plays the audio and updates state.
   */
  play() {
    this.setState({ counter: this.state.counter + 1 });
    //Log in event 'song_played' [Firebase Analytics]
    if (this.state.counter === 0) {
      this.props.firebase.analytics.logEvent('song_played', {
        song_id: this.state.id,
        feature: this.state.currFeature,
      });
    }

    this.updateTime = setInterval(() => {
      if (!this.audio.current) {
        console.log('audio short circuit');
        clearInterval(this.updateTime);
        this.setState({ time: null, play: false });
        return;
      }
      this.setState({ time: this.audio.current.currentTime, play: true });
      if (this.state.currFeature === 'Karaoke') {
        this.setState({ timeTracker: this.state.timeTracker + 75 });
      }
    }, 75);
    this.audio.current.play();
    this.setState({ time: this.audio.current.currentTime, play: true });
  }

  /**
   * Pauses the audio and updates state.
   */
  pause() {
    clearInterval(this.updateTime);
    this.setState({ play: false });
    this.audio.current.pause();
  }

  /**
   * Play and pause audio toggle.
   */
  playOrPause() {
    if (!this.state.hasClickedPlay) {
      this.props.firebase
        .incrementSongCount(this.state.id)
        .then((completedFeedback) => {
          this.setState({ completedFeedback: completedFeedback });
        })
        .catch((err) => {
          this.setState({ error: err });
        });
      this.setState({ hasClickedPlay: true });
    }
    if (this.state.play) {
      this.pause();
    } else {
      this.play();
    }
  }

  playback(speed) {
    this.audio.current.playbackRate = speed;
  }

  setVolume(val) {
    let sliderVal = val.value;
    this.audio.current.volume = parseFloat(sliderVal / 100);
    this.setState({ volume: sliderVal });
  }

  setTime(time) {
    this.audio.current.currentTime = time;
    this.setState({ time: this.audio.current.currentTime });
  }

  reset() {
    this.audio.current.currentTime = 0;
    this.pause();
    this.setState({
      ended: false,
      time: this.audio.current.currentTime,
    });
  }

  clickFullScreen() {
    if (isChrome || isFirefox) {
      var requestFullscreen = function (ele) {
        if (!document.fullscreenElement) {
          if (ele.requestFullscreen) {
            ele.requestFullscreen();
          } else if (ele.webkitRequestFullscreen) {
            ele.webkitRequestFullscreen();
          } else if (ele.mozRequestFullScreen) {
            ele.mozRequestFullScreen();
          } else if (ele.msRequestFullscreen) {
            ele.msRequestFullscreen();
          } else {
            console.log('Fullscreen API is not supported.');
          }
        } else {
          this.setState({ fullscreen: false });
          document.exitFullscreen();
        }
      }.bind(this);
      const node = this.myRef.current;
      requestFullscreen(node);
    } else {
      alert(
        'Sorry, this browser does not support full screen at the moment. Please use Chrome or Firefox for the best rapStudy experience :))'
      );
      return;
    }
  }

  completeFirstBreakdown() {
    this.props.firebase.completeFirstBreakdown();
    this.setState({
      firstBreakdown: false,
    });
  }

  completeFirstBlanks() {
    this.props.firebase.completeFirstBlanks();
    this.setState({
      firstBlanks: false,
    });
  }

  completeFirstReview() {
    this.props.firebase.completeFirstReview();
    this.setState({
      firstReview: false,
    });
  }

  completeFirstKaraoke() {
    this.props.firebase.completeFirstKaraoke();
    this.setState({
      firstKaraoke: false,
    });
  }

  updateCurrSetId(setId, featureName) {
    this.setState({
      currSetId: setId,
      currFeature: featureName,
    });
  }

  copyLink() {
    navigator.clipboard.writeText(window.location.href);
    this.props.alert.show(
      `Link to ${this.state.currFeature} copied to clipboard.`
    );
  }

  logAnalyticsShare() {
    this.props.firebase.analytics.logEvent('share-to-classroom', {
      contentType: this.state.currFeature,
    });
  }

  render() {
    if (this.state.error) {
      console.log(this.state.error);
      throw this.state.error;
    }

    // TODO SongView: Refactor into single song object instead of all its properties
    // TODO SongView (Secondary) : Form audio "engine" to pass in audio handlers
    return (
      <MainWrapper ref={this.myRef}>
        {this.state.fullscreen && (
          <FullscreenBackground
            fullscreen={this.state.fullscreen}
            src={this.state.img}
          ></FullscreenBackground>
        )}

        <audio id='mainAudio' ref={this.audio} />

        {this.state.ready ? (
          <div style={{ height: '100%' }}>
            <SongBanner
              backgroundColor={this.state.backgroundColor}
              setTime={this.setTime}
              imgSrc={this.state.img}
              playOrPause={this.playOrPause}
              setVolume={this.setVolume}
              play={this.state.play}
              hasClickedPlay={this.state.hasClickedPlay}
              pause={this.state.pause}
              volume={this.state.volume}
              genre={this.state.genre}
              newTitle={this.state.newTitle}
              origTitle={this.state.origTitle}
              origArtist={this.state.origArtist}
              subject={this.state.subject}
              unit={this.state.unit}
              topic={this.state.topic}
              currentTime={this.state.time}
              duration={this.state.duration}
              id={this.state.id}
              playback={this.playback}
              inLib={this.state.inLib}
              fullscreen={this.state.fullscreen}
              audioLoaded={this.state.audioLoaded}
              songArr={this.state.songArr}
              userToken={this.props.userToken}
              selectVariation={this.selectVariation}
              currSetId={this.state.currSetId}
              currFeature={this.state.currFeature}
              copyLink={this.copyLink}
              logAnalyticsShare={this.logAnalyticsShare}
            />
            <Route
              exact
              path={ROUTES.KARAOKE}
              render={(props) => (
                <Karaoke
                  {...props}
                  currentTime={this.state.time}
                  id={this.state.id}
                  play={this.state.play}
                  ended={this.state.ended}
                  reset={this.reset}
                  clickFullScreen={this.clickFullScreen}
                  fullscreen={this.state.fullscreen}
                  firstKaraoke={this.state.firstKaraoke}
                  completeFirstKaraoke={this.completeFirstKaraoke}
                  json={this.state.json}
                  completedFeedback={this.state.completedFeedback}
                  updateCurrSetId={this.updateCurrSetId}
                  userToken={this.props.userToken}
                />
              )}
            />
            <Route
              exact
              path={ROUTES.REVIEW}
              render={(props) => (
                <Review
                  {...props}
                  currentTime={this.state.time}
                  setTime={this.setTime}
                  id={this.state.id}
                  play={this.play}
                  ended={this.state.ended}
                  reset={this.reset}
                  duration={this.state.duration}
                  pause={this.pause}
                  playing={this.state.play}
                  nextSongId={this.nextSongId}
                  unit={this.state.unit}
                  newTitle={this.state.newTitle}
                  topic={this.state.topic}
                  clickFullScreen={this.clickFullScreen}
                  fullscreen={this.state.fullscreen}
                  firstReview={this.state.firstReview}
                  completeFirstReview={this.completeFirstReview}
                  json={this.state.json}
                  updateCurrSetId={this.updateCurrSetId}
                />
              )}
            />
            <Route
              exact
              path={ROUTES.BLANKS}
              render={(props) => (
                <Blanks
                  {...props}
                  currentTime={this.state.time}
                  id={this.state.id}
                  playOrPause={this.playOrPause}
                  pause={this.pause}
                  play={this.state.play}
                  ended={this.state.ended}
                  reset={this.reset}
                  newTitle={this.state.newTitle}
                  topic={this.state.topic}
                  clickFullScreen={this.clickFullScreen}
                  fullscreen={this.state.fullscreen}
                  firstBlanks={this.state.firstBlanks}
                  completeFirstBlanks={this.completeFirstBlanks}
                  json={this.state.json}
                  updateCurrSetId={this.updateCurrSetId}
                />
              )}
            />
            <Route
              exact
              path={ROUTES.GENIUS}
              render={(props) => (
                <Genius
                  {...props}
                  currentTime={this.state.time}
                  id={this.state.id}
                  reset={this.reset}
                  newTitle={this.state.newTitle}
                  topic={this.state.topic}
                  clickFullScreen={this.clickFullScreen}
                  fullscreen={this.state.fullscreen}
                  firstBreakdown={this.state.firstBreakdown}
                  completeFirstBreakdown={this.completeFirstBreakdown}
                  json={this.state.json}
                  setTime={this.setTime}
                  updateCurrSetId={this.updateCurrSetId}
                />
              )}
            />
          </div>
        ) : (
          <Loading />
        )}
      </MainWrapper>
    );
  }
}

const SongView = withAlert()(withFirebase(SongViewBase));
export default SongView;
