import React, { Component } from 'react';
import styled from 'styled-components';
import ExerciseThumbnail from '../../../../UI-Library/ItemDisplays/ExerciseThumbnail';
import { subjectColors } from '../../../../../constants/colors';
import * as ROUTES from '../../../../../constants/routes';

import { withRouter } from 'react-router-dom';
import Trash from '../../../../UI-Library/Icons/Trash';

const MainWrapper = styled.div`
  margin-bottom: 40px;
`;

class SetShare extends Component {
  renderCards() {
    const thumbnails = [];
    this.props.sets.forEach((song, index) => {
      const setsObj = song.getAllSets();
      const songData = song.getSongData();
      const setsArr = Object.values(setsObj);
      setsArr.forEach((set, index) => {
        const featureName = set.getFeatureName();
        const queryString = `?songId=${songData.id}&setId=${set.getSetId()}`;
        const linkTo =
          featureName === 'Breakdown'
            ? `${ROUTES.GENIUS}${queryString}`
            : featureName === 'Blanks'
            ? `${ROUTES.BLANKS}${queryString}`
            : `${ROUTES.REVIEW}${queryString}`;

        thumbnails.push(
          <div
            key={set.getSetId()}
            style={{ marginRight: '20px', marginBottom: '20px' }}
          >
            <ExerciseThumbnail
              src={songData.src}
              featureName={featureName}
              songName={songData.subtopic.name}
              songId={songData.id}
              setId={set.getSetId()}
              setName={set.getName()}
              backgroundColor={subjectColors[songData.subject.name]}
              clickable={true}
              onClick={() => this.props.history.push(linkTo)}
              active={false}
            >
              {this.props.isAdmin && (
                <Trash
                  size='24px'
                  onClick={() =>
                    this.props.remove(featureName, songData.id, set.getSetId())
                  }
                />
              )}
            </ExerciseThumbnail>
          </div>
        );
      });
    });
    return thumbnails;
  }

  render() {
    const emptyAndAdmin = this.props.sets.length === 0 && this.props.isAdmin;

    if (emptyAndAdmin) {
      return (
        <MainWrapper>
          <h2 className='bold secondary'>Shared Study Sets</h2>
          <p className='secondary'>
            To quickly add multiple study sets, go to the{' '}
            <span className='major'>Your Study Sets</span> page and click on the{' '}
            <img
              src={'/images/icons/utility/dropdowndots.svg'}
              style={{ width: '18px', height: '18px' }}
              alt=' dots '
            />{' '}
            icon next to the ones you want to add.
          </p>
        </MainWrapper>
      );
    }

    return (
      <MainWrapper>
        <h2 className='bold'>Shared Sets</h2>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {this.props.sets && this.props.sets.length > 0 && this.renderCards()}
        </div>
      </MainWrapper>
    );
  }
}

export default withRouter(SetShare);
