import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ThumbnailCarousel from './ThumbnailCarousel';
import Caret from '../../../UI-Library/Icons/Caret';

const MainWrapper = styled.div`
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
`;

const ScrollButton = styled.div`
  margin-left: 15px;
  cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
`;

class ThumbnailScroll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currSlide: 0,
      numSlides: 0,
    };
    this.scrollLeft = this.scrollLeft.bind(this);
    this.scrollRight = this.scrollRight.bind(this);
    this.onResize = this.onResize.bind(this);
    this.overrideSlideNum = this.overrideSlideNum.bind(this);
  }

  scrollLeft() {
    if (this.state.currSlide === 0) return;
    const currSlide = this.state.currSlide;
    this.setState({
      currSlide: currSlide - 1,
    });
  }

  scrollRight() {
    if (this.state.currSlide + 1 >= this.state.numSlides) return;
    const currSlide = this.state.currSlide;
    this.setState({
      currSlide: currSlide + 1,
    });
  }

  onResize(numSlides) {
    this.setState({
      numSlides: numSlides,
    });
  }

  overrideSlideNum(slideNum) {
    this.setState({
      currSlide: slideNum,
    });
  }

  render() {
    const clickableLeft = this.state.currSlide !== 0;
    const clickableRight =
      this.state.numSlides !== 0 &&
      this.state.currSlide !== this.state.numSlides - 1;

    return (
      <MainWrapper>
        <Header>
          <div></div>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ScrollButton clickable={clickableLeft} onClick={this.scrollLeft}>
              <Caret
                disabled={!clickableLeft}
                direction='left'
                onClick={this.scrollLeft}
              />
            </ScrollButton>
            <ScrollButton clickable={clickableRight} onClick={this.scrollRight}>
              <Caret
                disabled={!clickableRight}
                direction='right'
                onClick={this.scrollRight}
              />
            </ScrollButton>
          </div>
        </Header>

        <ThumbnailCarousel
          submissions={this.props.submissions}
          currSlide={this.state.currSlide}
          onResize={this.onResize}
          isDone={this.props.isDone}
          switchExercise={this.props.switchExercise}
          activeExerciseId={this.props.activeExerciseId}
          overrideSlideNum={this.overrideSlideNum}
        />
      </MainWrapper>
    );
  }
}

ThumbnailScroll.propTypes = {
  submissions: PropTypes.array.isRequired,
  isDone: PropTypes.bool,
  switchExercise: PropTypes.func,
  /**
   * The active exercise.
   */
  activeExerciseId: PropTypes.string,
};

export default ThumbnailScroll;
