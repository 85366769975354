import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import PlayPause from './PlayPause';
import Volume from './Volume';
import SpeedDropdown from './SpeedDropdown';
import RsSlider from '../../../UI-Library/Forms/RsSlider';

import { device } from '../../../../constants/breakpoints';
import Skip from '../../Icons/Skip';

const MainWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  ${(props) => (props.maxWidth ? `max-width: ${props.maxWidth};` : '')}
`;

const TimeStamp = styled.span`
  font-size: 0.8em;
  margin-left: 5px;
`;

const ProgressBar = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;

  @media ${device.tablet} {
    display: none;
  }
`;

/**
 * The audio bar includes the play pause button, the progress bar, the volume
 * slider, and the speed dropdown.
 */
class AudioBar extends Component {
  /**
   * Returns a string that represents the number of seconds in MM:SS format.
   * @param {number} time
   * @returns {string}
   */
  secondsToMMSS(time) {
    var minutes = Math.floor(time / 60);
    var seconds = time - minutes * 60;

    seconds = Math.floor(Math.round(seconds * 100) / 100);

    var result = minutes < 10 ? '0' + minutes : minutes;
    result += ':' + (seconds < 10 ? '0' + seconds : seconds);

    return result;
  }

  render() {
    const percentage = (this.props.currentTime / this.props.duration) * 100;

    return (
      <MainWrapper maxWidth={this.props.maxWidth}>
        {this.props.showNavigation && (
          <Skip onClick={this.props.prevSong} direction='left' hover={true} />
        )}
        <PlayPause
          play={this.props.play}
          audioLoaded={this.props.audioLoaded}
          onClick={this.props.playOrPause}
          ml={this.props.showNavigation ? '5px' : '0px'}
          mr={'5px'}
        />
        {this.props.showNavigation && (
          <Skip onClick={this.props.nextSong} direction='right' hover={true} />
        )}
        {this.props.showProgress && (
          <ProgressBar>
            <TimeStamp>{this.secondsToMMSS(this.props.currentTime)}</TimeStamp>
            <RsSlider
              onChange={this.props.handleProgressChange}
              label='progress'
              percentage={percentage}
              ml={'20px'}
            />
            <TimeStamp>{this.secondsToMMSS(this.props.duration)}</TimeStamp>
          </ProgressBar>
        )}
        {this.props.showSpeed && (
          <SpeedDropdown
            changeValue={this.props.changePlayback}
            playback={this.props.playback}
          />
        )}
        {this.props.showVolume && (
          <Volume
            value={this.props.volumeLevel}
            onChange={this.props.handleVolumeChange}
          />
        )}
      </MainWrapper>
    );
  }
}

AudioBar.propTypes = {
  /**
   * Is the audio loaded into the player?
   */
  audioLoaded: PropTypes.bool,
  /**
   * Is the audio currently playing?
   */
  play: PropTypes.bool.isRequired,
  /**
   * The currentTime from the audio controller.
   */
  currentTime: PropTypes.number,
  /**
   * The duration of the audio file.
   */
  duration: PropTypes.number,
  /**
   * The current speed of the audio.
   */
  playback: PropTypes.oneOf(['Normal', 'Slower', 'Slowest']),
  /**
   * The current volume level as a percentage. A number from 0
   * to 100.
   */
  volumeLevel: PropTypes.number,
  /**
   * The function to play and pause the audio.
   */
  playOrPause: PropTypes.func.isRequired,
  /**
   * The function fired when the progress bar is moved.
   */
  handleProgressChange: PropTypes.func,
  /**
   * The function fired when the volume bar is moved.
   */
  handleVolumeChange: PropTypes.func,
  /**
   * The function to change the speed of the audio. Must take two parameters,
   * the string of the speed, and the playback number.
   */
  changePlayback: PropTypes.func,
  /** Show the progress bar? */
  showProgress: PropTypes.bool,
  /** Show the speed dropdown? */
  showSpeed: PropTypes.bool,
  /** Show the volume bar? */
  showVolume: PropTypes.bool,
  /** Show next and previous buttons? */
  showNavigation: PropTypes.bool,
  /**
   * The function to move to the previous song.
   */
  prevSong: PropTypes.func,
  /**
   * The function to move to the next song.
   */
  nextSong: PropTypes.func,
  /**
   * The max width of the slider. Defaults to none.
   */
  maxWidth: PropTypes.string,
};

export default AudioBar;
