/**
 * Higher order function that takes in the route of the feature and returns the
 * correct selectSet function
 *
 * Takes in custom keyword setId, change the URL query string to reflect the new
 * setId, and set state for setId and get relevant resources.
 * @param {string} route
 */
export const selectSet = function (route) {
  return async function (setId) {
    try {
      this.setState({ ready: false });
      // Implement here
      this.props.history.push(
        route + '?songId=' + this.props.id + '&setId=' + setId
      );
      await this.getResources(setId);
    } catch (err) {
      this.setState({ error: err });
    }
  };
};

/**
 * Higher order function that takes in the route of the feature and the required
 * firebase function for creating the set for this feature and returns the
 * correct createSet function.
 *
 * Takes in name of new set and songId for current song and creates a new custom
 * set and handles the necesssary state changes.
 */
export const createSet = function (route, firebaseFunc) {
  return async function (name, songId) {
    try {
      if (!name || name.trim() === '') {
        return;
      }
      this.setState({ ready: false });
      // Implement here
      const setId = await firebaseFunc(this.props.id, name);
      this.props.history.replace(
        route + '?songId=' + this.props.id + '&setId=' + setId
      );
      await this.getResources(setId);
      this.setState({ edit: true });
      if (this.state.noSet) {
        this.setState({ noSet: false });
      }
    } catch (err) {
      this.setState({ error: err });
    }
  };
};

/**
 * Higher order function that takes in the route of the feature and the required
 * firebase function for deleting the set for this feature and returns the
 * correct deleteSet function.
 *
 * Takes in the setId, deletes this set and handles the necesssary state changes.
 */
export const deleteSet = function (route, firebaseFunc) {
  return async function (setId) {
    try {
      this.setState({ ready: false });
      // this.props.history.push(route + '?songId=' + this.props.id);
      await firebaseFunc(this.props.id, setId);
      if (this.props.alert) {
        this.props.alert.show('Set succesfully deleted.');
      }
      await this.getResources('null');
      // if (this.state.edit) {
      //   this.setState({ edit: false });
      // }
    } catch (err) {
      this.setState({ error: err });
    }
  };
};

export const findSetInArray = (arr, propName, setId) => {
  var result = arr.find((obj) => {
    return obj[propName] === setId;
  });
  return result;
};
