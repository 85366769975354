import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const TeacherAssignmentRoute = ({
  component: Component,
  path,
  redirectPath,
  isAdmin,
  ...rest
}) => (
  <Route
    {...rest}
    path={path}
    render={(props) => {
      if (isAdmin) {
        return <Component {...props} {...rest} />;
      } else {
        return <Redirect to={redirectPath} />;
      }
    }}
  />
);

export default TeacherAssignmentRoute;
