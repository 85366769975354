import CustomSetDict from '../../Classes/CustomSetDict';
import Song from '../../Classes/Song';
import StudySet from '../../Classes/StudySet';

/**
 * Returns a Promise that resolves with a custom set dict object.
 * @returns {Promise<CustomSetDict>}
 */
export async function getCustomSongs(
  collections = ['questionSets', 'annotationSets', 'keywordSets']
) {
  const converters = {
    questionSets: StudySet.reviewConverter,
    annotationSets: StudySet.breakdownConverter,
    keywordSets: StudySet.blanksConverter,
  };
  const uid = this.auth.currentUser.uid;
  const queries = await Promise.all(
    collections.map(
      async (collectionName) =>
        await this.db
          .collection(collectionName)
          .where('author', '==', uid)
          .orderBy('songId')
          .withConverter(converters[collectionName])
          .get()
    )
  );
  const res = new CustomSetDict();
  collections.forEach((colName, index) => {
    const querySnap = queries[index];

    if (colName === 'questionSets') {
      res.addReviewSets(querySnap);
    }
    if (colName === 'annotationSets') {
      res.addBreakdownSets(querySnap);
    }
    if (colName === 'keywordSets') {
      res.addBlanksSets(querySnap);
    }
  });

  const songIds = res.getSongIds();
  const songs = await Promise.all(
    songIds.map(async (songId) => {
      return await this.db
        .collection('songs')
        .doc(songId)
        .withConverter(Song.songConverter)
        .get();
    })
  );
  res.setSongDatas(songs);

  return res;
}
