import React, { Component } from 'react';
import styled from 'styled-components';

import Spinner from 'react-bootstrap/Spinner';

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding-top: 15%;
`;

/**
 * The standard loading spinner based on the bootstrap component.
 * Aligns itself in the center of the parent component with a slight
 * top padding.
 */
class Loading extends Component {
  render() {
    return (
      <MainWrapper>
        <Spinner animation='border' role='status'></Spinner>
      </MainWrapper>
    );
  }
}

export default Loading;
