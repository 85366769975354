import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { withFirebase } from '../../Firebase';
import { withAlert } from 'react-alert';

import PlaylistBanner from './PlaylistBanner';
import PlaylistContent from './PlaylistContent';
import Loading from '../../UI-Library/Misc/Loading';
import AddToPlaylistModal from '../../UI-Library/Modals/AddToPlaylistModal';
import ShareToClassModal from '../../UI-Library/Modals/ShareToClassModal';

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

/**
 * PlaylistView is the component for the entire Playlist.  Should be implemented
 * to Figma design found here: https://www.figma.com/file/yEqp78EvktVUTG9Pw9J211/rapstudy?node-id=782%3A0
 */
export class PlaylistViewBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      songs: null,
      recentSongs: null,
      orgRecent: null,
      date: null,
      arrow: null,
      playlistNames: null,
      color: null,
      ready: false,
      showClassShare: false,
      shareContentType: 'Playlist',
      shareContentId: this.props.id,
      error: null,
      author: null,
      isAuthor: false,
      name: null,
      src: null,
      description: null,
      showAddToPlaylist: false,
      selectedSong: null,
    };

    this.removeSong = this.removeSong.bind(this);
    this.addSong = this.addSong.bind(this);
    this.delete = this.delete.bind(this);
    this.changeHeader = this.changeHeader.bind(this);
    this.reorderSongs = this.reorderSongs.bind(this);
    this.setClassShareType = this.setClassShareType.bind(this);
    this.toggleClassShare = this.toggleClassShare.bind(this);
    this.onSelectColor = this.onSelectColor.bind(this);
    this.toggleAddToPlaylist = this.toggleAddToPlaylist.bind(this);
    this.copyLink = this.copyLink.bind(this);
    this.logAnalyticsShare = this.logAnalyticsShare.bind(this);
  }

  async addSong(song) {
    try {
      var recents = [];
      for (var i = 0; i < this.state.orgRecent.length; i++) {
        var exists = false;
        for (var j = 0; j < this.state.songs.length; j++) {
          if (this.state.orgRecent[i].id === this.state.songs[j].id) {
            exists = true;
            break;
          }
        }
        if (!exists) {
          recents.push(this.state.orgRecent[i]);
        }
      }

      await this.props.firebase.addPlaylistSong(this.state.id, song.id);
      var added = [];
      exists = false;
      for (var k = 0; k < this.state.songs.length; k++) {
        if (this.state.songs[k].id === song.id) {
          exists = true;
        }
        added.push(this.state.songs[k]);
      }

      if (!exists) {
        added.push(song);
        recents = recents.filter((value, index, arr) => {
          return value.id !== song.id;
        });
      }

      this.setState({
        songs: added,
        recentSongs: recents,
      });
    } catch (err) {
      this.setState({ error: err });
    }
  }

  async removeSong(song) {
    try {
      if (this.state.orgRecent) {
        var recents = [];

        for (var i = 0; i < this.state.orgRecent.length; i++) {
          var exists = false;
          for (var j = 0; j < this.state.songs.length; j++) {
            if (this.state.orgRecent[i].id === this.state.songs[j].id) {
              exists = true;
              break;
            }
          }
          if (!exists) {
            recents.push(this.state.orgRecent[i]);
          }
        }

        exists = false;
        for (var x = 0; x < this.state.orgRecent.length; x++) {
          if (this.state.orgRecent[x].id === song.id) {
            exists = true;
          }
        }

        if (exists) {
          recents.push(song);
        }
      }

      var filtered = this.state.songs.filter((value, index, arr) => {
        return value.id !== song.id;
      });
      await this.props.firebase.removePlaylistSong(this.state.id, song.id);

      this.setState({
        songs: filtered,
        recentSongs: recents,
      });
    } catch (err) {
      this.setState({ error: err });
    }
  }

  async delete() {
    try {
      this.props.history.push('/internal/dashboard');

      await this.props.firebase.removePlaylist(this.state.id);
      if (this.props.alert) {
        this.props.alert.show('Succesfully deleted playlist.');
      }
      return;
    } catch (err) {
      this.setState({ error: err });
    }
  }

  async changeHeader(name, description) {
    try {
      // Implement. Change the name and description of the playlist and change state.
      await this.props.firebase.editPlaylist(
        this.state.id,
        name,
        description,
        this.state.color
      );

      this.setState({
        name: name,
        description: description,
      });
    } catch (err) {
      this.setState({ error: err });
    }
  }

  async getPlaylistInfo(id) {
    try {
      // Get Playlist info
      const playlist = await this.props.firebase.getPlaylist(id);
      if (!playlist) return;
      const playlistData = playlist.data();
      const isAuthor =
        playlistData.author === this.props.userToken.claims.user_id;
      const authorProfile = await this.props.firebase.getUserProfile(
        playlistData.author
      );

      this.setState({
        name: playlistData.name,
        description: playlistData.description,
        date: playlistData.created.toDate().toDateString().substring(4),
        author: playlistData.author,
        isAuthor: isAuthor,
        authorName: authorProfile.first_name + ' ' + authorProfile.last_name,
        src: playlistData.firebaseImg ? playlistData.firebaseImg : null,
        color: playlistData.color ? playlistData.color : '#37dbf6', // defaults to rS blue if undefined.
      });

      // Get Playlist songs
      const songArr = await this.props.firebase.getPlaylistSongs(playlist);
      this.setState({
        songs: songArr,
      });
      return isAuthor;
    } catch (err) {
      this.setState({ error: err });
    }
  }

  async getRecentSongs() {
    try {
      const recentSongs = await this.props.firebase.getRecentSongs(5);
      this.setState(
        {
          recentSongs: recentSongs,
          orgRecent: recentSongs,
        },
        () => {
          return;
        }
      );
    } catch (err) {
      this.setState({ error: err });
    }
  }

  /**
   * Reorders the song array state and calls firebase function updatePlaylistSongsOrder
   * to update database.
   */
  async reorderSongs(startIndex, endIndex) {
    try {
      const result = this.state.songs;
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      this.setState({ songs: result });
      await this.props.firebase.updatePlaylistSongsOrder(this.state.id, result);
    } catch (err) {
      this.setState({ error: err });
    }
  }

  setClassShareType(type, id) {
    this.setState({
      shareContentType: type,
      shareContentId: id,
    });
  }

  /**
   * Toggles the share to class component.
   */
  toggleClassShare() {
    this.setState({
      showClassShare: !this.state.showClassShare,
    });
  }

  async onSelectColor(hex) {
    if (!hex || !(typeof hex === 'string')) {
      throw Error('Invalidated Precondition: Hex is not a string.');
    }
    this.setState({ color: hex });
    await this.props.firebase.editPlaylist(
      this.state.id,
      this.state.name,
      this.state.description,
      hex
    );
  }

  async componentDidMount() {
    try {
      if (!this.props.match) return;
      const params = this.props.match.params;
      this.setState({ id: params.playlistId });
      await this.fetchData(params.playlistId);
      this.setState({ ready: true });
    } catch (err) {
      this.setState({ error: err });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    try {
      const params = this.props.match.params;
      if (!this.props.match) return;
      if (params.playlistId !== prevProps.match.params.playlistId) {
        this.setState({ ready: false, id: params.playlistId });
        await this.fetchData(params.playlistId);
        this.setState({ ready: true });
      }
    } catch (err) {
      this.setState({ error: err });
    }
  }

  async fetchData(id) {
    try {
      const isAuthor = await this.getPlaylistInfo(id);
      if (isAuthor) await this.getRecentSongs();
      return;
    } catch (err) {
      this.setState({ error: err });
    }
  }

  toggleAddToPlaylist(id) {
    this.setState({
      selectedSong: id,
      showAddToPlaylist: !this.state.showAddToPlaylist,
    });
  }

  copyLink() {
    navigator.clipboard.writeText(
      `${window.location.origin}/internal/playlistView/${this.state.id}`
    );
    this.props.alert.show('Link to playlist copied to clipboard.');
  }

  logAnalyticsShare() {
    this.props.firebase.analytics.logEvent('share-to-classroom', {
      contentType: 'playlist',
    });
  }

  render() {
    if (this.state.error) {
      console.log(this.state.error);
      throw this.state.error;
    }
    if (!this.state.ready) return <Loading />;
    const isTeacher = this.props.userToken.claims.teacher;
    return (
      <MainWrapper>
        <PlaylistBanner
          userToken={this.props.userToken}
          id={this.state.id}
          name={this.state.name}
          desc={this.state.description}
          changeHeader={this.changeHeader}
          delete={this.delete}
          date={this.state.date}
          color={this.state.color}
          author={this.state.author}
          isAuthor={this.state.isAuthor}
          authorName={this.state.authorName}
          src={this.state.src}
          onSelectColor={this.onSelectColor}
          toggleClassShare={this.toggleClassShare}
          setClassShareType={this.setClassShareType}
          logAnalyticsShare={this.logAnalyticsShare}
          copyLink={this.copyLink}
        />
        <PlaylistContent
          userToken={this.props.userToken}
          songs={this.state.songs}
          removeSong={this.removeSong}
          recentSongs={this.state.recentSongs}
          orgRecent={this.state.recentSongs}
          addSong={this.addSong}
          playlistNames={this.state.playlistNames}
          reorder={this.reorderSongs}
          isAuthor={this.state.isAuthor}
          toggleAddToPlaylist={this.toggleAddToPlaylist}
          toggleClassShare={this.toggleClassShare}
          setClassShareType={this.setClassShareType}
        />
        <AddToPlaylistModal
          show={this.state.showAddToPlaylist}
          toggle={() => this.toggleAddToPlaylist(this.state.selectedSong)}
          id={this.state.selectedSong}
        />
        {isTeacher && (
          <ShareToClassModal
            show={this.state.showClassShare}
            toggle={this.toggleClassShare}
            contentType={this.state.shareContentType}
            contentId={this.state.shareContentId}
          />
        )}
      </MainWrapper>
    );
  }
}

const PlaylistView = withAlert()(withRouter(withFirebase(PlaylistViewBase)));

export default PlaylistView;
