import React, { Component } from 'react';
// eslint-disable-next-line no-unused-vars
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import styled from 'styled-components';
import VerticalNav from './VerticalNav';
import { isMobile } from 'react-device-detect';
import { withFirebase } from '../Firebase';

import Home from './Home';
import Browse from './Browse';
import ContentManager from './ContentManager';
import SongView from './SongView';
import PlaylistView from './PlaylistView';
import UnitView from './UnitView';
import Settings from './Settings';
import ErrorCatch from '../ErrorCatch';
import NotFound from '../404';
import MobileCatch from '../MobileCatch';
import Classes from './Classes';
import ClassView from './ClassView';

import Loading from '../UI-Library/Misc/Loading';
import AssignmentView from './AssignmentView';
import CreateAssignmentView from './CreateAssignmentView';

const PageWrapper = styled.div`
  height: 100%;
`;

const VerticalNavWrapper = styled.div`
  padding-right: 0;
`;

const ContentWrapper = styled.div`
  padding-left: 0;
  height: 100%;
  margin-left: 225px;
`;

/**
 * Internal is the parent component for the main featured app.
 *
 * It contains the side vertical navigation, as well as the content on the right.
 */
class Internal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      userData: null,
      assignmentsFollowed: null,
      // assignments: null
    };
  }

  componentDidMount() {
    const userToken = this.props.userToken;
    const isTeacher = userToken.claims.teacher;
    this.userListener = this.props.firebase.db
      .collection('users')
      .doc(this.props.firebase.auth.currentUser.uid)
      .onSnapshot(async (docSnap) => {
        if (!docSnap.exists) return;
        const userData = docSnap.data();
        this.setState({ ready: true, userData: userData });
      });
    if (!isTeacher) {
      this.assignmentsFollowedListener = this.props.firebase.db
        .collection('assignmentsFollowed')
        .where('studentId', '==', this.props.firebase.auth.currentUser.uid)
        .where('dueDate', '>=', new Date())
        .onSnapshot((querySnap) => {
          this.setState({
            assignmentsFollowed: querySnap.docs.map((docSnap) =>
              docSnap.data()
            ),
          });
        });
      // this.assignments = this.props.firebaes.db.collection('assignments').where('createdBy', '==', this.props.firebase.auth.currentUser.uid )
    } else {
      this.assignmentsFollowedListener = () => null;
    }
  }

  componentWillUnmount() {
    this.userListener();
    this.assignmentsFollowedListener();
  }

  render() {
    if (!this.state.ready) return <Loading />;

    return isMobile ? (
      <MobileCatch />
    ) : (
      <PageWrapper>
        <VerticalNavWrapper>
          <VerticalNav
            signOut={this.props.signOut}
            userToken={this.props.userToken}
            userData={this.state.userData}
            assignmentsFollowed={this.state.assignmentsFollowed}
          />
        </VerticalNavWrapper>
        <ContentWrapper>
          <Switch>
            <Route
              exact
              path={ROUTES.INTERNAL}
              render={(props) => (
                <ErrorCatch>
                  <Home
                    {...props}
                    userToken={this.props.userToken}
                    userData={this.state.userData}
                  />
                </ErrorCatch>
              )}
            />
            <Route
              exact
              path={ROUTES.DASHBOARD}
              render={(props) => (
                <ErrorCatch>
                  <Home
                    {...props}
                    userToken={this.props.userToken}
                    userData={this.state.userData}
                  />
                </ErrorCatch>
              )}
            />
            <Route
              path={ROUTES.BROWSE}
              render={(props) => (
                <ErrorCatch>
                  <Browse
                    {...props}
                    userToken={this.props.userToken}
                    userData={this.state.userData}
                  />
                </ErrorCatch>
              )}
            />
            <Route
              exact
              path={ROUTES.CONTENTMANAGER}
              render={(props) => (
                <ErrorCatch>
                  <ContentManager
                    {...props}
                    userToken={this.props.userToken}
                    userData={this.state.userData}
                  />
                </ErrorCatch>
              )}
            />
            <Route
              path={ROUTES.SONGVIEW}
              render={(props) => (
                <ErrorCatch>
                  <SongView
                    {...props}
                    userToken={this.props.userToken}
                    userData={this.state.userData}
                  />
                </ErrorCatch>
              )}
            />
            <Route
              path={ROUTES.PLAYLISTVIEW + '/:playlistId'}
              render={(props) => (
                <ErrorCatch>
                  <PlaylistView
                    {...props}
                    userToken={this.props.userToken}
                    userData={this.state.userData}
                  />
                </ErrorCatch>
              )}
            />
            <Route
              path={ROUTES.UNITVIEW + '/:unitId'}
              render={(props) => (
                <ErrorCatch>
                  <UnitView
                    {...props}
                    userToken={this.props.userToken}
                    userData={this.state.userData}
                  />
                </ErrorCatch>
              )}
            />
            <Route
              path={ROUTES.SETTINGS}
              render={(props) => (
                <ErrorCatch>
                  <Settings
                    {...props}
                    userToken={this.props.userToken}
                    userData={this.state.userData}
                  />
                </ErrorCatch>
              )}
            />
            <Route
              path={ROUTES.CREATEASSIGNMENT}
              render={(props) => (
                <ErrorCatch>
                  <CreateAssignmentView
                    {...props}
                    userToken={this.props.userToken}
                    userData={this.state.userData}
                  />
                </ErrorCatch>
              )}
            />
            <Route
              path={ROUTES.ASSIGNMENT}
              render={(props) => (
                <ErrorCatch>
                  <AssignmentView
                    {...props}
                    userToken={this.props.userToken}
                    userData={this.state.userData}
                  />
                </ErrorCatch>
              )}
            />
            <Route
              path={ROUTES.CLASSES}
              render={(props) => (
                <ErrorCatch>
                  <Classes
                    {...props}
                    userToken={this.props.userToken}
                    userData={this.state.userData}
                    assignmentsFollowed={this.state.assignmentsFollowed}
                  />
                </ErrorCatch>
              )}
            />
            <Route
              path={ROUTES.CLASSVIEW + '/:classId'}
              render={(props) => (
                <ErrorCatch>
                  <ClassView
                    {...props}
                    userToken={this.props.userToken}
                    userData={this.state.userData}
                    assignmentsFollowed={this.state.assignmentsFollowed}
                  />
                </ErrorCatch>
              )}
            />

            <Route
              render={(props) => (
                <NotFound
                  {...props}
                  userToken={this.props.userToken}
                  userData={this.state.userData}
                />
              )}
            />
          </Switch>
        </ContentWrapper>
      </PageWrapper>
    );
  }
}

export default withFirebase(Internal);
