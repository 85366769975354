import React, { Component } from 'react';
import Loading from '../UI-Library/Misc/Loading';
import styled from 'styled-components';

import { withFirebase } from '../Firebase';
import { withRouter } from 'react-router-dom';

import OnboardingCarousel from '../UI-Library/Misc/OnboardingCarousel';

import RsButton from '../UI-Library/ButtonsOrLinks/RsButton';
import RsInput from '../UI-Library/Forms/RsInput';

const PageWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 2%;
  padding-right: 2%;
`;

const MainWrapper = styled.div`
  max-width: 600px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const HR = styled.hr`
  border: 1px solid var(--rsSecondary);
`;

class FreeLanding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      expired: false,
      schoolName: '',
      firstVisit: null,
      code: '',
      errMsg: '', //notfound-code, notfound-email
    };
    this.completeOnboarding = this.completeOnboarding.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.verifyByEmail = this.verifyByEmail.bind(this);
    this.verifyByCode = this.verifyByCode.bind(this);
  }

  async componentDidMount() {
    this.userListener = this.props.firebase.db
      .collection('users')
      .doc(this.props.firebase.auth.currentUser.uid)
      .onSnapshot(async (docSnap) => {
        if (!docSnap.exists) return;
        const userData = docSnap.data();
        const expiryDate = userData.subscriptionEnds;
        var expired = false;
        var schoolName = '';
        if (expiryDate && expiryDate !== null) {
          const expiry = expiryDate.toDate();
          const today = new Date();
          expired = today > expiry;
          schoolName = userData.schoolName;
        }
        var result = false;
        if (!userData.firstVisit) {
          result = await this.verifyByEmail();
        }
        if (result) return;
        this.setState({
          ready: true,
          firstVisit: userData.firstVisit,
          expired: expired,
          schoolName: schoolName,
        });
      });
  }

  componentWillUnmount() {
    this.userListener();
  }

  async completeOnboarding(firstName, lastName, grades, subjects) {
    await this.props.firebase.completeOnboarding(
      firstName,
      lastName,
      grades,
      subjects
    );
  }

  async verifyByEmail() {
    const result = await this.props.firebase.emailSubscriberCheck();
    if (result.verified) {
      this.props.changeAuthState(result);
      return true;
    }
    return false;
  }

  async verifyByCode(code) {
    this.setState({ isVerifying: true });
    const result = await this.props.firebase.codeSubscriberCheck(code);
    if (result.verified) {
      this.setState({ isVerifying: false });
      this.props.changeAuthState(result);
      return;
    }
    this.setState({ isVerifying: false });
    this.setState({
      errMsg:
        "Either that code isn't registered, it is expired, or it has already been used.",
    });

    //Log in onboarding event onboarding_code [Firebase Analytics]
    this.props.firebase.analytics.logEvent('onboarding_code');
  }

  handleChange(evt) {
    const value = evt.target.value;
    this.setState({ [evt.target.name]: value });
  }

  render() {
    if (!this.state.ready) return <Loading />;

    if (this.state.firstVisit) {
      return (
        <PageWrapper>
          <MainWrapper>
            <p
              className='secondary bold text-left'
              style={{ marginBottom: 30, width: '100%' }}
            >
              Before we get started, tell us a little bit about yourself.
            </p>
            <OnboardingCarousel completeOnboarding={this.completeOnboarding} />{' '}
          </MainWrapper>
        </PageWrapper>
      );
    }

    return (
      <PageWrapper>
        <MainWrapper>
          <p className='secondary bold' style={{ marginBottom: 30 }}>
            {this.state.expired
              ? 'Oops! Looks like your verification from ' +
                this.state.schoolName +
                ' expired.'
              : 'Last step! Almost there!'}
          </p>

          <div style={{ width: '100%' }}>
            <p>Enter your provided individual verification code.</p>
            <RsInput
              name='code'
              value={this.state.code}
              onChange={this.handleChange}
            ></RsInput>
            <div style={{ width: '100%' }} className='text-right'>
              <RsButton
                mt={'10px'}
                disabled={this.state.isVerifying || !this.state.code}
                onClick={() => this.verifyByCode(this.state.code)}
              >
                Submit
              </RsButton>
            </div>

            <p
              className='text-center'
              style={{ color: 'red', fontSize: '14px', marginTop: '10px' }}
            >
              {this.state.errMsg}
            </p>
          </div>
          <HR />
          <div style={{ width: '100%' }}>
            <p className='secondary'>
              For email registrations, either your verification has expired, or
              we could not find your email it in our system. Please check again
              with your administrator, and for further support, email us at{' '}
              <span style={{ textDecoration: 'underline' }}>
                support@rapstudy.com
              </span>
              .
            </p>
            <div style={{ width: '100%' }} className='text-right'>
              <RsButton onClick={this.props.signOut}>Sign Out</RsButton>
            </div>
          </div>
        </MainWrapper>
      </PageWrapper>
    );
  }
}

export default withRouter(withFirebase(FreeLanding));
