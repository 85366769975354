// https://www.figma.com/file/yEqp78EvktVUTG9Pw9J211/rapStudy-web?node-id=5838%3A186
import React, { Component } from 'react';
import styled from 'styled-components';
import { withFirebase } from '../../../Firebase';
import AddShareButton from './AddShareButton';

import RsButton from '../../ButtonsOrLinks/RsButton';
import RsModal from '../RsModal';

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const ErrorMsg = styled.p`
  height: 20px;
  color: red;
  text-align: center;
  width: 100%;
  font-size: 14px;
`;

const ChoiceWrapper = styled.div`
  overflow-y: auto;
  height: 200px;
  padding: 10px;
  width: 100%;
  border: 1px solid var(--rsSecondary) !important;
  border-radius: 8px !important;
`;

/**
 * Props:
 * show: boolean
 * toggle: function that toggles the show/hide state of the component
 * contentType: string, Playlist / Review / Blanks / Breakdown / Song
 * contentId : string
 */
class AddShareModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      added: false,
    };
    this.add = this.add.bind(this);
  }

  async add(id) {
    const success = await this.props.add(id);
    if (success) this.setState({ added: true });
    return success;
  }

  renderChoice() {
    const itemIdName = this.props.itemIdName;
    const nameOrTitle = this.props.nameOrTitle;
    if (this.props.items.length === 0) {
      return (
        <p className='secondary small'>
          Nothing here yet! Click the create button below to get started.
        </p>
      );
    }
    return this.props.items.map((el, index) => (
      <AddShareButton
        key={index}
        title={el[nameOrTitle]}
        itemId={el[itemIdName]}
        add={this.add}
      />
    ));
  }

  render() {
    const items = this.props.items;
    return (
      <div>
        <RsModal
          show={this.props.show}
          toggle={this.props.toggle}
          title={this.props.title}
        >
          <RsModal.Body>
            {items && items.length > 0 && (
              <p
                style={{ margin: '0 0 5px 0', fontSize: '14px' }}
                class='secondary'
              >
                To add, click on a row below.
              </p>
            )}
            <ChoiceWrapper>
              {this.props.items ? this.renderChoice() : <span>Loading...</span>}
            </ChoiceWrapper>
          </RsModal.Body>
          <RsModal.Footer>
            <ButtonWrapper>
              <RsButton onClick={this.props.toggleNew}>
                {this.props.newPrompt}
              </RsButton>
              <RsButton
                disabled={!this.state.added}
                onClick={this.props.submit}
              >
                Done
              </RsButton>
            </ButtonWrapper>

            <ErrorMsg>{this.props.errorMsg}</ErrorMsg>
          </RsModal.Footer>
        </RsModal>
        {this.props.children}
      </div>
    );
  }
}

export default withFirebase(AddShareModal);
