import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const MainWrapper = styled.div`
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  height: 225px;
  ${(props) =>
    props.src
      ? `
    background: url(${props.src});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right center;
    `
      : `
    background: linear-gradient(
    0deg,
    var(--rsBackground) 0%,
    ${props.backgroundColor} 100%
    );
  `}
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;

const Lead = styled.p`
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 0;
`;

const Secondary = styled.p`
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 10px;
`;

/**
 * A basic banner to go at the top of pages, with the ability to render child
 * elements inside of it and/or a standard title. At this time, the default
 * color is rapStudy blue.
 *
 */
class SimpleBanner extends Component {
  render() {
    return (
      <MainWrapper
        backgroundColor={this.props.color ? this.props.color : 'var(--rsBlue)'}
        src={this.props.src}
      >
        {this.props.lead && <Lead>{this.props.lead}</Lead>}{' '}
        {this.props.sub && (
          <Secondary className='secondary'>{this.props.sub}</Secondary>
        )}
        {this.props.children}
      </MainWrapper>
    );
  }
}

SimpleBanner.propTypes = {
  /**
   * The optional title.
   */
  lead: PropTypes.string,
  /**
   * The optional subtitle.
   */
  sub: PropTypes.string,
  /**
   * The background color. Defaults to rapStudy Blue.
   */
  color: PropTypes.string,
  /**
   * The background image source. If defined, color is not used.
   */
  src: PropTypes.string,
};

export default SimpleBanner;
