import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Caret from '../../Icons/Caret';

const Wrapper = styled.div`
  width: 18px;
  height: 18px;
  position: absolute;
  top: 20px;
  left: 2%;
  &:hover {
    cursor: pointer;
  }
  z-index: 999;
  @media screen and (max-width: 1000px) {
    display: none;
  }
`;

/**
 * A button for backwards navigation. If neither onClick nor to props are defined,
 * the button uses the react router to navigate backwards one layer in the stack.
 * If the window width is less than 1000px, it is hidden.
 *
 * By default, it is positioned 40px from the top and 2% from the left of the
 * container.
 */
class BackArrow extends Component {
  constructor(props) {
    super(props);
    this.redirect = this.redirect.bind(this);
  }

  redirect() {
    if (this.props.onClick) {
      this.props.onClick();
    }
    if (this.props.to) {
      this.props.history.push(this.props.to);
    }
    if (!this.props.onClick && !this.props.to) {
      this.props.history.goBack();
    }
  }

  render() {
    return (
      <Wrapper>
        <Caret direction={'left'} onClick={this.redirect} />
      </Wrapper>
    );
  }
}

BackArrow.propTypes = {
  /**
   * The (optional) click behavior of the arrow button.
   */
  onClick: PropTypes.func,
  /**
   * The (optional) route to redirect to after clicking the button.
   */
  to: PropTypes.string,
};

export default withRouter(BackArrow);
