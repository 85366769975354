import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import ScrollSpan from '../../../AudioOrLyrics/ScrollSpan';

// eslint-disable-next-line no-unused-vars
import * as Scroll from 'react-scroll';
// eslint-disable-next-line no-unused-vars
import { animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import Lrc from '../../../../../Classes/Lrc';
import KeywordDict from '../../../../../Classes/KeywordDict';
import RsButton from '../../../ButtonsOrLinks/RsButton';

const AppWrapper = styled.div`
  height: 60vh;
  margin-top: 15px;
  width: 100%;
  position: relative;

  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */

  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }
`;

const Block = styled.div`
  margin-bottom: 3em;
  text-align: center;
`;

const Line = styled.p`
  margin-bottom: 5px;
`;

const HiddenWord = styled.span`
  border-bottom: 2px solid var(--rsMajor);
  color: transparent;
  font-size: 32px;
  user-select: none;
`;

const Span = styled.span`
  font-size: 32px;
  font-weight: bold;
  color: ${({ isKeyword }) =>
    isKeyword ? 'var(--rsMinor)' : 'var(--rsPrimary)'};
  ${({ edit }) =>
    edit &&
    `
    cursor: pointer;
    `};
`;

const PlayButton = styled.div`
  position: fixed;
  right: 5%;
  bottom: 150px;
`;

const scrollSpanStyle = {
  fontSize: '32px',
  fontWeight: 'bold',
  cursor: 'pointer',
};

class BlanksCenteredLyrics extends Component {
  constructor(props) {
    super(props);
    this.appWrapper = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.play && this.props.currentWord !== null) {
      // manage scrolling.
      const appWrapHeight = this.appWrapper.current.clientHeight;
      const offset = (appWrapHeight / 3) * -1;
      scroller.scrollTo('currentWord', {
        duration: 100,
        delay: 0,
        smooth: true,
        containerId: 'AppWrapper',
        offset: offset,
      });
    }

    if (
      this.props.currentWord !== null &&
      this.props.keywordDict.isKeyword(this.props.currentWord)
    ) {
      const currKeyWord = this.props.keywordDict.getKeyword(
        this.props.currentWord
      );
      if (!currKeyWord.isPassed()) {
        if (prevProps.play && this.props.play) {
          // Case 1: When the track is playing and encounters a keyword,
          // we pause.
          this.props.pause();
        } else if (!prevProps.play && this.props.play) {
          // Case 2: The track was paused, but now it is playing, so we
          // set the keyword to passed.
          this.props.setPassed(currKeyWord.getNumInLrc(), true);
        }
      }
    }
  }

  getContent() {
    if (!this.props.elrc) {
      return null;
    }
    let result = null;
    const elrc = this.props.elrc;
    const keywordDict = this.props.keywordDict;
    const currentWord = this.props.currentWord;

    result = elrc.blocks.map((blockObj, blockIndex) => {
      return (
        <Block key={'block' + blockIndex}>
          {blockObj.lines.map((lineObj, lineIndex) => {
            return (
              <Line key={'line' + lineObj.pos}>
                {lineObj.words.map((wordObj, wordIndex) => {
                  const isKeyword = keywordDict.isKeyword(wordObj.numInLrc);
                  const isPassed = isKeyword
                    ? keywordDict.isPassed(wordObj.numInLrc)
                    : false;
                  if (isKeyword && !isPassed) {
                    return (
                      <HiddenWord key={'word' + wordObj.numInLrc}>
                        {wordObj.word}
                      </HiddenWord>
                    );
                  } else {
                    return currentWord === wordObj.numInLrc ? (
                      <ScrollSpan
                        className='major'
                        id='currentWord'
                        key={'word' + wordObj.numInLrc}
                        style={scrollSpanStyle}
                        onClick={() => null}
                      >
                        {wordObj.word}
                      </ScrollSpan>
                    ) : (
                      <Span
                        key={'word' + wordObj.numInLrc}
                        onClick={() => null}
                        isKeyword={isKeyword}
                      >
                        {wordObj.word}
                      </Span>
                    );
                  }
                })}
              </Line>
            );
          })}
        </Block>
      );
    });
    return result;
  }

  render() {
    const showButton =
      // this.props.keywordDict.isKeyword(this.props.currentWord) &&
      !this.props.play;
    return (
      <AppWrapper id='AppWrapper' ref={this.appWrapper}>
        {this.getContent()}
        {showButton && (
          <PlayButton>
            <RsButton large={true} onClick={this.props.playOrPause}>
              {this.props.currentWord === null ? 'Play' : 'Continue'}
            </RsButton>
          </PlayButton>
        )}
      </AppWrapper>
    );
  }
}

BlanksCenteredLyrics.propTypes = {
  elrc: PropTypes.instanceOf(Lrc),
  playOrPause: PropTypes.func,
  keywordDict: PropTypes.instanceOf(KeywordDict),
  currentWord: PropTypes.number,
  play: PropTypes.bool,
  pause: PropTypes.func,
  setPassed: PropTypes.func,
};

export default BlanksCenteredLyrics;
