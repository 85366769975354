import React, { Component } from 'react';
import styled from 'styled-components';
import { withFirebase } from '../../Firebase';

import Loading from '../../UI-Library/Misc/Loading';
import UserInitial from '../../UI-Library/Misc/UserInitial';
import ColorPickerModal from '../../UI-Library/Modals/ColorPickerModal';
import { rowsPaletteList } from '../../../constants/colors';

import TagSelect from '../../UI-Library/Forms/TagSelect';
import FeedbackModal from '../../UI-Library/Modals/FeedbackModal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import RsButton from '../../UI-Library/ButtonsOrLinks/RsButton';
import RsInput from '../../UI-Library/Forms/RsInput';
import BackArrow from '../../UI-Library/ButtonsOrLinks/BackArrow';

import { capitalizeFLetter } from '../../../utility/stringFunctions';

const HorLine = styled.hr`
  border: 0.5px solid var(--rsSecondary);
  margin-bottom: 30px;
  margin-top: 10px;
`;

const Title = styled.p`
  font-weight: bold;
  font-size: 40px;
  margin: 0;
`;

const Subtitle = styled.p`
  font-weight: bold;
  font-size: 24px;
  margin-top: 30px;
  margin-bottom: -10px;
  display: inline-block;
`;

const Container = styled.div`
  padding 5%;
  position: relative;

`;

const Attributes = styled.p`
  margin-bottom: 10px;
  color: var(--rsTertiary);
`;

const AttributeContent = styled.p`
  font-weight: bold;
  margin-bottom: 10px;
`;

const Link = styled.div`
  text-decoration: underline;
  cursor: pointer;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const SecHR = styled.hr`
  border: 1px solid var(--rsSecondary);
  margin: 0;
`;

const Overview = styled.div`
  display: flex;
  align-items: center;
`;

const OverviewColor = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const OverviewText = styled.div`
  margin-left: 15px;
`;

const Info = styled.div`
  display: flex;
  font-size: 14px;
`;

const TOSLink = styled.a`
  text-decoration: none;
  color: var(--rsSecondary);
  &:hover {
    color: var(--rsPrimary);
    text-decoration: underline;
  }
  margin-right: 10px;
  margin-left: 10px;
`;

export class SettingsBase extends Component {
  constructor(props) {
    super(props);
    const userData = this.props.userData;

    this.state = {
      modFeedback: false,
      showColor: false,
      edit: false,
      error: null,
      firstNameInput: userData.first_name,
      lastNameInput: userData.last_name,
      gradeInput: userData.grade,
      subjectInput: userData.subject,
      colorInput: userData.color ? userData.color : '#37dbf6',
      gradeOptions: ['K', 1, 2, 3, 4, 5, 6, 7, 8, 'Administrator'],
      subjectOptions: [
        'Math',
        'English',
        'Science',
        'Language',
        'Social Studies',
        'ELA',
        'Technology',
        'Art',
        'Administrator',
      ],
    };
    this.changeInfo = this.changeInfo.bind(this);
    this.onChangeForm = this.onChangeForm.bind(this);
    this.handleGradeToggle = this.handleGradeToggle.bind(this);
    this.handleSubjectToggle = this.handleSubjectToggle.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.toggleColor = this.toggleColor.bind(this);
    this.onSelectColor = this.onSelectColor.bind(this);
  }

  handleGradeToggle(selected) {
    this.setState({ gradeInput: selected });
  }

  handleSubjectToggle(selected) {
    this.setState({ subjectInput: selected });
  }

  onChangeForm(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  onSelectColor(color) {
    this.setState({ colorInput: color });
    this.toggleColor();
  }

  changeInfo(firstName, lastName, grade, subject, color) {
    // Firebase set.
    this.props.firebase.editUserInfo(
      firstName,
      lastName,
      grade,
      subject,
      color
    );

    //Set User Property grade [Firebase Analytics]
    var result = Array.from(grade);
    if (result.length === 0) {
      this.props.firebase.analytics.setUserProperties({
        grade: 'Not Selected',
      });
    } else {
      this.props.firebase.analytics.setUserProperties({
        grade: result.toString(),
      });
    }
  }

  toggleEdit() {
    if (this.state.edit) {
      this.changeInfo(
        this.state.firstNameInput,
        this.state.lastNameInput,
        this.state.gradeInput,
        this.state.subjectInput,
        this.state.colorInput
      );
    }
    this.setState({ edit: !this.state.edit });
  }

  toggleColor() {
    this.setState({ showColor: !this.state.showColor });
  }

  render() {
    const userData = this.props.userData;

    // TODO Auth: Implement button to extend verification
    if (this.state.error) {
      console.log(this.state.error);
      throw this.state.error;
    }
    if (!this.props.userData) {
      return <Loading />;
    } else {
      return (
        <Container>
          <BackArrow />
          <Header>
            <Title>Settings</Title>
            <RsButton onClick={this.toggleEdit}>
              {this.state.edit ? 'Save' : 'Edit'}
            </RsButton>
          </Header>

          <Overview>
            <OverviewColor>
              <UserInitial
                letter={userData.first_name.charAt(0)}
                large={true}
                color={this.state.edit ? this.state.colorInput : userData.color}
              />
              {this.state.edit ? (
                <RsButton mt={'10px'} secondary onClick={this.toggleColor}>
                  Change color
                </RsButton>
              ) : null}
            </OverviewColor>

            <OverviewText>
              <p style={{ marginBottom: 0 }}>
                {userData.first_name + ' ' + userData.last_name}
              </p>
              <p style={{ marginBottom: 0 }}>{userData.email}</p>
            </OverviewText>
          </Overview>

          <Subtitle>Profile</Subtitle>

          <HorLine />
          <Row>
            <Col md='2'>
              <Attributes>First name</Attributes>
            </Col>
            <Col>
              {this.state.edit ? (
                <RsInput
                  name='firstNameInput'
                  value={this.state.firstNameInput}
                  onChange={this.onChangeForm}
                  mb={'10px'}
                ></RsInput>
              ) : (
                <AttributeContent>{userData.first_name}</AttributeContent>
              )}
            </Col>
          </Row>
          <Row>
            <Col md='2'>
              <Attributes>Last name</Attributes>
            </Col>
            <Col>
              {this.state.edit ? (
                <RsInput
                  name='lastNameInput'
                  value={this.state.lastNameInput}
                  onChange={this.onChangeForm}
                ></RsInput>
              ) : (
                <AttributeContent>{userData.last_name}</AttributeContent>
              )}
            </Col>
          </Row>
          <Row style={{ marginTop: this.state.edit ? 10 : 0 }}>
            <Col md='2'>
              <Attributes>Grades</Attributes>
            </Col>
            <Col md='10'>
              {this.state.edit ? (
                <>
                  <SecHR />
                  <TagSelect
                    selection={this.state.gradeOptions}
                    selected={this.state.gradeInput}
                    onChange={this.handleGradeToggle}
                  />
                </>
              ) : (
                <AttributeContent>
                  {userData.grade.map((item, index) => {
                    if (index === userData.grade.length - 1) {
                      return <span key={'GRADE_' + index}>{item}</span>;
                    } else {
                      return <span key={'GRADE_' + index}>{item + ', '}</span>;
                    }
                  })}
                </AttributeContent>
              )}
            </Col>
          </Row>
          <Row style={{ marginTop: this.state.edit ? 10 : 0 }}>
            <Col md='2'>
              <Attributes>Subjects</Attributes>
            </Col>
            <Col md='10'>
              {this.state.edit ? (
                <>
                  <SecHR />
                  <TagSelect
                    selection={this.state.subjectOptions}
                    selected={this.state.subjectInput}
                    onChange={this.handleSubjectToggle}
                  />
                </>
              ) : (
                <AttributeContent>
                  {userData.subject.map((item, index) => {
                    if (index === userData.subject.length - 1) {
                      return <span key={'SUBJECT_' + index}>{item}</span>;
                    } else {
                      return (
                        <span key={'SUBJECT_' + index}>{item + ', '}</span>
                      );
                    }
                  })}
                </AttributeContent>
              )}
            </Col>
          </Row>
          <Subtitle>Account</Subtitle>
          <HorLine />
          <Row>
            <Col md='2'>
              <Attributes>Email</Attributes>
            </Col>
            <Col md='auto'>
              <AttributeContent>{userData.email}</AttributeContent>
            </Col>
          </Row>
          <Row>
            <Col md='2'>
              <Attributes>Role</Attributes>
            </Col>
            <Col md='auto'>
              <AttributeContent>
                {capitalizeFLetter(userData.position)}
              </AttributeContent>
            </Col>
          </Row>
          <Row>
            <Col md='2'>
              <Attributes>Organization</Attributes>
            </Col>
            <Col md='auto'>
              <AttributeContent>{userData.schoolName}</AttributeContent>
            </Col>
          </Row>
          <Row>
            <Col md='2'>
              <Attributes>Verification Expiry</Attributes>
            </Col>
            <Col md='auto'>
              <AttributeContent>
                {userData.subscriptionEnds.toDate().toString()}
              </AttributeContent>
            </Col>
          </Row>
          <Subtitle>App</Subtitle>
          <HorLine />
          <Link onClick={() => this.setState({ modFeedback: true })}>
            Give us feedback
          </Link>

          <FeedbackModal
            show={this.state.modFeedback}
            onHide={() => this.setState({ modFeedback: false })}
            firstName={userData.first_name}
            lastName={userData.last_name}
            email={userData.email}
          ></FeedbackModal>
          <ColorPickerModal
            hexList={rowsPaletteList}
            toggle={this.toggleColor}
            onSelect={this.onSelectColor}
            show={this.state.showColor}
            selected={this.state.colorInput}
          />
          <p
            className='secondary'
            style={{ fontSize: '14px', marginTop: '20px' }}
          >
            If you would like to make any other changes to your account or
            remove your account, please send us an email at{' '}
            <span className='bold' style={{ textDecoration: 'underline' }}>
              support@rapstudy.com
            </span>
            .
          </p>
          <Info>
            <p style={{ margin: 0 }}>© 2021 rapStudy Inc.</p>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <TOSLink
                secondary={true}
                href={
                  'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/documents%2F%5BrapStudy%5D%20PP.pdf?alt=media&token=ac72e73b-1f95-4b69-a46b-de02d0bea869'
                }
                download='rapStudy Privacy Policy'
                target='_blank'
              >
                Privacy Policy
              </TOSLink>
              <TOSLink
                secondary={true}
                href={
                  'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/documents%2F%5BrapStudy%5D%20TOU.pdf?alt=media&token=ff00b065-3e73-43c3-8e02-0cf2b338596f'
                }
                download='rapStudy Terms of Use'
                target='_blank'
              >
                Terms of Use
              </TOSLink>
            </div>
          </Info>
        </Container>
      );
    }
  }
}

const Settings = withFirebase(SettingsBase);
export default Settings;
