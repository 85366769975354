/** Queries the given song id's mp3 URL from firestore.
 *
 * @param {string} songId - id of song
 * @returns a promise of URL
 */
export async function getMp3(songId) {
  // var songsRef = db.reference.child('songs').child(songId);
  var songFolderRef = this.storage.ref('songs').child(songId);
  var fileName = songId.concat('.mp3');
  var url = await songFolderRef.child(fileName).getDownloadURL();
  return url;
}

/**
 * Returns a Promise that resolves with the DocumentSnapshot of the song.
 * @param {string} songId
 * @returns {Promise<DocumentSnapshot>}
 */
export async function getSong(songId) {
  const song = await this.db.collection('songs').doc(songId).get();
  if (song.exists) {
    return song;
  } else {
    throw this.NotFoundError;
  }
}

/** Queries the given song id's JSON URL from firestore.
 *
 * @param {string} songId - id of song
 * @returns a promise of URL
 */
export async function getJson(songId) {
  var songFolderRef = this.storage.ref('songs').child(songId);
  var fileName = songId.concat('_json.txt');
  var url = await songFolderRef.child(fileName).getDownloadURL();
  var jsonResponse = await fetch(url);
  var json_txt = await jsonResponse.text();
  return json_txt;
}

/** Queries the given song id's jpg URL from firestore.
 *
 * @param {string} subtopicId - name of the subtopic
 * @returns a promise of URL
 */
export async function getSongCover(subtopicId) {
  try {
    var url = await this.storage
      .ref('images')
      .child('subtopics')
      .child(subtopicId.concat('.png'))
      .getDownloadURL();
    return url;
  } catch (err) {
    return null;
  }
}

export async function incrementSongCount(songId) {
  try {
    var userRef = this.db.collection('users').doc(this.auth.currentUser.uid);
    var userSongSnap = await userRef.collection('songs').doc(songId).get();
    var completedFeedback = userSongSnap.get('completedFeedback');
    if (userSongSnap.exists) {
      await userSongSnap.ref.update({
        count: this.firebase.firestore.FieldValue.increment(1),
        lastAccess: this.firebase.firestore.FieldValue.serverTimestamp(),
      });
      return completedFeedback;
    } else {
      await this.initUserSongDoc(songId);
      return false;
    }
  } catch (err) {
    throw err;
  }
}

//======================= HELPER FUNCTIONS ==================
export async function initUserSongDoc(songId) {
  var userDoc = this.db.collection('users').doc(this.auth.currentUser.uid);
  var song = await this.db.collection('songs').doc(songId).get();
  if (!song.exists) throw this.NotFoundError;
  var songData = song.data();

  var defaultData = {
    newTitle: songData.newTitle,
    origTitle: songData.origTitle,
    subject: songData.subject,
    unit: songData.unit,
    topic: songData.topic,
    subtopic: songData.subtopic,
    count: 0,
    lastAccess: this.firebase.firestore.FieldValue.serverTimestamp(),
    completedFeedback: false,
  };

  await userDoc.collection('songs').doc(songId).set(defaultData);
  return {
    data: () => {
      return defaultData;
    },
  };
}
