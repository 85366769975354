import React from 'react';
import styled from 'styled-components';
import Carousel from 'react-bootstrap/Carousel';
import ScrollButton from './ScrollButton';

import { device } from '../../../../constants/breakpoints';
import { isMobile } from 'react-device-detect';
import UpdatesSignUp from '../../UpdatesSignUp';

const MainWrapper = styled.div`
  height: calc(100vh - 200px);
  background: white;
`;

const Jumbo1 = styled.div`
  background: linear-gradient(360deg, #4831d4 0%, rgba(255, 255, 255, 0) 100%),
    #37dbf6;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TestimonialsContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  height: 70%;
  width: 100%;
`;

const TestimonialsCard = styled.div`
  width: auto;
  max-width: 1000px;
  height: 80%;
  background: white;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  box-shadow: 3px 3px 15px #000000a3;
  margin-left: 2.5%;
  margin-right: 2.5%;
  font-size: 20px;
  padding: 2%;
  overflow: hidden;

  @media ${device.tablet} {
    font-size: 14px;
  }
  @media ${device.mobileM} {
    font-size: 12px;
  }
`;

const CarouselItem = styled(Carousel.Item)`
  width: 100%;
  text-align: center;
`;

const TestimonialText = styled.p`
  color: black;
  margin-top: 20px;
  @media ${device.tablet} {
    margin-top: 20px;
  }
`;

const QuoteByText = styled.p`
  color: black;
  margin-top: 30px;
  font-weight: 300;
  @media ${device.tablet} {
    margin-top: 10px;
  }
`;

class AboutTestimonials extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currIndex: 0,
      numQuotes: 3,
    };

    this.scrollLeft = this.scrollLeft.bind(this);
    this.scrollRight = this.scrollRight.bind(this);
  }

  scrollLeft() {
    if (this.state.currIndex === 0) return;
    const currIndex = this.state.currIndex;
    this.setState({
      currIndex: currIndex - 1,
    });
  }

  scrollRight() {
    if (this.state.currIndex + 1 >= this.state.numQuotes) return;

    const currIndex = this.state.currIndex;
    this.setState({
      currIndex: currIndex + 1,
    });
  }

  render() {
    var data = [
      {
        quote:
          '"My students know the lyrics to the songs they like. So, when I introduce the scientific method with music and lyrics, it helps make the instruction more meaningful and engaging for my students."',
        quoteBy: 'Ms. Grant, 6th Grade Science at South Orange Middle School',
      },
      {
        quote:
          '"As the Executive Director of Special Education, I especially see promise for increased achievement and engagement with grade level curriculum for students with disabilities and other struggling learners."',
        quoteBy:
          'Robert M., Executive Director and Special Programs, Fairfield Public Schools',
      },
      {
        quote:
          '"My co-workers and I thought the songs were BRILLIANT and helped to engage most of the students. As our program has a very unique set of learners (students with emotional and behavioral disorders) ... it was SO, SO helpful and resourceful to have another song to choose from for moments such as this ... so this was such an ideal way to review and remember information!"',
        quoteBy: 'Kaitlin Z., Behavioral Instructor, Tomlinson Middle School',
      },
    ];

    var formattedTestimonals = data.map((item, index) => {
      return (
        <CarouselItem key={'carouselItem_' + index}>
          <div>
            <img src='/images/landing/quoteHeader.svg' alt='' />
            <TestimonialText>{item.quote}</TestimonialText>
            <QuoteByText>{item.quoteBy}</QuoteByText>
          </div>
        </CarouselItem>
      );
    });
    return (
      <MainWrapper>
        <Jumbo1>
          <TestimonialsContainer>
            {!isMobile && (
              <ScrollButton scroll={this.scrollLeft} clickable={true} />
            )}

            <TestimonialsCard>
              {isMobile ? (
                <Carousel
                  interval={7000}
                  controls={false}
                  fade={false}
                  indicators={false}
                >
                  {formattedTestimonals}
                </Carousel>
              ) : (
                <Carousel
                  interval={null}
                  controls={false}
                  activeIndex={this.state.currIndex}
                  fade={false}
                  indicators={false}
                >
                  {formattedTestimonals}
                </Carousel>
              )}
            </TestimonialsCard>
            {!isMobile && (
              <ScrollButton
                scroll={this.scrollRight}
                clickable={true}
                right={true}
              />
            )}
          </TestimonialsContainer>
          <UpdatesSignUp />
        </Jumbo1>
      </MainWrapper>
    );
  }
}

export default AboutTestimonials;
