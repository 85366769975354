import React from 'react';

const alertStyle = {
  backgroundColor: 'var(--rsMagenta)',
  color: 'white',
  paddingTop: '5px',
  paddingBottom: '5px',
  paddingLeft: '15px',
  paddingRight: '15px',
  borderRadius: '8px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow: '0px 2px 2px 2px rgba(0, 0, 0, 0.03)',
};

const CustomAlert = ({ message, options, style, close }) => {
  return (
    <div style={{ ...alertStyle, ...style }}>
      <span className='bold'>{message}</span>
    </div>
  );
};

export default CustomAlert;
