import React, { Component } from 'react';
import * as ROUTES from '../../../../constants/routes';
import BannerNav from '../../../UI-Library/Navs/BannerNav';

import Folder from '../../../UI-Library/Icons/Folder';
import Person from '../../../UI-Library/Icons/Person';
import Checklist from '../../../UI-Library/Icons/Checklist';

class ClassNav extends Component {
  render() {
    const items = [
      {
        icon: Folder,
        to: ROUTES.CLASSVIEW + '/' + this.props.classId + '/sharedContent',
        name: 'Shared Content',
      },

      {
        icon: Checklist,
        to: ROUTES.CLASSVIEW + '/' + this.props.classId + '/assignments',
        name: 'Assignments',
      },
      {
        icon: Person,
        to: ROUTES.CLASSVIEW + '/' + this.props.classId + '/members',
        name: 'Members',
      },
    ];
    return <BannerNav replace={true} items={items} />;
  }
}

export default ClassNav;
