import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Trash extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    };
    this.handleEnter = this.handleEnter.bind(this);
    this.handleLeave = this.handleLeave.bind(this);
  }

  handleEnter() {
    this.setState({ hover: true });
  }

  handleLeave() {
    this.setState({ hover: false });
  }

  render() {
    const hover =
      typeof this.props.hover !== 'undefined'
        ? this.props.hover
        : this.state.hover;

    const color = this.props.disabled
      ? 'var(--rsDisabled)'
      : hover
      ? 'var(--rsPrimary)'
      : 'var(--rsSecondary)';
    return (
      <div
        onMouseEnter={this.handleEnter}
        onMouseLeave={this.handleLeave}
        onClick={this.props.disabled ? null : this.props.onClick}
        style={{
          cursor:
            this.props.onClick && !this.props.disabled ? 'pointer' : 'default',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <svg
          width={this.props.size ? this.props.size : '24px'}
          height={this.props.size ? this.props.size : '24px'}
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 18 18'
        >
          <polyline
            points='7.98 3.09 7.98 1.43 10.79 1.43 10.79 3.09'
            fill='none'
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
          />
          <line
            x1='3.84'
            y1='3.09'
            x2='14.92'
            y2='3.09'
            fill='none'
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
          />
          <polyline
            points='14.96 5.76 14.08 16.73 4.69 16.73 3.81 5.76'
            fill='none'
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
          />
          <line
            x1='7.86'
            y1='14.22'
            x2='7.15'
            y2='6.13'
            fill='none'
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
          />
          <line
            x1='11.62'
            y1='6.1'
            x2='10.9'
            y2='14.22'
            fill='none'
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
          />
        </svg>
      </div>
    );
  }
}

Trash.propTypes = {
  /**
   * Should the icon take on disabled styling?
   */
  disabled: PropTypes.bool,
  /**
   * The function fired when the icon is clicked.
   */
  onClick: PropTypes.func,
  /**
   * The size of the icon. Defaults to 24px.
   */
  size: PropTypes.string,
  /**
   * A hover override from the parent component. If defined, the local
   * state hover is ignored.
   */
  hover: PropTypes.bool,
};

export default Trash;
