import React, { Component } from 'react';
import styled from 'styled-components';

import { Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';

const Icon = styled.div`
  &:hover {
    cursor: pointer;
  }
  width: 36px;
  height: 36px;
  margin-right: ${(props) => (props.mr ? props.mr : '0px')};
  margin-left: ${(props) => (props.ml ? props.ml : '0px')};
`;

/**
 * The play/pause button.
 */
class PlayPause extends Component {
  render() {
    var svg = null;

    if (!this.props.audioLoaded) {
      return (
        <Spinner
          as='span'
          animation='border'
          size='sm'
          role='status'
          aria-hidden='true'
        ></Spinner>
      );
    } else if (!this.props.play) {
      svg = (
        <svg
          id='Layer_1'
          data-name='Layer 1'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 36 36'
        >
          <circle
            cx='17.84'
            cy='17.84'
            r='16'
            fill='none'
            stroke='white'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='3'
          />
          <polygon
            points='24.56 17.84 13.78 11.61 13.78 24.06 24.56 17.84'
            fill='white'
            stroke='white'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='3'
          />
        </svg>
      );
    } else {
      svg = (
        <svg
          id='Layer_1'
          data-name='Layer 1'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 36 36'
        >
          <circle
            cx='17.97'
            cy='17.97'
            r='15.87'
            fill='none'
            stroke='white'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='3'
          />
          <line
            x1='14.01'
            y1='11.92'
            x2='14.01'
            y2='24.26'
            fill='none'
            stroke='white'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='3'
          />
          <line
            x1='21.94'
            y1='11.92'
            x2='21.94'
            y2='24.26'
            fill='none'
            stroke='white'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='3'
          />
        </svg>
      );
    }

    return (
      <Icon onClick={this.props.onClick} mr={this.props.mr} ml={this.props.ml}>
        {svg}
      </Icon>
    );
  }
}

PlayPause.propTypes = {
  /**
   * Is the audio loaded into the player?
   */
  audioLoaded: PropTypes.bool,
  /**
   * Is the audio currently playing?
   */
  play: PropTypes.bool.isRequired,
  /**
   * The function fired when the icon is clicked.
   */
  onClick: PropTypes.func,
  /**
   * Custom margin right. Defaults to 0px.
   */
  mr: PropTypes.string,
  /**
   * Custom margin left. Defaults to 0px.
   */
  ml: PropTypes.string,
};

export default PlayPause;
