import React, { Component } from 'react';
import styled from 'styled-components';
import Pencil from '../../../Icons/Pencil';
import RsButton from '../../../ButtonsOrLinks/RsButton';
import Trash from '../../../Icons/Trash';
import Tooltip from '../../../Misc/Tooltip';

const MainWrapper = styled.div`
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

const HeaderInput = styled.input`
  background-color: transparent;
  border: none;
  border-bottom: 1px solid var(--rsSecondary);
  color: var(--rsPrimary);
  :focus {
    outline: none;
  }
  font-size: 32px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
`;

class ViewHeader extends Component {
  render() {
    return (
      <MainWrapper>
        <div
          style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}
        >
          <HeaderInput
            type='input'
            value={this.props.title}
            onChange={this.props.handleTitleChange}
          />
          <Pencil hover={true} />
        </div>
        <ButtonWrapper>
          <Tooltip placement='bottom' text='Delete set'>
            <Trash onClick={this.props.deleteModalToggle} />
          </Tooltip>

          <RsButton
            ml={'15px'}
            secondary={true}
            onClick={this.props.discardModalToggle}
          >
            Cancel
          </RsButton>

          <RsButton
            ml={'15px'}
            onClick={this.props.toggleEdit}
            disabled={this.props.title.trim().length === 0}
          >
            Save
          </RsButton>
        </ButtonWrapper>
      </MainWrapper>
    );
  }
}

export default ViewHeader;
