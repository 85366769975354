import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import KeywordDict from '../../../../../Classes/KeywordDict';
import ListItem from './ListItem';
import BlanksSideDiv from '../BlanksSideDiv';

const List = styled.div`
  padding: 5px 0 5px 0;
  display: flex;
  flex-wrap: wrap;
`;

/**
 * The word bank component used in blanks.
 */
export default class WordBank extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.choose = this.choose.bind(this);
  }

  choose(word) {
    if (this.props.currentWord === null) return null;
    const kObj = this.props.keywordDict.getKeyword(this.props.currentWord);
    if (!kObj || kObj.isCorrect()) return null;
    return this.props.onSelect(this.props.currentWord, word);
  }

  render() {
    const words = new Set();
    const uniques = [];
    const disabled = this.props.disabled;
    this.props.keywordDict.getKeywordsArray().forEach((keywordObj) => {
      if (!words.has(keywordObj.getWord())) {
        words.add(keywordObj.getWord());
        uniques.push(keywordObj);
      }
    });
    const uniquesArr = Array.from(uniques).sort((a, b) => {
      // We sort by the keywordId, which shuffles them enough (since keywordIds)
      // are randomly generated, but are sorted in a systematic manner.
      return a.getId().localeCompare(b.getId());
    });
    const uniqueWords = uniquesArr.map((obj) => obj.getWord());
    return (
      <BlanksSideDiv
        disabled={disabled}
        title={'Word bank'}
        sub={'Pick a word from below!'}
      >
        <List>
          {uniqueWords.map((word) => (
            <ListItem
              key={'wordbank_' + word}
              choose={this.choose}
              word={word}
              disabled={disabled}
            ></ListItem>
          ))}
        </List>
      </BlanksSideDiv>
    );
  }
}

WordBank.propTypes = {
  /**
   * The main keyword dictionary data structure.
   */
  keywordDict: PropTypes.instanceOf(KeywordDict),
  /**
   * The function fired when a choice is selected. Takes two parameters,
   * the number representing the numInLrc of the keyword, and the string
   * representing the actual text of the keyword.
   */
  onSelect: PropTypes.func,
  /**
   * Is selection disabled?
   */
  disabled: PropTypes.bool,
  /**
   * The current numInLrc word.
   */
  currentWord: PropTypes.number,
};

WordBank.defaultProps = {
  keywordDict: null,
  onSelect: () => null,
  disabled: false,
  currentWord: null,
};
