import React, { Component } from 'react';
import styled from 'styled-components';

const Block = styled.div`
  margin-bottom: 3em;
  text-align: center;
`;

const Line = styled.p`
  margin-bottom: 5px;
`;

const Keyword = styled.span`
  color: var(--rsMagenta);
  font-size: 32px;
  cursor: pointer;
  font-weight: bold;
`;

const Span = styled.span`
  font-size: 32px;
  font-weight: bold;
  cursor: pointer;
`;

class BlanksEdit extends Component {
  getContent() {
    if (!this.props.elrc) {
      return null;
    }
    let result = null;
    const elrc = this.props.elrc;
    const keywordDict = this.props.keywordDict;

    result = elrc.blocks.map((blockObj, blockIndex) => {
      return (
        <Block key={'block' + blockIndex}>
          {blockObj.lines.map((lineObj, lineIndex) => {
            return (
              <Line key={'line' + lineObj.pos}>
                {lineObj.words.map((wordObj, wordIndex) => {
                  const isKeyword = keywordDict.isKeyword(wordObj.numInLrc);
                  if (isKeyword) {
                    return (
                      <Keyword
                        key={'word' + wordObj.numInLrc}
                        onClick={() =>
                          this.props.toggleWord(wordObj.numInLrc, wordObj.word)
                        }
                      >
                        {wordObj.word}
                      </Keyword>
                    );
                  } else {
                    return (
                      <Span
                        key={'word' + wordObj.numInLrc}
                        onClick={() =>
                          this.props.toggleWord(wordObj.numInLrc, wordObj.word)
                        }
                      >
                        {wordObj.word}
                      </Span>
                    );
                  }
                })}
              </Line>
            );
          })}
        </Block>
      );
    });
    return result;
  }

  render() {
    return <div>{this.getContent()}</div>;
  }
}

export default BlanksEdit;
