import React, { Component } from 'react';
import PropTypes from 'prop-types';

class CircleDots extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    };
    this.handleEnter = this.handleEnter.bind(this);
    this.handleLeave = this.handleLeave.bind(this);
  }

  handleEnter() {
    this.setState({ hover: true });
  }

  handleLeave() {
    this.setState({ hover: false });
  }

  render() {
    const hover =
      typeof this.props.hover !== 'undefined'
        ? this.props.hover
        : this.state.hover;

    const color = this.props.disabled
      ? 'var(--rsDisabled)'
      : hover
      ? 'var(--rsPrimary)'
      : 'var(--rsSecondary)';

    return (
      <span
        onMouseEnter={this.handleEnter}
        onMouseLeave={this.handleLeave}
        onClick={this.props.disabled ? null : this.props.onClick}
        style={{
          cursor:
            this.props.onClick && !this.props.disabled ? 'pointer' : 'default',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <svg
          width={this.props.size ? this.props.size : '24px'}
          height={this.props.size ? this.props.size : '24px'}
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 18 18'
        >
          <circle
            cx='9'
            cy='9'
            r='7.72'
            fill='none'
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <circle cx='13.09' cy='9.16' r='0.95' fill={color} />
          <path
            d='M13.09,10.34a1.18,1.18,0,1,1,1.18-1.18A1.18,1.18,0,0,1,13.09,10.34Zm0-1.9a.72.72,0,1,0,.71.72A.72.72,0,0,0,13.09,8.44Z'
            fill={color}
          />
          <circle cx='4.75' cy='9.16' r='0.95' fill={color} />
          <path
            d='M4.75,10.34A1.18,1.18,0,1,1,5.93,9.16,1.18,1.18,0,0,1,4.75,10.34Zm0-1.9a.72.72,0,1,0,.72.72A.72.72,0,0,0,4.75,8.44Z'
            fill={color}
          />
          <circle cx='8.92' cy='9.16' r='0.95' fill={color} />
          <path
            d='M8.92,10.34A1.18,1.18,0,1,1,10.1,9.16,1.18,1.18,0,0,1,8.92,10.34Zm0-1.9a.72.72,0,1,0,.72.72A.72.72,0,0,0,8.92,8.44Z'
            fill={color}
          />
        </svg>
      </span>
    );
  }
}

CircleDots.propTypes = {
  /**
   * Should the icon take on disabled styling?
   */
  disabled: PropTypes.bool,
  /**
   * The function fired when the icon is clicked.
   */
  onClick: PropTypes.func,
  /**
   * The size of the icon. Defaults to 24px.
   */
  size: PropTypes.string,
  /**
   * A hover override from the parent component. If defined, the local
   * state hover is ignored.
   */
  hover: PropTypes.bool,
  /**
   * Is the component a span or a div?
   */
  span: PropTypes.bool,
};

export default CircleDots;
