import React, { Component } from 'react';
import styled from 'styled-components';

const Rate = styled.div`
  text-align: center;
`;

const RatingImg = styled.img`
  width: 48px;
  height: 48px;
`;

const RatingButton = styled.button`
  outline: none;
  border: none;
  background: none;
`;

const RatingRow = styled.div`
  margin-top: 10px;
`;

const TextRow = styled.div`
  margin-top: 10px;
`;

const CircleRow = styled.div`
  height: 30px;
  margin-top: 20px;
`;

const TextCol = styled.div`
  min-height: 40px;
  padding: 0;
  text-align: center;
`;

const Circle = styled.span`
  background-color: var(--rsDisabled);
  width: 26px;
  height: 26px;
  border-radius: 50%;
  position: absolute;
  bottom: calc(50% - 13px);
  left: calc(50% - 13px);
  cursor: pointer;
`;

const ActiveCircle = styled.span`
  background-color: var(--rsBlue);
  width: 18px;
  height: 18px;
  border-radius: 50%;
  position: absolute;
  bottom: calc(50% - 9px);
  left: calc(50% - 9px);
`;

const CircleCol = styled.div`
  position: relative;
`;

const LineLeft = styled.span`
  background-color: var(--rsDisabled);
  width: 50%;
  height: 4px;
  position: absolute;
  bottom: calc(50% - 2px);
  left: 0;
`;

const LineRight = styled.span`
  background-color: var(--rsDisabled);
  width: 50%;
  height: 4px;
  position: absolute;
  bottom: calc(50% - 2px);
  left: calc(50% + 12.25px);
`;

class Rating extends Component {
  render() {
    return (
      <div>
        <RatingRow className='row'>
          <Rate className='col'>
            <RatingButton onClick={() => this.props.handleRatingClick(1)}>
              <RatingImg src='/images/rating/rating1.png' alt='' />
            </RatingButton>
          </Rate>
          <Rate className='col'>
            <RatingButton onClick={() => this.props.handleRatingClick(2)}>
              <RatingImg src='/images/rating/rating2.png' alt='' />
            </RatingButton>
          </Rate>
          <Rate className='col'>
            <RatingButton onClick={() => this.props.handleRatingClick(3)}>
              <RatingImg src='/images/rating/rating3.png' alt='' />
            </RatingButton>
          </Rate>
          <Rate className='col'>
            <RatingButton onClick={() => this.props.handleRatingClick(4)}>
              <RatingImg src='/images/rating/rating4.png' alt='' />
            </RatingButton>
          </Rate>
          <Rate className='col'>
            <RatingButton onClick={() => this.props.handleRatingClick(5)}>
              <RatingImg src='/images/rating/rating5.png' alt='' />
            </RatingButton>
          </Rate>
          <Rate className='col'>
            <RatingButton onClick={() => this.props.handleRatingClick(6)}>
              <RatingImg src='/images/rating/rating6.png' alt='' />
            </RatingButton>
          </Rate>
        </RatingRow>
        <CircleRow className='row'>
          <CircleCol className='col'>
            <Circle onClick={() => this.props.handleRatingClick(1)} />
            {this.props.rating === 1 ? <ActiveCircle /> : null}
            <LineRight />
          </CircleCol>
          <CircleCol className='col'>
            <LineLeft />
            <Circle onClick={() => this.props.handleRatingClick(2)} />
            {this.props.rating === 2 ? <ActiveCircle /> : null}

            <LineRight />
          </CircleCol>
          <CircleCol className='col'>
            <LineLeft />
            <Circle onClick={() => this.props.handleRatingClick(3)} />
            {this.props.rating === 3 ? <ActiveCircle /> : null}

            <LineRight />
          </CircleCol>
          <CircleCol className='col'>
            <LineLeft />
            <Circle onClick={() => this.props.handleRatingClick(4)} />
            {this.props.rating === 4 ? <ActiveCircle /> : null}

            <LineRight />
          </CircleCol>
          <CircleCol className='col'>
            <LineLeft />
            <Circle onClick={() => this.props.handleRatingClick(5)} />
            {this.props.rating === 5 ? <ActiveCircle /> : null}

            <LineRight />
          </CircleCol>
          <CircleCol className='col'>
            <LineLeft />
            <Circle onClick={() => this.props.handleRatingClick(6)} />
            {this.props.rating === 6 ? <ActiveCircle /> : null}
          </CircleCol>
        </CircleRow>
        <TextRow className='row'>
          <TextCol className='col'>
            {this.props.rating === 1 ? (
              <p
                className='bold'
                style={{ marginBottom: '0', color: 'var(--rsPrimary)' }}
              >
                it was bad
              </p>
            ) : null}
          </TextCol>
          <TextCol className='col'>
            {this.props.rating === 2 ? (
              <p
                className='bold'
                style={{ marginBottom: '0', color: 'var(--rsPrimary)' }}
              >
                not the best
              </p>
            ) : null}
          </TextCol>
          <TextCol className='col'>
            {this.props.rating === 3 ? (
              <p
                className='bold'
                style={{ marginBottom: '0', color: 'var(--rsPrimary)' }}
              >
                meh
              </p>
            ) : null}
          </TextCol>
          <TextCol className='col'>
            {this.props.rating === 4 ? (
              <p
                className='bold'
                style={{ marginBottom: '0', color: 'var(--rsPrimary)' }}
              >
                I liked it
              </p>
            ) : null}
          </TextCol>
          <TextCol className='col'>
            {this.props.rating === 5 ? (
              <p
                className='bold'
                style={{ marginBottom: '0', color: 'var(--rsPrimary)' }}
              >
                it was great!
              </p>
            ) : null}
          </TextCol>
          <TextCol className='col'>
            {this.props.rating === 6 ? (
              <p
                className='bold'
                style={{ marginBottom: '0', color: 'var(--rsPrimary)' }}
              >
                LOVED it!
              </p>
            ) : null}
          </TextCol>
        </TextRow>
      </div>
    );
  }
}

export default Rating;
