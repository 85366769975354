import React, { Component } from 'react';
import styled from 'styled-components';

import RsButton from '../../../UI-Library/ButtonsOrLinks/RsButton';

const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 30px;
`;

class CreateAssignmentView extends Component {
  render() {
    if (this.props.page === 0)
      return (
        <Footer>
          <div></div>
          <RsButton
            disabled={this.props.disabledNext}
            onClick={this.props.next}
          >
            Next
          </RsButton>
        </Footer>
      );
    if (this.props.page === 1)
      return (
        <Footer>
          <RsButton secondary={true} onClick={this.props.back}>
            Back
          </RsButton>
          <RsButton
            onClick={this.props.submit}
            disabled={this.props.disabledNext || this.props.creating}
          >
            Submit
          </RsButton>
        </Footer>
      );
    return null;
  }
}

export default CreateAssignmentView;
