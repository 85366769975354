import React, { Component } from 'react';
import styled from 'styled-components';
import { withFirebase } from '../../Firebase';
import { withAlert } from 'react-alert';

import EmptySets from './EmptySets';
import DeleteConfirmModal from '../../UI-Library/Modals/DeleteConfirmModal';
import ShareToClassModal from '../../UI-Library/Modals/ShareToClassModal';
import CreateAnySetModal from '../../UI-Library/Modals/CreateAnySetModal';

import Loading from '../../UI-Library/Misc/Loading';
import SongCard from '../../UI-Library/ItemDisplays/SongCard';

import { Accordion, Nav } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import CustomSetDict from '../../../Classes/CustomSetDict';
import SimpleBanner from '../../UI-Library/Containers/SimpleBanner';
import BackArrow from '../../UI-Library/ButtonsOrLinks/BackArrow';
import DropdownMenu from '../../UI-Library/ButtonsOrLinks/DropdownMenu';
import TextToggler from '../../UI-Library/ButtonsOrLinks/TextToggler';

const PageWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: 90%;
  padding-top: 20px;
  margin: auto;
`;

const Title = styled.h1`
  font-weight: bold;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const NavbarDropdown = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: white;
  @media screen and (max-width: 1120px) {
    font-size: 14px;
  }
`;

const NavbarLi = styled.li`
  display: inline-block;
  font-size: 1.25rem;
`;

const BlockNav = {
  marginLeft: 'auto',
  alignItems: 'center',
};

export const CreateButton = styled.button`
  width: 150px;
`;

export class ContentManagerBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      songDicts: new CustomSetDict(),
      error: null,
      ready: false,
      sortBy: 'Most Recent',
      showDelete: false,
      showShareToClass: false,
      curSongId: '',
      curSongName: '',
      curTopicName: '',
      curSetId: '',
      curFeature: 'Breakdown',
      curSorted: [],
      showCreate: false,
    };

    this.delete = this.delete.bind(this);
    this.deleteModalToggle = this.deleteModalToggle.bind(this);
    this.shareToClassModalToggle = this.shareToClassModalToggle.bind(this);
    this.shareToClassShowToggle = this.shareToClassShowToggle.bind(this);
    this.sortByRecent = this.sortByRecent.bind(this);
    this.sortByTitle = this.sortByTitle.bind(this);
    this.sortByUnit = this.sortByUnit.bind(this);
    this.setCustomContentTypes = this.setCustomContentTypes.bind(this);
    this.customContentModalToggle = this.customContentModalToggle.bind(this);
  }

  async componentDidMount() {
    try {
      const songDicts = await this.props.firebase.getCustomSongs();
      const arr = songDicts.getSortedSongListByRecent();
      this.setState({ songDicts: songDicts, curSorted: arr, ready: true });
    } catch (err) {
      this.setState({ error: err });
    }
  }

  sortByRecent() {
    this.setState({
      sortBy: 'Most Recent',
      curSorted: this.state.songDicts.getSortedSongListByRecent(),
    });
  }

  sortByTitle() {
    this.setState({
      sortBy: 'Song',
      curSorted: this.state.songDicts.getSortedSongListByName(),
    });
  }

  sortByUnit() {
    this.setState({
      sortBy: 'Unit',
      curSorted: this.state.songDicts.getSortedSongListByUnit(),
    });
  }

  deleteModalToggle(feature, songId, setId) {
    this.setState({
      showDelete: !this.state.showDelete,
      curFeature: feature,
      curSongId: songId,
      curSetId: setId,
    });
  }

  shareToClassModalToggle(feature, setId) {
    this.setState({
      showShareToClass: !this.state.showShareToClass,
      curFeature: feature,
      curSetId: setId,
    });
  }

  shareToClassShowToggle() {
    this.setState({
      showShareToClass: !this.state.showShareToClass,
    });
  }

  setCustomContentTypes(feature, songId, topicName, songName) {
    this.setState({
      curFeature: feature,
      curSongId: songId,
      curSongName: songName,
      curTopicName: topicName,
    });
  }

  customContentModalToggle() {
    this.setState({
      showCreate: !this.state.showCreate,
    });
  }

  async delete(feature, songId, setId) {
    try {
      if (feature === 'Breakdown') {
        this.props.firebase.deleteCustomAnnotations(songId, setId).then(() => {
          this.props.alert.show('The set was successfully deleted.');
        });
      } else if (feature === 'Blanks') {
        this.props.firebase.deleteCustomKeywords(songId, setId).then(() => {
          this.props.alert.show('The set was successfully deleted.');
        });
      } else {
        this.props.firebase.deleteCustomQuestions(songId, setId).then(() => {
          this.props.alert.show('The set was successfully deleted.');
        });
      }
      var songDicts = this.state.songDicts;
      songDicts.deleteSet(feature, songId, setId);
      const arr = songDicts.getSortedSongListByRecent();
      this.setState({ songDicts: songDicts, curSorted: arr });
    } catch (err) {
      this.setState({ error: err });
    }
  }

  renderCards() {
    var cardDivs = this.state.curSorted.map((song, index) => {
      return (
        <SongCard
          key={'card_' + song.songId}
          song={song}
          index={index}
          showCreate={true}
          showDropdown={true}
          showRemove={false}
          deleteModalToggle={this.deleteModalToggle}
          shareModalToggle={this.shareToClassModalToggle}
          customContentModalToggle={this.customContentModalToggle}
          isTeacher={this.props.userToken.claims.teacher}
          setCustomContentTypes={this.setCustomContentTypes}
        ></SongCard>
      );
    });
    return cardDivs;
  }

  // have to account for case when this.state.songs.length == 0
  render() {
    var empty = this.state.songDicts.isEmpty();
    if (this.state.error) {
      throw this.state.error;
    }
    if (!this.state.ready) {
      return <Loading />;
    }
    if (empty)
      return (
        <PageWrapper>
          <SimpleBanner lead={'Your Study Sets'} color={'var(--rsBlue)'}>
            <BackArrow />
          </SimpleBanner>
          <MainWrapper>
            <EmptySets />
          </MainWrapper>
        </PageWrapper>
      );
    return (
      <PageWrapper>
        <SimpleBanner color={'var(--rsBlue)'}>
          <BackArrow />
          <Header>
            <Title>Your Study Sets</Title>
            <Nav style={BlockNav}>
              <NavbarLi className='nav-item'>
                <DropdownMenu
                  width={150}
                  direction={'center'}
                  menuItems={[
                    {
                      desc: 'Most recent',
                      toggleMenuItemAction: () => this.sortByRecent(),
                      requiredRole: 'Both',
                    },
                    {
                      desc: 'Song',
                      toggleMenuItemAction: () => this.sortByTitle(),
                      requiredRole: 'Both',
                    },
                    {
                      desc: 'Unit',
                      toggleMenuItemAction: () => this.sortByUnit(),
                      requiredRole: 'Both',
                    },
                  ]}
                >
                  <TextToggler clickable={true}>
                    <NavbarDropdown>
                      Sort by: {this.state.sortBy}
                    </NavbarDropdown>
                    <img
                      src='/images/icons/utility/caretgrey.png'
                      style={{ marginLeft: '5px' }}
                      alt=''
                    />
                  </TextToggler>
                </DropdownMenu>
              </NavbarLi>
            </Nav>
          </Header>
        </SimpleBanner>
        <MainWrapper>
          <Accordion>{this.renderCards()}</Accordion>
          <DeleteConfirmModal
            title={'Delete Set?'}
            delete={() => {
              this.delete(
                this.state.curFeature,
                this.state.curSongId,
                this.state.curSetId
              );
            }}
            show={this.state.showDelete}
            toggle={() =>
              this.deleteModalToggle(
                this.state.curFeature,
                this.state.curSongId,
                this.state.curSetId
              )
            }
          />
          <ShareToClassModal
            show={this.state.showShareToClass}
            toggle={this.shareToClassShowToggle}
            contentType={this.state.curFeature}
            contentId={this.state.curSetId}
          />
          <CreateAnySetModal
            show={this.state.showCreate}
            toggle={this.customContentModalToggle}
            topicName={this.state.curTopicName}
            songName={this.state.curSongName}
            songId={this.state.curSongId}
          />
        </MainWrapper>
      </PageWrapper>
    );
  }
}

const ContentManager = withAlert()(withFirebase(ContentManagerBase));
export default ContentManager;
