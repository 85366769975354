import React, { Component } from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  outline: 0;
  border: 0;
  background: none;
  font-size: 24px;
  cursor: ${(props) => (props.clickable ? 'pointer' : 'default !important')};
  margin: 0;
`;

class ScrollButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    };
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
  }

  handleMouseEnter() {
    this.setState({ hover: true });
  }

  handleMouseLeave() {
    this.setState({ hover: false });
  }

  render() {
    const stroke = this.state.hover ? 'white' : 'rgba(255,255,255,0.75)';

    return (
      <StyledButton
        onClick={this.props.scroll}
        disabled={!this.props.clickable}
        clickable={this.props.clickable}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <svg
          width='39'
          height='69'
          viewBox='0 0 39 69'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          {this.props.right ? (
            <path
              d='M1.99999 67L34.5 34.5001L2 1.99999'
              stroke={stroke}
              strokeWidth='5'
            />
          ) : (
            <path
              d='M36.5 2.00001L4 34.4999L36.5 67'
              stroke={stroke}
              strokeWidth='5'
            />
          )}
        </svg>
      </StyledButton>
    );
  }
}

export default ScrollButton;
